<template>
  <!-- Progress bar -->
  <ProgressBar :quote="quote" :past-statuses="pastStatuses" />

  <!-- Change Status -->
  <SplitButton
    v-if="nextStatus"
    color="secondary"
    :disabled="disabled"
    :options="STATUS_OPTIONS"
    :text="`Mark ${nextStatus.toLowerCase()}`"
    @click:button="updateStatus"
    @click:option="updateStatus"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Quote, QuotePastStatus } from '@/js/Api/Quote';
import { STATUS_OPTIONS, StatusOption, STATUSES } from '@/js/Presenters/Quote';
import SplitButton from '@/js/Components/SplitButton.vue';
import ProgressBar from './ProgressBar.vue';

interface Props {
  quote: Quote;
  disabled: boolean;
  pastStatuses: QuotePastStatus[];
}

interface Emits {
  (e: 'update:status', status: Quote['status']): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const nextStatus = computed(() => {
  const activeStatusIndex = STATUSES.findIndex((status) => status === props.quote.status);
  if (!STATUSES[activeStatusIndex + 1]) return null;
  return STATUSES[activeStatusIndex + 1];
});

const updateStatus = (option?: StatusOption) => {
  const statusToEmit = option?.value ?? nextStatus.value;
  if (statusToEmit === null) return;
  emit('update:status', statusToEmit);
};
</script>
