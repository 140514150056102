import { z } from 'zod';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { CoverageTypeSchema } from './CoverageType';

export const IntakeHOSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  policy_id: z.number().nullable(),
  quote_group_id: z.number().nullable(),
  is_complete: z.boolean(),
  address_id: z.number().nullable(),
  Effective_date: z.string().nullable(),
  Expiration_date: z.string().nullable(),
  Employers_Name: z.string().nullable(),
  Usage_Type: z.string().nullable(),
  Occupancy: z.string().nullable(),
  Construction_Type: z.string().nullable(),
  Siding: z.string().nullable(),
  Year_Built: z.string().nullable(),
  Num_of_Stories: z.string().nullable(),
  Num_of_Families: z.string().nullable(),
  Total_Living_Area: z.string().nullable(),
  Basement: z.string(),
  Swimming_pool: z.string(),
  Above_Ground: z.string(),
  In_Ground: z.string(),
  With_Fence: z.string(),
  Slide: z.string(),
  Roof_type: z.string().nullable(),
  Roof_Shape: z.string(),
  Heat_Type: z.string().nullable(),
  Oil_Storage_Tank_Location: z.string().nullable(),
  Fire_Hydrant_dst: z.string().nullable(),
  Fire_Station_dst: z.string().nullable(),
  Wiring_Part: z.string().nullable(),
  Wiring_Comp: z.string().nullable(),
  Wiring_Year: z.string().nullable(),
  Plumbing_Part: z.string().nullable(),
  Plumbing_Comp: z.string().nullable(),
  Plumbing_Year: z.string().nullable(),
  Heating_Part: z.string().nullable(),
  Heating_Comp: z.string().nullable(),
  Heating_Year: z.string().nullable(),
  Roofing_Part: z.string().nullable(),
  Roofing_Comp: z.string().nullable(),
  Roofing_Year: z.string().nullable(),
  Exterior_Paint_Year: z.string().nullable(),
  Protection_Class: z.string().nullable(),
  Security: z.string().nullable(),
  Electrical_System: z.string().nullable(),
  HO_Form: z.string().nullable(),
  Dwelling_Limit: z.string().nullable(),
  Other_Structures_Limit: z.string().nullable(),
  Personal_Property_Limit: z.string().nullable(),
  Loss_of_Use: z.string().nullable(),
  Personal_Liability_Each_Occurrence: z.string().nullable(),
  Deductible_All_Peril: z.string().nullable(),
  Deductible_Wind: z.string().nullable(),
  Deductible_Hurricane: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullable(),
  coverage_type: CoverageTypeSchema,
});

export type IntakeHO = z.infer<typeof IntakeHOSchema>;

async function find({ clientId, id }: { clientId: number; id: number }) {
  const response = await axios.get(`/api/clients/${clientId}/intakes/ho/${id}`).catch((error) => {
    Sentry.captureException(error);
  });

  if (!response) return undefined;

  return z
    .object({
      data: IntakeHOSchema,
    })
    .parseAsync(response.data);
}

export default {
  find,
};
