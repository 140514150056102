<template>
  <svg class="svg-icon" :class="`svg-icon--${name}`" :style="{ color }">
    <use :href="symbolId" />
  </svg>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  prefix: {
    type: String,
    default: 'icon',
  },
  color: {
    type: String,
    default: null,
  },
});

const symbolId = computed(() => `#${props.prefix}-${props.name}`);
</script>

<style lang="scss">
.svg-icon {
  color: inherit;
  width: 24px;
  height: 24px;

  &[disabled]:not([disabled='false']) {
    pointer-events: none;
  }
}
</style>
