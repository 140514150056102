<template>
  <div class="intake-tabs">
    <ul class="intake-tabs__tabs">
      <li
        v-for="(tab, i) in tabs"
        :key="i"
        :class="{
          'intake-tabs__tab': true,
          'intake-tabs__tab--disabled': tab.isDeleted,
          'intake-tabs__tab--selected': tab.title === activeTab.title,
        }"
        @click="selectTab(tab)"
      >
        <SvgIcon class="svg-icon--intake-icon" :name="tab.iconName" />
        {{ tab.title }}
        <SvgIcon v-if="tab.isComplete" name="checkmark-circle" />
        <SvgIcon
          v-if="tab.title !== 'General' && !tab.isDeleted && me.permissions['intakes.delete']"
          name="close"
          @click.stop="markAsDeleted(tab)"
        />
        <SvgIcon
          v-if="tab.title !== 'General' && tab.isDeleted && me.permissions['intakes_deleted.restore']"
          name="rotate-left"
          @click.stop="restoreDeleted(tab)"
        />
      </li>
      <DropdownCoverages
        :disabled="!canEdit"
        button-text="Select Coverage"
        :selected-ids="existingIds"
        :disabled-ids="existingIds"
        @click:confirm="updateIntakes"
      />
    </ul>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios';
import { useRoute } from 'vue-router';
import { ref, computed, watch, onMounted } from 'vue';
import IntakeApi, { Intake } from '@/js/Api/Intake';
import Loader from '@/js/Helpers/Loader';
import { me } from '@/js/Helpers/Session';
import { toastSuccess, toastError, confirm } from '@/js/Helpers/Alert';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import DropdownCoverages from '@/js/Common/DropdownCoverages.vue';

interface Props {
  intakes: Intake[];
  clientId: number;
  canEdit: boolean;
  parentId: number;
  parentType: 'Policy' | 'QuoteGroup';
}

export type Tab = {
  title: string;
  intakeId: number | null;
  intakeType: string | null;
  isComplete: boolean;
  isDeleted: boolean;
  abbreviation: string | null;
  iconName: string;
  componentName: string;
};

const props = defineProps<Props>();

const emit = defineEmits(['update:intakes', 'update:active-tab']);

// Tabs
const tabs = computed<Tab[]>(() => [
  {
    title: 'General',
    intakeId: null,
    intakeType: null,
    isComplete: false,
    isDeleted: false,
    abbreviation: null,
    iconName: 'info',
    componentName: 'IntakeGeneral',
  },
  ...props.intakes.map((intake) => ({
    title: intake.coverage_type.name,
    intakeId: intake.intake_body_id,
    intakeType: intake.intake_body_type,
    isComplete: intake.intake_body?.is_complete ?? false,
    isDeleted: intake.intake_body ? false : true,
    abbreviation: intake.coverage_type.abbreviation,
    iconName: intake.coverage_type.icon_name,
    componentName: `Intake${intake.coverage_type.abbreviation}`,
  })),
]);

const route = useRoute();

const activeTab = ref<Tab>(tabs.value.find((t) => t.intakeType === route.query.intake) ?? tabs.value[0]);

onMounted(() => watch(activeTab, () => emit('update:active-tab', activeTab.value), { immediate: true }));

const existingIds = computed(() => props.intakes.map((intake) => intake.coverage_type.id));

const selectTab = (tab: Tab) => {
  if (!tab.isDeleted) activeTab.value = tab;
};

const updateIntakes = (coverageIDs) => {
  if (!coverageIDs.length) return;

  const coverageIDsToAdd = coverageIDs.filter((selectedID) => !existingIds.value.includes(selectedID));

  if (!coverageIDsToAdd.length) return;

  const endpointPart = props.parentType === 'Policy' ? 'policies' : 'quotegroups';

  Loader.start();
  axios
    .post(`/api/clients/${props.clientId}/${endpointPart}/${props.parentId}/coverages`, {
      _method: 'PUT',
      coverage_ids: coverageIDsToAdd,
    })
    .then((response) => {
      emit('update:intakes', response.data.data);
      toastSuccess();
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      Loader.end();
    });
};

const markAsDeleted = async (tab: Tab) => {
  const result = await confirm({
    text: 'Mark this coverage as Deleted?',
    confirmButtonText: 'Confirm',
  });
  if (!result.isConfirmed) return;

  Loader.start();
  IntakeApi.softDelete({ intakeId: tab.intakeId, intakeType: tab.abbreviation })
    .then(() => {
      const intake = props.intakes.find((i) => i.coverage_type.abbreviation === tab.abbreviation);
      intake.intake_body = null;
      tab.isDeleted = true;
      toastSuccess('Coverage marked as Deleted.');
      activeTab.value = tabs.value[0];
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      Loader.end();
    });
};

const restoreDeleted = async (tab: Tab) => {
  const result = await confirm({
    text: 'Restore deleted coverage?',
    confirmButtonText: 'Confirm',
  });
  if (!result.isConfirmed) return;

  Loader.start();
  IntakeApi.restore({ intakeId: tab.intakeId, intakeType: tab.abbreviation })
    .then(({ data: intakeBody }) => {
      const intake = props.intakes.find((i) => i.coverage_type.abbreviation === tab.abbreviation);
      intake.intake_body = intakeBody;
      tab.isDeleted = false;
      toastSuccess('Coverage has been restored.');
      activeTab.value = tab;
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      Loader.end();
    });
};
</script>

<style lang="scss">
.intake-tabs {
  &__tabs {
    display: flex;
    flex-wrap: wrap;
    row-gap: rem(25px);
    column-gap: rem(50px);
    list-style: none;
    padding: rem(10px) 0 0 0;
    margin-bottom: rem(25px);
    border-bottom: 1px solid #cccccc;
  }

  &__tab {
    color: color('gray', 6);
    padding-bottom: rem(17px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 4px solid transparent;
    cursor: pointer;

    &:hover {
      .svg-icon--checkmark-circle {
        display: none;
      }
      .svg-icon--close,
      .svg-icon--rotate-left {
        display: unset;
      }
    }

    .svg-icon--intake-icon {
      margin-right: rem(7px);
      width: rem(26px);
    }

    .svg-icon--checkmark-circle {
      width: 1.1em;
      height: 1.1em;
      margin-left: 0.25em;
    }

    .svg-icon--close,
    .svg-icon--rotate-left {
      display: none;
      width: 1.1em;
      height: 1.1em;
      margin-left: 0.25em;
      cursor: pointer;
    }

    .add-coverage {
      color: #1a497f;
    }

    // Selected
    &--selected {
      color: #000000;
      border-bottom: rem(5px) solid color('primary');
      .svg-icon {
        color: color('primary');
      }
    }

    // Disabled
    &--disabled {
      color: color('gray', 4);
      text-decoration: line-through;
      cursor: default;
      //pointer-events: none;
    }
  }
}
</style>
