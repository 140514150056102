import axios from 'axios';
import { z } from 'zod';
import * as Sentry from '@sentry/vue';
import { CoverageTypeSchema } from './CoverageType';

export const IntakeGenericSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  policy_id: z.number().nullable(),
  quote_group_id: z.number().nullable(),
  is_complete: z.boolean(),
  description: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullable(),
  coverage_type: CoverageTypeSchema,
});

export type IntakeGeneric = z.infer<typeof IntakeGenericSchema>;

async function find({ clientId, id, type }: { clientId: number; id: number; type: string }) {
  const response = await axios.get(`/api/clients/${clientId}/intakes/generic/${id}?type=${type}`).catch((error) => {
    Sentry.captureException(error);
  });

  if (!response) return undefined;

  return z
    .object({
      data: IntakeGenericSchema,
    })
    .parseAsync(response.data);
}

export default {
  find,
};
