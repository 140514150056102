<template>
  <div :class="`alert alert--${type}`">
    <!-- Message (text) -->
    <div class="alert__message">
      <!-- Header -->
      <div class="alert__header">
        <slot name="header" />
      </div>

      <!-- Body -->
      <div class="alert__body">
        <slot name="body" />
      </div>
    </div>

    <!-- Icon -->
    <img class="alert__icon" :src="`/img/icons/${type}-white.svg`" />
  </div>
</template>

<script>
const alertTypes = ['warning'];

export default {
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => alertTypes.includes(value),
    },
  },
};
</script>

<style lang="scss">
.alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: rem(7px) rem(10px);
  margin-bottom: rem(16px);
  border-radius: rem(7px);

  // Types
  &--warning {
    color: white;
    background-color: $error;
  }

  // Header
  &__header {
    margin-bottom: rem(5px);
    font-size: rem(14px);
  }

  // Body
  &__body {
    line-height: 1.2;
    font-size: rem(18px);
  }

  &__icon {
    margin-right: rem(10px);
  }
}
</style>
