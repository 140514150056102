import { z } from 'zod';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { PolicySchema, STATUSES as POLICY_STATUSES } from './Policy';
import { UserSchema } from './User';
import { CertificateDOOSchema } from './CertificateDOO';
import { CertificateHolderSchema } from './CertificateHolder';

export const STATUSES = [...POLICY_STATUSES, 'Active, unverified', 'No policies'] as const;

const PolicySchemaWithPivot = PolicySchema.extend({
  pivot: z.object({
    id: z.number(),
    row: z.number(),
    letter: z.string(),
    template_id: z.number(),
    policy_id: z.number(),
  }),
});

export const CertificateTemplateSchema = z.object({
  id: z.number(),
  type: z.string(),
  name: z.string(),
  approved: z.boolean(),
  reapproval: z.boolean(),
  request_approval: z.boolean(),
  last_requested_on: z.string().nullable(),
  last_requested_by: z.number().nullable(),
  currently_requested_by: z.number().nullable(),
  holder_id: z.number().nullable(),
  doo_id: z.number().nullable(),
  client_id: z.number(),
  created_by: z.number(),
  approved_by: z.number().nullable(),
  approved_on: z.string().nullable(),
  last_approved_on: z.string().nullable(),
  last_approved_by: z.number().nullable(),
  acord: z.any(), // FIX ME
  approval_reset_cause: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),

  // Appends
  status: z.enum(STATUSES),
  verification: z
    .object({
      user: UserSchema,
      date: z.string(),
    })
    .nullable(),

  // Relations
  doo: CertificateDOOSchema.nullish(),
  holder: CertificateHolderSchema.nullish(),
  policy: PolicySchema.nullish(),
  policies: z.array(PolicySchemaWithPivot).nullish(),
  created_by_user: UserSchema.nullish(),
  approved_by_user: UserSchema.nullish(),
  last_approved_by_user: UserSchema.nullish(),
  last_requested_by_user: UserSchema.nullish(),
});

export type CertificateTemplate = z.infer<typeof CertificateTemplateSchema>;

async function find({ clientId, id }: { clientId: number; id: number }) {
  const response = await axios.get(`/api/clients/${clientId}/certificates/templates/${id}`).catch((error) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: CertificateTemplateSchema,
    })
    .parseAsync(response.data);
}

async function create({ clientId, type }: { clientId: number; type: string }) {
  const response = await axios
    .post(`/api/clients/${clientId}/certificates/templates`, {
      type,
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

  return z.object({ data: CertificateTemplateSchema }).parseAsync(response.data);
}

async function update({ clientId, templateId }: { clientId: number; templateId: number }) {
  const response = await axios
    .post(`/api/clients/${clientId}/certificates/templates/${templateId}`, {
      _method: 'PUT',
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

  return z.object({ data: CertificateTemplateSchema }).parseAsync(response.data);
}

async function clone({ clientId, templateId }: { clientId: number; templateId: number }) {
  const response = await axios
    .post(`/api/clients/${clientId}/certificates/templates/clone`, {
      template_id: templateId,
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

  return z.object({ data: CertificateTemplateSchema }).parseAsync(response.data);
}

async function updateAcord({ clientId, id, acord }: { clientId: number; id: number; acord: object }) {
  const response = await axios
    .post(`/api/clients/${clientId}/certificates/templates/${id}/acord`, {
      ...acord,
      _method: 'PUT',
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: CertificateTemplateSchema,
    })
    .parseAsync(response.data);
}

async function updateHolder({ clientId, id, holderId }: { clientId: number; id: number; holderId: number }) {
  const response = await axios
    .post(`/api/clients/${clientId}/certificates/templates/${id}/holder`, {
      holder_id: holderId,
      _method: 'PUT',
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: CertificateTemplateSchema,
    })
    .parseAsync(response.data);
}

async function updateDOO({ clientId, id, dooId }: { clientId: number; id: number; dooId: number }) {
  const response = await axios
    .post(`/api/clients/${clientId}/certificates/templates/${id}/doo`, {
      doo_id: dooId,
      _method: 'PUT',
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: CertificateTemplateSchema,
    })
    .parseAsync(response.data);
}

async function updatePolicy({ clientId, id, policyId }: { clientId: number; id: number; policyId: number }) {
  const response = await axios
    .post(`/api/clients/${clientId}/certificates/templates/${id}/policy`, {
      policy_id: policyId,
      _method: 'PUT',
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: CertificateTemplateSchema,
    })
    .parseAsync(response.data);
}

async function checkPolicies({ clientId, id, policyIds }: { clientId: number; id: number; policyIds: number[] }) {
  const response = await axios
    .post(`/api/clients/${clientId}/certificates/templates/${id}/policies/check`, {
      policy_ids: policyIds,
      _method: 'PUT',
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: CertificateTemplateSchema,
    })
    .parseAsync(response.data);
}

const CertificateTypesSchema = z.tuple([
  z.object({
    name: z.literal('Certificate of insurance'),
    abbreviation: z.literal('COI'),
  }),
  z.object({
    name: z.literal('Certificate of insurance (2 pages)'),
    abbreviation: z.literal('COI2P'),
  }),
  z.object({
    name: z.literal('Evidence of property insurance'),
    abbreviation: z.literal('EOPI'),
  }),
  z.object({
    name: z.literal('Evidence of commercial property insurance'),
    abbreviation: z.literal('EOCPI'),
  }),
  z.object({
    name: z.literal('Insurance binder'),
    abbreviation: z.literal('IB'),
  }),
  z.object({
    name: z.literal('ID card'),
    abbreviation: z.literal('IC'),
  }),
]);

export type CertificateTypes = z.infer<typeof CertificateTypesSchema>;

async function types() {
  const response = await axios.get('/api/certificates/types').catch((error) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: CertificateTypesSchema }).parseAsync(response.data);
}

// COI
const coi = {
  async updatePolicy({
    clientId,
    templateId,
    policyId,
    row,
    letter,
  }: {
    clientId: number;
    templateId: number;
    policyId: number;
    row: number;
    letter: string;
  }) {
    const response = await axios
      .post(`/api/clients/${clientId}/certificates/templates/coi/${templateId}/policies`, {
        row,
        letter,
        policy_id: policyId,
      })
      .catch((error) => {
        Sentry.captureException(error);
        throw error;
      });

    return z
      .object({
        data: CertificateTemplateSchema,
      })
      .parseAsync(response.data);
  },

  async removePolicy({
    clientId,
    templateId,
    policyId,
    row,
    letter,
  }: {
    clientId: number;
    templateId: number;
    policyId: number;
    row: number;
    letter: string;
  }) {
    const response = await axios
      .post(`/api/clients/${clientId}/certificates/templates/coi/${templateId}/policies`, {
        row,
        letter,
        policy_id: policyId,
        _method: 'DELETE',
      })
      .catch((error) => {
        Sentry.captureException(error);
        throw error;
      });

    return z
      .object({
        data: CertificateTemplateSchema,
      })
      .parseAsync(response.data);
  },
};

export default {
  find,
  create,
  update,
  clone,
  updateAcord,
  updateHolder,
  updateDOO,
  updatePolicy,
  checkPolicies,
  types,
  coi,
};
