<template>
  <div class="attachments-panel">
    <template v-if="node">
      <!-- Header -->
      <h4 class="attachments-panel__header">
        {{ node.name }}
        <span v-if="node.policy" class="attachments-panel__header-details">
          {{ getPolicyDetails(node) }}
        </span>
      </h4>

      <!-- Actions -->
      <div class="attachments-panel__actions">
        <div class="attachments-panel__actions-left">
          <SvgIcon v-if="!node.is_root" name="arrow-left" role="button" @click="goBack" />
        </div>
        <div class="attachments-panel__actions-right">
          <template v-if="tree">
            <MoveButton :node="node" :disabled="!canMove" :tree="tree" @move="moveNodes" />
            <div class="separator"></div>
          </template>
          <div
            role="button"
            :class="{ 'attachments-panel__action': true, 'attachments-panel__action--disabled': !canDelete }"
            @click="deleteNodes"
          >
            <SvgIcon name="trashcan" />
            Delete
          </div>
          <div class="separator"></div>
          <div
            role="button"
            :class="{ 'attachments-panel__action': true, 'attachments-panel__action--disabled': !canUpload }"
            @click="fileInputRef.click()"
          >
            <SvgIcon name="file-add" />
            Add Documents
            <input ref="fileInputRef" type="file" style="display: none" multiple @change="uploadFiles" />
          </div>
          <div class="separator"></div>
          <div
            role="button"
            :class="{ 'attachments-panel__action': true, 'attachments-panel__action--disabled': !canCreateFolder }"
            @click="createFolder"
          >
            <SvgIcon name="folder-add" />
            Add Folder
          </div>
        </div>
      </div>

      <!-- Table -->
      <TableListHaystack
        v-show="!isDragging"
        :key="node.tree.length"
        :haystack="node.tree"
        theme="embedded"
        :class="{ 'table-list--dragging': isDragging }"
        grid-template-columns="1.5rem 1.5fr 1fr 1fr 1fr 1fr 4.2rem"
        :is-row-clickable="false"
        :columns="columns"
        initial-sort-order="desc"
        initially-sorted-by="Date uploaded"
        @dragenter="onDragEnter"
      >
        <!-- Checkbox -->
        <template #checkbox="{ item }">
          <InputCheckbox v-model="checkboxesMap[item.path]" />
        </template>

        <!-- Document name -->
        <template #document="{ item }">
          <a @click="openNode(item)">
            <SvgIcon v-if="item.type === 'folder'" name="folder" />
            {{ item.name }}
          </a>
        </template>

        <!-- Actions -->
        <template #actions="{ item }">
          <SvgIcon v-if="item.type === 'file'" name="file-download" @click="downloadFile(item)" />
          <div v-if="item.type === 'file'" class="separator"></div>
          <SvgIcon
            name="pencil"
            :class="{ 'svg-icon--disabled': !node.permissions.rename }"
            @click="renameNode(item)"
          />
        </template>
      </TableListHaystack>

      <!-- Footer -->
      <div v-show="!isDragging" class="attachments-panel__footer">
        <p class="attachments-panel__footer-text">To upload a screenshot from clipboard paste it here</p>
        <FormGroup type="text" placeholder="Paste from clipboard" @paste="uploadFiles" />
      </div>

      <!-- Dropzone -->
      <div
        v-show="isDragging"
        class="attachments-panel__dropzone"
        @dragleave="isDragging = false"
        @drop.prevent.stop="onFilesDrop"
        @dragover.prevent.stop
      >
        Drop files here to upload them
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import StorageApi, { TreeNode } from '@/js/Api/Storage';
import Loader from '@/js/Helpers/Loader';
import { prompt, toast, toastError } from '@/js/Helpers/Alert';
import { processAttachments } from '@/js/Helpers/Attachment';
import { toDateString } from '@/js/Helpers';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import TableListHaystack from '@/js/Components/TableListHaystack.vue';
import InputCheckbox from '@/js/Common/Form/Input/Checkbox.vue';
import FormGroup from '@/js/Common/Form/FormGroup/FormGroup.vue';
import MoveButton from '@/js/Components/Storage/MoveButton.vue';

const props = defineProps<{
  tree: TreeNode[] | null;
  node: TreeNode | null;
  policy?: TreeNode['policy'];
}>();

const emit = defineEmits(['click:open', 'click:back', 'add:nodes', 'rename:node', 'delete:nodes', 'move:nodes']);

const checkboxesMap = ref<{ [path: string]: boolean }>({});

const fileInputRef = ref<HTMLInputElement | null>(null);

// At least 1 checked
const atLeastOneChecked = computed(
  () => Object.keys(checkboxesMap.value).filter((path) => !!checkboxesMap.value[path]).length > 0,
);

// Permissions
const canUpload = computed(() => (props.node ? props.node.permissions.upload : false));
const canCreateFolder = computed(() => (props.node ? props.node.permissions.create_folder : false));
const canMove = computed(() => atLeastOneChecked.value && (props.node ? props.node.permissions.move : false));
const canDelete = computed(() => atLeastOneChecked.value && (props.node ? props.node.permissions.delete : false));

// Columns
const columns = [
  { slotName: 'checkbox', tdClass: 'table-list__td--checkbox' },
  {
    name: 'Document',
    sortBy: (n: TreeNode) => n.name,
    slotName: 'document',
    tdClass: 'table-list__td--document',
  },
  {
    name: 'Date uploaded',
    sortBy: (n: TreeNode) => n.created_at,
    get: (n: TreeNode) => toDateString(n.created_at),
    tdClass: 'table-list__td--grey',
  },
  {
    name: 'Policy number',
    get: () => props.policy?.number,
    tdClass: 'table-list__td--grey',
  },
  {
    name: 'Effective date',
    get: () => toDateString(props.policy?.effective_date),
    tdClass: 'table-list__td--grey',
    sortBy: (n: TreeNode) => n?.policy?.effective_date,
  },
  {
    name: 'Expiration date',
    get: () => toDateString(props.policy?.expiration_date),
    tdClass: 'table-list__td--grey',
    sortBy: (n: TreeNode) => n?.policy?.expiration_date,
  },
  { slotName: 'actions', tdClass: 'table-list__td--actions' },
];

const getPolicyDetails = (node: TreeNode) => {
  if (!node.policy) return null;
  const policyDetails = [
    node.policy.number,
    [toDateString(node.policy.effective_date), toDateString(node.policy.expiration_date)]
      .filter((el) => el)
      .join(' - '),
  ]
    .filter((el) => el)
    .join(', ');
  return policyDetails ? `Policy: ${policyDetails}` : null;
};

// Upload files
const uploadFiles = (event: Event) => {
  event.target.blur();
  processAttachments(event)
    .then((files) => {
      Loader.start();
      StorageApi.upload({ path: props.node.path, files })
        .then((data) => {
          emit('add:nodes', data.data);
        })
        .catch((e) => {
          Loader.end();
          if (e?.response?.data?.message) toastError(e.response.data.message);
        });
    })
    .catch((error: Error) => {
      toast({ title: error.message, icon: 'warning' });
    });
};

// Drag and dropping
const isDragging = ref<boolean>(false);
const onDragEnter = () => {
  if (!props.node) return;
  if (props.node.permissions.upload) isDragging.value = true;
};
const onFilesDrop = (e: DragEvent) => {
  isDragging.value = false;
  uploadFiles(e);
};

// Go back
const goBack = () => {
  checkboxesMap.value = {};
  emit('click:back');
};

// Open
const openNode = (node: TreeNode) => {
  checkboxesMap.value = {};
  emit('click:open', node);
};

// Download file
const downloadFile = (node: TreeNode) => {
  Loader.start();
  StorageApi.download({ path: node.path }).finally(() => {
    Loader.end();
  });
};

// Delete
const deleteNodes = async () => {
  const promptResult = await prompt({
    icon: 'warning',
    confirmButtonText: 'Delete',
    text: 'Type "delete" to confirm',
    inputValidator: (value: string) => (value !== 'delete' ? 'Invalid input' : null),
  });
  if (!promptResult.isConfirmed || promptResult.value !== 'delete') return;

  const paths = Object.keys(checkboxesMap.value).filter((path) => checkboxesMap.value[path]);

  Loader.start();
  StorageApi.delete({ paths })
    .then((data) => {
      checkboxesMap.value = {};
      if (data === '') emit('delete:nodes', paths);
    })
    .catch(() => {
      Loader.end();
    });
};

// Create folder
const createFolder = async () => {
  const promptResult = await prompt({
    icon: 'question',
    confirmButtonText: 'Confirm',
    text: 'Type in a name of a folder',
    inputValidator: (value: string) => (!value ? 'You need to write something!' : null),
  });
  if (!promptResult.isConfirmed) return;

  Loader.start();
  StorageApi.createDirectory({ path: props.node.path, name: promptResult.value })
    .then((data) => {
      if (data) emit('add:nodes', [data.data]);
    })
    .catch(() => {
      Loader.end();
    });
};

// Rename
const renameNode = async (node: TreeNode) => {
  const nameArray = node.name.split('.');
  const fileExt = nameArray.pop();
  const nameWithoutFileExt = nameArray.join('.');
  const inputValue = node.type === 'file' ? nameWithoutFileExt : node.name;

  const promptResult = await prompt({
    icon: 'question',
    confirmButtonText: 'Confirm',
    text: 'Type in a new name',
    inputValue,
    inputValidator: (value: string) => (!value ? 'You need to write something!' : null),
  });
  if (!promptResult.isConfirmed) return;

  const finalName = node.type === 'file' ? `${promptResult.value}.${fileExt}` : promptResult.value;

  Loader.start();
  StorageApi.rename({ path: node.path, name: finalName })
    .then(() => {
      emit('rename:node');
    })
    .catch((e) => {
      Loader.end();
      if (e?.response?.data?.message) toastError(e.response.data.message);
    });
};

// Move
const moveNodes = async (destination: string) => {
  const promptResult = await prompt({
    icon: 'warning',
    confirmButtonText: 'Move',
    text: 'Type "move" to confirm',
    inputValidator: (value: string) => (value !== 'move' ? 'Invalid input' : null),
  });
  if (!promptResult.isConfirmed || promptResult.value !== 'move') return;

  const pathsMap = Object.keys(checkboxesMap.value)
    .filter((path) => checkboxesMap.value[path])
    .reduce((result: { [p: string]: string }, path) => {
      const name = path.split('/').pop();
      const newPath = `${destination}/${name}`;
      result[path] = newPath;
      return result;
    }, {});

  Loader.start();
  StorageApi.move({ pathsMap })
    .then(() => {
      checkboxesMap.value = {};
      emit('move:nodes');
    })
    .catch(() => {
      Loader.end();
    });
};
</script>

<style lang="scss">
$padding-x: rem(39px);

.attachments-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: rem(12px);
  background-color: white;
  box-shadow: rem(0px) rem(1px) rem(6px) #00000014;
  padding: rem(29px) 0;

  &__header {
    font-size: rem(19px);
    padding: 0 #{$padding-x};
    &-details {
      color: #999999;
      font-size: rem(16px);
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: rem(23px);
    padding: 0 #{$padding-x};
    margin-bottom: rem(22px);
    .svg-icon--arrow-left {
      cursor: pointer;
    }
    &-left {
      display: flex;
      align-items: center;
    }
    &-right {
      display: flex;
      .separator {
        content: '';
        display: block;
        width: rem(1px);
        height: 100%;
        background-color: #ccc;
        margin: 0 rem(14px);
      }
    }
  }

  &__action {
    display: flex;
    align-items: center;
    font-size: rem(14px);
    cursor: pointer;
    color: color('primary');
    transition: 0.1s color;
    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &:hover {
      color: black;
    }
    .svg-icon {
      margin-right: rem(6px);
    }
  }

  // TableList
  .table-list {
    padding: 0 #{$padding-x};
    flex: 1;
    &__thead {
      background-color: white;
    }
    &__tr {
      border-color: color('gray', 2);
    }
    &__td {
      font-size: rem(17px);
    }
    &__td--checkbox .checkbox {
      color: #666;
    }
    &__td--document {
      a {
        color: black;
        display: flex;
        align-items: center;
      }
      .svg-icon {
        width: rem(24px);
        min-width: rem(24px);
        color: color('primary');
        margin-right: rem(10px);
      }
    }
    &__td--grey {
      color: color('gray');
    }
    &__td--actions {
      justify-content: flex-end;
      .svg-icon {
        color: color('primary');
        cursor: pointer;
        transition: 0.1s color;
        &:hover {
          color: black;
        }
        &--disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
      .separator {
        content: '';
        display: block;
        width: rem(1px);
        height: 60%;
        background-color: #ccc;
        margin: auto rem(10px);
      }
    }
  }

  // Footer
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem(34px) #{$padding-x * 2} rem(43px) #{$padding-x * 2};
    border-top: rem(1px) solid #ccc;
    .form-group {
      width: 100%;
      margin-bottom: 0;
    }
  }

  // Dropzone
  &__dropzone {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f5f7;
    color: #636f88;
    margin: 0 #{$padding-x};
  }
}
</style>
