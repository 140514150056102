import { z } from 'zod';
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';
import { queryBuilderToQueryString } from '@/js/Helpers';
import { UserSchema } from '@/js/Api/User';

export const QuoteTeamSchema = z.object({
  id: z.number(),
  name: z.string(),
  last_assigned_to: z.number().nullable(),
  created_at: z.string(),
  updated_at: z.string(),

  // Appends
  model_type: z.literal('Quote team'),

  // Relations
  members: z.array(UserSchema).optional(),
});

export type QuoteTeam = z.infer<typeof QuoteTeamSchema>;

// ***************************************************
// Index
// ***************************************************

type IndexArgs = {
  queryBuilder: {
    page: number;
    sort?: {
      order: 'desc' | 'asc';
      column: 'name' | 'created_at';
    };
    includes?: 'members'[];
    filters?: {
      name?: string;
    };
  };
};

async function index({ queryBuilder }: IndexArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/quote-teams?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  // FIXME:
  return response;
}

// ***************************************************
// All
// ***************************************************

type AllArgs = {
  queryBuilder: {
    sort?: {
      order: 'desc' | 'asc';
      column: 'name' | 'created_at';
    };
    includes?: 'members'[];
    filters?: {
      id?: string[];
      name?: string;
    };
  };
};

async function all({ queryBuilder }: AllArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/quote-teams/all?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: z.array(QuoteTeamSchema),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Create
// ***************************************************

type CreateArgs = {
  form: {
    name: QuoteTeam['name'];
    member_ids: number[];
  };
};

async function create({ form }: CreateArgs) {
  const response = await axios.post('/api/quote-teams', form).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: QuoteTeamSchema,
    })
    .parseAsync(response.data);
}

// ***************************************************
// Update
// ***************************************************

type UpdateArgs = {
  id: number;
  form: {
    name: QuoteTeam['name'];
    member_ids: number[];
  };
};

async function update({ id, form }: UpdateArgs) {
  const response = await axios.put(`/api/quote-teams/${id}`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: QuoteTeamSchema,
    })
    .parseAsync(response.data);
}

export default {
  index,
  all,
  create,
  update,
};
