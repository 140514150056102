<template>
  <label
    :class="{
      checkbox: true,
      [`checkbox--${type}`]: true,
      'checkbox--disabled': disabled,
    }"
  >
    <div class="checkbox__container">
      <!-- Input -->
      <input
        v-bind="attrs"
        ref="checkbox"
        type="checkbox"
        :name="attrs.name"
        class="checkbox__input"
        :value="1"
        :checked="modelValue"
        :disabled="disabled"
        @click="onClick"
      />

      <!-- Checkmark -->
      <div class="checkbox__checkmark-container">
        <svg class="checkbox__checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path fill="none" stroke="currentColor" stroke-width="4" d="M1.73 12.91l6.37 6.37L22.79 4.59" />
        </svg>
      </div>
    </div>

    <!-- Label -->
    <div class="checkbox__label">
      <slot name="label">
        {{ label }}
      </slot>
    </div>
  </label>
</template>

<script setup>
import { useAttrs } from 'vue';
import { toastError } from '@/js/Helpers/Alert';

const props = defineProps({
  modelValue: {
    type: null,
    default: null,
  },
  uncheckedValue: {
    type: null,
    default: null,
  },
  uncheckedUndefined: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  autosave: {
    type: Boolean,
    default: true,
  },
  autosaveMethod: {
    type: String,
    default: null,
  },
  validator: {
    type: Function,
    default: null,
  },
  type: {
    type: String,
    default: 'square',
    validator: (type) => ['circle', 'square'].includes(type),
  },
});

const emit = defineEmits(['update:model-value', 'input', 'change', 'after-autosave']);

const attrs = useAttrs();

const onClick = async (e) => {
  // Validate
  if (props.validator) {
    const result = await props.validator(e);
    if (result === false) {
      e.preventDefault();
      return false;
    }
  }

  // Value
  const uncheckedValue = props.uncheckedUndefined ? undefined : props.uncheckedValue;
  const value = e.target.checked ? true : uncheckedValue;

  // Events
  emit('update:model-value', value);
  emit('input', e);
  emit('change', e);

  if (!props.autosaveMethod || !props.autosave) return;

  // Autosave
  props
    .autosaveMethod(attrs.name, value)
    .then((response) => {
      emit('after-autosave', response, e.target);
    })

    // Errors
    .catch((error) => {
      const { response } = error;
      if (!response) {
        throw error;
      } else if (response.status === 422) {
        toastError(response.data.errors[attrs.name][0]);
      } else {
        toastError('An error occurred while saving data.');
      }
    });
};
</script>

<style lang="scss">
.checkbox {
  display: flex;
  align-items: center;
  font-size: rem(16px);

  // Cursor
  cursor: pointer;

  &--disabled {
    cursor: default;
    pointer-events: none;
    color: color('gray', 4);
  }

  // Left
  &__container {
    display: flex;
    position: relative;
    width: 1.125em;
    height: 1.125em;
    min-width: 1.125em;
    min-height: 1.125em;
  }

  // Right
  &__label {
    margin-left: rem(10px);
    line-height: 1;
  }

  // Input
  &__input {
    opacity: 0;
    margin: 0;
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  // Box
  &__checkmark-container {
    display: inline-grid;
    border-radius: 0.15em;
    border: 0.15em solid currentcolor;
    width: 100%;
    height: 100%;
  }

  // Show/hide checkmark
  &__checkmark {
    width: 100%;
    height: 100%;
    margin: auto;
    transform: scale(0);
    transform-origin: bottom left;
    transition: transform 0.1s ease-in 25ms;
  }

  &__input:checked + &__checkmark-container &__checkmark {
    transform: scale(1);
  }

  // Colors
  &__input:checked + &__checkmark-container {
    border-color: color('primary');
    background-color: color('primary');
  }

  &__input:checked + &__checkmark-container &__checkmark {
    color: white;
  }

  // Box shadow on focus
  &__input:focus + &__checkmark-container {
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }

  // Square
  &--circle &__checkmark-container {
    border-radius: 50%;
  }
}
</style>
