import { ref } from 'vue';
import { defineStore } from 'pinia';
import swr from './swr';
import { User } from '@/js/Api/User';
import CertificateTemplateApi from '@/js/Api/CertificateTemplate';
import SettingsApi from '@/js/Api/Settings';
import CarrierApi from '@/js/Api/Carrier';
import AgencyApi from '@/js/Api/Agency';
import CoverageTypeApi from '@/js/Api/CoverageType';

export default defineStore('global', () => {
  const processingPromise = null;

  // FIX ME
  const me = window.me as User;

  const certificateTypes = ref(null);
  const coverageTypes = ref(null);
  const generalSettings = ref(null);
  const carriers = ref(null);
  const agencies = ref(null);

  function swrCertificateTypes() {
    return swr({
      ref: certificateTypes,
      invalidation: 1,
      fetcher: CertificateTemplateApi.types,
    });
  }

  function swrCoverageTypes() {
    return swr({
      ref: coverageTypes,
      invalidation: 1,
      fetcher: CoverageTypeApi.all,
    });
  }

  function swrGeneralSettings() {
    return swr({
      ref: generalSettings,
      invalidation: 1,
      fetcher: SettingsApi.generalSettings,
    });
  }

  function swrCarriers() {
    return swr({
      ref: carriers,
      invalidation: 1,
      fetcher: CarrierApi.all,
    });
  }

  function swrAgencies() {
    return swr({
      ref: agencies,
      invalidation: 1,
      fetcher: AgencyApi.all,
    });
  }

  return {
    me,
    processingPromise,
    carriers,
    agencies,
    coverageTypes,
    swrCertificateTypes,
    swrCoverageTypes,
    swrGeneralSettings,
    swrCarriers,
    swrAgencies,
  };
});
