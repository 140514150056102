<template>
  <Modal ref="ModalRef" :is-loading="loading" :close-on-button-click="false" @button:click="FormContainerRef.submit()">
    <!-- Title -->
    <template #title> Insert reinstatement </template>

    <!-- Body -->
    <FormContainer ref="FormContainerRef" validate not-on-parent-validate @submit="submit">
      <!-- Cancellation information -->
      <h3>
        {{ new Date(cancellation.date) < new Date() ? 'Cancelled' : 'Cancellation pending' }}
        on
        {{ toDateString(cancellation.date) }}
      </h3>
      <div v-if="cancellation.carrier_underwrighting_reason">
        <strong>Carrier/underwriting reason:</strong>
        {{ cancellation.carrier_underwrighting_reason }}
      </div>
      <div v-if="cancellation.amount">
        <strong>Payment amount:</strong>
        {{ formatCents(cancellation.amount) }}
      </div>
      <div>
        <strong>Cancellation reasons:</strong>
        <ul>
          <li v-for="reason in cancellation.reasons.split(',')" :key="reason">{{ reason }}</li>
        </ul>
      </div>

      <!-- Reinstatement date -->
      <FormGroup
        v-model="form.reinstatement"
        type="date"
        label-text="Reinstatement date"
        :today-icon="true"
        :max="new Date().addYears(100).toDateInput()"
        :min="new Date().subtractYears(100).toDateInput()"
        required
      />
    </FormContainer>
  </Modal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { formatCents, toDateString } from '@/js/Helpers';
import { toastSuccess, toastError } from '@/js/Helpers/Alert';
import PolicyCancellationApi, { PolicyCancellation, PolicyCancellationForm } from '@/js/Api/PolicyCancellation';
import Modal from '@/js/Common/Modal.vue';
import FormGroup from '@/js/Common/Form/FormGroup/FormGroup.vue';
import FormContainer from '@/js/Common/Form/FormContainer.vue';

interface Props {
  cancellation: PolicyCancellation;
}

const props = defineProps<Props>();

const emit = defineEmits(['update:cancellation']);

const ModalRef = ref<InstanceType<typeof Modal> | null>(null);
const FormContainerRef = ref<InstanceType<typeof FormContainer> | null>(null);
const form = ref<PolicyCancellationForm>({});
const loading = ref<boolean>(false);

const submit = () => {
  loading.value = true;
  PolicyCancellationApi.update({
    id: props.cancellation.id,
    policyId: props.cancellation.policy_id,
    clientId: props.cancellation.client_id,
    form: form.value,
  })
    .then(({ data: cancellation }) => {
      emit('update:cancellation', cancellation);
      toastSuccess('Policy has been reinstated.');
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      loading.value = false;
    });
};

const show = () => ModalRef.value.show();

defineExpose({
  show,
});
</script>

<style lang="scss" scoped>
h3 {
  font-size: rem(25px);
  color: $error;
  font-weight: bold;
  margin-bottom: rem(15px);
}
ul {
  margin-bottom: rem(17.5px);
  padding-left: rem(50px);
}
.submit-group {
  margin-top: rem(20px);
}
</style>
