<template>
  <!-- Contact fields -->
  <FormGroup
    v-model.uppercase="contactLocal.first_name"
    type="text"
    name="first_name"
    label-text="First name"
    :disabled="disabled"
    @update:model-value="emitUpdateDebounced"
  />
  <FormGroup
    v-model.uppercase="contactLocal.last_name"
    type="text"
    name="last_name"
    label-text="Last name"
    :disabled="disabled"
    @update:model-value="emitUpdateDebounced"
  />
  <FormGroup
    v-model.uppercase="contactLocal.title"
    type="text"
    name="title"
    label-text="Title"
    :disabled="disabled"
    @update:model-value="emitUpdateDebounced"
  />

  <!-- Group (phones) -->
  <ContactsPhones
    :contact-id="contact.id"
    :is-contact-primary="contact.is_primary"
    :disabled="disabled"
    :phones="contact.phones"
    @update:phones="
      (phones) => {
        contactLocal.phones = phones;
        emitUpdate();
      }
    "
  />

  <!-- Group (emails) -->
  <ContactsEmails
    :contact-id="contact.id"
    :is-contact-primary="contact.is_primary"
    :disabled="disabled"
    :emails="contact.emails"
    @update:emails="
      (emails) => {
        contactLocal.emails = emails;
        emitUpdate();
      }
    "
  />
</template>

<script>
import { watch, ref } from 'vue';
import debounce from 'debounce';

import FormGroup from '@/js/Common/Form/FormGroup/FormGroup.vue';

import ContactsEmails from './ContactsEmails.vue';
import ContactsPhones from './ContactsPhones.vue';

export default {
  components: {
    FormGroup,
    ContactsEmails,
    ContactsPhones,
  },

  props: {
    contact: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['update:contact'],

  setup(props, { emit }) {
    // Contact (local copy)
    const contactLocal = ref({ ...props.contact });

    // Override local copy when props change
    watch(
      () => props.contact,
      () => (contactLocal.value = { ...props.contact })
    );

    // Emit event
    const emitUpdate = () => emit('update:contact', contactLocal.value),
      emitUpdateDebounced = debounce(emitUpdate, 1000);

    return {
      contactLocal,
      emitUpdate,
      emitUpdateDebounced,
    };
  },
};
</script>
