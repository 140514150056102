<template>
  <div class="quote-group-list">
    <div
      v-for="quoteGroup in quoteGroupsComputed"
      :key="quoteGroup.id"
      class="quote-group"
      :class="{
        'quote-group': true,
        'quote-group--deleted': quoteGroup.deleted_at !== null,
      }"
      role="button"
      @click="emit('quote-group:click', quoteGroup)"
    >
      <div
        v-for="(row, i) in quoteGroup.rows"
        :key="i"
        class="row"
        :class="{ closed: row.mostAdvancedQuoteStatus === 'Not Sold' || row.mostAdvancedQuoteStatus === 'Declined' }"
      >
        <div class="left">
          <div class="icons">
            <div v-for="(iconUrl, j) in row.iconUrls" :key="j" class="icon">
              <img :src="getIconUrl(iconUrl)" />
            </div>
          </div>
          <div class="details">
            <div class="major">
              <span>{{ row.titleLeft }}</span>
              <span v-if="row.titleRight">{{ row.titleRight }}</span>
            </div>
            <div class="minor">
              <div>
                {{ row.quoteCount ? row.quoteCount + ' quote' + (row.quoteCount > 1 ? 's' : '') : 'No quotes' }}
              </div>
              <template v-if="row.quoteCount && (row.starred || row.quotePrice)">
                <div>-</div>
                <i class="fas" :class="[row.starred ? 'fa-star starred' : 'fa-dollar-sign cheapest']" />
                <div>{{ row.carrier }}</div>
              </template>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="details" :class="{ blue: row.mostAdvancedQuoteStatus === 'To Present' }">
            <div class="major">
              {{ row.mostAdvancedQuoteStatus ?? 'Lead created' }}
            </div>
            <div v-if="row.mostAdvancedQuoteEffectiveDate" class="minor">
              Effective
              {{ row.mostAdvancedQuoteEffectiveDate ? toDateString(row.mostAdvancedQuoteEffectiveDate) : '/' }}
            </div>
          </div>
          <div class="price">{{ row.quotePrice ? formatCents(row.quotePrice) : '$TBA' }}</div>
        </div>
      </div>

      <!-- Delete indicator -->
      <div v-if="quoteGroup.deleted_at" class="quote-group__delete-indicator">Deleted</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { toDateString, formatCents } from '@/js/Helpers';
import { Quote } from '@/js/Api/Quote';
import { QuoteGroup } from '@/js/Api/QuoteGroup';
import { QuoteGroupIntake } from '@/js/Api/QuoteGroupIntake';

interface Props {
  quoteGroups: QuoteGroup[];
}

const props = defineProps<Props>();

const emit = defineEmits(['quote-group:click']);

const iconColorMap = { car: 'light', house: 'orange', paragliding: 'green', badge: 'light' };
const getIconUrl = (iconUrl: string) => {
  const icon = iconUrl.split('/img/icons/')[1].split('.')[0];
  if (!iconColorMap[icon]) return iconUrl;
  return `/img/icons/${icon}-${iconColorMap[icon]}.svg`;
};

const quoteStatuses = [
  {
    id: 1,
    dead: true,
    name: 'Not Sold',
  },
  {
    id: 2,
    dead: true,
    name: 'Declined',
  },
  {
    id: 3,
    initial: true,
    name: 'To Submit',
  },
  {
    id: 4,
    name: 'Submitted',
  },
  {
    id: 5,
    name: 'Received',
  },
  {
    id: 5,
    name: 'Presented',
  },
  {
    id: 6,
    name: 'Sold',
  },
];

const quoteGroupsComputed = computed(() => {
  const quoteGroups = props.quoteGroups;
  const quoteGroupsFormatted = [];

  const quoteStatusMap = {};
  quoteStatuses.forEach((status) => {
    quoteStatusMap[status.name] = status.id;
  });

  // Helper vars
  let map = new Map();
  let row = null;
  let quoteMap = new Map<number, Quote>();
  let intakeMap = new Map<number, QuoteGroupIntake>();
  let intakesTemp = null;
  let quotesTemp = null;
  let lastCommaIndex = null;
  let quoteGroupFormatted = null;

  // Format each quote group (complexity n^3)
  quoteGroups.forEach((quoteGroup) => {
    // Reset these helper vars on every quoteGroup
    map = new Map();
    intakeMap = new Map();
    quoteMap = new Map();
    quoteGroupFormatted = {
      id: quoteGroup.id,
      deleted_at: quoteGroup.deleted_at,
      rows: [],
    };

    quoteGroup.intakes.forEach((intake) => {
      if (intake.intake_body === null && quoteGroup.deleted_at === null) return;

      // Build intakeMap | e.g. {intakeID} => { ... }
      intakeMap.set(intake.id, intake);

      intake.quotes.forEach((quote) => {
        // Build quoteMap | e.g. {quoteID} => { ... }
        quoteMap.set(quote.id, quote);

        // Build map | e.g. {quoteID} => [{intakeID1}, {intakeID2}]
        if (!map.has(quote.id)) map.set(quote.id, '' + intake.id);
        else map.set(quote.id, map.get(quote.id) + ',' + intake.id);
      });

      // If intake doesn't have any quotes it goes straight into final result
      if (!intake.quotes.length) {
        row = {};
        row.titleLeft = intake.coverage_type.abbreviation;
        row.iconUrls = [intake.coverage_type.icon_url];
        row.quoteCount = 0;
        quoteGroupFormatted.rows.push(row);
      }
    });

    // Merge keys that have the same value | e.g. 65 => '87,43', 98 => '87,43' into '65,98' => '87,43'
    map.forEach((intakeIDs1, quoteID1) => {
      map.forEach((intakeIDs2, quoteID2) => {
        if (quoteID1 === quoteID2 || intakeIDs1 !== intakeIDs2) return;
        if (!map.has(intakeIDs1)) map.set(intakeIDs1, quoteID1 + ',' + quoteID2);
        else map.set(intakeIDs1, map.get(intakeIDs1) + ',' + quoteID2);
        map.delete(quoteID1);
        map.delete(quoteID2);
      });
    });

    // Flip remaining (unique value) keys
    map.forEach((value, key) => {
      if (Number.isInteger(key)) {
        map.set(value, '' + key);
        map.delete(key, value);
      }
    });

    // Build final row e.g. { iconUrls: [], titleLeft: '...', etc. }
    map.forEach((quoteIDs, intakeIDs) => {
      intakesTemp = intakeIDs.split(',').map((intakeID) => intakeMap.get(Number(intakeID)));
      quotesTemp = quoteIDs.split(',').map((quoteID) => quoteMap.get(Number(quoteID)));
      row = {};

      row.titleLeft = intakesTemp.map((intake) => intake.coverage_type.abbreviation).join(', ');
      row.iconUrls = intakesTemp.map((intake) => intake.coverage_type.icon_url);
      row.quoteCount = quotesTemp.length;

      const mostAdvancedQuote = quotesTemp.reduce(
        (prev, curr) => (quoteStatusMap[prev.status] > quoteStatusMap[curr.status] ? prev : curr),
        quotesTemp[0],
      );
      row.mostAdvancedQuoteStatus = mostAdvancedQuote.status;
      row.mostAdvancedQuoteEffectiveDate = mostAdvancedQuote.effective_date;

      const starredQuote = quotesTemp.find((quote) => quote.is_preferred);
      if (starredQuote) {
        row.quotePrice = starredQuote.price;
        row.carrier = starredQuote.carrier_id ? starredQuote.carrier.name : '';
        row.starred = true;
      } else {
        const cheapestQuote = quotesTemp.reduce((prev, curr) => {
          if (prev.price === null && curr.price === null) return prev;
          if (prev.price === null) return curr;
          if (curr.price === null) return prev;
          if (prev.price <= curr.price) return prev;
          return curr;
        }, quotesTemp[0]);
        row.quotePrice = cheapestQuote.price;
        row.carrier = cheapestQuote.carrier_id ? cheapestQuote.carrier.name : '';
        row.starred = false;
      }
      quoteGroupFormatted.rows.push(row);
    });

    // Push this group
    quoteGroupsFormatted.push(quoteGroupFormatted);
  });

  return quoteGroupsFormatted;
});
</script>

<style lang="scss" scoped>
// Panel body
.quote-group-list {
  padding: rem(24px) rem(20px);

  .quote-group {
    cursor: pointer;
    transition: background-color 0.2s;
    padding: 0 rem(5px);
    position: relative;

    &--deleted {
      opacity: 0.5;
    }

    // Highlight
    background-color: #e5e9ed;
    padding: rem(5px);
    margin-bottom: rem(5px);
    border-radius: rem(6px);
    &:hover {
      background-color: color('primary', 1);
    }

    .row {
      margin-bottom: rem(10px);
      padding-left: rem(11px);
      padding-right: rem(17px);
      border: rem(1px) solid #5f83ac;
      border-radius: rem(6px);
      background-color: white;
      height: rem(46px);
      display: grid;
      grid-template-columns: 3fr 2fr;

      &:last-child {
        margin-bottom: 0;
      }

      &.closed {
        opacity: 0.7;
        background-color: #f4f4f4;
        border: none;
      }

      .details {
        font-size: rem(12px);
        line-height: rem(14px);
        .minor {
          color: color('gray');
          display: flex;
          align-items: center;
          > * {
            margin-right: rem(2.5px);
          }
        }
      }

      .left {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .icons {
          margin-right: rem(10px);
          display: flex;
          align-items: center;
          .icon {
            display: flex;
            align-items: center;
            padding: 0 rem(6.5px);
            border-right: rem(1px) solid color('gray', 2);
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
              border-right: none;
            }
            img {
              font-size: rem(24px);
            }
          }
        }
        .details {
          .major span:first-child {
            padding-right: rem(4px);
          }
          .major span:last-child:not(:only-child) {
            padding-left: rem(4px);
            border-left: rem(1px) solid color('gray', 2);
          }
          .minor {
            > .starred {
              transform: translateY(rem(2px));
              margin-left: rem(4px);
              margin-right: rem(4px);
              font-size: rem(9px);
              color: #fabb0e;
            }
            > .cheapest {
              font-size: rem(8px);
              width: rem(10px);
              height: rem(10px);
              background: #c3e68b;
              color: white;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .right {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr 1fr;
        .details {
          &.blue {
            .major,
            .minor {
              color: color('primary');
            }
          }
          .major,
          .minor {
            display: block;
            text-align: right;
          }
        }
        .price {
          font-size: rem(18px);
          line-height: rem(23px);
          font-weight: bold;
          min-width: rem(80px);
          text-align: right;
        }
      }
    }

    // Delete indicator
    &__delete-indicator {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      color: white;
      font-weight: bold;
      background-color: red;
      width: 7em;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
