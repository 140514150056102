<template>
  <IMaskComponent
    :value="localValue"
    mask="num%"
    type="text"
    :lazy="false"
    :eager="true"
    :blocks="{
      num: {
        mask: Number,
        scale: 3,
        radix: '.',
        mapToRadix: ['.'],
      },
    }"
    @blur="handleBlur"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import numeral from 'numeral';
import { IMaskComponent } from 'vue-imask';

type PercentageInputProps = {
  modelValue: string | number | null;
};

const props = defineProps<PercentageInputProps>();

const emit = defineEmits(['update:modelValue']);

const localValue = computed(() => {
  return numeral(props.modelValue ?? 0)
    .divide(100)
    .format('0.[000]%');
});

const handleBlur = (event: FocusEvent) => {
  const inputElement = event.target as HTMLInputElement;
  const parsedValue = numeral(inputElement.value).multiply(100).value()?.toString();

  emit('update:modelValue', parsedValue);
};
</script>
