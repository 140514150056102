import { z } from 'zod';

export const QuoteGroupStatusSchema = z.object({
  id: z.number(),
  quote_group_id: z.number(),
  name: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
});

export type QuoteGroupStatus = z.infer<typeof QuoteGroupStatusSchema>;
