<template>
  <div
    ref="rootRef"
    :class="{
      'form-group-display': true,
      'form-group-display--disabled': disabled,
      'form-group-display--invalid': !isValid,
      [`form-group-display--theme-${theme}`]: true,
    }"
    @click="showDisabledReason = !showDisabledReason"
  >
    <!-- Label -->
    <slot name="label">
      <div class="form-group-display__label-container">
        {{ label }}
      </div>
    </slot>

    <!-- Content -->
    <div class="form-group-display__content" @click="emit('click:content')">
      <div class="form-group-display__content-value">
        <slot />
      </div>
    </div>

    <!-- Input for validation -->
    <input ref="inputRef" type="text" :required="required" :style="{ display: 'none' }" :value="inputValue" />

    <!-- Disabled reason -->
    <Tooltip v-if="disabled && disabledReason" :target-el="rootRef" :show-when="showDisabledReason" placement="top">
      {{ disabledReason }}
    </Tooltip>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick } from 'vue';
import Tooltip from '@/js/Common/Tooltip.vue';

const props = defineProps({
  label: {
    type: String,
    default: null,
  },
  theme: {
    type: String,
    default: 'input',
    validator: (v) => ['input', 'text'].includes(v),
  },
  inputValue: {
    type: null,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabledReason: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['click:content']);

const rootRef = ref(null);
const showDisabledReason = ref(false);
const inputRef = ref(null);
const isValid = ref(true);

const observerCallback = () => {
  isValid.value = !inputRef.value.classList.contains('is-invalid');
};

onMounted(() => {
  observerCallback();
  const observer = new MutationObserver(observerCallback);
  observer.observe(inputRef.value, { attributes: true });
});

watch(
  () => props.inputValue,
  () => {
    nextTick(() => {
      if (inputRef.value.validity.valid) {
        inputRef.value.classList.remove('is-invalid');
        const errorEl = rootRef.value.querySelector('.error');
        if (errorEl) errorEl.remove();
      }
    });
  },
);
</script>

<style lang="scss">
.form-group-display {
  $self: '.form-group-display';

  min-height: 2.66em;
  margin-bottom: rem(10px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: white;
  display: flex;
  flex-direction: column;

  &--theme-text {
    background-color: transparent;
  }

  &__label-container {
    font-size: rem(12px);
    color: color('gray', 5);
    white-space: nowrap;
  }

  &--theme-input &__label-container {
    left: rem($inputPaddingX);
  }

  &__content {
    font-size: rem(18px);
    color: black;
    display: flex;
    align-items: flex-end;
  }

  &__content &__content-value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__disabled-reason {
    font-size: rem(12px);
    background-color: white;
    padding: rem(2px) rem(5px);
  }

  // Input theme
  &--theme-input {
    border: rem(1px) solid color('gray', 3);
    border-radius: rem(3px);
    padding-left: $inputPaddingX;
    padding-right: $inputPaddingX;
  }

  // Disabled
  &--disabled {
    background-color: #f8f8f8;
    #{$self}__content {
      color: #545454;
    }
  }

  // Invalid
  &--invalid {
    border-color: $error;
    padding-right: rem(50px);
    background-image: url($errorIconURL);
    background-repeat: no-repeat;
    background-position: right rem(12.5px) center;
    background-size: rem(18px);
  }

  &--invalid .error {
    font-size: rem(12px);
    color: $error;
    margin: 0;
  }
}
</style>
