// Only validate form with this selector
const selector = '[validate]';

// No need to validate these fields
const ignoreInputs = ['file', 'reset', 'submit', 'button'];

// Default error massages
const messages = {
  required: 'Please fill out this field.',
  requiredSelect: 'Please select a value.',
  email: 'Please enter a valid email address.',
  url: 'Please enter a valid URL.',
  number: 'Please enter a valid number.',
  default: 'The value you entered for this field is invalid.',
  taxid: 'Please enter a valid tax ID / SS #',
};

// Validate form fields
function hasError(field) {
  if (field.disabled || ignoreInputs.includes(field.type)) return false;

  if (!field.validity) return false;

  const { validity } = field;

  if (field.getAttribute('taxid') === '') {
    if (!field.value) return false;
    for (let i = 0; i < field.value.length; i++)
      if (field.value[i] < '0' || field.value[i] > '9') return messages.taxid;
    if (field.value.length !== 9) return messages.taxid;
    return false;
  }

  if (field.getAttribute('type') === 'number')
    if (/^[+-]?([0-9]*[.])?[0-9]+$/.test(field.value)) return false;
    else return 'Please enter a valid number.';

  if (validity.valid) return false;

  if (validity.valueMissing) return messages.required;

  if (validity.typeMismatch) {
    if (field.type === 'email') return messages.email;

    if (field.type === 'url') return messages.url;
  }

  if (validity.badInput) return messages.number;

  return messages.default;
}

// Show error message
function showError(field, error) {
  field.classList.add('is-invalid');

  let message = field.parentNode.querySelector('.error');

  if (!message) {
    message = document.createElement('span');
    message.classList.add('error');

    field.after(message);
  }

  message.innerHTML = error;
  message.style.display = 'inline-block';
  message.style.visibility = 'visible';
}

// remove error message
function removeError(field) {
  field.classList.remove('is-invalid');

  const message = field.nextElementSibling;

  if (!(message instanceof Element) || !message.matches('span.error')) return;

  message.innerHTML = '';
  message.style.display = 'none';
  message.style.visibility = 'hidden';
}

function addNoValidate() {
  const forms = document.querySelectorAll(selector);

  for (let i = 0; i < forms.length; i += 1) {
    forms[i].setAttribute('novalidate', true);
  }
}

function removeNoValidate() {
  const forms = document.querySelectorAll(selector);

  for (let i = 0; i < forms.length; i += 1) {
    forms[i].removeAttribute('novalidate');
  }
}

// Handle input events
function inputHandler(event) {
  if (!event.target.form || !event.target.form.matches(selector)) return;

  const error = hasError(event.target);

  if (error) {
    showError(event.target, error);
    return;
  }

  removeError(event.target);
}

// Handle blur events
function blurHandler(event) {
  if (!event.target.form || !event.target.form.matches(selector)) return;

  const error = hasError(event.target);

  if (error) {
    showError(event.target, error);
    return;
  }

  removeError(event.target);
}

function changeHandler(event) {
  if (!event.target.form || !event.target.form.matches(selector)) return;

  const error = hasError(event.target);

  if (error) {
    showError(event.target, error);
    event.stopPropagation();
    return;
  }

  removeError(event.target);
}

// Handle Submit
function submitHandler(event) {
  if (!event.target.matches(selector)) return;

  const fields = [...event.target.elements];
  const subForms = event.target.querySelectorAll('form[validate]:not([not-on-parent-validate])');
  subForms.forEach((form) => fields.push(...form.elements));

  let firstError;

  for (let i = 0; i < fields.length; i += 1) {
    const error = hasError(fields[i]);

    if (error) {
      showError(fields[i], error);

      if (!firstError) {
        firstError = fields[i];
      }
    }
  }

  if (firstError) {
    event.preventDefault();
    event.stopPropagation();
    firstError.focus();
  }
}

const validateForm = (form) => {
  const fields = [...form.elements];
  const subForms = form.querySelectorAll('form[validate]:not([not-on-parent-validate])');
  subForms.forEach((x) => fields.push(...x.elements));

  let firstError;

  for (let i = 0; i < fields.length; i += 1) {
    const error = hasError(fields[i]);

    if (error) {
      showError(fields[i], error);

      if (!firstError) {
        firstError = fields[i];
      }
    } else {
      removeError(fields[i]);
    }
  }
};

const validateField = (field) => {
  const error = hasError(field);

  if (error) showError(field, error);
  else removeError(field);
};

// Plugin initialization
export default function install(Vue) {
  document.addEventListener('input', inputHandler, true);
  document.addEventListener('blur', blurHandler, true);
  document.addEventListener('change', changeHandler, true);
  document.addEventListener('submit', submitHandler, true);

  Vue.mixin({
    mounted() {
      addNoValidate();

      const forms = document.querySelectorAll('form[validate][validate-on-load]');

      forms.forEach((x) => validateForm(x));
    },

    methods: {
      $validateForm: validateForm, // eslint-disable-line no-param-reassign
      $validateField: validateField, // eslint-disable-line no-param-reassign
    },

    unMounted() {
      removeNoValidate();
    },
  });
}
