<template>
  <div class="policy">
    <div class="divider"></div>

    <!-- Policy header -->
    <PolicyHeader
      :policy="policy"
      :in-acord-view="inAcordView"
      @update:policy="(policy) => emit('update:policy', policy)"
      @click:attachments="emit('click:attachments')"
    />

    <!-- Policy form -->
    <PolicyForm :policy="policy" :can-edit="canEdit" />

    <!-- Policy client form -->
    <PolicyClientForm :client="client" :can-edit="canEdit" />

    <!-- Policy endorsments -->
    <PolicyEndorsements />

    <!-- Policy details -->
    <PolicyDetails :policy="policy" :can-edit="canEdit" :client="client" />
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue';
import type { ComputedRef } from 'vue';
import { Client } from '@/js/Api/Client';
import { Policy } from '@/js/Api/Policy';
import PolicyHeader from './PolicyHeader/PolicyHeader.vue';
import PolicyForm from './PolicyForm.vue';
import PolicyClientForm from './PolicyClientForm.vue';
import PolicyEndorsements from './PolicyEndorsements.vue';
import PolicyDetails from './PolicyDetails.vue';

interface PolicyWithIntakes extends Policy {
  intakes: Exclude<Policy['intakes'], undefined>;
}

interface Props {
  client: Client;
  policy: PolicyWithIntakes;
  inAcordView?: boolean;
}

interface Emits {
  (e: 'click:attachments'): void;
  (e: 'update:policy', policy: Policy): void;
}

withDefaults(defineProps<Props>(), {
  inAcordView: false,
});

const emit = defineEmits<Emits>();

const canEdit = inject('canEditClient') as ComputedRef<boolean>;
</script>

<style lang="scss">
.policy {
  .divider {
    margin-top: rem(17px);
    margin-bottom: rem(23px);
    background: #ccc;
    height: rem(1px);
  }
  .policy-header {
    margin-bottom: 1em;
  }
}
</style>
