import Pusher from 'pusher-js';
import Echo from 'laravel-echo';

const EchoConnection = new Echo({
  broadcaster: 'reverb',
  key: import.meta.env.VITE_REVERB_APP_KEY,
  wsHost: window.location.hostname,
  forceTLS: window.location.protocol === 'https:',
  enabledTransports: ['ws', 'wss'],
});

export default function () {
  return EchoConnection;
}
