<template>
  <!-- Table list -->
  <TableList
    :columns="[
      {
        name: 'Priority',
        value: 'priority',
        slot: true,
        slotName: 'priority',
      },
      {
        name: 'Points',
        value: 'weight',
      },
      {
        name: 'Call.',
        value: 'callback',
        slot: true,
        slotName: 'callback',
      },
      {
        name: 'Rescheduled from',
        value: 'old_due',
        get: (item) => (item.old_due === today ? 'Today' : new Date(item.old_due).toDateSlashShort()),
        tdClass: (item) => (item.old_due === today ? 'table-list__td--today' : null),
      },
      {
        name: 'Rescheduled to',
        value: 'due',
        get: (item) => (item.due === today ? 'Today' : new Date(item.due).toDateSlashShort()),
        tdClass: (item) => (item.due === today ? 'table-list__td--today' : null),
      },
      {
        name: 'Client',
        value: 'client.name',
        get: getClientName,
      },
      {
        name: 'Note',
        get: (item) => item.content,
      },
      {
        name: 'Created by',
        value: 'followup_created_by.name',
      },
      {
        name: 'Assigned to',
        value: 'assigned_to.name',
      },
      {
        slot: true,
        slotName: 'watch',
      },
    ]"
    :api-filters="filters"
    :api-endpoint="'/dashboard/data/watch'"
    :api-sorted-by="$store.state.dashboard.followupsPanelSettings.sortedBy"
    :api-sort-order="$store.state.dashboard.followupsPanelSettings.sortOrder"
    :api-vuex-property="'dashboard/watchViewFollowups'"
    :api-vuex-mutation="'dashboard/setWatchViewFollowups'"
    :tr-class="(item) => ({ 'table-list__tr--updated': isUpdated(item), 'table-list__tr--deleted': item.deleted })"
    grid-template-columns="1fr 1.5fr 1fr 3fr 3fr 7fr 10fr 3fr 3fr 1fr"
    @item:click="onItemClick"
    @sort="(...args) => $emit('sort', ...args)"
  >
    <!-- No items -->
    <template #noitems> No followups yet </template>

    <!-- Priority -->
    <template #priority="{ item }">
      <SvgIcon :name="`followup-${item.priority_readable.toLowerCase()}`" />
    </template>

    <!-- Callback -->
    <template #callback="{ item }">
      <SvgIcon v-if="item.callback" name="phone-dial" color="#90D65C" />
    </template>

    <!-- Watch -->
    <template #watch="{ item }">
      <img
        v-if="item.close_cleared == false"
        class="icon eye"
        data-tooltip="Clear closed followup"
        src="/img/icons/watch_closed-24px.svg"
        @click.stop="eyeClick(item)"
      />
      <img
        v-else-if="item.reschedule_cleared == false"
        class="icon eye"
        data-tooltip="Clear rescheduled followup"
        src="/img/icons/watch_rescheduled-24px.svg"
        @click.stop="eyeClick(item)"
      />
      <img
        v-else-if="item.is_watching"
        class="icon eye"
        data-tooltip="Unwatch this followup"
        src="/img/icons/watch_off-24px.svg"
        @click.stop="eyeClick(item)"
      />
      <img
        v-else
        class="icon eye"
        data-tooltip="Watch this followup"
        src="/img/icons/watch_on-24px.svg"
        @click.stop="eyeClick(item)"
      />
    </template>
  </TableList>
</template>

<script>
import TicketNoteApi from '@/js/Api/TicketNote';
import { useStore } from 'vuex';
import { computed } from 'vue';
import useAlert from '@/js/Composables/useAlert';
import useLoader from '@/js/Composables/useLoader';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import TableList from '@/js/Components/TableList.vue';

export default {
  components: {
    SvgIcon,
    TableList,
  },

  inheritAttrs: false,

  emits: ['sort', 'click:followup', 'watch:followup'],

  setup(props, { emit }) {
    const store = useStore();
    const { toast } = useAlert();
    const { loader } = useLoader();

    const today = new Date().toDateInput();

    const filters = computed(() => store.state.dashboard.filters);

    const eyeClick = (followup) => {
      loader.start();

      // Clearing
      if (followup.close_cleared == false || followup.reschedule_cleared == false) {
        const clearType = followup.close_cleared == false ? 'close' : 'reschedule';
        TicketNoteApi.clear({ id: followup.id, clearType })
          .then(() => {
            if (clearType == 'reschedule') {
              followup.old_due = null;
              followup.reschedule_cleared = true;
              toast({ title: 'Rescheduled followup cleared.', icon: 'success' });
            } else {
              store.commit('dashboard/clearClosedFollowup', followup);
              toast({ title: 'Closed followup cleared.', icon: 'success' });
            }
          })
          .catch(() => {
            toast({ title: 'An error has occurred.', icon: 'error' });
          })
          .finally(() => {
            loader.end();
          });

        // Watching / Unwatching
      } else {
        emit('watch:followup', followup);
      }
    };

    const getClientName = (followup) => {
      if (followup.client && followup.client.name) return followup.client.name;
      if (followup.client && !followup.client.name) return '(Draft)';
      return '';
    };

    const isUpdated = (item) => item.status === 'Resolved' || item.rescheduled;

    const onItemClick = (item) => {
      if (!item.deleted) emit('click:followup', item);
    };

    return {
      today,
      filters,
      eyeClick,
      getClientName,
      isUpdated,
      onItemClick,
    };
  },
};
</script>
