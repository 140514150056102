import { z } from 'zod';

export const DriverViolationSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  driver_id: z.number(),
  description: z.string().nullable(),
  occurance_date: z.string().nullable(),
  conviction_date: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});

export type DriverViolation = z.infer<typeof DriverViolationSchema>;
