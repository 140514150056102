<template>
  <div
    ref="el"
    :class="[
      'dropdown',
      `dropdown--${strategy}`,
      {
        'dropdown--shown': isShown,
        'dropdown--disabled': disabled,
      },
      containerClass,
    ]"
    :style="containerStyle"
  >
    <a v-if="displayTrigger" ref="trigger" class="dropdown__trigger dropdown-trigger" tabindex="0" href="#">
      <slot name="trigger"></slot>
    </a>

    <div ref="menu" class="dropdown__menu dropdown-menu" @click="onMenuClick">
      <slot name="menu" :hide="hide" :show="show" :toggle="toggle"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { createPopper } from '@popperjs/core';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  placement: {
    type: String,
    default: 'bottom',
  },
  strategy: {
    type: String,
    default: 'absolute',
  },
  offset: {
    type: Array,
    default: () => [0, 0],
  },
  flip: {
    type: Boolean,
    default: true,
  },
  containerClass: {
    type: [String, Array, Object],
    default: null,
  },
  containerStyle: {
    type: [String, Array, Object],
    default: null,
  },
  closeOnInsideClick: {
    type: Boolean,
    default: true,
  },
  closeOnOutsideClick: {
    type: Boolean,
    default: true,
  },
  displayTrigger: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['show', 'shown', 'hide', 'hidden', 'toggle', 'toggled']);

const el = ref(null);

// Vars
const isNested = ref(false);
const trigger = ref(null);
const menu = ref(null);
const isShown = ref(false);

const popperInstance = ref(null);

let onOutsideClickListenerSet = false;

// Actions
const hide = () => {
  if (!menu.value) return;
  if (menu.value.getAttribute('data-show') === null) return;
  emit('hide');
  menu.value.removeAttribute('data-show');
  popperInstance.value.setOptions({
    modifiers: [{ name: 'eventListeners', enabled: false }],
  });
  isShown.value = false;
  emit('hidden');
};
const show = () => {
  if (menu.value.getAttribute('data-show') !== null) return;
  if (props.disabled) return;
  emit('show');
  menu.value.setAttribute('data-show', '');
  popperInstance.value.setOptions({
    modifiers: [
      {
        name: 'offset',
        options: { offset: props.offset },
      },
      {
        name: 'flip',
        enabled: props.flip,
      },
      { name: 'eventListeners', enabled: true },
    ],
  });
  popperInstance.value.update();
  isShown.value = true;
  emit('shown');

  // Hide menu on outside click
  if (!onOutsideClickListenerSet && props.closeOnOutsideClick) {
    onClickOutside(el.value, (event) => {
      if (event.target.classList.contains('swal2-cancel')) return;
      hide();
    });
    onOutsideClickListenerSet = true;
  }
};
const toggle = () => {
  emit('toggle');
  if (menu.value.getAttribute('data-show') != null) hide();
  else show();
  emit('toggled');
};

// Hide menu on menu click
const onMenuClick = (e) => {
  if (!props.closeOnInsideClick) return;
  if (e.target.tagName === 'INPUT') return;
  hide();
};

onMounted(() => {
  // Create popper
  popperInstance.value = createPopper(el.value, menu.value, {
    strategy: props.strategy,
    placement: props.placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: props.offset,
        },
      },
      {
        name: 'flip',
        enabled: props.flip,
      },
      { name: 'eventListeners', enabled: false },
    ],
  });

  // Toggle menu on click
  if (trigger.value)
    trigger.value.addEventListener('click', (event) => {
      if (isNested.value) event.stopPropagation();
      toggle();
    });

  // Is nested
  if (el.value?.parentNode) isNested.value = el.value.parentNode.classList.contains('dropdown__menu');
});

// Cleanup
onBeforeUnmount(() => {
  if (trigger.value) trigger.value.removeEventListener('click', toggle);
  if (menu.value) menu.value.removeEventListener('click', hide);
  if (popperInstance.value) popperInstance.value.destroy();
});

defineExpose({ popperInstance, show, hide, toggle });
</script>

<style lang="scss">
// Dropdown (absolute)
.dropdown--absolute {
  position: relative;

  // .dropdown-menu {
  //   min-width: 100%;
  // }
}

// Dropdown
.dropdown {
  font-size: rem(16px);

  $ddItemFontSize: 1em;
  $ddItemPadding: 0.9em 1em;

  // Disabled
  &--disabled &-trigger {
    pointer-events: none;
  }

  &-trigger {
    display: flex;
    text-decoration: none;

    // color: inherit;
    cursor: pointer;
  }

  &-menu {
    z-index: 100;
    width: auto;
    min-width: rem(150px);
    background-color: white;
    box-shadow: 0 rem(3px) rem(18px) #00000054;
    max-height: 50vh;
    display: none;

    &[data-show] {
      display: block;
    }

    // Radius
    border-radius: rem(8px);

    > .dropdown-item:first-child {
      border-top-left-radius: rem(8px);
      border-top-right-radius: rem(8px);
    }

    > .dropdown-item:last-child {
      border-bottom-left-radius: rem(8px);
      border-bottom-right-radius: rem(8px);
    }

    // Dropdown description
    .dropdown-description {
      margin: 0;
      font-size: rem(13px);
      padding: rem(2.5px) rem(15px);
      border-bottom: rem(1px) solid color('gray', 3);
    }

    // Dropdown item
    .dropdown-item,
    .dropdown-item .dropdown-trigger {
      color: color('gray', 9);
      text-decoration: none;
      width: 100%;
      display: flex;
      align-items: center;
      background-color: white;
      padding: $ddItemPadding;
      font-size: $ddItemFontSize;
      white-space: nowrap;
      font-style: inherit;
      font-family: inherit;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: #f8f9fa;
      }

      &:not(:last-child) {
        border-bottom: rem(1px) solid color('gray', 3);
      }

      &.delete {
        color: #c31919;
      }

      &.add {
        color: color('primary');
      }

      &.disabled,
      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      .icon {
        width: 1.2em;
        font-size: 1.2em;
        margin-right: 0.7em;
      }

      .svg-icon {
        width: 1.4em;
        color: inherit;
        margin-right: 0.7em;
      }
    }

    .dropdown-item-description {
      background-color: white;
      font-size: 0.8em;
      padding: 0.7em 1.1em;
    }

    // Nested dropdown
    > .dropdown {
      &.right .dropdown-trigger {
        .dropdown-item .icon {
          margin-left: 0.7em;
          margin-right: 0;
        }
      }

      .dropdown-trigger {
        text-decoration: none;
        width: 100%;
        display: flex;
        align-items: center;
        color: $text;
        background-color: white;
        padding: $ddItemPadding;
        font-size: $ddItemFontSize;
        white-space: nowrap;
        font-style: inherit;
        font-family: inherit;
        border: none;
        cursor: pointer;
        border-bottom: rem(1px) solid color('gray', 3);

        &:hover {
          background-color: #f8f9fa;
        }
      }
    }
  }
}
</style>
