<template>
  <Dropdown class="move-button" :disabled="disabled" :close-on-inside-click="false" @hidden="reset">
    <template #trigger>
      <SvgIcon name="merge" />
      Move
    </template>

    <template #menu="{ hide }">
      <!-- Header -->
      <div class="move-button__header">
        <div class="move-button__header-left">
          <SvgIcon v-if="historyStack.length > 0" name="arrow-left" role="button" @click="goBack" />
          <div class="move-button__node-text">
            {{ currentNode?.name }}
            <div v-if="currentNode?.details" class="move-button__node-details">
              {{ currentNode.details }}
            </div>
          </div>
        </div>
        <SvgIcon name="close" role="button" @click="hide" />
      </div>

      <!-- List -->
      <div class="move-button__list">
        <div
          v-for="nodeInList in (currentNode?.tree || tree).filter((n) => n.type === 'folder')"
          :key="`${nodeInList.name}-${nodeInList.path}`"
          role="button"
          class="move-button__list-node"
          @click="openNode(nodeInList)"
        >
          <SvgIcon name="folder" />
          <div class="move-button__node-text">
            {{ nodeInList.name }}
            <div v-if="nodeInList.details" class="move-button__node-details">
              {{ nodeInList.details }}
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="move-button__footer">
        <Btn :disabled="!canMove" @click="onMove">Move here</Btn>
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import type { Ref } from 'vue';
import type { ClientTreeNode } from '@/js/Api/Storage';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import Dropdown from '@/js/Common/Dropdown.vue';
import Btn from '@/js/Components/Btn.vue';

const props = defineProps<{
  tree: ClientTreeNode[];
  node: ClientTreeNode | null;
  disabled: boolean;
}>();

const emit = defineEmits(['move']);

const currentNode: Ref<ClientTreeNode | null> = ref(null);

const historyStack: Ref<ClientTreeNode[]> = ref([]);

const reset = () => {
  currentNode.value = null;
};

const openNode = (clickedNode: ClientTreeNode) => {
  historyStack.value.push(currentNode.value);
  currentNode.value = clickedNode;
};

const goBack = () => {
  const parent = historyStack.value.pop();
  currentNode.value = parent;
};

const canMove = computed(() =>
  currentNode.value ? currentNode.value.permissions && currentNode.value.permissions.move : false
);

const onMove = () => {
  emit('move', currentNode.value.path);
};
</script>

<style lang="scss">
.move-button {
  &.dropdown--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .dropdown__menu {
    min-width: rem(400px);
    min-height: rem(400px);
  }
  &.dropdown--shown .dropdown__menu {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #dfe2e6;
    padding: rem(8px) rem(12px);
    &-left {
      display: flex;
      align-items: flex-start;
    }
    .svg-icon--arrow-left {
      cursor: pointer;
      margin-right: rem(10px);
    }
    .svg-icon--close {
      width: rem(16px);
      cursor: pointer;
      margin-left: rem(15px);
    }
  }

  &__node {
    &-details {
      color: #999999;
      white-space: nowrap;
    }
  }

  &__list {
    flex: 1;
    overflow: auto;
    &-node {
      cursor: pointer;
      padding: rem(6px) rem(10px);
      white-space: nowrap;
      display: flex;
      align-items: center;
      &:hover {
        background-color: #c9e3ff;
      }
      .svg-icon--folder {
        width: rem(30px);
        height: rem(30px);
        color: color('primary');
        margin-right: rem(10px);
      }
    }
    &-empty-text {
      padding: rem(10px);
      text-align: center;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    border-top: rem(1px) solid #ccc;
    padding: rem(10px);
  }
}
</style>
