<template>
  <Modal ref="ModalRef" :is-loading="loading" :close-on-button-click="false" @button:click="EditFormRef.submit()">
    <!-- Title -->
    <template #title> Edit cancellation </template>

    <!-- Modal body -->
    <EditForm
      ref="EditFormRef"
      :cancellation="cancellation"
      @processing="loading = true"
      @finish="loading = false"
      @success="onUpdated"
    />
  </Modal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { PolicyCancellation } from '@/js/Api/PolicyCancellation';
import Modal from '@/js/Common/Modal.vue';
import EditForm from './Edit.vue';

interface Props {
  cancellation: PolicyCancellation;
}

interface Emits {
  (e: 'updated', cancellation: PolicyCancellation): void;
}

defineProps<Props>();

const emit = defineEmits<Emits>();

const loading = ref<boolean>(false);
const ModalRef = ref<InstanceType<typeof Modal> | null>(null);
const EditFormRef = ref<InstanceType<typeof EditForm> | null>(null);

const onUpdated = (cancellation: PolicyCancellation) => {
  emit('updated', cancellation);
};

const show = () => {
  ModalRef.value.show();
};

defineExpose({
  show,
});
</script>

<style lang="scss" scoped>
.form-check-container.reasons {
  margin-bottom: rem(20px);
}
</style>
