<template>
  <div class="note__body note__body--create-followup">
    <!-- Created by user -->
    <div class="note__user note__user--created-by">
      <UserCircle :initials="note.created_by.initials" />
      <label class="note__user-label">{{ shortenUserName(note.created_by.name) }}</label>
    </div>

    <!-- Followup form -->
    <FollowupCreateForm ref="FollowupCreateFormRef" :show-submit="false" @submit="submit" />

    <!-- Image -->
    <NoteAttachments ref="NoteAttachmentsRef" :attachment-paths="note.attachment_paths" />

    <!-- Content -->
    <TextareaAutoExpandable
      ref="TextareaAutoExpandableRef"
      v-model="noteLocal.content"
      name="text"
      input-class="note__content"
      :disabled="!me.permissions['edit-note-content']"
      @input="(e) => e.target.classList.remove('is-invalid')"
      @paste="(event) => NoteAttachmentsRef.onPaste(event)"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import TicketNoteApi from '@/js/Api/TicketNote';
import { toast } from '@/js/Helpers/Alert';
import Loader from '@/js/Helpers/Loader';
import UserCircle from '@/js/Components/UserCircle.vue';
import TextareaAutoExpandable from '@/js/Components/TextareaAutoExpandable.vue';
import FollowupCreateForm from '@/js/Components/Tickets/FollowupCreateForm.vue';
import NoteAttachments from '@/js/Components/Tickets/NoteAttachments.vue';
import useImageStore from '@/js/store/pinia/useImageStore';

const props = defineProps({
  note: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:note', 'finished-editing']);

const noteLocal = ref({ ...props.note });

const NoteAttachmentsRef = ref(null);
const TextareaAutoExpandableRef = ref(null);
const FollowupCreateFormRef = ref(null);

const shortenUserName = (name) => {
  const words = name.split(' ');
  if (words.length === 1) return name;
  return `${words[0]} ${words
    .splice(1, words.length - 1)
    .map((w) => `${w.charAt(0).toUpperCase()}.`)
    .join(' ')}`;
};

const imageStore = useImageStore();

// Submit
const submit = (form) => {
  if (!form) return FollowupCreateFormRef.value.submit();
  if (!noteLocal.value.content)
    return TextareaAutoExpandableRef.value.$el.querySelector('textarea').classList.add('is-invalid');

  const noteId = props.note.id;
  const clientId = props.note.client_id;

  // Request
  Loader.start();
  return TicketNoteApi.update({
    id: noteId,
    body: {
      ...form,
      content: noteLocal.value.content,
      attachments: NoteAttachmentsRef.value.attachments,
    },
  })
    .then(({ data: note }) => {
      const imagesDirPath = clientId ? `clients/${clientId}/notes/${noteId}` : `notes/${noteId}`;
      imageStore.updateImagesInDir(imagesDirPath);
      emit('update:note', note);
      emit('finished-editing', note);
      toast({ title: 'Followup created', icon: 'success' });
    })
    .catch(() => {
      toast({ title: 'An error occurred', icon: 'error' });
    })
    .finally(() => {
      Loader.end();
    });
};

defineExpose({ submit });
</script>

<style lang="scss">
.note__body--create-followup {
  $note: '.note';

  display: flex;
  flex-direction: column;
  padding: rem(15px);

  // Created by
  .note__user--created-by {
    align-self: flex-start;
    margin-left: rem(5px);
  }

  // Followup form
  .create-followup-form {
    margin-top: 1em;
  }

  // Attachments
  .note-attachments {
    align-self: flex-start;
  }

  // Content
  .form-group--textarea-autoexpandable {
    margin-top: rem(10px);
    margin-bottom: 0;
  }
}
</style>
