<template>
  <div
    :class="{
      'dropdown-item': true,
      'dropdown-item--disabled': disabled,
    }"
    @click="onClick"
  >
    <slot>
      <SvgIcon v-if="icon && iconPosition === 'before'" :name="icon" style="margin-right: 1em" />
      {{ label }}
      <SvgIcon v-if="icon && iconPosition === 'after'" :name="icon" style="margin-left: 1em" />
    </slot>
  </div>
</template>

<script setup>
import SvgIcon from '@/js/Components/SvgIcon.vue';

const props = defineProps({
  label: {
    type: String,
    default: null,
  },
  icon: {
    type: String,
    default: null,
  },
  iconPosition: {
    type: String,
    default: 'before',
    validator: (val) => ['before', 'after'].includes(val),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const onClick = (e) => {
  if (props.disabled) e.stopPropagation();
};
</script>
