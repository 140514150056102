<template>
  <div class="policy-cancellation-update-form">
    <!-- Cancellation date -->
    <FormGroup type="date" name="date" label-text="Cancellation date" />

    <!-- Reasons checkboxes -->
    <FormGroupCheckbox
      name="reasons"
      class="reasons"
      label="Cancellation reason"
      :multivalue="true"
      :checkboxes="[
        { checkedValue: 'Client request', label: 'Client request' },
        { checkedValue: 'Non-payment', label: 'Non-payment' },
        { checkedValue: 'Carrier/underwriting', label: 'Carrier/underwriting' },
      ]"
    />

    <!-- Amount -->
    <FormGroup
      v-if="values.reasons.includes('Non-payment')"
      type="currency"
      name="amount"
      label-text="Amount"
      group-class="mt-1"
    />

    <!-- Carrier / underwrighting reason -->
    <FormGroup
      v-if="values.reasons.includes('Carrier/underwriting')"
      type="text"
      name="carrier_underwrighting_reason"
      label-text="Carrier/underwrighting reason"
      group-class="mt-1"
    />
  </div>
</template>

<script setup lang="ts">
import { z } from 'zod';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import { toastSuccess, toastError } from '@/js/Helpers/Alert';
import PolicyCancellationApi, { PolicyCancellation } from '@/js/Api/PolicyCancellation';
import FormGroup from '@/js/Components/Form/FormGroup.vue';
import FormGroupCheckbox from '@/js/Components/Form/FormGroupCheckbox.vue';

interface Props {
  cancellation: PolicyCancellation;
}

interface Emits {
  (e: 'processing'): void;
  (e: 'success', cancellation: PolicyCancellation): void;
  (e: 'fail', error: Error): void;
  (e: 'finish'): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const formSchema = z
  .object({
    date: z.string(),
    amount: z.number().nullable(),
    reasons: z.string().array().min(1),
    carrier_underwrighting_reason: z.string().nullable(),
  })
  .refine((form) => (form.reasons.includes('Non-payment') ? form.amount !== null : true), {
    message: "Amount is required if 'Non-payment' is checked",
    path: ['amount'],
  })
  .refine(
    (form) => (form.reasons.includes('Carrier/underwriting') ? form.carrier_underwrighting_reason !== null : true),
    {
      message: "Carrier/underwriting reason is required if 'Carrier/underwriting' is checked",
      path: ['carrier_underwrighting_reason'],
    },
  );

const { values, handleSubmit } = useForm({
  keepValuesOnUnmount: true,
  validationSchema: toTypedSchema(formSchema),
  initialValues: {
    date: props.cancellation.date,
    amount: props.cancellation.amount,
    reasons: props.cancellation.reasons.split(','),
    carrier_underwrighting_reason: props.cancellation.carrier_underwrighting_reason,
  },
});

const submit = handleSubmit((form) => {
  emit('processing');
  PolicyCancellationApi.update({
    id: props.cancellation.id,
    clientId: props.cancellation.client_id,
    policyId: props.cancellation.policy_id,
    form,
  })
    .then(({ data }) => {
      emit('success', data);
      toastSuccess('Policy has been canceled.');
    })
    .catch((e) => {
      toastError();
      emit('fail', e);
    })
    .finally(() => {
      emit('finish');
    });
});

defineExpose({
  submit,
});
</script>
