<template>
  <div v-if="attachments.length" class="note-attachments">
    <div v-for="(attachment, i) in attachments" :key="i" class="note-attachments__item">
      <a class="note-attachments__item-link" target="_blank" :href="attachmentHref(attachment.path)">
        {{ attachment.name }}
      </a>
      <SvgIcon v-if="!disabled" name="close" @click="removeAttachment(attachment)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { toast } from '@/js/Helpers/Alert';
import { processAttachments } from '@/js/Helpers/Attachment';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import { Attachment } from '@/js/Api/Storage';

interface Props {
  disabled?: boolean;
  attachmentPaths?: string[] | null;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  attachmentPaths: () => [],
});

const pastedAttachments = ref<Attachment[]>([]);
const existingAttachmentPaths = ref(props.attachmentPaths ? [...props.attachmentPaths] : []);

const attachments = computed(() => {
  const existingAttachmentNames = existingAttachmentPaths.value.map((path) => ({
    name: path.split('/').pop(),
    path,
  }));
  const pastedAttachmentNames = pastedAttachments.value.map((attachment) => ({
    name: attachment.name,
    base64: attachment.base64,
  }));
  return [...existingAttachmentNames, ...pastedAttachmentNames];
});

const onPaste = (event) => {
  processAttachments(event)
    .then((files) => {
      pastedAttachments.value = pastedAttachments.value.concat(files);
    })
    .catch((error: Error) => {
      toast({ title: error.message, icon: 'warning' });
    });
};

const removeAttachment = (attachment) => {
  if (attachment.base64) {
    const i = pastedAttachments.value.findIndex((a) => a === attachment);
    pastedAttachments.value.splice(i, 1);
  } else {
    const i = existingAttachmentPaths.value.findIndex((path) => path === attachment.path);
    existingAttachmentPaths.value.splice(i, 1);
  }
};

const attachmentHref = (path) => {
  if (!path) return null;
  return `/api/storage/open?path=${encodeURIComponent(path)}`;
};

defineExpose({ onPaste, attachments, pastedAttachments });
</script>

<style lang="scss">
.note-attachments {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: color('gray', 2);
    padding: rem(1px) rem(10px);

    &:not(:last-child) {
      margin-bottom: rem(5px);
    }

    &-link {
      text-decoration: none;
      font-weight: bold;
      font-size: rem(16px);

      &:not([href]) {
        cursor: default;
        color: color('text', 2);
      }
    }

    .svg-icon {
      cursor: pointer;
      width: rem(16px);
      margin-left: rem(15px);
    }
  }
}
</style>
