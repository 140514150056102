import { z } from 'zod';
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';
import { Policy } from './Policy';
import { AddressSchema } from './Address';
import { ContactSchema } from './Contact';
import queryBuilderToQueryString from '../Helpers/queryBuilderToQueryString';

export const CarrierSchema = z.object({
  id: z.number(),
  name: z.string(),
  url: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),

  // Relations
  addresses: AddressSchema.array().optional(),
  contacts: ContactSchema.array().optional(),

  // Aggregates
  quotes_count: z.number().optional(),
  policies_count: z.number().optional(),
  policies_premium: z.number().optional(),
  policies_active_count: z.number().optional(),
  policies_active_premium: z.number().optional(),

  // Appends
  model_type: z.literal('Carrier'),
});

export type Carrier = z.infer<typeof CarrierSchema>;

// ***************************************************
// Index
// ***************************************************

type IndexArgs = {
  queryBuilder: {
    page: number;
    filters?: {
      name?: string;
      policies?: {
        status?: Policy['status'];
      };
    };
    includes?: ['addresses' | 'contacts.phones' | 'contacts.emails' | 'quotesCount' | 'policiesCount'];
  };
};

async function index({ queryBuilder }: IndexArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/carriers?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  // TODO: We should validate the return, have to refactor a Ajax Select component for it
  return response;
}

// ***************************************************
// All
// ***************************************************

type AllArgs = {
  queryBuilder: {
    filters?: {
      id?: number[];
      name?: string;
      policies?: {
        status?: Policy['status'];
      };
    };
    includes?: ['addresses' | 'contacts.phones' | 'contacts.emails' | 'quotesCount' | 'policiesCount'];
  };
};

async function all({ queryBuilder }: AllArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/carriers/all?${qs}`).catch((error) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: CarrierSchema.array(),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Find
// ***************************************************

async function find({ id }: { id: number }) {
  const response = await axios.get(`/api/carriers/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: CarrierSchema,
    })
    .parseAsync(response.data);
}

function create(form) {
  return axios.post('/api/carriers', form);
}

function update(form, id) {
  return axios.post(`/api/carriers/${id}`, { _method: 'PUT', ...form });
}

function destroy(id) {
  return axios.post(`/api/carriers/${id}`, { _method: 'DELETE' });
}

export default {
  all,
  find,
  index,
  create,
  update,
  destroy,
};
