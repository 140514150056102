import { z } from 'zod';

export const DriverAccidentSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  driver_id: z.number(),
  description: z.string().nullable(),
  occurance_date: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});

export type DriverAccident = z.infer<typeof DriverAccidentSchema>;
