<template>
  <Dropdown
    ref="DropdownRef"
    :offset="[0, 10]"
    :strategy="strategy"
    :display-trigger="showDropdownTrigger"
    placement="bottom-start"
    :disabled="disabled"
    class="dropdown--search-in-dropdown"
    @show="onShow"
    @shown="onShown"
    @hide="onHide"
    @hidden="onHidden"
  >
    <!-- Input -->
    <template #trigger>
      <slot name="dropdown-trigger">
        <FormGroupDisplay :label="label" :theme="dropdownTriggerType" :required="required" :input-value="modelValue">
          {{ modelValue?.name }}
        </FormGroupDisplay>
      </slot>
    </template>

    <!-- Search -->
    <template #menu>
      <SearchResources
        ref="SearchResourcesRef"
        :haystack="searchHaystack"
        :replace-input-value="searchReplaceInputValue"
        :results-placement="resultsPlacement"
        :query-initial-value="searchQueryInitialValue"
        :empty-query-haystack="emptyQueryHaystack"
        :api-endpoint="searchEndpoint"
        :display-results-as-dropdown="false"
        :matches-type="searchMatchesType"
        @item:selected="(item) => emit('update:model-value', item)"
        @input:empty="(event) => emit('input:empty', event)"
      >
        <template #match-inner="{ match }">
          <slot name="match-inner" :match="match" />
        </template>
        <template #match="{ match }">
          <slot name="match" :match="match" />
        </template>
        <template #matches="{ matches }">
          <slot name="matches" :matches="matches" />
        </template>
        <template #matches-footer>
          <slot name="matches-footer" />
        </template>
      </SearchResources>
    </template>
  </Dropdown>
</template>

<script setup>
import { ref } from 'vue';
import Dropdown from '@/js/Common/Dropdown.vue';
import SearchResources from '@/js/Components/SearchResources.vue';
import FormGroupDisplay from '@/js/Common/Form/FormGroupDisplay.vue';

defineProps({
  modelValue: {
    type: Object,
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  strategy: {
    type: String,
    default: 'absolute',
    validator: (v) => ['absolute', 'fixed'].includes(v),
  },
  resultsPlacement: {
    type: String,
    default: 'bottom-start',
  },
  searchEndpoint: {
    type: String,
    default: null,
  },
  searchHaystack: {
    type: Array,
    default: null,
  },
  searchQueryInitialValue: {
    type: String,
    default: '',
  },
  searchReplaceInputValue: {
    type: Boolean,
    default: false,
  },
  searchMatchesType: {
    type: String,
    default: undefined,
  },
  emptyQueryHaystack: {
    type: Array,
    default: null,
  },
  showDropdownTrigger: {
    type: Boolean,
    default: true,
  },
  dropdownTriggerType: {
    type: String,
    default: 'text',
  },
});

const emit = defineEmits(['show', 'shown', 'hide', 'hidden', 'update:model-value', 'input:empty']);

const SearchResourcesRef = ref(null);

const onShow = () => emit('show');
const onShown = () => {
  SearchResourcesRef.value.$refs.triggerNode.focus();
  emit('shown');
};

const onHide = () => {
  emit('hide');
};
const onHidden = () => {
  emit('hidden');
};
</script>

<style lang="scss">
.dropdown--search-in-dropdown {
  // Dropdown trigger
  .dropdown__trigger {
    display: block;
    width: 100%;
  }

  .search {
    margin-bottom: 0;

    &__input-container {
      padding: rem(14px) rem(13px);
      border-bottom: rem(1px) solid color('gray', 3);
    }

    &__input {
      height: rem(28px);
      font-size: rem(14px);
    }

    &__matches-scrollable {
      max-height: 30vh;
    }
  }
}
</style>
