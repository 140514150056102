<template>
  <SidebarRightPanel class="sidebar-right-panel--tickets">
    <!-- Header -->
    <template #header>
      <div class="sidebar-right-panel--tickets__title">All tickets</div>
      <div class="sidebar-right-panel--tickets__status">
        <div class="sidebar-right-panel--tickets__status-left">
          {{ countOpenTickets }} open ticket{{ countOpenTickets > 1 ? 's' : '' }}
        </div>
        <div class="sidebar-right-panel--tickets__status-right">
          / {{ countResolvedTickets }} closed ticket{{ countResolvedTickets > 1 ? 's' : '' }}
        </div>
      </div>
    </template>

    <!-- Tickets list -->
    <template #body>
      <template v-if="tickets.length">
        <TicketListItem
          v-for="ticket in tickets"
          :key="ticket.id"
          :ticket="ticket"
          @click="emit('ticket:click', ticket)"
        />
      </template>
      <template v-else> No activity yet </template>
    </template>
  </SidebarRightPanel>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Ticket } from '@/js/Api/Ticket';
import TicketListItem from '@/js/Components/Tickets/ListItem.vue';
import SidebarRightPanel from '../../SidebarRightPanel.vue';

interface Props {
  tickets: Ticket[];
  clientId: number;
}

const props = defineProps<Props>();

const emit = defineEmits(['ticket:click']);

// Count
const countOpenTickets = computed(() => props.tickets.filter((t) => t.status === 'Open').length);
const countResolvedTickets = computed(() => props.tickets.filter((t) => t.status === 'Resolved').length);
</script>

<style lang="scss">
.sidebar-right-panel--tickets {
  // Body
  .sidebar-right-panel__body {
    padding-left: rem(32px);
    padding-right: rem(32px);

    .ticket:not(:last-child) {
      margin-bottom: rem(10px);
    }
  }

  // Title (Top)
  &__title {
    line-height: 1.2;
    font-size: rem(16px);
  }

  // Status (Top)
  &__status {
    font-size: rem(14px);

    &-left,
    &-right {
      display: inline;
    }

    &-right {
      color: color('gray', 5);
    }
  }
}
</style>
