<template>
  <FormGroup
    ref="FormGroupRef"
    :model-value="modelValue"
    type="textarea"
    class="autoexpandable"
    group-class="form-group--textarea-autoexpandable"
    :label-hidden="!label"
    :label-text="label"
    :after-autosave="afterAutosave"
    @input="expand"
    @update:model-value="(value) => emit('update:model-value', value)"
  />
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue';
import FormGroup from '@/js/Common/Form/FormGroup/FormGroup.vue';

interface Props {
  label?: string;
  modelValue: string | null;
  afterAutosave?: () => any;
}

defineProps<Props>();

const emit = defineEmits(['after-autosave', 'update:model-value']);

const FormGroupRef = ref<InstanceType<typeof FormGroup> | null>(null);

const expand = () => {
  if (FormGroupRef.value === null) return;
  const textarea = FormGroupRef.value.$refs.input as HTMLTextAreaElement;
  const offset = textarea.offsetHeight - textarea.clientHeight;
  textarea.style.height = 'auto';
  const newHeight = textarea.scrollHeight;
  textarea.style.height = `${newHeight + offset}px`;
};

onMounted(() => {
  nextTick(expand);
});
</script>

<style lang="scss">
textarea.autoexpandable {
  min-height: rem(62px);
}
</style>
