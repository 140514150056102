<template>
  <a class="button-add" href="#" @click.prevent="emit('click')">
    <SvgIcon v-if="theme === 'plus'" name="plus" />
    <SvgIcon v-else-if="theme === 'circle'" name="plus-circle" />
    <slot> Add </slot>
  </a>
</template>

<script setup lang="ts">
import SvgIcon from '@/js/Components/SvgIcon.vue';

interface Props {
  theme?: string;
}

interface Emits {
  (e: 'click'): void;
}

withDefaults(defineProps<Props>(), {
  theme: 'plus',
});

const emit = defineEmits<Emits>();
</script>

<style lang="scss">
a.button-add {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-size: rem(16px);
  line-height: rem(18px);
  color: color('primary');
  img {
    margin-right: rem(8px);
  }
}
</style>
