<template>
  <Dropdown
    ref="DropdownRef"
    strategy="fixed"
    class="input-select"
    :class="{
      'input-select--multivalue': multivalue,
    }"
    placement="bottom-start"
    :disabled="disabled"
    :close-on-inside-click="false"
    @shown="onDropdownShown"
  >
    <!-- Drodpown trigger -->
    <template #trigger>
      <slot name="trigger">
        <FormGroupDisplay :disabled="disabled" :required="required" :input-value="inputValue">
          <template #label>
            <slot name="label-container">
              <div class="form-group__label-container">
                <div class="form-group__label">
                  <slot name="label">
                    {{ labelComputed }}
                  </slot>
                  <CopyBtn @click.stop="copyValue" />
                </div>
                <div class="form-group__controls">
                  <slot name="controls" />
                </div>
              </div>
            </slot>
          </template>
          <slot name="selected-option" :item="modelValue">
            <template v-if="multivalue && modelValue">
              <span
                v-for="(selectedOption, i) in modelValue"
                :key="i"
                class="input-select__selected-option"
                @click.stop
              >
                {{ selectedOption[labelMethod] }}
                <SvgIcon name="close" @click="onSelect(selectedOption, false)" />
              </span>
            </template>
            <template v-else-if="modelValue">
              {{ modelValue[labelMethod] }}
            </template>
          </slot>
        </FormGroupDisplay>
      </slot>
    </template>

    <!-- Dropdown body -->
    <template #menu>
      <!-- Search -->
      <div class="input-select__search">
        <SvgIcon name="search" />
        <input
          ref="searchInputRef"
          v-model="searchQuery"
          class="input-select__search-input"
          type="text"
          :format-value="(_, val) => (val === null ? undefined : val)"
          @keydown.stop="onSearchKeydown"
        />
      </div>

      <!-- Filters -->
      <template v-if="filterCheckboxes?.length">
        <InputCheckbox
          v-for="(checkbox, i) in filterCheckboxes"
          :key="i"
          v-model="filters[checkbox.name]"
          :label="checkbox.label"
          class="input-select__filter"
          @update:model-value="
            (value) => (value === null ? (filters[checkbox.name] = undefined) : (filters[checkbox.name] = value))
          "
        />
      </template>

      <!-- Options -->
      <div ref="optionsRef" class="input-select__options">
        <slot name="prefix"></slot>

        <InputCheckbox
          v-for="(option, i) in optionsComputed"
          :key="checkboxRerenderKey(option)"
          ref="optionRefs"
          :model-value="isChecked(option)"
          class="input-select__option"
          :class="currentFocus === i ? 'input-select__option--focused' : ''"
          :validator="canUncheck"
          @update:model-value="(checked) => onSelect(option, checked)"
        >
          <template #label>
            <slot name="item" :item="option">
              {{ option[labelMethod] }}
              <span class="input-select__option-model-type">
                {{ option.model_type }}
              </span>
            </slot>
          </template>
        </InputCheckbox>
      </div>
    </template>
  </Dropdown>
</template>

<script setup>
import { ref, computed } from 'vue';
import Clipboard from '@/js/Helpers/Clipboard';
import Dropdown from '@/js/Common/Dropdown.vue';
import CopyBtn from '@/js/Components/CopyBtn.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import InputCheckbox from '@/js/Common/Form/Input/Checkbox.vue';
import FormGroupDisplay from '@/js/Common/Form/FormGroupDisplay.vue';
import { useElementSize } from '@vueuse/core';

const props = defineProps({
  modelValue: {
    type: null,
    default: null,
  },
  options: {
    type: Array,
    required: true,
  },
  multivalue: {
    type: Boolean,
    default: false,
  },
  labelMethod: {
    type: String,
    default: 'label',
  },
  label: {
    type: String,
    default: null,
  },
  filterCheckboxes: {
    type: Array,
    default: null,
  },
  disabled: {
    type: Boolean,
    required: true,
  },
  required: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['update:model-value', 'selected']);

// State
const searchQuery = ref();
const filters = ref({});
const currentFocus = ref(-1);
const optionRefs = ref(null);
const optionsRef = ref(null);
const DropdownRef = ref(null);
const searchInputRef = ref(null);
const optionRerenderKey = ref(1);

const optionsComputed = computed(() => {
  if (!searchQuery.value) return props.options;
  const queryLowerCase = searchQuery.value.toLowerCase();
  return props.options.filter((option) => {
    if (!option.value) return false;
    return option.value.toLowerCase().search(queryLowerCase) !== -1;
  });
});

const { width } = useElementSize(DropdownRef);

const computedWidth = computed(() => {
  return width.value ? `${width.value}px` : '100%';
});

const onDropdownShown = () => {
  searchInputRef.value.focus();
};

const removeOptionAndReturnOptions = (option) => {
  const newModelValue = [...props.modelValue];
  const index = newModelValue.findIndex((opt) => opt.model_type === option.model_type && opt.value === option.value);
  newModelValue.splice(index, 1);
  return newModelValue;
};

const onSelect = (option, checked) => {
  let newModelValue = null;
  if (!props.multivalue && checked) newModelValue = option;
  else if (!props.multivalue && !checked) newModelValue = null;
  else if (props.multivalue && checked) newModelValue = props.modelValue.concat(option);
  else if (props.multivalue && !checked) newModelValue = removeOptionAndReturnOptions(option);

  emit('update:model-value', newModelValue);
  emit('selected', { option, checked });
};

const onSearchKeydown = (e) => {
  if (![40, 38, 13].includes(e.keyCode)) return;

  e.preventDefault();

  const items = optionRefs.value;

  if (e.keyCode === 40) {
    currentFocus.value += 1;
  } else if (e.keyCode === 38) {
    currentFocus.value -= 1;
  } else if (e.keyCode === 13) {
    if (currentFocus.value < 0 || currentFocus.value > optionsComputed.value - 1) return;
    items[currentFocus.value].$el.click();
    searchInputRef.value.focus();
    return;
  }

  if (currentFocus.value <= -1) currentFocus.value = 0;
  else if (currentFocus.value >= items.length) currentFocus.value = items.length - 1;
};

const multivalueIdsMap = computed(() => {
  if (!props.modelValue) return null;
  return props.modelValue.reduce((acc, curr) => {
    acc[`${curr.value}-${curr.model_type}`] = true;
    return acc;
  }, {});
});

const isChecked = (option) => {
  if (!props.modelValue) return false;
  if (!props.multivalue)
    return option.value === props.modelValue.value && option.model_type === props.modelValue.model_type;
  return !!multivalueIdsMap.value[`${option.value}-${option.model_type}`];
};

const inputValue = computed(() => {
  if (!props.modelValue) return null;
  if (props.multivalue) return props.modelValue.length > 0 ? 1 : null;
  return props.modelValue ? 1 : null;
});

const labelComputed = computed(() => {
  if (!props.label) return null;
  if (props.multivalue && props.modelValue) return `${props.label} (${props.modelValue.length})`;
  if (!props.multivalue && props.modelValue) return `${props.label} (${props.modelValue.model_type})`;
  return props.label;
});

const canUncheck = (event) => {
  if (props.multivalue) return true;
  if (event.target.checked === false && props.required) return false;
  return true;
};

const checkboxRerenderKey = (option) => {
  const result = `${option.value}-${option.model_type}`;
  if (props.multivalue) return result;
  if (!props.modelValue) return result;
  return `${optionRerenderKey.value}-${result}-${props.modelValue.value}-${props.modelValue.model_type}`;
};

const copyValue = () => {
  if (!props.modelValue) return null;
  if (props.multivalue) Clipboard.copy(props.modelValue.map((v) => v[props.labelMethod]).join(', '));
  else Clipboard.copy(props.modelValue[props.labelMethod]);
};
</script>

<style lang="scss">
$option-padding-x: 0.8em;
$search-icon-width: 1.25em;

.input-select {
  // Trigger
  .dropdown__trigger {
    width: 100%;
    .form-group-display {
      width: 100%;
      min-height: rem(48px);
      margin-bottom: 0;
      .form-group__label-container,
      .form-group__label,
      .form-group__controls {
        display: flex;
        align-items: center;
      }
      .form-group__label-container {
        @include form-label;
        padding-top: rem(4px);
        justify-content: space-between;
      }
    }
  }

  // Search
  &__search {
    position: relative;

    .svg-icon {
      top: 0.25em;
      left: $option-padding-x;
      color: #999;
      width: $search-icon-width;
      position: absolute;
    }

    &-input {
      width: 100%;
      font-family: inherit;
      font-size: 1.1em;
      padding: 0.3em 0.3em 0.3em #{$option-padding-x + $search-icon-width + 0.25em};
      border-radius: rem(3px);
      border: rem(1px) solid color('gray', 3);
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

      &:focus {
        outline: 0;
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
      }
    }
  }

  // Filters
  &__filter {
    justify-content: center;
    padding: 1em $option-padding-x;
    border-bottom: rem(1px) solid #ccc;
  }

  .dropdown__menu {
    //width: 100%;
    max-height: unset;
  }

  .dropdown--fixed .dropdown__menu {
    width: v-bind(computedWidth);
  }

  &__options {
    max-height: 50vh;
    overflow: auto;
  }

  &__option {
    display: flex;
    padding: 0.5em $option-padding-x;
    transition: 0.1s background-color;

    &:not(:last-child) {
      border-bottom: rem(1px) solid #ccc;
    }

    &:hover,
    &--focused {
      background-color: #e3e3e3;
    }

    .checkbox__label {
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-model-type {
      display: block;
      color: color('gray', 5);
      line-height: 1;
    }
  }

  &__infinite-scroll-trigger {
    display: flex;
    justify-content: center;
    padding: 0.5em 1em;
  }

  // Multivalue
  &--multivalue {
    // Trigger
    .dropdown__trigger {
      .form-group-display__content {
        padding-top: rem(7.5px);
        padding-bottom: rem(5px);
      }

      .form-group-display__content-value {
        overflow: unset;
        white-space: unset;
        text-overflow: unset;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .input-select__selected-option {
        display: flex;
        align-items: center;
        font-size: rem(16px);
        padding: 0 rem(7.5px);
        background-color: color('gray', 2);
        cursor: default;

        .svg-icon {
          cursor: pointer;
          width: rem(15px);
          margin-left: rem(10px);
        }

        &:not(:first-child) {
          margin-top: rem(7px);
        }
      }
    }
  }
}
</style>
