export const toPlural = function (string) {
  let last1 = string.slice(-1);
  if (last1 === 'y') return string.slice(0, string.length - 1) + 'ies';
  else if (last1 === 'x') return string + 'es';
  else if (last1 === 's') return string + 'es';
  else return string + 's';
};

export const toCapitalized = function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
String.prototype.decapitalize = function () {
  return this.charAt(0).toLowerCase() + this.slice(1);
};
String.prototype.toFloat = function () {
  let float = parseFloat(this.replace(',', ''));
  return isNaN(float) ? 0 : float;
};
String.prototype.shorten = function (n) {
  return this ? (this.length > n ? this.substring(0, n) + '...' : this) : '';
};

export default {
  toPlural,
  toCapitalized,
};
