import { z } from 'zod';

export const CargoTypeSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  intake_ca_id: z.number(),
  type: z
    .string()
    .transform((value) => value.toLowerCase())
    .nullable(),
  percentage: z.number().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});
