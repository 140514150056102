import { z } from 'zod';

export const PriorExistingInsuranceSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  intake_ca_id: z.number(),
  prior_carrier: z.string().nullable(),
  prior_coverage: z.string().nullable(),
  prior_duration: z.string().nullable(),
  prior_price: z.string().nullable(),
  prior_loss_runs_available: z.boolean().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});
