<template>
  <Modal ref="ModalRef" class="modal--note" :is-loading="loading" @modalhidden="$emit('hidden:modal')">
    <!-- Header -->
    <template #header="{ hide }">
      <NoteModalHeader
        :mode="mode"
        :note="note"
        :client-name="clientName"
        :primary-contact="primaryContact"
        @modal:hide="hide"
        @update:note="(note) => $emit('update:note', note)"
      />
    </template>

    <!-- Body -->
    <NoteModalBody
      v-model:loading="loading"
      :mode="mode"
      :note="note"
      :ticket="ticket"
      :tickets="tickets"
      :can-edit="canEdit && canEditClient"
      @update:note="(note) => $emit('update:note', note)"
      @update:subnote="(subnote) => $emit('update:subnote', subnote)"
      @create:subnote="(subnote) => $emit('create:subnote', subnote)"
      @deleted:subnote="(subnote) => $emit('deleted:subnote', subnote)"
    />

    <!-- Footer -->
    <template #footer="{ hide }">
      <div class="flex">
        <Btn
          v-if="canEdit && canEditClient && note.permissions.edit"
          variant="outline"
          @click="mode = mode === 'View' ? 'Edit' : 'View'"
        >
          Switch to {{ mode === 'View' ? 'Edit' : 'View' }}
        </Btn>
        <Btn
          v-if="canEdit && canEditClient && note.permissions.edit && me.permissions['ticket_notes.delete']"
          class="ml-2"
          color="danger"
          @click="deleteNote"
        >
          Delete
        </Btn>
      </div>
      <Btn @click="hide">Close</Btn>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { watch, ref, computed } from 'vue';
import { me } from '@/js/Helpers/Session';
import { confirm, prompt, toastSuccess, toastError } from '@/js/Helpers/Alert';
import ClientApi, { Client, PrimaryContact } from '@/js/Api/Client';
import TicketApi, { Ticket } from '@/js/Api/Ticket';
import TicketNoteApi, { TicketNote } from '@/js/Api/TicketNote';
import Btn from '@/js/Components/Btn.vue';
import Loader from '@/js/Helpers/Loader';
import Modal from '@/js/Common/Modal.vue';
import NoteModalHeader from './NoteModalHeader.vue';
import NoteModalBody from './NoteModalBody.vue';

interface Props {
  note: TicketNote;
  tickets?: Ticket[] | null;
  promptToResolveTicket?: boolean;
  canEdit?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  tickets: null,
  canEdit: true,
  promptToResolveTicket: true,
});

const emit = defineEmits([
  'hidden:modal',
  'update:note',
  'update:subnote',
  'create:subnote',
  'deleted:note',
  'deleted:subnote',
]);

const loading = ref(false);

// View and edit mode
const mode = ref<'View' | 'Edit'>('View');

const ModalRef = ref<InstanceType<typeof Modal> | null>(null);

// Fetch client
const client = ref<Client | null>(null);
if (props.note.client_id)
  ClientApi.find({ id: props.note.client_id }).then((response) => (client.value = response.data));

const canEditClient = computed(() => (client.value && client.value.canThisUserEdit) || !props.note.client_id);

// Fetch tickets
const ticketsLocal = ref(props.tickets ?? []);
if (!props.tickets && props.note.client_id)
  TicketApi.all({
    queryBuilder: {
      filters: {
        client_id: props.note.client_id,
      },
      sorts: [
        {
          name: 'created_at',
          order: 'desc',
        },
      ],
      includes: ['notes'],
    },
  }).then(({ data }) => (ticketsLocal.value = data));

// Client name
const clientName = computed(() => client.value?.name ?? null);

// Ticket
const ticket = computed(() => ticketsLocal.value.find((t) => t.id === props.note.ticket_id) ?? null);

// Resolve a ticket when status of a note or subnotes change
if (props.promptToResolveTicket) {
  const noteStatuses = computed(() => {
    return props.note.status + props.note.notes.filter((n) => n.is_followup).reduce((c, n) => c + n.status, '');
  });

  watch(noteStatuses, async () => {
    // Get ticket status
    if (!ticket.value) return;
    const ticketStatus = await TicketApi.getStatus({ id: ticket.value.id })
      .then(({ data }) => data)
      .catch(() => {
        toastSuccess('An error occurred while fetching ticket status.');
      });

    // If user resolved last followup in an open ticket
    if (ticketStatus.status === 'Open' && ticketStatus.open_followups_count === 0) {
      const confirmResult = await confirm({
        icon: 'question',
        allowOutsideClick: false,
        confirmButtonText: 'Resolve ticket',
        text: "This ticket doesn't have any open followups anymore, do you want to close it now?",
      });

      if (confirmResult.isConfirmed)
        TicketApi.update({
          id: ticket.value.id,
          body: {
            status: 'Resolved',
          },
        });
    }
  });
}

const deleteNote = async () => {
  const result = await prompt({
    title: 'Are you sure?',
    text: 'Type in "delete" to confirm',
  });

  if (!result.value || result.value.toLowerCase() !== 'delete') return;

  Loader.start();
  TicketNoteApi.softDelete({ id: props.note.id })
    .then(() => {
      toastSuccess();
      emit('deleted:note', props.note);
      ModalRef.value.hide();
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      Loader.end();
    });
};

// Primary contact
const primaryContact = ref<PrimaryContact | null>(null);
if (props.note.client_id)
  ClientApi.primaryContact({ id: props.note.client_id }).then(({ data }) => (primaryContact.value = data));
</script>

<style lang="scss">
.modal--note {
  // Header
  .modal__header {
    padding: 0;
  }

  // Footer
  .modal__footer {
    justify-content: space-between;
  }
}
</style>
