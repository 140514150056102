import { z } from 'zod';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { CoverageTypeSchema } from './CoverageType';

const CGLRatingsSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  intakes_cgl_id: z.number().nullable(),
  rating_type: z.string().nullable(),
  rating_value: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});

export const IntakeCGLSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  policy_id: z.number().nullable(),
  quote_group_id: z.number().nullable(),
  is_complete: z.boolean(),
  Limits: z.string(),
  Per_Occurrence: z.string().nullable(),
  Aggregate: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullable(),
  coverage_type: CoverageTypeSchema,
  cgl_ratings: z.array(CGLRatingsSchema).nullish(),
});

export type IntakeCGL = z.infer<typeof IntakeCGLSchema>;

async function find({ clientId, id }: { clientId: number; id: number }) {
  const response = await axios.get(`/api/clients/${clientId}/intakes/cgl/${id}`).catch((error) => {
    Sentry.captureException(error);
  });

  if (!response) return undefined;

  return z
    .object({
      data: IntakeCGLSchema,
    })
    .parseAsync(response.data);
}

export default {
  find,
};
