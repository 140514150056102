<template>
  <FormGroup
    :model-value="selectedOption"
    type="ajax-select"
    label-method="name"
    label="Assignee"
    class="assignee-input"
    :disabled="disabled"
    :fetch-options="fetchOptions"
    :multivalue="false"
    :on-select="onSelect"
  >
    <template #selected-option>
      <slot name="selected-option" />
    </template>
  </FormGroup>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import FormGroup from '@/js/Components/Form/FormGroup.vue';
import TicketNoteAssigneeApi, { TicketNoteAssignee } from '@/js/Api/TicketNoteAssignee';

export type Option = {
  id: number;
  name: string;
  model_type: string;
};

type ModelValue = TicketNoteAssignee | null | undefined;

interface Props {
  modelValue: ModelValue;
  disabled?: boolean;
  onSelect: Function;
}

const props = defineProps<Props>();

const selectedOption = computed(() => {
  if (props.modelValue)
    return {
      id: props.modelValue.id,
      name: props.modelValue.name,
      model_type: props.modelValue.model_type,
    };
  return null;
});

const fetchOptions = (query: string | undefined, page: number) => {
  return TicketNoteAssigneeApi.index({
    queryBuilder: {
      page,
      filters: {
        name: query,
      },
    },
  });
};
</script>
