<template>
  <div class="pending-approval">
    <div class="pending-approval__title">Non renewed / final cancel pending approval</div>
    <div class="pending-approval__content">
      <router-link :to="{ name: 'PoliciesPendingApproval' }" class="link"> {{ count ?? 0 }} policies </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import PolicyApi from '@/js/Api/Policy';
import useEcho from '@/js/Composables/useEcho';

const count = ref<number | null>(null);
PolicyApi.count({
  filters: {
    non_renewed_requested: true,
  },
}).then(({ data }) => {
  count.value = data;
});

useEcho()
  .channel('non-renewed-policies-pending-approval-count')
  .listen('.update', (event) => {
    if (count.value === null) return;
    if (event.sign === '+') count.value += 1;
    if (event.sign === '-') count.value -= 1;
  });
</script>

<style lang="scss" scoped>
.pending-approval {
  display: flex;
  flex-direction: column;
  gap: rem(15px);

  &__title {
    padding-left: rem(17px);
    color: #505355;
    font-size: rem(12px);
    line-height: rem(14px);
  }

  &__content {
    padding-left: rem(11px);

    .link {
      padding: rem(13px) rem(17px) rem(14px) rem(11px);
      border-radius: rem(22px);
      background-color: #dfe2e6;
      color: #505355;
      font-size: rem(16px);
      line-height: rem(19px);
    }
  }
}
</style>
