<template>
  <!-- Followup create form -->
  <FollowupCreateForm
    v-if="!note.is_followup && mode === 'Edit' && showCreateFollowupForm"
    @submit="convertToFollowup"
  />

  <!-- Convert to followup btn -->
  <Btn
    v-if="!note.is_followup && mode === 'Edit' && !showCreateFollowupForm"
    variant="outline"
    class="modal--note__btn-followup"
    @click="showCreateFollowupForm = true"
  >
    <SvgIcon name="sticky-note" />
    Convert to followup
  </Btn>

  <!-- Select ticket (edit mode) -->
  <TicketSelect
    v-if="ticket && tickets && mode === 'Edit' && me.permissions['transfer-notes']"
    :ticket="ticket"
    :tickets="tickets"
    :disabled="!me.permissions['transfer-notes'] || ticket.notes.length === 1"
    :disabled-reason="
      ticket.notes.length === 1 ? 'Can\'t transfer the last note in a ticket' : 'You don\'t have sufficient permissions'
    "
    @update:ticket="transferNote"
  />

  <!-- Ticket (view mode) -->
  <div v-else-if="ticket" class="modal--note__ticket--view">
    <strong class="modal--note__ticket-type">
      {{ ticket.type }}
    </strong>
    {{ ticket.subject }}
  </div>

  <!-- Note content (email) -->
  <!-- <MailboxMessageIframe v-if="note.mailbox_message" :value="note.mailbox_message" /> -->

  <!-- Note content (non email, edit mode) -->
  <TextareaAutoExpandable
    v-if="me.permissions['edit-note-content'] && mode === 'Edit'"
    v-model="noteLocal.content"
    label="Note content"
    name="content"
    :autosave-url="`/api/notes/${note.id}/autosave`"
    :after-autosave="emitNoteUpdate"
  />

  <!-- Note content (non email, view mode) -->
  <div v-else class="modal--note__content--view" v-html="noteLocal.content.replaceAll('\n', '<br>')"></div>

  <!-- Note list controls -->
  <div class="modal--note__list-controls" :class="`modal--note__list-controls--${mode.toLowerCase()}`">
    <!-- Icon -->
    <SvgIcon class="modal--note__list-controls-icon" :name="listView === 'Subnotes' ? 'list-pyramid' : 'clock-arrow'" />

    <!-- Active view -->
    <span class="modal--note__list-controls-active">{{ listView }} View</span>

    <span>-</span>

    <!-- Inactive view -->
    <a class="modal--note__list-controls-inactive" @click="listView = listView === 'Subnotes' ? 'History' : 'Subnotes'">
      Switch to {{ listView === 'Subnotes' ? 'History' : 'Subnotes' }} View
    </a>
  </div>

  <!-- List -->
  <div class="modal--note__list">
    <!-- Subnotes view -->
    <template v-if="listView === 'Subnotes'">
      <NoteItem
        v-for="(subnote, i) in noteLocal.notes"
        :key="`${subnote.id}-${subnote.is_followup}`"
        v-model:note="noteLocal.notes[i]"
        followup-update-form-type="big"
        @update:note="(subnote) => emit('update:subnote', subnote)"
        @deleted:note="(subnote) => emit('deleted:subnote', subnote)"
      />
    </template>

    <!-- History view -->
    <HistoryView v-else :note="note" />

    <!-- Subnote form -->
    <CreateSubnoteForm
      v-if="canEdit && note.permissions.edit"
      :key="note.notes.length"
      :note="note"
      :show-discard-btn="false"
      @post="addSubnote"
    />
  </div>
</template>

<script setup lang="ts">
import { Ticket } from '@/js/Api/Ticket';
import TicketNoteApi, { TicketNote } from '@/js/Api/TicketNote';
import { ref, computed } from 'vue';
import { me } from '@/js/Helpers/Session';
import { toastSuccess, toastError, confirm } from '@/js/Helpers/Alert';
import useClientStore from '@/js/store/pinia/useClientStore';
import Btn from '@/js/Components/Btn.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import NoteItem from '@/js/Components/Tickets/NoteItem/NoteItem.vue';
import TicketSelect, { Value as TicketSelectValue } from '@/js/Components/Tickets/TicketSelect.vue';
import TextareaAutoExpandable from '@/js/Components/TextareaAutoExpandable.vue';
import CreateSubnoteForm from '@/js/Components/Tickets/CreateSubnoteForm.vue';
// import MailboxMessageIframe from '@/js/Components/Mailbox/MailboxMessageIframe.vue';
import FollowupCreateForm, { EventSubmitPayload } from '@/js/Components/Tickets/FollowupCreateForm.vue';
import HistoryView from './HistoryView.vue';

interface Props {
  mode: 'View' | 'Edit';
  note: TicketNote;
  ticket: Ticket | null;
  tickets: Ticket[] | null;
  canEdit?: boolean;
  loading: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits(['update:note', 'update:subnote', 'create:subnote', 'deleted:subnote', 'update:loading']);

const store = useClientStore();

// Local state
const noteLocal = computed({
  get: () => ({ ...props.note }),
  set: (note) => emit('update:note', note),
});

const showCreateFollowupForm = ref(false);

const listView = ref('Subnotes');

// Update state
const emitNoteUpdate = () => emit('update:note', noteLocal.value, true);

// Convert to followup
const convertToFollowup = (form: EventSubmitPayload) => {
  const payload = {
    ...form,
    content: props.note.content,
  };

  // Request
  emit('update:loading', true);
  TicketNoteApi.create(payload)
    .then((response) => {
      emit('update:note', response.data.note);
      toastSuccess('Followup created');
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      emit('update:loading', false);
    });
};

// Transfer note to a different ticket
const transferNote = async (toTicket: TicketSelectValue) => {
  // Prompt
  const result = await confirm({
    icon: 'warning',
    title: 'Transfer note',
    confirmButtonText: 'Transfer',
    text: 'This note will be transfered to the selected ticket. Are you sure?',
  });
  if (!result.isConfirmed) return;

  // Transfer
  emit('update:loading', true);
  TicketNoteApi.transfer({
    id: props.note.id,
    toTicket: typeof toTicket === 'string' ? toTicket : toTicket.id,
  })
    .then(({ data }) => {
      const rNote = data.note;
      const rToTicket = data.toTicket;
      store.transferNote({
        note: rNote,
        toTicket: rToTicket,
        fromTicketId: props.note.ticket_id,
      });
      Object.assign(props.note, rNote);
      toastSuccess();
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      emit('update:loading', false);
    });
};

// Add subnote
const addSubnote = (content: string) => {
  const payload = {
    note_id: props.note.id,
    client_id: props.note.client_id,
    ticket_id: props.note.ticket_id,
    content,
    is_followup: false,
  };

  emit('update:loading', true);
  TicketNoteApi.create(payload)
    .then(({ data }) => {
      emit('create:subnote', data);
      toastSuccess('Note updated');
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      emit('update:loading', false);
    });
};
</script>

<style lang="scss">
.modal--note {
  .modal__body {
    overflow: auto;
    max-height: rem(780px);
    padding: rem(20px) rem(28px);
  }

  // Followup btn
  .modal__body > &__btn-followup {
    margin-bottom: rem(20px);

    .svg-icon {
      margin-right: rem(10px);
    }
  }

  // Create followup form
  .modal__body > .create-followup-form {
    display: inline-flex;
    margin-bottom: rem(20px);
    padding-bottom: rem(10px);
    border-bottom: rem(1px) solid color('gray', 3);

    &__assign-dropdown {
      flex: inherit;
    }
  }

  // Ticket (view mode)
  &__ticket--view {
    font-size: rem(14px);
    color: color('gray', 7);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__ticket-type {
    color: color('primary');
    margin-right: rem(5px);
  }

  .mailbox-message-iframe {
    height: 35vh;
  }

  // Note content (edit mode)
  .modal__body > .form-group--textarea-autoexpandable {
    margin-top: rem(18px);
    margin-bottom: 0;

    .form-group__input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  // Note content (view mode)
  &__content--view {
    max-height: rem(350px);
    margin-top: rem(16px);
    margin-bottom: rem(20px);
    overflow: auto;
  }

  // Controls
  &__list-controls {
    display: flex;
    align-items: center;
    padding-left: rem(10px);
    margin-bottom: rem(12px);
    height: rem(30px);
    font-size: rem(13px);
    background-color: #f7f5f5;
    border: rem(1px) solid color('gray', 3);
    border-top: none;
    border-bottom-left-radius: rem(3px);
    border-bottom-right-radius: rem(3px);
    color: color('gray');

    &--view {
      border-top: rem(1px) solid color('gray', 3);
    }

    > * {
      margin-right: rem(6px);
    }

    .svg-icon {
      width: rem(16px);
      height: rem(16px);
      color: color('gray');
    }

    &-inactive {
      color: color('primary');
    }
  }

  // Subnotes
  &__list {
    padding-left: rem(30px);
  }

  .note__body {
    cursor: unset !important;
  }

  .note__user .user-circle {
    width: rem(29px);
    height: rem(29px);
    font-size: rem(15px);
  }

  .create-subnote-form {
    margin-top: rem(10px);
  }
}
</style>
