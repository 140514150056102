<template>
  <div
    :class="{
      note__body: true,
      'note__body--view': true,
      [`note__body--${note.status?.toLowerCase()}`]: note.is_followup,
      pulse: note.pulse,
    }"
    @click="$emit('click:note')"
  >
    <!-- Priority indicator -->
    <div v-if="note.is_followup" class="note__priority-weight">
      <div
        v-if="note.status === 'Open'"
        class="note__weight"
        :class="`note__weight--${note.priority_readable.toLowerCase()}`"
      >
        {{ note.weight }}
      </div>
      <div class="note__priority" :class="`note__priority--${note.priority_readable.toLowerCase()}`"></div>
    </div>

    <!-- Timestamps -->
    <div class="note__timestamps">
      <div class="note__timestamp note__timestamp--created-at">
        Created: {{ new Date(note.created_at).toDateSlash() }}
      </div>
      <div v-if="!note.note_id" class="note__timestamps-divider"></div>
      <div v-if="!note.note_id" class="note__timestamp note__timestamp--updated-at">
        Updated: {{ new Date(note.updated_at).toDateSlash() }}
      </div>
    </div>

    <!-- Created by (note) -->
    <div v-if="!note.is_followup" class="note__user note__user--created-by">
      <UserCircle :initials="note.is_followup ? note.followup_created_by.initials : note.created_by.initials" />
      <label class="note__user-label">
        {{ shortenUserName(note.is_followup ? note.followup_created_by.name : note.created_by.name) }}
      </label>
    </div>

    <!-- Followup info -->
    <div v-else class="note__top">
      <div class="note__users">
        <div class="note__user note__user--created-by">
          <UserCircle :initials="note.is_followup ? note.followup_created_by.initials : note.created_by.initials" />
          <label class="note__user-label">
            {{ shortenUserName(note.is_followup ? note.followup_created_by.name : note.created_by.name) }}
          </label>
        </div>
        <SvgIcon class="note__assignee-arrow" name="arrow-right" />
        <a class="note__user note__user--assignee" @click="notifyAssignee">
          <SvgIcon name="bell" />
          <UserCircle :initials="note.assigned_to.initials" />
          <label class="note__user-label">{{ shortenUserName(note.assigned_to.name) }}</label>
        </a>
      </div>

      <!-- Priority box -->
      <SvgIcon :name="`followup-${note.priority_readable.toLowerCase()}`" class="svg-icon--priority" />

      <!-- Due -->
      <div class="note__due">
        <label class="note__due-label">Due:</label>
        <span class="note__due-date">
          {{ new Date(note.due).toDateSlash() }}
        </span>
      </div>

      <!-- Callback -->
      <SvgIcon name="callback" :color="note.callback ? '#5ac10e' : '#cccccc'" />

      <!-- Watch tooltip -->
      <WatchTooltip :watchers="note.watchers" />

      <!-- Resolved indicator -->
      <div v-if="note.status === 'Resolved'" class="note__resolved-indicator">
        <SvgIcon name="checkmark-circle" color="#39B529" />
        RESOLVED
      </div>
    </div>

    <!-- Attachments -->
    <NoteAttachments :attachment-paths="note.attachment_paths" :disabled="true" />

    <!-- Content -->
    <div class="note__content" v-html="noteContent"></div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import TicketNoteApi from '@/js/Api/TicketNote';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import UserCircle from '@/js/Components/UserCircle.vue';
import WatchTooltip from '@/js/Components/Tickets/WatchTooltip.vue';
import NoteAttachments from '@/js/Components/Tickets/NoteAttachments.vue';
import { shortenUserName } from '@/js/Presenters/User';
import { confirm, toastError, toastSuccess } from '@/js/Helpers/Alert';
import Loader from '@/js/Helpers/Loader';

const props = defineProps({
  note: {
    type: Object,
    required: true,
  },
});

defineEmits(['click:note']);

const noteContent = computed(() => props.note.content.replaceAll('\n', '<br>'));

const notifyAssignee = async () => {
  const result = await confirm({
    icon: 'question',
    confirmButtonText: 'Confirm',
    text: `Notify ${props.note.assigned_to.name}?`,
  });

  if (!result.isConfirmed) return;

  Loader.start();
  TicketNoteApi.notify({ id: props.note.id })
    .then(() => {
      toastSuccess('Notification sent');
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      Loader.end();
    });
};
</script>

<style lang="scss">
.note__body {
  $note: '.note';

  &--view {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: rem(5px) rem(9px) rem(8px) rem(20px);

    // Priority and weight
    position: relative;
    #{$note}__priority-weight {
      top: rem(3px);
      bottom: 0;
      left: rem(5px);
      position: absolute;
      height: calc(100% - #{rem(8px)});
      display: flex;
      flex-direction: column;
    }
    #{$note}__weight {
      text-align: center;
      font-size: rem(13px);
      line-height: 1.1;

      &--low {
        color: color('followup-priority', 1);
      }

      &--medium {
        color: color('followup-priority', 2);
      }

      &--high {
        color: color('followup-priority', 3);
      }

      &--urgent {
        color: color('followup-priority', 4);
      }
    }
    #{$note}__priority {
      flex: 1;
      width: rem(9px);
      border-radius: rem(2px);

      &--low {
        background-color: color('followup-priority', 1);
      }

      &--medium {
        background-color: color('followup-priority', 2);
      }

      &--high {
        background-color: color('followup-priority', 3);
      }

      &--urgent {
        background-color: color('followup-priority', 4);
      }
    }

    // Timestamps (center)
    #{$note}__timestamps {
      align-self: flex-end;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    #{$note}__timestamp {
      color: color('gray');
      font-size: rem(12px);
      white-space: nowrap;

      &--updated-at {
        color: color('gray', 7);
      }
    }
    #{$note}__timestamps-divider {
      width: rem(1px);
      height: rem(9px);
      margin: 0 rem(3.5px);
      background-color: color('gray', 3);
    }

    // Top
    #{$note}__top {
      display: flex;
      align-items: center;
      margin-top: rem(5px);

      > *:not(:first-child) {
        margin-left: rem(20px);
      }
    }

    .svg-icon--priority,
    .svg-icon--callback,
    .watch--tooltip {
      width: rem(24px);
    }

    // Users
    #{$note}__users {
      padding-top: rem(5px);
      display: flex;
    }

    #{$note}__user--assignee {
      position: relative;
      .svg-icon--bell {
        top: rem(2px);
        display: none;
        color: white;
        position: absolute;
        width: rem(16px);
      }
      &:hover {
        .svg-icon--bell {
          display: unset;
        }
        .user-circle__text {
          display: none;
        }
      }
    }

    // Assigned arrow
    #{$note}__assignee-arrow {
      margin: rem(4px) rem(5px) 0 rem(5px);
      min-width: rem(24px);
      color: color('gray', 3);
    }

    // Due
    #{$note}__due {
      display: flex;
      align-items: center;
      flex-direction: column;

      // padding-top: rem(5px);
      &-label {
        line-height: 1;
        color: color('gray', 3);
        font-size: rem(12px);
      }

      &-date {
        display: block;
        font-size: rem(15px);
      }
    }
  }

  // Resolved state
  #{$note}__resolved-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $success;
    text-align: center;
    font-size: rem(10px);
    margin-left: rem(14px);
    margin-right: rem(10px);
    line-height: 1;

    .svg-icon {
      color: $success;
    }
  }

  &--resolved {
    border-color: $success !important;
    #{$note}__priority {
      height: 90%;
      background-color: $success;
    }
  }

  // Attachments
  .note-attachments {
    margin-top: rem(15px);
  }

  // Content
  #{$note}__content {
    padding: rem(5px);
    padding-bottom: 0;
    margin-top: rem(10px);
  }
}

.note:not(.note--expanded) .note__content {
  max-height: rem(65px);
  overflow: hidden;
}
</style>
