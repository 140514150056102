<template>
  <div class="client-layout__main edit-view">
    <!-- Header -->
    <div class="edit-view__header">
      <div class="left">
        <h5>
          <img src="/img/icons/info-black.svg" />
          General Info
        </h5>

        <DropdownCoverages @click:confirm="createQuoteGroup" />
      </div>

      <!-- Complete button -->
      <div class="right">
        <button class="btn btn-secondary" type="button" @click="goToMainView">Save without coverage</button>
      </div>
    </div>

    <!-- Body -->
    <div class="edit-view__body">
      <!-- Warnings -->
      <ProducerWarning v-if="producer" :producer-id="producer.id" :producer-type="producer.model_type" />

      <!-- General intake form -->
      <IntakeGeneral :client="client" :disabled="!canEditClient" @update:client="emitUpdate" />
    </div>
  </div>
</template>

<script setup>
import { computed, inject, defineAsyncComponent } from 'vue';
import { useRouter } from 'vue-router';
import QuoteGroupApi from '@/js/Api/QuoteGroup';
import useClientStore from '@/js/store/pinia/useClientStore';

import Loader from '@/js/Helpers/Loader';
import { toastError } from '@/js/Helpers/Alert';
import DropdownCoverages from '@/js/Common/DropdownCoverages.vue';
import ProducerWarning from '@/js/Components/Producers/Warning.vue';

const IntakeGeneral = defineAsyncComponent(() => import('@/js/Common/Form/IntakeGeneral/IntakeGeneral.vue'));

defineProps({
  client: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['update:client']);

const store = useClientStore();

const emitUpdate = (value) => emit('update:client', value);

// Tools
const clientID = inject('clientID');
const router = useRouter();

const canEditClient = inject('canEditClient');

const producer = computed(() => store.producer);

// Create quote group
const createQuoteGroup = (coverageIDs) => {
  if (!coverageIDs.length) return;

  Loader.start();
  QuoteGroupApi.create({ clientId: clientID.value, coverageTypeIds: coverageIDs })
    .then(({ quoteGroup, note }) => {
      store.addOrUpdateNote(note, true);
      store.currentTicketId = note.ticket_id;
      router.push({ path: `/clients/${clientID.value}/quotegroups/${quoteGroup.id}/edit` });
    })
    .catch(() => {
      toastError('An error has occurred while creating a quote group.');
    })
    .finally(() => {
      Loader.end();
    });
};

// Go to main view
const goToMainView = () => {
  router.push(`/clients/${clientID.value}`);
};
</script>

<style lang="scss" scoped>
$padding-left: 40px;

// Header
.edit-view__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: rem(1px) solid color('gray', 3);
  border-bottom: rem(1px) solid color('gray', 3);
  padding: rem(14px) 0 rem(14px) rem($padding-left);
  margin-bottom: rem(15px);

  .left {
    display: flex;
    align-items: center;

    h5 {
      display: flex;
      align-items: center;
      font-size: rem(16px);
      line-height: rem(21px);
      margin-right: rem(75px);

      img {
        margin-right: rem(8px);
      }
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-size: rem(14px);
      line-height: rem(18px);
      color: color('primary');

      img {
        margin-right: rem(8px);
      }
    }
  }
}

// Body
.edit-view__body {
  padding-left: rem($padding-left);
  padding-right: rem(60px);

  :deep(.form-section.contacts),
  :deep(.form-section.addresses) {
    border-bottom: none;
  }

  :deep(.form-group) {
    margin-bottom: 0;
  }
}
</style>
