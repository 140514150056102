<template>
  <nav class="sidebar-left sidebar-left--open">
    <div class="sidebar-left__wrapper">
      <!-- Links -->
      <SidebarLeftLinks />

      <div class="sidebar-left__divider"></div>

      <!-- Certificates pending approval -->
      <template v-if="me.permissions['view-reports']">
        <CertificatesPendingApproval />
        <div class="sidebar-left__divider"></div>
      </template>

      <!-- Non renewed / final cancel pending approval -->
      <template v-if="me.permissions['policies.non_renewed.request']">
        <NonRenewedPendingApproval />
        <div class="sidebar-left__divider"></div>
      </template>

      <!-- List of opened clients -->
      <OpenedClients :key="clientId" />
    </div>
  </nav>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import OpenedClients from './OpenedClients.vue';
import CertificatesPendingApproval from './CertificatesPendingApproval.vue';
import NonRenewedPendingApproval from './NonRenewedPendingApproval.vue';
import SidebarLeftLinks from './Links/SidebarLeftLinks.vue';

// Client ID
const route = useRoute();
const clientId = computed(() => route.params.clientId);
</script>

<style lang="scss">
.sidebar-left {
  width: 0;
  max-width: rem($sidebarLeftWidthPX);
  height: 100%;
  max-height: 92vh;
  overflow-y: auto;
  top: rem($topbarHeightPX);
  transition: all 0.4s;

  &.sidebar-left--open {
    width: rem($sidebarLeftWidthPX);
  }

  &__wrapper {
    padding: rem(18px) rem(13px) rem(18px) rem(13px);
  }

  .sidebar-left__divider {
    margin: rem(30px) 0;
    height: rem(1px);
    background-color: color('gray', 3);
  }
}
</style>
