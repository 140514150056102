<template>
  <div class="dashboard">
    <!-- Header -->
    <DashboardHeader />

    <!-- Filters -->
    <Filters />

    <div class="dashboard__panels">
      <!-- Daily activity -->
      <DailyActivityPanel />

      <!-- FollowupsPanel -->
      <FollowupsPanel />
    </div>
  </div>
</template>

<script>
import Filters from './Filters.vue';
import DailyActivityPanel from './DailyActivityPanel.vue';
import FollowupsPanel from './FollowupsPanel/FollowupsPanel.vue';
import DashboardHeader from './Header/DashboardHeader.vue';

export default {
  components: {
    Filters,
    FollowupsPanel,
    DailyActivityPanel,
    DashboardHeader,
  },
};
</script>

<style lang="scss">
.dashboard {
  // Panels height
  display: flex;
  flex-direction: column;
  height: 100%;

  .dashboard__panels {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .slide-panel {
    overflow: hidden;
  }

  .slide-panel--active {
    flex: 1;
  }

  .slide-panel__body {
    height: 100%;
    overflow: auto;
    padding: 0;
  }

  .table-list {
    max-height: 100%;
    padding: 0 rem(15px);
    box-shadow: none;

    &__tbody {
      padding: 0;
    }

    &__thead {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .user-boxes {
    padding: 0 rem(15px);
  }

  .table-list__td--today .table-list__td-text,
  .table-list__td--past .table-list__td-text {
    font-weight: bold;
    background-color: #efefef;
    padding: rem(1px) rem(7px);
    border-radius: rem(5px);
  }

  .table-list__td--past .table-list__td-text {
    color: $red;
  }

  // Resolved followups
  .table-list__tr--updated {
    background-color: rgb(90 193 14 / 20%);
  }

  // Deleted followups
  .table-list__tr--deleted {
    background-color: #ffd2d2;
  }
}
</style>
