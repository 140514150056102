<template>
  <form :class="cssClass" :style="{ 'grid-area': 'lines' }">
    <!-- Header -->
    <div :class="`${cssClass}__header`">
      <h5>Lines of coverage</h5>
      <DropdownCoverages
        :selected-ids="existingCoverageIds"
        :allowed-ids="allowedCoverageIds"
        @click:confirm="onConfirmedCoverages"
      />
    </div>

    <!-- Coverages -->
    <div v-for="(field, i) in fields" :key="field.key" :class="`${cssClass}__coverage`">
      <!-- Icon -->
      <SvgIcon :name="field.value.coverage_type.icon_name" />

      <!-- Description -->
      <FormGroup
        type="text"
        label-text="Description"
        :name="`coverages[${i}].description`"
        :disabled="disabled"
        :autosave-method="(v) => save(field.value.coverage_type.abbreviation, 'description', v)"
      />

      <!-- Coverage type -->
      <FormGroup
        type="text"
        label-text="Coverage detail"
        :name="`coverages[${i}].detail`"
        :disabled="disabled"
        :autosave-method="(v) => save(field.value.coverage_type.abbreviation, 'detail', v)"
      />

      <!-- Coverage amount -->
      <FormGroup
        type="text"
        label-text="Coverage amount"
        :name="`coverages[${i}].amount`"
        :disabled="disabled"
        :autosave-method="(v) => save(field.value.coverage_type.abbreviation, 'amount', v)"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { z } from 'zod';
import { computed } from 'vue';
import { useForm, useFieldArray } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import { toast } from '@/js/Helpers/Alert';
import QuoteApi from '@/js/Api/Quote';
import { CoverageType, CoverageTypeSchema } from '@/js/Api/CoverageType';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import FormGroup from '@/js/Components/Form/FormGroup.vue';
import DropdownCoverages from '@/js/Common/DropdownCoverages.vue';

type Quote = Awaited<ReturnType<typeof QuoteApi.find>>['data'];

interface Props {
  quote: Quote;
  availableCoverageTypes: CoverageType[];
  disabled: boolean;
}

interface Emits {
  (e: 'loading', state: boolean): void;
  (e: 'update:quote', quote: Quote): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const cssClass = 'quote-update-form-coverage-types';

// Existing ids
const existingCoverageIds = computed(() => props.quote.intakes.map((intake) => intake.coverage_type.id));

// Non existing ids
const allowedCoverageIds = computed(() => {
  return props.availableCoverageTypes.map((coverageType) => coverageType.id);
});

const FormSchema = z.object({
  coverages: z
    .object({
      description: z.string().nullable(),
      detail: z.string().nullable(),
      amount: z.string().nullable(),
      coverage_type: CoverageTypeSchema,
    })
    .passthrough()
    .array(),
});

type Form = z.infer<typeof FormSchema>;
type QuoteCoverage = Form['coverages'][number];

const toFormValues = (quote: Quote): Form => ({
  coverages: quote.intakes.map((intake) => ({
    description: intake.quote_coverage.description,
    detail: intake.quote_coverage.detail,
    amount: intake.quote_coverage.amount,
    coverage_type: intake.coverage_type,
  })),
});

const { setValues } = useForm({
  validationSchema: toTypedSchema(FormSchema),
  initialValues: toFormValues(props.quote),
});

const { fields } = useFieldArray<QuoteCoverage>('coverages');

// Save
const save = async (type: CoverageType['abbreviation'], name: keyof QuoteCoverage, value: Form[keyof Form]) => {
  return QuoteApi.updateCoverageType({
    id: props.quote.id,
    type,
    form: {
      [name]: value,
    },
  }).then(({ data }) => {
    emit('update:quote', data);
  });
};

// On confirm
const onConfirmedCoverages = (coverageTypeIds: number[]) => {
  if (!coverageTypeIds.length) {
    toast({ title: 'You must have at least 1 coverage selected.', icon: 'warning' });
    return;
  }

  // No change
  if (coverageTypeIds.sort().toString() === existingCoverageIds.value.sort().toString()) return;

  // Update
  emit('loading', true);
  QuoteApi.updateCoverageTypes({
    id: props.quote.id,
    form: {
      coverage_type_ids: coverageTypeIds,
    },
  })
    .then(({ data }) => {
      setValues(toFormValues(data));
      emit('update:quote', data);
    })
    .catch(() => {
      toast({ title: 'An error occurred while saving coverages', icon: 'error' });
    })
    .finally(() => {
      emit('loading', false);
    });
};
</script>

<style lang="scss">
.quote-update-form-coverage-types {
  &__header {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    font-size: rem(19px);
    align-items: center;
    margin-bottom: rem(15px);
  }

  &__coverage {
    display: grid;
    align-items: center;
    margin-bottom: rem(10px);
    grid-column-gap: rem(19px);
    grid-template-columns: rem(30px) 2fr 2fr 1fr;

    .svg-icon {
      color: color('primary');
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}
</style>
