<template>
  <FormGrid class="contacts" :disabled="disabled" @click:create="create">
    <!-- Header -->
    <template #header> Contacts </template>

    <FormGridGroup v-for="(contact, i) in contacts" :key="contact.id" :expanded="true">
      <!-- Number -->
      <template #col-number-prepend>
        <img v-if="contact.is_primary" class="icon verified" src="/img/icons/verified_blue-24px.svg" />
      </template>

      <!-- Contacts row -->
      <FormGridRow
        :autosave-url="`/api/contacts/${contact.id}/autosave`"
        :style="{ 'grid-template-columns': '1fr 1fr 1fr 2fr 2fr' }"
      >
        <!-- Fields -->
        <ContactsRow :disabled="disabled" :contact="contact" @update:contact="(contact) => update(contact, i)" />

        <!-- Dots menu -->
        <template #dots-links-prepend>
          <a
            v-if="!disabled && !contact.is_primary"
            href="#"
            class="dropdown-item"
            @click.prevent="makeContactPrimary(contact.id)"
          >
            <img class="icon verified" src="/img/icons/verified_blue-24px.svg" />
            Make primary
          </a>
          <a v-if="!disabled" class="dropdown-item add" @click.prevent="createPhone(contact.id)">
            <img class="icon" src="/img/icons/add-24px.svg" />
            Add Phone
          </a>
          <a v-if="!disabled" class="dropdown-item add" @click.prevent="createEmail(contact.id)">
            <img class="icon" src="/img/icons/add-24px.svg" />
            Add Email
          </a>
          <a
            v-if="!disabled && contacts.length > 1"
            href="#"
            class="dropdown-item delete"
            @click.prevent="destroy(contact.id)"
          >
            <i class="fas fa-trash-alt icon"></i>
            Delete Contact
          </a>
        </template>
      </FormGridRow>
    </FormGridGroup>
  </FormGrid>
</template>

<script>
import useAlert from '@/js/Composables/useAlert';
import useLoader from '@/js/Composables/useLoader';

import FormGrid from '@/js/Common/Form/FormGrid/FormGrid.vue';
import FormGridRow from '@/js/Common/Form/FormGrid/FormGridRow.vue';
import FormGridGroup from '@/js/Common/Form/FormGrid/FormGridGroup.vue';

import ContactsRow from './ContactsRow.vue';

export default {
  components: {
    FormGrid,
    FormGridGroup,
    FormGridRow,
    ContactsRow,
  },

  props: {
    contacts: {
      type: Array,
      required: true,
    },
    parentType: {
      type: String,
      default: null,
    },
    parentId: {
      type: [Number, String],
      default: null,
    },
    withNumbers: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:contacts'],

  setup(props, { emit }) {
    // Tools
    const { loader } = useLoader();
    const { toast, confirm } = useAlert();

    // Create contact
    const create = () => {
      loader.start();
      axios
        .post(`/api/contacts`, {
          parent_type: props.parentType,
          parent_id: props.parentId,
        })
        .then((response) => {
          emit('update:contacts', [...props.contacts, response.data]);
        })
        .catch(() => {
          toast({ title: 'An error occurred.', icon: 'error' });
        })
        .finally(() => {
          loader.end();
        });
    };

    // Delete contact
    const destroy = async (contactID) => {
      const result = await confirm();
      if (!result.isConfirmed) return;
      loader.start();
      axios
        .post(`/api/contacts/${contactID}`, { _method: 'DELETE' })
        .then(() => {
          emit(
            'update:contacts',
            props.contacts.filter((c) => c.id !== contactID),
          );
          toast({ title: 'Success', icon: 'success' });
        })
        .catch(() => {
          toast({ title: 'An error occurred.', icon: 'error' });
        })
        .finally(() => {
          loader.end();
        });
    };

    // Update contact
    const update = (contact, i) => {
      const contacts = [...props.contacts];
      contacts[i] = contact;
      emit('update:contacts', contacts);
    };

    // Make contact primary
    const makeContactPrimary = (contactID) => {
      loader.start();
      axios
        .post(`/api/contacts/${contactID}/primary`, { _method: 'PUT' })
        .then(() => {
          const contacts = [...props.contacts];
          contacts.forEach((c) => {
            c.is_primary = c.id === contactID;
            c.phones.forEach((p) => (p.is_primary = false));
            c.emails.forEach((e) => (e.is_primary = false));
          });
          emit('update:contacts', contacts);
          toast({ title: 'Success', icon: 'success' });
        })
        .catch(() => {
          toast({ title: 'An error occurred.', icon: 'error' });
        })
        .finally(() => {
          loader.end();
        });
    };

    // Create phone
    const createPhone = (contactID) => {
      loader.start();
      axios
        .post(`/api/contacts/${contactID}/phones`)
        .then((response) => {
          const contacts = [...props.contacts];
          const i = contacts.findIndex((c) => c.id === contactID);
          contacts[i].phones.push(response.data);
          emit('update:contacts', contacts);
        })
        .catch(() => {
          toast({ title: 'An error occurred.', icon: 'error' });
        })
        .finally(() => {
          loader.end();
        });
    };

    // Create email
    const createEmail = (contactID) => {
      loader.start();
      axios
        .post(`/api/contacts/${contactID}/emails`, {
          parent_id: contactID,
          parent_type: 'contact',
        })
        .then((response) => {
          const contacts = [...props.contacts];
          const i = contacts.findIndex((c) => c.id === contactID);
          contacts[i].emails.push(response.data);
          emit('update:contacts', contacts);
        })
        .catch(() => {
          toast({ title: 'An error occurred.', icon: 'error' });
        })
        .finally(() => {
          loader.end();
        });
    };

    return {
      create,
      destroy,
      update,
      createPhone,
      createEmail,
      makeContactPrimary,
    };
  },
};
</script>

<style lang="scss">
.form-grid.contacts {
  .form-grid__row.phone .form-group label,
  .form-grid__row.email .form-group label {
    display: flex;
    align-items: center;
  }

  img.icon.verified {
    width: rem(18px);
    height: rem(18px);
    margin-left: rem(3px);
  }

  .col-number img.icon.verified {
    width: rem(12px);
    height: rem(12px);
    position: absolute;
    top: rem(-3px);
    right: rem(-3px);
  }
}
</style>
