import axios, { AxiosError } from 'axios';
import { z } from 'zod';
import * as Sentry from '@sentry/vue';
import objectToQueryString from '../Helpers/objectToQueryString';
import queryBuilderToQueryString from '../Helpers/queryBuilderToQueryString';
import { Client, ClientSchema } from './Client';
import { TicketNote, TicketNoteSchema, CreateArgs as TicketNoteCreateArgs } from './TicketNote';
import { TicketUnsavedTextSchema } from './TicketUnsavedText';

const BaseTicketSchema = z.object({
  id: z.number(),
  client_id: z.number().nullable(),
  created_by_id: z.number(),
  type: z.string(),
  status: z.enum(['Open', 'Resolved']),
  subject: z.string(),
  resolved_by_id: z.number().nullable(),
  resolved_on: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});

export type Ticket = z.infer<typeof BaseTicketSchema> & {
  client?: Client | null;
  notes?: TicketNote[] | null;
  target_tickets?: Ticket[] | null;
  merged_tickets?: Ticket[] | null;
};

export const TicketSchema: z.ZodType<Ticket> = BaseTicketSchema.extend({
  client: z.lazy(() => ClientSchema.nullish()),
  notes: z.lazy(() => TicketNoteSchema.array().nullish()),
  target_tickets: z.lazy(() => TicketSchema.array().nullish()),
  merged_tickets: z.lazy(() => TicketSchema.array().nullish()),
});

// ***************************************************
// All
// ***************************************************

type AllArgs = {
  queryBuilder: {
    sorts?: {
      order: 'desc' | 'asc';
      name: 'created_at';
    }[];
    includes?: (
      | 'target_tickets'
      | 'merged_tickets'
      | 'notes'
      | 'notes.unsaved_text'
      | 'notes.watchers'
      | 'notes.created_by'
      | 'notes.assigned_to'
      | 'notes.followup_created_by'
      | 'notes.notes.watchers'
      | 'notes.notes.created_by'
      | 'notes.notes.assigned_to'
      | 'notes.notes.followup_created_by'
    )[];
    filters: {
      client_id: number;
    };
  };
};

async function all({ queryBuilder }: AllArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/tickets/all?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: TicketSchema.array(),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Create
// ***************************************************

type CreateArgs = {
  type: Ticket['type'];
  client_id: number;
  note: Omit<TicketNoteCreateArgs, 'note_id' | 'ticket_id' | 'client_id'>;
};

async function create(args: CreateArgs) {
  const response = await axios.post('/api/tickets', args).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: TicketSchema }).parseAsync(response.data);
}

// ***************************************************
// Update
// ***************************************************

type UpdateArgs = {
  id: number;
  body: {
    subject?: string;
    type?: string;
    status?: Ticket['status'];
  };
};

async function update({ id, body }: UpdateArgs) {
  const response = await axios.put(`/api/tickets/${id}`, body).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: TicketSchema }).parseAsync(response.data);
}

// ***************************************************
// Merge
// ***************************************************

type MergeArgs = {
  targetTicketId: number;
  mergingTicketId: number;
};

async function merge({ mergingTicketId, targetTicketId }: MergeArgs) {
  const response = await axios
    .post(`/api/tickets/${mergingTicketId}/merge`, {
      target_ticket_id: targetTicketId,
    })
    .catch((error: AxiosError) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: z.object({
        target_ticket: TicketSchema,
        merged_ticket: TicketSchema,
      }),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Get status
// ***************************************************

async function getStatus({ id }: { id: number }) {
  const response = await axios.get(`/api/tickets/${id}/status`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: z.object({
        status: BaseTicketSchema.shape.status,
        open_followups_count: z.number(),
      }),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Get unsaved text
// ***************************************************

type UnsavedTextArgs = {
  client_id?: number;
  note_id?: number;
};

async function getUnsavedText(args: UnsavedTextArgs) {
  const qs = objectToQueryString(args);
  const response = await axios.get(`/api/tickets/text?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: TicketUnsavedTextSchema.nullable(),
    })
    .parseAsync(response.data);
}

export default {
  all,
  create,
  update,
  merge,
  getStatus,
  getUnsavedText,
};
