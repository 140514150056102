import { z } from 'zod';
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';
import queryBuilderToQueryString from '../Helpers/queryBuilderToQueryString';
import { UserSchema } from './User';
import { PolicySchema } from './Policy';
import { QuoteGroupSchema } from './QuoteGroup';
import { AddressSchema } from './Address';
import { ContactSchema } from './Contact';
import { EmailSchema } from './Email';
import { PhoneSchema } from './Phone';
import { ReferralSourceSchema } from './ReferralSource';
import { CustomerServiceTeamSchema } from './CustomerServiceTeam';
import { ProducerSchema } from './Producer';
import { DotResponse } from './Utils';

export const TYPES = ['Individual', 'Organization'] as const;

export const ClientSchema = z.object({
  id: z.number(),
  user_id: z.number().nullable(),
  status: z.enum(['Prospect', 'Active']),
  type: z.enum(TYPES),
  dot: z.string().nullable(),
  mc: z.string().nullable(),
  fein: z.string().nullable(),
  first_name: z.string().nullable(),
  middle_name: z.string().nullable(),
  last_name: z.string().nullable(),
  company_name: z.string().nullable(),
  description: z.string().nullable(),
  tax_id: z.string().nullable(),
  dob: z.string().nullable(),
  ssn: z.string().nullable(),
  marital_status: z.string().nullable(),
  producer_type: z.string().nullable(),
  producer_id: z.number().nullable(),
  producer_assigned_on: z.string().nullable(),
  quoting_specialist_id: z.number().nullable(),
  customer_service_type: z.string().nullable(),
  customer_service_id: z.number().nullable(),
  referral_source_type: z.string().nullable(),
  referral_source_id: z.number().nullable(),
  first_close: z.boolean(),
  created_at: z.string(),
  updated_at: z.string(),

  // Appends
  name: z.string(),
  model_type: z.literal('Client'),
  canThisUserEdit: z.boolean().nullish(),
});

export type Client = z.infer<typeof ClientSchema>;

// ***************************************************
// Index
// ***************************************************

type IndexArgs = {
  queryBuilder: {
    page: number;
    sorts?: {
      order: 'desc' | 'asc';
      name: 'name' | 'created_at' | 'user.name';
    }[];
    filters?: {
      search?: string;
      user_id?: number[];
      producer?: string[];
      created_at?: string;
      policies?: {
        inforce?: boolean;
        agency_id?: number[];
        carrier_id?: number[];
        coverage_type?: string[];
        renewal_status_as_parent?: string[];
      };
    };
  };
};

async function index({ queryBuilder }: IndexArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/clients?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return response;
}

// ***************************************************
// All
// ***************************************************

type AllArgs = {
  queryBuilder: {
    sorts: {
      order: 'desc' | 'asc';
      name: 'name' | 'created_at' | 'user.name';
    }[];
    includes?: ('producer' | 'user')[];
    filters?: {
      id?: number[];
      dot?: string[] | string | null;
      search?: string;
      user_id?: number[];
      producer?: string[];
      created_at?: string;
      policies?: {
        inforce?: true;
        agency_id?: number[];
        carrier_id?: number[];
        coverage_type?: string[];
        renewal_status?: string[];
      };
    };
  };
};

async function all({ queryBuilder }: AllArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/clients/all?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: ClientSchema.array() }).parseAsync(response.data);
}

// ***************************************************
// Count
// ***************************************************

type CountArg = {
  filters: {
    producer?: string[];
    all_ended_policies?: true;
    at_least_one_ended_policy?: true;
    policies?: {
      inforce?: true;
      agency_id?: number[];
      carrier_id?: number[];
      coverage_type?: string[];
    };
  };
};
async function count(queryBuilder: CountArg) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/clients/count?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: z.number() }).parseAsync(response.data);
}

// ***************************************************
// Find
// ***************************************************

async function find({ id }: { id: number }) {
  const response = await axios.get(`/api/clients/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: ClientSchema }).parseAsync(response.data);
}

// ***************************************************
// Primary contact
// ***************************************************

const PrimaryContactSchema = z.object({
  name: ContactSchema.shape.name,
  email: EmailSchema.shape.email,
  phoneNumber: PhoneSchema.shape.number,
});

export type PrimaryContact = z.infer<typeof PrimaryContactSchema>;

async function primaryContact({ id }: { id: number }) {
  const response = await axios.get(`/api/clients/${id}/primary-contact`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: PrimaryContactSchema }).parseAsync(response.data);
}

// ***************************************************
// Update
// ***************************************************

type UpdateArgs = {
  id: number;
  form: {
    type?: Client['type'];
    first_name?: Client['first_name'];
    middle_name?: Client['middle_name'];
    last_name?: Client['last_name'];
    company_name?: Client['company_name'];
    description?: Client['description'];
    mc?: Client['mc'];
    dot?: Client['dot'];
    fein?: Client['fein'];
  };
};

async function update({ id, form }: UpdateArgs) {
  const response = await axios.put(`/api/clients/${id}`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: ClientSchema }).parseAsync(response.data);
}

// ***************************************************
// SAFER import
// ***************************************************

type SaferImportArgs = {
  id: number;
  data: DotResponse;
};

async function saferImport({ id, data }: SaferImportArgs) {
  const response = await axios.put(`/api/clients/${id}/safer-import`, data).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: z.object({
        client: ClientSchema,
        addresses: AddressSchema.array(),
        contacts: ContactSchema.array(),
      }),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Destroy
// ***************************************************

async function destroy({ id }: { id: number }) {
  const response = await axios.delete(`/api/clients/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.literal('').parseAsync(response.data);
}

export default {
  all,
  find,
  count,
  primaryContact,
  index,
  destroy,
  update,
  saferImport,

  merge({ primaryID, mergingID }) {
    return axios.post(`/api/clients/${primaryID}/merge`, { merging_id: mergingID });
  },

  open({ clientId }) {
    return axios.post(`/api/clients/${clientId}/open`);
  },

  close({ clientId }) {
    return axios.post(`/api/clients/${clientId}/close`);
  },

  relatedClients: {
    async all({ id }: { id: number }) {
      const response = await axios.get(`/api/clients/${id}/related-clients/all`).catch((error) => {
        Sentry.captureException(error);
        throw error;
      });

      return z.object({ data: z.array(ClientSchema) }).parseAsync(response.data);
    },

    add({ id, clientId }) {
      return axios.post(`/api/clients/${id}/related-clients/add`, { client_id: clientId });
    },

    remove({ id, clientId }) {
      return axios.post(`/api/clients/${id}/related-clients/remove`, { client_id: clientId });
    },
  },

  referralSource: {
    async find({ id }: { id: number }) {
      const response = await axios.get(`/api/clients/${id}/referral-source`).catch((error) => {
        Sentry.captureException(error);
        throw error;
      });

      const ReferralSourceRelationSchema = z.union([ClientSchema, ReferralSourceSchema]);

      return z.object({ data: ReferralSourceRelationSchema.nullable() }).parseAsync(response.data);
    },

    update({ id, data }) {
      return axios.post(`/api/clients/${id}/referral-source`, { ...data, _method: 'PUT' });
    },
  },

  producer: {
    async find({ id }: { id: number }) {
      const response = await axios.get(`/api/clients/${id}/producer`).catch((error) => {
        Sentry.captureException(error);
        throw error;
      });

      return z.object({ data: ProducerSchema.nullable() }).parseAsync(response.data);
    },

    update({ id, data }) {
      return axios.post(`/api/clients/${id}/producer`, { ...data, _method: 'PUT' });
    },
  },

  customerService: {
    async find({ id }: { id: number }) {
      const response = await axios.get(`/api/clients/${id}/customer-service`).catch((error) => {
        Sentry.captureException(error);
        throw error;
      });

      const CustomerServiceRelationSchema = z.union([UserSchema, CustomerServiceTeamSchema]);

      return z.object({ data: CustomerServiceRelationSchema.nullable() }).parseAsync(response.data);
    },

    update({ id, data }) {
      return axios.post(`/api/clients/${id}/customer-service`, { ...data, _method: 'PUT' });
    },
  },

  quotingSpecialist: {
    async find({ id }: { id: number }) {
      const response = await axios.get(`/api/clients/${id}/quoting-specialist`).catch((error) => {
        Sentry.captureException(error);
        throw error;
      });

      return z.object({ data: UserSchema.nullable() }).parseAsync(response.data);
    },

    update({ id, data }) {
      return axios.post(`/api/clients/${id}/quoting-specialist`, { ...data, _method: 'PUT' });
    },
  },

  addresses: {
    async all({ id }: { id: number }) {
      const response = await axios.get(`/api/clients/${id}/addresses/all`).catch((error) => {
        Sentry.captureException(error);
        throw error;
      });

      return z.object({ data: z.array(AddressSchema) }).parseAsync(response.data);
    },
  },

  contacts: {
    async all({ id }: { id: number }) {
      const response = await axios.get(`/api/clients/${id}/contacts/all`).catch((error) => {
        Sentry.captureException(error);
        throw error;
      });

      return z.object({ data: z.array(ContactSchema) }).parseAsync(response.data);
    },
  },

  policies: {
    async all({ id }: { id: number }) {
      const response = await axios.get(`/api/clients/${id}/policies/all`).catch((error) => {
        Sentry.captureException(error);
        throw error;
      });

      return z.object({ data: z.array(PolicySchema) }).parseAsync(response.data);
    },
  },

  quoteGroups: {
    async all({ id }: { id: number }) {
      const response = await axios.get(`/api/clients/${id}/quotegroups/all`).catch((error) => {
        Sentry.captureException(error);
        throw error;
      });

      return z.object({ data: z.array(QuoteGroupSchema) }).parseAsync(response.data);
    },
  },
};
