export default {
  namespaced: true,

  state: () => ({
    externalProducers: [],
  }),

  mutations: {
    // External producers
    setExternalProducers(state, response) {
      state.externalProducers = response.data;
    },
    updateExternalProducer(state, producer) {
      const i = state.externalProducers.findIndex((p) => p.id === producer.id);
      state.externalProducers[i] = producer;
    },

    // Merge clients
    setMergeClientsList(state, response) {
      state.mergeClientsList = response.data;
    },
    removeClientsFromMergeClientsList(state, mergingClientIDs) {
      state.mergeClientsList = state.mergeClientsList
        .map((group) => ({
          ...group,
          clients: group.clients.filter((client) => !mergingClientIDs.includes(client.id)),
        }))
        .filter((group) => group.clients.length > 1);
    },
  },
};
