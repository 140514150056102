import config from '@/js/config.js';
import axios from 'axios';

export default {
  namespaced: true,

  state: () => ({
    config,
    initializationCompleted: false,
    inactivityPopupPresent: false,
  }),

  actions: {
    // Notifications
    fetchNotifications({ commit }) {
      return axios.get('/api/notifications').then((response) => {
        commit('setNotifications', response.data.notifications);
      });
    },

    // Opened clients list
    fetchAndUpdateOpenedClientsList({ commit }, { clientID } = {}) {
      const body = { __method: 'PUT' };
      if (clientID) body.client_id = clientID;
      return axios.post('/api/clients/opened', body).then((response) => {
        commit('setOpenedClientsList', response.data.list);
      });
    },
    fetchOpenedClienstRow(context, { clientID }) {
      return axios.get(`/api/clients/${clientID}/opened-row`).then((response) => {
        return response.data.row;
      });
    },

    // Coverages
    fetchCoverages({ commit }) {
      return axios.get('/api/coverages').then((response) => {
        commit('setCoverages', response.data.coverages);
      });
    },

    // Agencies
    fetchAgencies({ commit }) {
      return axios.get('/api/agencies/all').then((response) => {
        commit('setAgencies', response.data.data);
      });
    },

    // Carriers
    fetchCarriers({ commit }) {
      return axios.get('/api/carriers/all').then((response) => {
        commit('setCarriers', response.data.data);
      });
    },

    // Finance companies
    fetchFinanceCompanies({ commit }) {
      return axios.get('/api/finance-companies/all').then((response) => {
        commit('setFinanceCompanies', response.data.finance_companies);
      });
    },

    // Users
    fetchUsers({ commit }) {
      return axios.get('/api/users/all').then((response) => {
        commit('setUsers', response.data.users);
      });
    },

    // DOOs
    fetchDOOs({ commit }, { include = [] }) {
      return axios.get(`/api/doos${include.length ? `?include=${include.join(',')}` : ''}`).then((response) => {
        commit('setDOOs', response.data.doos);
      });
    },

    // Producers
    fetchProducers({ commit }) {
      return axios.get('/api/producers').then((response) => {
        commit('setProducers', response.data.producers);
      });
    },

    // Producer users
    fetchProducerUsers({ commit }) {
      return axios.get('/api/producers/users').then((response) => {
        commit('setProducerUsers', response.data.users);
      });
    },

    // Quoting specialists
    fetchQuotingSpecialists({ commit }) {
      return axios.get('/api/quoting-specialists').then((response) => {
        commit('setQuotingSpecialists', response.data.quotingSpecialists);
      });
    },

    // Customer service entities
    fetchCustomerServiceEntities({ commit }) {
      return axios.get('/api/customer-service-entities').then((response) => {
        commit('setCustomerServiceEntities', response.data.customerServiceEntities);
      });
    },

    // Roles
    fetchRoles({ commit }) {
      return axios.get('/api/users/roles').then((response) => {
        commit('setRoles', response.data.roles);
      });
    },

    // Categories
    fetchCategories({ commit }) {
      return axios.get('/api/categories/all').then((response) => {
        commit('setCategories', response.data.categories);
      });
    },

    // Followup assign to options
    fetchFollowupAssignToOptions({ commit }) {
      return axios.get('/api/followups/assign-to-options').then((response) => {
        commit('setAssignToOptions', response.data.assignToOptions);
      });
    },

    // Followup assign to options
    fetchQuoteTeamMembers({ commit }) {
      return axios.get('/api/quote-team-members').then((response) => {
        commit('setQuoteTeamMembers', response.data.quoteTeamMembers);
      });
    },

    // Settings
    fetchDefaultAgency({ commit }) {
      return axios.get('/api/settings/default-agency').then((response) => {
        commit('setDefaultAgency', response.data.defaultAgency);
      });
    },
    fetchMapperData({ commit }) {
      return axios.get('/mapper/init').then((response) => {
        commit('setMapperData', response.data);
      });
    },

    // Fetch duplicate clients
    fetchClientDuplicates({ commit }) {
      return axios.get('/api/clients/duplicates').then((response) => {
        commit('setClientDuplicates', response.data.clientDuplicates);
      });
    },
  },

  mutations: {
    payload(state, payload) {
      Object.keys(payload).forEach((property) => (state[property] = payload[property]));
    },
    initialization(state, payload) {
      Object.keys(payload).forEach((property) => (state[property] = payload[property]));
    },

    // Me (loggedin user)
    setMe(state, user) {
      state.me = user;
    },

    // Notifications
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    unshiftNotification(state, notification) {
      state.notifications.unshift(notification);
    },

    // Opened clients
    setOpenedClientsList(state, list) {
      state.openedClientsList = list;
    },
    removeClientsFromOpenedClientsList(state, clientIDs) {
      state.openedClientsList = state.openedClientsList.filter(
        (row) => !clientIDs.find((clientID) => clientID === row.client_id),
      );
    },
    updateOpenedClientName(state, client) {
      const row = state.openedClientsList.find((row) => row.client_id === client.id);
      if (row) row.client_name = client.name;
    },

    // Coverages
    setCoverages(state, coverages) {
      state.coverages = coverages;
      state.coveragesPrimary = coverages.filter((coverage) => coverage.primary);
      state.coveragesSecondary = coverages.filter((coverage) => !coverage.primary);
    },

    // Agencies
    setAgencies(state, agencies) {
      state.agencies = agencies;
    },

    // Carriers
    setCarriers(state, carriers) {
      state.carriers = carriers;
    },

    // Finance companies
    setFinanceCompanies(state, financeCompanies) {
      state.financeCompanies = financeCompanies;
    },

    // Users
    setUsers(state, users) {
      state.users = users;
    },

    // Holders
    setDOOs(state, doos) {
      state.doos = doos;
    },

    // Producers
    setProducers(state, producers) {
      state.producers = producers;
    },

    // Producer users
    setProducerUsers(state, producerUsers) {
      state.producerUsers = producerUsers;
    },

    // Quoting specialists
    setQuotingSpecialists(state, quotingSpecialists) {
      state.quotingSpecialists = quotingSpecialists;
    },

    // Customer service entities
    setCustomerServiceEntities(state, customerServiceEntities) {
      state.customerServiceEntities = customerServiceEntities;
    },

    // Roles
    setRoles(state, roles) {
      state.roles = roles;
    },

    // Categories
    setCategories(state, categories) {
      state.categories = categories;
    },

    setAssignToOptions(state, assignToOptions) {
      state.assignToOptions = assignToOptions;
    },

    // Quote team members
    setQuoteTeamMembers(state, quoteTeamMembers) {
      state.quoteTeamMembers = quoteTeamMembers;
    },

    // Quoting options
    setQuotingOptions(state, quotingOptions) {
      state.quotingOptions = quotingOptions;
    },

    // Settings
    setDefaultAgency(state, defaultAgency) {
      state.defaultAgency = defaultAgency;
    },
    setMapperData(state, responseData) {
      state.mapper = { ...responseData };
    },
  },
};
