<template>
  <UserInput
    :model-value="note.created_by"
    :label="label"
    :required="true"
    :multivalue="false"
    :disabled="disabled"
    @update:model-value="autosave"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { toDateString } from '@/js/Helpers';
import { User } from '@/js/Api/User';
import TicketNoteApi, { TicketNote } from '@/js/Api/TicketNote';
import UserInput from '@/js/Components/Users/Input.vue';

interface Props {
  note: TicketNote;
  disabled: boolean;
}

interface Emits {
  (e: 'update:note', note: TicketNote): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const autosave = async (user: User) => {
  TicketNoteApi.autosave({ id: props.note.id, name: 'created_by_id', value: user.id }).then(({ data }) => {
    emit('update:note', data);
  });
};

const label = computed(() => {
  const createdOn = toDateString(props.note.followup_created_at ?? props.note.created_at);
  return `Created (${createdOn})`;
});
</script>
