export const applyModifers = (value, modifiers) => {
  if (value === '') return null;

  if (modifiers.number) {
    const numberValue = Number(value);
    return !Number.isNaN(numberValue) ? numberValue : 0;
  }

  if (value && modifiers.uppercase) return value.toUpperCase();

  return value;
};

export const formatZIP = (e) => {
  const { length } = e.target.value;
  if (e.which > 31 && (e.which < 48 || e.which > 57)) e.preventDefault();
  else if (length >= 10)
    if (e.target.selectionStart === 0 && e.target.selectionEnd === length) return;
    else e.preventDefault();
  else if (length === 5) e.target.value += '-';
};

export const formatPhone = (e) => {
  const { length } = e.target.value;
  if (e.which > 31 && (e.which < 48 || e.which > 57)) e.preventDefault();
  else if (length >= 12 && (e.target.selectionStart !== 0 || e.target.selectionEnd !== length)) e.preventDefault();
  else if (length === 3 || length === 7) e.target.value += '-';
};

export const formatMoney = (e, modifiers) => {
  let { value } = e.target;
  if (!value || !/^[0-9.,]*$/.test(value)) return;

  value = parseFloat(value.replaceAll(',', ''));

  if (!modifiers.nodecimals) value = (Math.round(value * 100) / 100).toFixed(2);

  value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  e.target.value = value;
};

export const copyPhone = (event) => {
  event.stopImmediatePropagation();

  const el = document.createElement('textarea');
  const text = event.target.value;
  // const text = event.target.value ? event.target.value.replaceAll('-', '') : '';

  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export default {
  formatZIP,
  formatPhone,
  formatMoney,
  copyPhone,
  applyModifers,
};
