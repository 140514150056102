<template>
  <router-view v-if="policy && client" v-model:policy="policy" :client="client" />
</template>

<script setup lang="ts">
import { ref, provide, computed } from 'vue';
import { Client } from '@/js/Api/Client';
import PolicyApi, { Policy } from '@/js/Api/Policy';
import useClientStore from '@/js/store/pinia/useClientStore';

interface Props {
  clientId: number;
  policyId: string;
  client: Client | null;
}

const props = defineProps<Props>();

const policyId = computed(() => Number(props.policyId));

const store = useClientStore();

const policy = ref<null | Policy>(null);
PolicyApi.find({ clientId: props.clientId, id: policyId.value }).then(({ data }) => {
  policy.value = data;
  store.policy = data;
});

provide('policyID', policyId.value);
</script>
