<template>
  <div ref="tableEl" class="table-list" :class="`table-list--${theme}`">
    <!-- thead -->
    <div v-if="items.length" class="table-list__thead" :style="{ 'grid-template-columns': gridTemplateColumns }">
      <!-- th -->
      <div
        v-for="(column, i) in columns"
        :key="i"
        :class="[
          {
            'table-list__th': true,
            'table-list__th--sortable': column.sortBy && column.name,
            'table-list__th--sorted': sortedBy === column.name,
          },
          typeof column.thClass === 'function' ? column.thClass(column) : column.thClass,
        ]"
        @click="sort(column)"
      >
        <!-- Arrow -->
        <SvgIcon v-if="sortedBy === column.name" :name="`arrow-${sortOrder === 'desc' ? 'down' : 'up'}-long`" />

        <!-- Name -->
        {{ column.name ?? '' }}
      </div>
    </div>

    <!-- tbody (empty) -->
    <template v-if="!items.length">
      <div class="table-list__tbody table-list__tbody--empty">
        <slot name="noitems"> No items in the list </slot>
      </div>
    </template>

    <!-- tbody -->
    <template v-else-if="items.length">
      <!-- tbody -->
      <div class="table-list__tbody">
        <!-- tr -->
        <div
          v-for="(item, i) in items"
          :key="i"
          :class="[
            {
              'table-list__tr': true,
              'table-list__tr--clickable': isRowClickable,
            },
            typeof trClass === 'function' ? trClass(item) : trClass,
          ]"
          :style="{ 'grid-template-columns': gridTemplateColumns }"
          @click="emit('item:click', item, i)"
        >
          <!-- td -->
          <div
            v-for="(column, j) in columns"
            :key="j"
            class="table-list__td"
            :class="typeof column.tdClass === 'function' ? column.tdClass(item) : column.tdClass"
          >
            <!-- Else if: html -->
            <template v-if="column.slotName">
              <slot :name="column.slotName" :item="item" :i="i" />
            </template>

            <!-- Else: column value -->
            <span v-else-if="column.get" class="table-list__td-text">
              {{ column.get(item) }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { useResizeObserver } from '@vueuse/core';
import { watch, ref, onMounted, Ref } from 'vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';

type ClassAttr = string | object | (string | object)[];
type Primitive = string | number | boolean | undefined | null;

export interface Column {
  get?: <Item>(item: Item) => Primitive;
  name?: string;
  tdClass?: ClassAttr | (<Item>(item: Item) => ClassAttr);
  thClass?: ClassAttr | ((column: Column) => ClassAttr);
  slotName?: string;
  sortBy?: <Item>(item: Item) => Primitive;
}
</script>

<script setup lang="ts" generic="Item">
type Haystack = Item[];
type SortOrder = 'asc' | 'desc';
type Theme = 'material' | 'embedded';

interface Column {
  get?: (item: Item) => Primitive;
  name?: string;
  tdClass?: ClassAttr | ((item: Item) => ClassAttr);
  thClass?: ClassAttr | ((column: Column) => ClassAttr);
  slotName?: string;
  sortBy?: (item: Item) => Primitive;
}

interface Props {
  haystack: Haystack;
  columns: Column[];
  gridTemplateColumns: string;
  initiallySortedBy?: string;
  initialSortOrder?: SortOrder;
  theme?: Theme;
  trClass?: ClassAttr | ((item: Item) => ClassAttr);
  heightElement?: string;
  isRowClickable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  theme: 'material',
  trClass: undefined,
  heightElement: undefined,
  isRowClickable: true,
  initiallySortedBy: undefined,
  initialSortOrder: undefined,
});

const emit = defineEmits(['item:click']);

const items = ref([...props.haystack]) as Ref<Item[]>;

// Table height
const tableEl = ref<HTMLElement | null>(null);
onMounted(() => {
  if (!props.heightElement) return;
  const heightEl = document.querySelector(props.heightElement);
  if (heightEl instanceof HTMLElement && tableEl.value instanceof HTMLElement) {
    useResizeObserver(heightEl, () => {
      tableEl.value.style.height = `${
        heightEl.getBoundingClientRect().bottom - tableEl.value.getBoundingClientRect().top - 30
      }px`;
    });
  }
});

// Sorting
const sortedBy = ref(props.initiallySortedBy);
const sortOrder = ref(props.initialSortOrder);
const sort = (column: Column | null) => {
  if (!sortedBy.value || !sortOrder.value) return;
  if (column && !column.sortBy) return;

  let order = 1;
  if (column === null) {
    column = props.columns.find((col) => col.name === sortedBy.value) as Column;
    order = sortOrder.value === 'asc' ? 1 : -1;
  } else if (sortedBy.value !== column.name) {
    order = -1;
  } else {
    order = sortOrder.value === 'desc' ? 1 : -1;
  }

  items.value.sort((o1, o2) => {
    if (!column?.sortBy) return 1;
    const a = column.sortBy(o1);
    const b = column.sortBy(o2);
    if (!a && !b) return 0;
    if (!a && b) return order * -1;
    if (a && !b) return order * 1;
    if (typeof a === 'string' && typeof b === 'string') return order * a.localeCompare(b);
    if (typeof a === 'number' && typeof b === 'number') return order * (a > b ? 1 : -1);
    return order * 1;
  });
  sortedBy.value = column.name;
  sortOrder.value = order === 1 ? 'asc' : 'desc';
};

watch(
  () => props.haystack,
  () => {
    items.value = [...props.haystack];
    sort(null);
  },
  { deep: true },
);
</script>

<style lang="scss">
$tdPaddingX: rem(7.5px);
$tbodyPaddingX: rem(13px);
$rowBorderBottom: rem(1px) solid #ccc;

.table-list {
  overflow: auto;

  // Layout
  &__thead,
  &__tr {
    display: grid;
  }

  // thead
  position: relative;

  &__thead {
    top: 0;
    left: 0;
    position: sticky;
    z-index: 1;
    padding: rem(13px) $tbodyPaddingX;
    padding-bottom: rem(6px);
    background-color: white;
  }

  // tbody
  &__tbody {
    padding: 0 $tbodyPaddingX;
  }

  // tbody (empty)
  &__tbody--empty {
    text-align: center;
    padding: rem(40px) 0;
  }

  // th
  &__th {
    padding: 0 $tdPaddingX;
    color: #999;
    font-size: rem(12px);
    line-height: rem(14px);
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &--sortable {
      cursor: pointer;
    }

    &--sorted {
      color: $aqua;
    }

    .svg-icon {
      width: rem(8px);
      margin-right: rem(5px);
    }
  }

  // tr
  &__tr {
    &:not(:last-child) {
      border-bottom: $rowBorderBottom;
    }

    &--clickable {
      cursor: pointer;
    }
  }

  // td
  &__td {
    height: rem(54px);
    display: flex;
    align-items: center;
    font-size: rem(16px);
    overflow: hidden;
    padding: 0 $tdPaddingX;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &-text,
    & > * {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

// Theme material
.table-list--material {
  @include box-shadow-light;

  background-color: white;

  .table-list__thead {
    background-color: white;
    border-bottom: rem(1px) solid color('gray', 3);
  }
}

// Theme embedded
.table-list--embedded {
  .table-list__thead {
    padding-left: 0;
    padding-right: 0;
    border-bottom: $rowBorderBottom;
  }

  .table-list__tbody {
    padding-left: 0;
    padding-right: 0;
  }

  .table-list__thead {
    background-color: unset;
  }

  .table-list__tr {
    border-bottom: $rowBorderBottom;
  }
}

// Responsive
@media screen and (max-width: 2000px) {
  .table-list {
    &__th {
      padding: 0 rem(5px);
    }

    &__td {
      padding: rem(11px) rem(5px);
    }
  }
}
</style>
