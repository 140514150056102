import Swal from 'sweetalert2';

function prompt(options) {
  return Swal.mixin({
    text: 'Type in "delete" to confirm.',
    input: 'text',
    showCancelButton: true,
    confirmButtonText: 'Delete',
    confirmButtonColor: '#19497F',
    cancelButtonColor: '#E90001'
  })
  .fire(options);
};

function confirm(options) {
  return Swal.mixin({
    text: 'Are you sure?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#19497F',
    cancelButtonColor: '#E90001',
    confirmButtonText: 'Yes, delete it!'
  }).fire(options);
};

function toast(options) {
  return Swal.mixin({
    customClass: {container: 'swal-toast-container'},
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000
  })
  .fire(options);
};

function toastError(message) {
  return toast({ title: message ?? 'An error occurred.', icon: 'error' });
}

export default function() {
  return {
    prompt,
    confirm,
    toast,
    toastError
  };
};
