<template>
  <div ref="el" class="form-grid" :class="{ disabled: disabled }">
    <!-- Header -->
    <div v-if="header" class="form-grid__header">
      <div class="left">
        <h6><slot name="header"></slot></h6>
        <ButtonAdd v-if="!disabled && addButton" @click="$emit('click:create')" />
        <div v-if="!disabled && addButton && importButton" class="divider vertical"></div>
        <a v-if="!disabled && importButton" class="form-grid__import-button" @click="$emit('click:import')">
          <SvgIcon name="list-add" />
          Add from list
        </a>
      </div>

      <div v-if="detailsLink || filters.length" class="right">
        <a v-if="detailsLink" v-show="isThereOverflownGroup" class="details-link" @click="expandAll">
          {{ areAllGroupsExpanded ? 'Show less details' : 'Show all details' }}
        </a>

        <div v-if="detailsLink && filters.length" class="divider vertical"></div>

        <FormGroupMini
          v-if="false"
          type="select"
          label-text="View:"
          :options="filters.map((f) => ({ label: f, value: f }))"
          @update:model-value="(v) => $emit('update:filter', v)"
        />
      </div>
    </div>

    <!-- Rows -->
    <div class="form-grid__groups">
      <slot :expanded-map="expandedMap"></slot>
    </div>
  </div>
</template>

<script>
import { provide, reactive, ref, computed, onMounted } from 'vue';
import ButtonAdd from '@/js/Common/ButtonAdd.vue';
import FormGroupMini from '@/js/Components/Form/FormGroup.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';

export default {
  name: 'FormGrid',

  components: {
    ButtonAdd,
    FormGroupMini,
    SvgIcon,
  },

  props: {
    filter: {
      type: String,
    },
    filters: {
      type: Array,
      default: [],
    },
    disabled: {
      type: Boolean,
    },
    detailsLink: {
      type: Boolean,
      default: true,
    },
    addButton: {
      type: Boolean,
      default: true,
    },
    importButton: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['click:create', 'update:filter', 'click:import'],

  setup(props) {
    const el = ref(null);

    // Map of expanded groups
    const expandedMap = reactive({});
    provide('expandedMap', expandedMap);

    const overflowMap = reactive({});
    provide('overflowMap', overflowMap);

    // Are all rows expanded?
    const areAllGroupsExpanded = computed(() => {
      return (
        Object.keys(expandedMap).find((groupIndex) => {
          if (!expandedMap[groupIndex] && !overflowMap[groupIndex]) return false;
          return !expandedMap[groupIndex];
        }) === undefined
      );
    });

    // Is there overflown group?
    const isThereOverflownGroup = computed(() => {
      return Object.keys(overflowMap).find((groupIndex) => overflowMap[groupIndex]) !== undefined;
    });

    // Actions
    const expandAll = () => {
      const expand = !areAllGroupsExpanded.value;
      Object.keys(expandedMap).forEach((groupIndex) => (expandedMap[groupIndex] = expand));
    };

    // Recalculate group index
    const groupRemovedTrigger = ref(1);
    provide('groupRemovedTrigger', groupRemovedTrigger);
    onMounted(() => {
      new MutationObserver((mutationsList) => {
        if (mutationsList.length !== 1 || mutationsList[0].removedNodes.length !== 1) return;
        groupRemovedTrigger.value++;
      }).observe(el.value, { childList: true });
    });

    return {
      el,
      expandAll,
      expandedMap,
      areAllGroupsExpanded,
      isThereOverflownGroup,
    };
  },
};
</script>

<style lang="scss">
.form-grid {
  border-radius: rem(4px);
  margin-bottom: rem(20px);

  // Global
  .form-group {
    margin-bottom: 0;

    label {
      white-space: nowrap;
    }

    .form-control {
      border: none;

      &::-webkit-calendar-picker-indicator {
        display: none;
      }
    }
  }

  .dropdown-menu .form-group .form-control {
    border: rem(1px) solid color('gray', 3);
  }

  .divider {
    margin: 0 rem(12px);
  }

  // Header
  .form-grid__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: rem(math.div($dotsTriggerDimension, 2));

    .left {
      display: flex;
      align-items: flex-start;

      h6 {
        @include form-section-title;

        margin-right: rem(35px);
      }
    }

    .right {
      display: flex;

      a.details-link {
        font-size: rem(14px);
        user-select: none;
      }
    }
  }

  &__import-button {
    display: flex;
    align-items: center;
    font-size: rem(16px);
    .svg-icon {
      width: rem(20px);
      margin-right: rem(10px);
    }
  }

  // Disabled
  &.disabled {
    // Dots
    // .dropdown.dots {
    //   display: none;
    // }

    // Padding
    //.form-group {
    //  label {
    //    padding-left: rem($inputPaddingX);
    //  }

    //  .form-control {
    //    padding-left: rem($inputPaddingX);
    //  }
    //}
  }

  // Disabled inputs inside non disabled grid
  &:not(.disabled) {
    .form-group.disabled label {
      padding-left: rem($inputPaddingX);
    }

    .form-control:disabled,
    .form-control[readonly] {
      opacity: initial;
      border: initial;
      padding-left: rem($inputPaddingX);
    }
  }
}
</style>
