<template>
  <div class="certificates">
    <div class="title">Certificates pending approval</div>
    <div class="content">
      <router-link :to="{ name: 'CertificatesPendingApproval' }" class="link">
        {{ pendingApprovalCount ?? 0 }} certificates
      </router-link>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import useSWR from '@/js/Composables/useSWR';
import useEcho from '@/js/Composables/useEcho';

export default {
  setup() {
    const store = useStore();

    const { pendingApprovalCount, promise } = useSWR(
      'reports/fetchPendingApprovalCount',
      'reports/pendingApprovalCount'
    );

    useEcho()
      .channel('certificates-pending-approval-count')
      .listen('.add', () => {
        if (pendingApprovalCount.value != null)
          store.commit('reports/setPendingApprovalCount', pendingApprovalCount.value + 1);
        else promise.then(() => store.commit('reports/setPendingApprovalCount', pendingApprovalCount.value + 1));
      })
      .listen('.sub', () => {
        if (pendingApprovalCount.value != null)
          store.commit('reports/setPendingApprovalCount', pendingApprovalCount.value - 1);
        else promise.then(() => store.commit('reports/setPendingApprovalCount', pendingApprovalCount.value - 1));
      });

    return {
      pendingApprovalCount,
    };
  },
};
</script>

<style lang="scss" scoped>
.certificates {
  display: flex;
  flex-direction: column;
  gap: rem(15px);

  .title {
    padding-left: rem(17px);
    color: #505355;
    font-size: rem(12px);
    line-height: rem(14px);
  }

  .content {
    padding-left: rem(11px);

    .link {
      padding: rem(13px) rem(17px) rem(14px) rem(11px);
      border-radius: rem(22px);
      background-color: #dfe2e6;
      color: #505355;
      font-size: rem(16px);
      line-height: rem(19px);
    }
  }
}
</style>
