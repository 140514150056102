<template>
  <Alert v-if="warnings.length" type="warning" class="alert--producer">
    <template #header> Producer </template>
    <template #body>
      <ul>
        <li v-for="(warning, i) in warnings" :key="i">
          {{ warning }}
        </li>
      </ul>
    </template>
  </Alert>
</template>

<script setup lang="ts">
import { ref, computed, inject, watch } from 'vue';
import Alert from '@/js/Components/Alert.vue';
import useClientStore from '@/js/store/pinia/useClientStore';
import ProducerApi, { Producer } from '@/js/Api/Producer';

interface Props {
  producerId: Producer['id'];
  producerType: Producer['model_type'];
}

const props = defineProps<Props>();

// Tools
const clientID = inject('clientID');

// Addresses
const clientStore = useClientStore();
const { ref: addresses } = clientStore.swrAddresses({ id: clientID.value });

// Producer
const producer = ref();

// Refetch producer when ID changes
watch(
  [() => props.producerId, () => props.producerType],
  () => {
    ProducerApi.find({ id: props.producerId, type: props.producerType }).then(({ data }) => {
      producer.value = data;
    });
  },
  { immediate: true },
);

// Warnings
const warnings = computed(() => {
  const result = [];

  if (!producer.value) return result;

  // States warning
  if (producer.value.licenses && addresses.value) {
    const checkedStates = new Map();
    const licensesMap = new Map(producer.value.licenses.map((license) => [license.state_abbr, license]));
    addresses.value.forEach((address) => {
      if (!address.province) return;
      if (checkedStates.has(address.province)) return;
      if (!licensesMap.has(address.province)) result.push(`${address.province} doesn't exist in producer licenses.`);
      else if (!licensesMap.get(address.province).valid_until)
        result.push(`${address.province} is missing "Valid until" field.`);
      checkedStates.set(address.province, true);
    });
  }

  // Document warning
  if (producer.value.documents) {
    const eoiDocument = producer.value.documents.find((d) => d.type === 'E&O Insurance document');
    if (!eoiDocument.valid_until) result.push('E&O Insurance missing valid until field');
    if (!eoiDocument.filename) result.push('E&O Insurance missing document file');

    const epaDocument = producer.value.documents.find((d) => d.type === 'External producer agreement');
    if (!epaDocument.valid_until) result.push('External producer agreement missing valid until field');
    if (!eoiDocument.filename) result.push('External producer agreement missing document file');
  }

  return result;
});
</script>
