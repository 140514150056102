<template>
  <Modal ref="ModalRef" :is-loading="loading">
    <template #title>{{ agency?.name }}</template>
    <template #footer-btn-text>Close</template>
    <AgencyShow v-if="agency" :agency="agency" />
  </Modal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { toastError } from '@/js/Helpers/Alert';
import AgencyApi, { Agency } from '@/js/Api/Agency';
import Modal from '@/js/Common/Modal.vue';
import AgencyShow from '@/js/Components/Agencies/Show.vue';

const loading = ref<boolean>(false);
const agency = ref<Agency | null>(null);
const ModalRef = ref<InstanceType<typeof Modal> | null>(null);

const open = (id: number) => {
  loading.value = true;
  ModalRef.value.show();
  AgencyApi.find({ id })
    .then(({ data }) => {
      agency.value = data;
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      loading.value = false;
    });
};

defineExpose({
  open,
});
</script>
