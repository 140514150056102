import { z } from 'zod';
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';

export const CoverageTypeCASchema = z.object({
  id: z.literal(1),
  primary: z.literal(true),
  model: z.literal('IntakeCA'),
  abbreviation: z.literal('CA'),
  name: z.literal('Commercial Auto'),
  icon_name: z.literal('car'),
  icon_url: z.literal('/img/icons/car.svg'),
  estimate_price: z.object({
    low: z.literal(null),
    high: z.literal(null),
  }),
});
export const CoverageTypePASchema = z.object({
  id: z.literal(2),
  primary: z.literal(true),
  model: z.literal('IntakePA'),
  abbreviation: z.literal('PA'),
  name: z.literal('Personal auto'),
  icon_name: z.literal('car'),
  icon_url: z.literal('/img/icons/car.svg'),
  estimate_price: z.object({
    low: z.literal(null),
    high: z.literal(null),
  }),
});
export const CoverageTypeCPSchema = z.object({
  id: z.literal(3),
  primary: z.literal(true),
  model: z.literal('IntakeCP'),
  abbreviation: z.literal('CP'),
  name: z.literal('Commercial Property'),
  icon_name: z.literal('house'),
  icon_url: z.literal('/img/icons/house.svg'),
  estimate_price: z.object({
    low: z.literal(null),
    high: z.literal(null),
  }),
});
export const CoverageTypeHOSchema = z.object({
  id: z.literal(4),
  primary: z.literal(true),
  model: z.literal('IntakeHO'),
  abbreviation: z.literal('HO'),
  name: z.literal('Homeowners'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeWCSchema = z.object({
  id: z.literal(5),
  primary: z.literal(true),
  model: z.literal('IntakeWC'),
  abbreviation: z.literal('WC'),
  name: z.literal('Workers Compensation'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeCGLSchema = z.object({
  id: z.literal(6),
  primary: z.literal(true),
  model: z.literal('IntakeCGL'),
  abbreviation: z.literal('CGL'),
  name: z.literal('General Liability'),
  icon_name: z.literal('paragliding'),
  icon_url: z.literal('/img/icons/paragliding.svg'),
  estimate_price: z.object({
    low: z.literal(null),
    high: z.literal(null),
  }),
});
export const CoverageTypeBoatSchema = z.object({
  id: z.literal(7),
  primary: z.literal(false),
  model: z.literal('IntakeBoat'),
  abbreviation: z.literal('Boat'),
  name: z.literal('Boat'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeBondSchema = z.object({
  id: z.literal(8),
  primary: z.literal(false),
  model: z.literal('IntakeBond'),
  abbreviation: z.literal('Bond'),
  name: z.literal('Bond'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeCargoSchema = z.object({
  id: z.literal(9),
  primary: z.literal(false),
  model: z.literal('IntakeCargo'),
  abbreviation: z.literal('Cargo'),
  name: z.literal('Cargo'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeCLSchema = z.object({
  id: z.literal(10),
  primary: z.literal(false),
  model: z.literal('IntakeCL'),
  abbreviation: z.literal('CL'),
  name: z.literal('Cyber Liability'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeCPKSchema = z.object({
  id: z.literal(11),
  primary: z.literal(false),
  model: z.literal('IntakeCPK'),
  abbreviation: z.literal('CPK'),
  name: z.literal('Commercial Package'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeCUESchema = z.object({
  id: z.literal(12),
  primary: z.literal(false),
  model: z.literal('IntakeCUE'),
  abbreviation: z.literal('CUE'),
  name: z.literal('Commercial Umbrella / Excess'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeDentalSchema = z.object({
  id: z.literal(13),
  primary: z.literal(false),
  model: z.literal('IntakeDental'),
  abbreviation: z.literal('Dental'),
  name: z.literal('Dental'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeDisabilitySchema = z.object({
  id: z.literal(14),
  primary: z.literal(false),
  model: z.literal('IntakeDisability'),
  abbreviation: z.literal('Disability'),
  name: z.literal('Disability'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeDOSchema = z.object({
  id: z.literal(15),
  primary: z.literal(false),
  model: z.literal('IntakeDO'),
  abbreviation: z.literal('DO'),
  name: z.literal('D&O'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeDwellingSchema = z.object({
  id: z.literal(16),
  primary: z.literal(false),
  model: z.literal('IntakeDwelling'),
  abbreviation: z.literal('Dwelling'),
  name: z.literal('Dwelling'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeEPLSchema = z.object({
  id: z.literal(17),
  primary: z.literal(false),
  model: z.literal('IntakeEPL'),
  abbreviation: z.literal('EPL'),
  name: z.literal('Employment Practices Liability'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeCFSchema = z.object({
  id: z.literal(18),
  primary: z.literal(false),
  model: z.literal('IntakeCF'),
  abbreviation: z.literal('CF'),
  name: z.literal('Crime & Fidelity'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeFloodSchema = z.object({
  id: z.literal(19),
  primary: z.literal(false),
  model: z.literal('IntakeFlood'),
  abbreviation: z.literal('Flood'),
  name: z.literal('Flood'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeGMSchema = z.object({
  id: z.literal(20),
  primary: z.literal(false),
  model: z.literal('IntakeGM'),
  abbreviation: z.literal('GM'),
  name: z.literal('Group Medical'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeLISchema = z.object({
  id: z.literal(21),
  primary: z.literal(false),
  model: z.literal('IntakeLI'),
  abbreviation: z.literal('LI'),
  name: z.literal('Life Insurance'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeLLSchema = z.object({
  id: z.literal(22),
  primary: z.literal(false),
  model: z.literal('IntakeLL'),
  abbreviation: z.literal('LL'),
  name: z.literal('Liquor Liability'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeMotorcycleSchema = z.object({
  id: z.literal(23),
  primary: z.literal(false),
  model: z.literal('IntakeMotorcycle'),
  abbreviation: z.literal('Motorcycle'),
  name: z.literal('Motorcycle'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeNTLSchema = z.object({
  id: z.literal(24),
  primary: z.literal(false),
  model: z.literal('IntakeNTL'),
  abbreviation: z.literal('NTL'),
  name: z.literal('Non Trucking Liability'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeOASchema = z.object({
  id: z.literal(25),
  primary: z.literal(false),
  model: z.literal('IntakeOA'),
  abbreviation: z.literal('OA'),
  name: z.literal('Occupational Accident'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypePBASchema = z.object({
  id: z.literal(26),
  primary: z.literal(false),
  model: z.literal('IntakePBA'),
  abbreviation: z.literal('PBA'),
  name: z.literal('Public Auto'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypePDSchema = z.object({
  id: z.literal(27),
  primary: z.literal(false),
  model: z.literal('IntakePD'),
  abbreviation: z.literal('PD'),
  name: z.literal('Physical Damage'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypePLSchema = z.object({
  id: z.literal(28),
  primary: z.literal(false),
  model: z.literal('IntakePL'),
  abbreviation: z.literal('PL'),
  name: z.literal('Professional Liability'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeELSchema = z.object({
  id: z.literal(29),
  primary: z.literal(false),
  model: z.literal('IntakeEL'),
  abbreviation: z.literal('EL'),
  name: z.literal('Environmental Liability'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});
export const CoverageTypeRentersSchema = z.object({
  id: z.literal(30),
  primary: z.literal(false),
  model: z.literal('IntakeRenters'),
  abbreviation: z.literal('Renters'),
  name: z.literal('Renters'),
  icon_name: z.literal('badge'),
  icon_url: z.literal('/img/icons/badge.svg'),
});

export const CoverageTypeSchemas = [
  CoverageTypeCASchema,
  CoverageTypePASchema,
  CoverageTypeCPSchema,
  CoverageTypeHOSchema,
  CoverageTypeWCSchema,
  CoverageTypeCGLSchema,
  CoverageTypeBoatSchema,
  CoverageTypeBondSchema,
  CoverageTypeCargoSchema,
  CoverageTypeCLSchema,
  CoverageTypeCPKSchema,
  CoverageTypeCUESchema,
  CoverageTypeDentalSchema,
  CoverageTypeDisabilitySchema,
  CoverageTypeDOSchema,
  CoverageTypeDwellingSchema,
  CoverageTypeEPLSchema,
  CoverageTypeCFSchema,
  CoverageTypeFloodSchema,
  CoverageTypeGMSchema,
  CoverageTypeLISchema,
  CoverageTypeLLSchema,
  CoverageTypeMotorcycleSchema,
  CoverageTypeNTLSchema,
  CoverageTypeOASchema,
  CoverageTypePBASchema,
  CoverageTypePDSchema,
  CoverageTypePLSchema,
  CoverageTypeELSchema,
  CoverageTypeRentersSchema,
] as const;

export const CoverageTypeSchema = z.union(CoverageTypeSchemas);

export type CoverageType = z.infer<typeof CoverageTypeSchema>;

export const CoverageTypeIdSchema = z.union(
  CoverageTypeSchemas.map((s) => z.literal(s.shape.id.value)) as [
    z.ZodLiteral<CoverageType['id']>,
    z.ZodLiteral<CoverageType['id']>,
    ...z.ZodLiteral<CoverageType['id']>[],
  ],
);

// ***************************************************
// All
// ***************************************************

async function all() {
  const response = await axios.get('/api/coverages/all').catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: CoverageTypeSchema.array(),
    })
    .parseAsync(response.data);
}

export default {
  all,
};
