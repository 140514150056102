<template>
  <div class="nav-switcher">

    <template v-for="(item, i) in items" :key="i">
      <a
        v-if="!item.link && (typeof item.permission === 'boolean' ? item.permission : true)"
        href="#"
        class="nav-switcher__item"
        :class="{ 'nav-switcher__item--active': item.active }"
        @click.prevent="onItemClick(item, i)">
          <div class="nav-switcher__item-text">{{ item.text }}</div>
      </a>
      <router-link
        v-else-if="item.link && (typeof item.permission === 'boolean' ? item.permission : true)"
        exact
        class="nav-switcher__item"
        :class="{ 'nav-switcher__item--active': item.active }"
        :to="item.link">
          <div class="nav-switcher__item-text">{{ item.text }}</div>
      </router-link>
    </template>

    <!-- Slot for custom elements -->
    <slot/>

  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  emits: ['selected'],

  setup(props, context) {
    let activeItem = null;

    const onItemClick = (item, i) => {
      let activeItem = props.items.find(item => item.active);
      if (activeItem)
        delete activeItem.active;
      props.items[i].active = true;
      activeItem = props.items[i];

      context.emit('selected', activeItem);
    };

    return {
      onItemClick,
      activeItem
    }
  }
}
</script>

<style lang="scss">
.nav-switcher {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: rem(3px) rem(4px);
  background-color: #DFE2E6;
  border-radius: rem(22px);

  &__item {
    color: color('gray', 7);
    text-decoration: none;
    padding: rem(2px) rem(14px);
    cursor: pointer;
    border-radius: rem(22px);
    font-size: rem(14px);
    &:hover {
      color: color('gray', 7);
    }
    &:not(:last-of-type) {
      margin-right: rem(6px);
    }
    &--active {
      color: black;
      background-color: white;
    }
  }

  &__item-text {
    display: flex;
    align-items: center;
  }

}
</style>
