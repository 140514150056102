import { z } from 'zod';
import { UserSchema } from './User';

const IntakeChildTypeSchema = z.union([z.literal('driver'), z.literal('vehicle')]);

const IntakeChildStatusStringSchema = z.union([
  z.literal('Added'),
  z.literal('Quoted'),
  z.literal('Removed'),
  z.literal('Quoted Not Taken'),
]);

export type IntakeChildType = z.infer<typeof IntakeChildTypeSchema>;
export type IntakeChildStatusString = z.infer<typeof IntakeChildStatusStringSchema>;

export const IntakeChildStatusSchema = z.object({
  id: z.number(),
  policy_id: z.number().nullable(),
  quote_group_id: z.number().nullable(),
  intake_type: z.string(),
  intake_id: z.number(),
  child_id: z.number(),
  child_type: IntakeChildTypeSchema,
  status: z.string(),
  date: z.string().nullable(),
  created_by_id: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  policy_number: z.string().nullable(),
  policy_status: z.string().nullable(), // @FIXME - import PolicyStatusSchema from './Policy'
  policy_effective_date: z.string().nullable(),
  policy_expiration_date: z.string().nullable(),
  quote_group_desired_effective_date: z.string().nullable(),

  // Relations
  created_by: UserSchema.or(z.undefined()),
});

export type IntakeChildStatus = z.infer<typeof IntakeChildStatusSchema>;
