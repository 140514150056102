<template>
  <div class="client-layout">
    <template v-if="!errorCode">
      <!-- Header -->
      <ClientHeader :client="client" />

      <!-- View -->
      <router-view v-slot="{ Component }">
        <component
          :is="Component"
          :client="client"
          :client-id="clientIdInt"
          @update:client="clientStore.updateClient"
        />
      </router-view>
    </template>

    <template v-else-if="errorCode === 403">
      <h1>Unauthorized</h1>
    </template>
  </div>
</template>

<script setup>
import { computed, provide } from 'vue';

import useClientStore from '@/js/store/pinia/useClientStore';
import ClientHeader from './ClientHeader.vue';

const props = defineProps({
  clientId: {
    type: [String, Number],
    required: true,
  },
});

const clientIdInt = computed(() => Number(props.clientId));

const clientStore = useClientStore();
const { ref: client, error } = clientStore.swrClient({ id: clientIdInt.value });

const errorCode = computed(() => error.value?.response?.status || null);

// Provide clientID
provide('clientID', clientIdInt);

// Can edit client
const canEdit = computed(() => (client.value ? client.value.canThisUserEdit : false));
provide('canEditClient', canEdit);
</script>

<style lang="scss" scoped>
.client-layout {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
