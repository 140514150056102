import type { Attachment } from '@/js/Api/Storage';

const LIMIT_MB = 100;

export const processAttachments = (event: Event): Promise<Attachment[]> => {
  let items: File[] = [];

  // Extract File[] from Event
  if (event.type === 'drop' && event instanceof DragEvent && event.dataTransfer) {
    items = Array.from(event.dataTransfer.files);
    if (items.length === 0) throw 'No files';
  } else if (event.type === 'change' && event.target instanceof HTMLInputElement && event.target.files) {
    items = Array.from(event.target.files);
    event.target.value = '';
  } else if (event.type === 'paste' && event instanceof ClipboardEvent && event.clipboardData) {
    items = Array.from(event.clipboardData.items).reduce((carry, item) => {
      const file = item.getAsFile();
      if (file) carry.push(file);
      return carry;
    }, items);
  }

  if (items.find((item) => item.size / (LIMIT_MB * 10000) > 100))
    return Promise.reject(new Error(`One of the files is larger then ${LIMIT_MB}MB`));

  // Convert File[] to Attachment[]
  const promises: Promise<Attachment>[] = Array.from(items).map((file) => {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = () => {
        const base64 = (reader.result as string).replace(/data:.+;base64,/, '');
        resolve({ name: file.name, base64 });
      };
      reader.readAsDataURL(file);
    });
  });

  return Promise.all(promises);
};
