<template>
  <FormGroup
    :model-value="note?.watchers"
    class="watchlist"
    type="ajax-select"
    label-method="name"
    :multivalue="true"
    :on-select="onSelect"
    :fetch-options="fetchOptions"
  >
    <template #trigger="{ value: watchers }">
      <SvgIcon name="eye" :class="{ 'svg-icon--active': watchers.length > 0 }" />
    </template>
  </FormGroup>
</template>

<script setup lang="ts">
import UserApi, { User } from '@/js/Api/User';
import TicketNoteApi, { TicketNote } from '@/js/Api/TicketNote';
import { toast } from '@/js/Helpers/Alert';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import FormGroup from '@/js/Components/Form/FormGroup.vue';

interface Props {
  note?: TicketNote;
  modelValue?: User[];
}

interface Emits {
  (e: 'update:note', note: TicketNote): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const fetchOptions = (query: string | undefined, page: number) => {
  return UserApi.index({
    queryBuilder: {
      page,
      filters: {
        search: query,
      },
    },
  });
};

const onSelect = async (option: User, checked: boolean, selected: User[]) => {
  if (props.note?.is_followup)
    return TicketNoteApi.watch({ id: props.note.id, watcherIds: selected.map((o) => o.id) })
      .then(({ data }) => {
        emit('update:note', { ...props.note, watchers: data } as TicketNote);
      })
      .catch(() => {
        toast({ title: 'An error occurred.', icon: 'error' });
      });
};
</script>

<style lang="scss">
.form-group.watchlist {
  margin-bottom: 0;

  .dropdown__trigger {
    border-radius: 50%;

    .svg-icon {
      color: #c3c3c3;

      &--active {
        color: color('primary');
      }
    }
  }

  .dropdown__trigger:hover,
  &.dropdown--shown .dropdown__trigger {
    background-color: #e5e5e5;

    .svg-icon {
      color: color('primary');
    }
  }

  .dropdown__menu {
    min-width: rem(200px);
  }
}
</style>
