<template>
  <div class="client-header">
    <!-- Left -->
    <div class="client-header__left">
      <!-- Go back button -->
      <SvgIcon v-if="!isClientPage" name="arrow-left" @click="goBack" />

      <!-- Copy button -->
      <div v-if="isClientPage || isPolicyPage">
        <SvgIcon name="copy" color="#a3b6cc" @click="copyIconClick" />
        <Tooltip :show-when="showCopyTooltip"> Information has been copied. </Tooltip>
      </div>

      <!-- Client info -->
      <div v-if="client" class="client-header__info">
        <h3 class="client-header__name">
          {{ client.name || 'New Client' }}
          <template v-if="childPageTitle">
            <span class="client-header__slash">/</span>
            {{ childPageTitle }}
          </template>
        </h3>

        <!-- Details -->
        <p class="client-header__details">
          <span v-if="primaryClientName">{{ primaryClientName }}</span>
          <span v-if="primaryPhoneNumber">{{ primaryPhoneNumber }}</span>
          <span v-if="primaryEmailAddress">{{ primaryEmailAddress }}</span>

          <!-- Attachments link -->
          <router-link class="link" :to="`/clients/${clientID}/attachments`">
            <SvgIcon name="paperclip" />
            Client attachments
          </router-link>

          <!-- TODO: Correct route and number -->
          <router-link class="link" :to="`/clients/${clientID}/invoices`">
            <SvgIcon name="invoices" />
            Invoices
            <template v-if="invoiceCount != null">({{ invoiceCount }})</template>
          </router-link>

          <router-link class="link" :to="`/clients/${clientID}/payments`">
            <SvgIcon name="payments" />
          </router-link>

          <!-- Certificates link -->
          <router-link
            v-if="me.permissions['certificates.page']"
            class="link"
            :to="`/clients/${clientID}/certificates`"
          >
            <SvgIcon name="file-shield" />
            Certificates
          </router-link>

          <!-- Applications -->
          <router-link class="link" :to="`/clients/${clientID}/applications`">
            <SvgIcon name="application" />
            Applications
          </router-link>

          <!-- Vehicles & Drivers -->
          <router-link class="link" :to="`/clients/${clientID}/vehicles-and-drivers`">
            <SvgIcon name="car" />
            Vehicles & Drivers
          </router-link>

          <!-- Merge link -->
          <a v-if="me.permissions['delete-clients']" class="link" @click="openMergeModal">
            <SvgIcon name="merge" />
            Merge
          </a>

          <!-- Merge modal -->
          <MergeModal v-if="client" ref="MergeModalRef" :client="client" />
        </p>
      </div>
    </div>

    <!-- Right -->
    <div class="client-header__right">
      <!-- Timer -->
      <Timer v-if="client" :parent="client" :parent-id="clientID" parent-type="client" />
    </div>
  </div>
</template>

<script setup>
import { computed, inject, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { me } from '@/js/Helpers/Session';
import Clipboard from '@/js/Helpers/Clipboard';
import Timer from '@/js/Common/Timer.vue';
import Tooltip from '@/js/Common/Tooltip.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import MergeModal from '@/js/Components/Clients/MergeModal.vue';
import useClientStore from '@/js/store/pinia/useClientStore';

const props = defineProps({
  client: {
    type: null,
    required: true,
  },
});

const clientID = inject('clientID');

const clientStore = useClientStore();

// Router view
const route = useRoute();
const childPageName = computed(() => (route.matched.length >= 2 ? route.matched[1].name : null));
const isClientPage = computed(() => childPageName.value === 'ClientMainView');
const isPolicyPage = computed(() => childPageName.value === 'ClientPolicy');

// Redirect
const router = useRouter();
const goBack = () => {
  if (router.currentRoute.value.name === 'ClientCertificatesTemplate')
    router.push(`/clients/${clientID.value}/certificates`);
  else router.push(`/clients/${clientID.value}`);
};

// Child page title
const childPageTitle = computed(() => {
  if (route.matched.length < 2) return false;
  if (childPageName.value === 'ClientCertificatesPanels' || childPageName.value === 'ClientCertificatesTemplate')
    return 'Certificates';
  if (childPageName.value === 'ClientPolicy' && !clientStore.policy?.number) return 'POLICY';
  if (childPageName.value === 'ClientPolicy' && clientStore.policy.number) return `POLICY ${clientStore.policy.number}`;
  return false;
});

// Copy
const showCopyTooltip = ref(1);
const copyIconClick = () => {
  const text =
    isPolicyPage.value && clientStore.policy?.number
      ? `${props.client.name} / POLICY ${clientStore.policy?.number}`
      : props.client.name;
  Clipboard.copy(text);
  showCopyTooltip.value += 1;
};

// Primary client name, phone and email
const { ref: contacts } = clientStore.swrContacts({ id: clientID.value });
const primaryClientName = computed(() => {
  if (!contacts.value) return null;
  const primaryContact = contacts.value.find((c) => c.is_primary) || contacts.value[0];
  return primaryContact.name;
});
const primaryPhoneNumber = computed(() => {
  if (!contacts.value) return null;
  const primaryContact = contacts.value.find((c) => c.is_primary) || contacts.value[0];
  const primaryPhone = primaryContact.phones.find((p) => p.is_primary) || primaryContact.phones[0];
  return primaryPhone.number;
});
const primaryEmailAddress = computed(() => {
  if (!contacts.value) return null;
  const primaryContact = contacts.value.find((c) => c.is_primary) || contacts.value[0];
  const primaryEmail = primaryContact.emails.find((e) => e.is_primary) || primaryContact.emails[0];
  return primaryEmail.email;
});

const invoiceCount = ref(null);

// onMounted(() => {
//   axios.get(`/api/clients/${clientID.value}/invoices`).then((r) => {
//     invoiceCount.value = r.data.data.length;
//   });
// });

// Merge
const MergeModalRef = ref(null);
const openMergeModal = () => {
  MergeModalRef.value.$refs.ModalRef.show();
};
</script>

<style lang="scss">
.client-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: rem(10px);

  // Left
  &__left {
    display: flex;
    align-items: flex-start;
  }

  .svg-icon--copy,
  .svg-icon--arrow-left {
    cursor: pointer;
    margin-top: rem(3px);
    margin-right: rem(15px);
  }

  // Name
  &__name {
    font-size: rem(22px);
    line-height: rem(27px);
    margin-bottom: rem(4px);
    color: color('text', 1);
  }

  &__slash {
    color: color('gray', 4);
    margin: 0 rem(5px);
  }

  // Details
  &__details {
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: rem(12px);
    line-height: rem(14px);
    color: color('text', 4);

    > * {
      padding: 0 rem(8px);
      border-right: rem(1px) solid color('text', 4);

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border-right: none;
      }

      &.link {
        display: flex;
        align-items: center;
      }

      .svg-icon {
        width: rem(16px);
        height: rem(16px);
        margin-right: rem(4px);
      }
    }
  }

  // Right
  &__right {
    display: flex;
    align-items: center;

    .lock-container {
      margin-right: rem(25px);

      .icon {
        color: color('primary');
        font-size: rem(24px);
      }
    }
  }
}
</style>
