<template>
  <div
    ref="rootRef"
    :class="{
      popper: true,
      'popper--shown': isShown,
      [`popper--placement-${placement}`]: true,
    }"
  >
    <slot />
  </div>
</template>

<script setup>
import { createPopper } from '@popperjs/core';
import { onClickOutside, useResizeObserver } from '@vueuse/core';
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';

const props = defineProps({
  placement: {
    type: String,
    default: 'top',
  },
  strategy: {
    type: String,
    default: 'fixed',
  },
  offset: {
    type: Array,
    default: () => [0, 8],
  },
  targetEl: {
    type: Object,
    default: null,
  },
  showWhen: {
    type: [Number, Boolean],
    default: false,
  },
  showOn: {
    type: String,
    default: null,
    validator: (v) => ['hover', 'click'].includes(v),
  },
});

const emit = defineEmits(['show', 'shown', 'hide', 'hidden', 'toggle', 'toggled']);

const rootRef = ref(null);

const isShown = ref(false);

let popperInstance = null;

// Actions
const hide = () => {
  if (!isShown.value) return;
  emit('hide');
  popperInstance.setOptions({
    modifiers: [{ name: 'eventListeners', enabled: false }],
  });
  isShown.value = false;
  emit('hidden');
};
const show = () => {
  if (isShown.value) return;
  emit('show');
  popperInstance.setOptions({
    modifiers: [
      {
        name: 'offset',
        options: { offset: props.offset },
      },
      { name: 'eventListeners', enabled: true },
    ],
  });
  popperInstance.update();
  isShown.value = true;
  emit('shown');
};
const toggle = () => {
  if (isShown.value) hide();
  else show();
};

let targetEl = null;

onMounted(() => {
  // Get target el
  targetEl = props.targetEl ?? rootRef.value.parentElement;

  // Hover
  targetEl.removeEventListener('mouseenter', show);
  targetEl.removeEventListener('mouseleave', hide);
  if (props.showOn === 'hover') {
    targetEl.addEventListener('mouseenter', show);
    targetEl.addEventListener('mouseleave', hide);
  }

  // Click
  targetEl.removeEventListener('click', toggle);
  if (props.showOn === 'click') targetEl.addEventListener('click', toggle);

  // Create popper
  popperInstance = createPopper(targetEl, rootRef.value, {
    strategy: props.strategy,
    placement: props.placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: props.offset,
        },
      },
      { name: 'eventListeners', enabled: false },
    ],
  });

  if (props.showOn === null) show();

  // Hide popper on outside click
  if (props.showOn !== null)
    onClickOutside(targetEl, () => {
      hide();
    });

  // If parent element changes size
  useResizeObserver(targetEl, () => {
    if (isShown.value) popperInstance.update();
  });
});

// Watch for "show" trigger that parent controls
watch(
  () => props.showWhen,
  () => {
    show();
  }
);

// Cleanup
onBeforeUnmount(() => {
  popperInstance.destroy();
  targetEl.removeEventListener('click', toggle);
  targetEl.removeEventListener('mouseleave', hide);
  targetEl.removeEventListener('mouseleave', hide);
});
</script>

<style lang="scss">
.popper {
  display: none;

  &--shown {
    display: block;
  }
}
</style>
