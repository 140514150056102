import { z } from 'zod';
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';
import { queryBuilderToQueryString } from '@/js/Helpers';
import { QuoteSchema } from './Quote';
import { QuoteGroupStatusSchema } from './QuoteGroupStatus';
import { QuoteGroupIntakeSchema } from './QuoteGroupIntake';

export const QuoteGroupSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  desired_effective_date: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullable(),

  // Quotes
  cover_whale_quote_id: z.string().nullable(),

  // Relations
  quotes: QuoteSchema.array().optional(),
  intakes: z.array(QuoteGroupIntakeSchema).optional(),
  statuses: z.array(QuoteGroupStatusSchema).optional(),
});

export type QuoteGroup = z.infer<typeof QuoteGroupSchema>;

type AllQueryBuilder = {
  sort?: {
    order: 'desc' | 'asc';
    column: 'created_at';
  };
  includes?: ('intakes' | 'intakes.intake_body' | 'intakes.quotes' | 'intakes.quotes.carrier')[];
  filters?: {
    client_id: number;
    with_trashed?: true;
  };
};

async function all({ queryBuilder }: { queryBuilder: AllQueryBuilder }) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/quote-groups/all?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: QuoteGroupSchema.array(),
    })
    .parseAsync(response.data);
}

async function find({ clientId, id }: { clientId: number; id: number }) {
  const response = await axios.get(`/api/clients/${clientId}/quotegroups/${id}`).catch((error) => {
    Sentry.captureException(error);
  });

  if (!response) return undefined;

  return z
    .object({
      data: QuoteGroupSchema,
    })
    .parseAsync(response.data);
}

async function create({ clientId, coverageTypeIds }: { clientId: number; coverageTypeIds: number[] }) {
  const response = await axios
    .post(`/api/clients/${clientId}/quotegroups`, {
      coverage_ids: coverageTypeIds,
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      // @FIXME
      note: z.any(),
      quoteGroup: QuoteGroupSchema,
    })
    .parseAsync(response.data);
}

export default {
  all,
  find,
  create,
};
