import { z } from 'zod';

export const QuoteGroupIntakeQuoteSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  quote_id: z.number(),
  quote_group_intake_id: z.number(),
  description: z.string().nullable(),
  detail: z.string().nullable(),
  amount: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});

export type QuoteGroupIntakeQuote = z.infer<typeof QuoteGroupIntakeQuoteSchema>;
