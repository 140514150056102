<template>
  <div :class="{ active: isActiveLocal, 'slide-panel--active': isActiveLocal }" class="slide-panel">
    <!-- Panel header-->
    <div class="slide-panel__header" @click="onHeaderClick">
      <!--  -->
      <slot name="header-left" :is-active="isActiveLocal" />

      <!-- Slider -->
      <div class="slide-panel__header-right">
        <slot name="header-right" :is-active="isActiveLocal" />
        <FormGroupCheckboxSlider v-model="isActiveLocal" :checkboxes="[{ inputValue: true }]" />
      </div>
    </div>

    <!-- Panel body -->
    <div class="slide-panel__body">
      <slot />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import FormGroupCheckboxSlider from './CheckboxSlider.vue';

export default {
  components: {
    FormGroupCheckboxSlider,
  },

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:is-active'],

  setup(props, { emit }) {
    // Attrs
    const isActiveLocal = ref(props.isActive);

    const onHeaderClick = (e) => {
      e.preventDefault();
      isActiveLocal.value = !isActiveLocal.value;
      emit('update:is-active', isActiveLocal.value);
    };

    return {
      isActiveLocal,
      onHeaderClick,
    };
  },
};
</script>

<style lang="scss">
.slide-panel {
  display: flex;
  flex-direction: column;
  margin-bottom: rem(10px);
  background: transparent;
  border: rem(1px) solid #ccc;
  border-radius: rem(6px);

  // Hover
  transition: 0.2s border-color;

  &:not(.active):hover {
    border-color: color('primary') !important;
  }

  .slide-panel__header {
    cursor: pointer;
  }

  // Active
  &--active {
    background-color: white;
    box-shadow: 0 rem(3px) rem(15px) rgb(0 0 0 / 10%);
    border-color: transparent;

    > .slide-panel__body {
      max-height: none;
      padding-bottom: rem(20px);
    }
  }

  // Header
  &__header {
    padding: rem(15px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &-left {
      color: #666;
      display: flex;
      align-items: center;

      &-text {
        font-weight: bold;
        font-size: rem(14px);
        line-height: rem(14px);
        padding-left: rem(30px);
      }

      &-icon {
        position: absolute;
        font-size: rem(18px);

        // margin-right: rem(9px);
      }
    }

    &-right {
      display: flex;
      align-items: center;

      > *:not(:last-child) {
        margin-right: rem(25px);
      }

      .form-checks > .form-check {
        display: flex;
      }
    }
  }

  // Body
  &__body {
    max-height: 0;
    overflow: hidden;
    padding: 0 rem(15px);
  }

  // Table list inside slide panel
  &__body .table-list {
    box-shadow: none;
  }
}
</style>
