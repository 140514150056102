<template>
  <KeepAlive>
    <Component
      :is="activeComponent"
      :client="client"
      :disabled="disabled"
      :client-id="client.id"
      :intake-id="activeTab.intakeId"
      :generic-type="activeTab.abbreviation"
      @update:client="(event) => emit('update:client', event)"
    />
  </KeepAlive>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, provide } from 'vue';
import { Client } from '@/js/Api/Client';
import { Tab } from './Tabs.vue';

interface Props {
  activeTab: Tab;
  client: Client;
  disabled: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits(['update:client']);

// @FIXME
const intakeIdComp = computed(() => props.activeTab.intakeId);
provide('intakeID', intakeIdComp);

const components = {
  IntakeCA: defineAsyncComponent(() => import('./IntakeCA/IntakeCA.vue')),
  IntakePA: defineAsyncComponent(() => import('./IntakePA/IntakePA.vue')),
  IntakeHO: defineAsyncComponent(() => import('./IntakeHO/IntakeHO.vue')),
  IntakeCGL: defineAsyncComponent(() => import('./IntakeGL/IntakeGL.vue')),
  IntakeWC: defineAsyncComponent(() => import('./IntakeWC/IntakeWC.vue')),
  IntakeCP: defineAsyncComponent(() => import('./IntakeCP/IntakeCP.vue')),
  IntakeCargo: defineAsyncComponent(() => import('./IntakeCargo/IntakeCargo.vue')),
  IntakePD: defineAsyncComponent(() => import('./IntakePD/IntakePD.vue')),
  IntakeNTL: defineAsyncComponent(() => import('./IntakeNTL/IntakeNTL.vue')),
  IntakeGeneric: defineAsyncComponent(() => import('./IntakeGeneric/IntakeGeneric.vue')),
  IntakeGeneral: defineAsyncComponent(() => import('@/js/Common/Form/IntakeGeneral/IntakeGeneral.vue')),
};

const activeComponent = computed(() => components[props.activeTab.componentName] ?? components.IntakeGeneric);
</script>
