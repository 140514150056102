<template>
  <!-- No items -->
  <div v-if="users && !users.length" class="user-boxes-no-items">No items in the list</div>

  <div v-if="users && users.length" class="user-boxes">
    <div v-for="user in users" :key="user.id" class="user-box">
      <div>
        <!-- Top -->
        <div class="user-box__top">
          <div class="user-box__profile">
            <i class="fas fa-user-circle icon"></i>
            <div class="user-box__username">
              {{ user.name }}
            </div>
          </div>
          <div class="user-box__followup-count">
            <div>
              <div class="number">{{ user.followups_urgent_count }}</div>
              <div class="text">URGENT</div>
            </div>
            <div>
              <div class="number">{{ user.followups_count }}</div>
              <div class="text">TASKS</div>
            </div>
          </div>
        </div>

        <!-- Center -->
        <div class="user-box__center">
          <table>
            <tbody>
              <template v-for="followup in user.followups" :key="followup.id">
                <tr class="user-box__followup" @click="$emit('click:followup', followup)">
                  <td class="td-priority-client">
                    <div>
                      <div :class="['user-box__followup_indicator', followup.priority_readable.toLowerCase()]"></div>
                      <div class="text">
                        {{ displayClientName(followup) }}
                      </div>
                    </div>
                  </td>
                  <td class="td-content">
                    {{ followup.content.shorten(20) }}
                  </td>
                  <td class="td-due">
                    {{ new Date(followup.due).toDateSlashShort() }}
                  </td>
                  <td class="td-eye">
                    <div>
                      <img
                        v-if="followup.close_cleared == false"
                        class="icon eye"
                        data-tooltip="Clear closed followup"
                        src="/img/icons/watch_closed-24px.svg"
                        @click.stop="eyeClick(followup, user)"
                      />
                      <img
                        v-else-if="followup.reschedule_cleared == false"
                        class="icon eye"
                        data-tooltip="Clear rescheduled followup"
                        src="/img/icons/watch_rescheduled-24px.svg"
                        @click.stop="eyeClick(followup, user)"
                      />
                      <img
                        v-else-if="followup.is_watching"
                        class="icon eye"
                        data-tooltip="Unwatch this followup"
                        src="/img/icons/watch_off-24px.svg"
                        @click.stop="eyeClick(followup, user)"
                      />
                      <img
                        v-else
                        class="icon eye"
                        data-tooltip="Watch this followup"
                        src="/img/icons/watch_on-24px.svg"
                        @click.stop="eyeClick(followup, user)"
                      />
                    </div>
                  </td>
                  <td class="td-weight">
                    {{ followup.weight }}
                  </td>
                </tr>
                <tr class="spacer"></tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Bottom -->
      <div class="user-box__bottom">
        <a href="#" @click.prevent="viewUserTasks(user.id)"> View all tasks for {{ user.name }} </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios';
import TicketNoteApi from '@/js/Api/TicketNote';
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { toast } from '@/js/Helpers/Alert';
import Loader from '@/js/Helpers/Loader';

const emit = defineEmits(['click:followup', 'watch:followup', 'sort']);

const store = useStore();

const errorMessage = ref(null);

const users = computed(() => store.state.dashboard.teamwatchViewUsers);

axios
  .get('/dashboard/data/teamwatch')
  .then((response) => store.commit('dashboard/setTeamWatchViewUsers', response))
  .catch((error) => (errorMessage.value = error.response.data.message));

const eyeClick = (followup) => {
  Loader.start();

  // Clearing
  if (followup.close_cleared == false || followup.reschedule_cleared == false) {
    const clearType = followup.close_cleared == false ? 'close' : 'reschedule';
    TicketNoteApi.clear({ id: followup.id, clearType })
      .then(() => {
        if (clearType == 'reschedule') {
          followup.old_due = null;
          followup.reschedule_cleared = true;
          toast({ title: 'Rescheduled followup cleared.', icon: 'success' });
        } else {
          store.commit('dashboard/clearClosedFollowup', followup);
          toast({ title: 'Closed followup cleared.', icon: 'success' });
        }
      })
      .catch(() => {
        toast({ title: 'An error has occurred.', icon: 'error' });
      })
      .finally(() => {
        Loader.end();
      });

    // Watching / Unwatching
  } else {
    emit('watch:followup', followup);
  }
};

const viewUserTasks = (userID) => {
  store.commit('dashboard/updateFilter', { property: 'assignee', value: userID });
  store.commit('dashboard/setFollowupsView', 'regular');
};

const displayClientName = (followup) => {
  if (followup.client && followup.client.name) return followup.client.name.toLowerCase();
  else if (followup.client && !followup.client.name) return '(Draft)';
  else return 'Non-client';
};
</script>

<style lang="scss" scoped>
.user-boxes-no-items {
  text-align: center;
}

.user-boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: rem(291px);
  grid-gap: rem(10px);

  .user-box {
    background-color: white;
    border: rem(1px) solid color('gray', 3);
    border-radius: rem(6px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // Top
    .user-box__top {
      display: flex;
      justify-content: space-between;
      padding: rem(10px) rem(24px) rem(14px) rem(17px);
      .user-box__profile {
        display: flex;
        align-items: center;
        .icon {
          font-size: 29px;
        }
        .user-box__username {
          color: color('gray', 5);
          width: rem(70px);
          font-size: rem(12px);
          line-height: rem(14px);
          margin-left: rem(10px);
        }
      }
      .user-box__followup-count {
        display: flex;
        & > div {
          text-align: center;
          .number {
            font-size: rem(30px);
            line-height: rem(30px);
          }
          .text {
            font-size: rem(10px);
            line-height: rem(16px);
          }
        }
        & > div:first-of-type {
          margin-right: rem(31px);
          color: $red;
        }
        & > div:last-of-type {
          color: black;
          .number {
            font-weight: bold;
          }
        }
      }
    }

    // Center
    .user-box__center {
      padding: 0 rem(9px);
      table {
        border-collapse: inherit;
        border-spacing: 0;
      }
      tr:not(.spacer) {
        border-radius: rem(6px);
        color: color('text', 2);
        cursor: pointer;
        transition: box-shadow 0.2s;
        box-shadow: none;
        &:hover {
          @include box-shadow-light;
        }
        td {
          padding: rem(5px) rem(8px) rem(5px) 0;
          border-top: rem(1px) solid color('gray', 3);
          border-bottom: rem(1px) solid color('gray', 3);
        }
        td:first-of-type {
          padding-left: rem(7px);
          border-left: rem(1px) solid color('gray', 3);
          border-top-left-radius: rem(6px);
          border-bottom-left-radius: rem(6px);
        }
        td:last-of-type {
          padding-right: rem(11px);
          border-right: rem(1px) solid color('gray', 3);
          border-top-right-radius: rem(6px);
          border-bottom-right-radius: rem(6px);
        }
        td.td-priority-client {
          max-width: 33%;
          & > div {
            display: flex;
            align-items: center;
            .user-box__followup_indicator {
              width: rem(4px);
              height: rem(28px);
              margin-right: rem(8px);
              border-radius: rem(3px);
              &.low {
                background-color: color('followup-priority', 1);
              }
              &.medium {
                background-color: color('followup-priority', 2);
              }
              &.high {
                background-color: color('followup-priority', 3);
              }
              &.urgent {
                background-color: color('followup-priority', 4);
              }
            }
            .text {
              font-size: rem(14px);
              line-height: rem(16px);
              max-width: rem(120px);
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              text-transform: capitalize;
            }
          }
        }
        td.td-content {
          font-size: rem(14px);
          line-height: rem(16px);
          // font-weight: bold;
        }
        td.td-due {
          font-size: rem(14px);
          line-height: rem(16px);
        }
        td.td-eye {
          width: rem(24px);
          & > div {
            display: flex;
            align-items: center;
          }
        }
        td.td-weight {
          width: 1%;
          text-align: right;
          font-size: rem(16px);
          line-height: rem(17px);
        }
      }
      tr.spacer {
        height: rem(4px);
      }
    }

    // Bottom
    .user-box__bottom {
      height: rem(35px);
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        display: block;
        text-decoration: none;
        font-size: rem(14px);
        line-height: rem(16px);
        color: #1a497f;
      }
    }
  }
}
</style>
