<template>
  <div class="sidebar-right-panel">

    <!-- Header -->
    <div class="sidebar-right-panel__header">
      <slot name="header"></slot>
    </div>

    <!-- Divider -->
    <div class="sidebar-right-panel__divider"></div>

    <!-- Body -->
    <div class="sidebar-right-panel__body">
      <slot name="body"></slot>
    </div>

  </div>
</template>

<style lang="scss">
.sidebar-right-panel {
  $self: &;

  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  // Top
  &__header {
    padding-left: rem(22px);
    padding-right: rem(22px);
  }

  // Body
  &__body {
    flex: 1;
    overflow: auto;
  }

  // Divider
  &__divider {
    min-height: rem(1px);
    background-color: color('gray', 3);
    margin: rem(10px) 0;
  }

}
</style>
