<template>
  <div class="topbar">
    <!-- Left -->
    <div class="topbar__left">
      <!-- Toggle sidebar button -->
      <button class="topbar__menu-icon" @click="toggleSidebar">
        <i class="fas fa-bars" />
      </button>

      <!-- Application name -->
      <router-link to="/dashboard" class="topbar__branding">
        <h2 class="topbar__branding-title">Great Agency Inc.</h2>
        <span class="topbar__branding-desc">Insurance Portal</span>
      </router-link>
    </div>

    <!-- Client search -->
    <div class="topbar__center">
      <SearchResources
        api-endpoint="/search"
        placeholder="Search for insured..."
        :api-params="{ 'highlight[0]': '*' }"
        :min-search-chars="me.permissions['search-chars']"
        @item:selected="goToClient"
      >
        <template #match-inner="{ match }">
          <div v-html="match.name ?? '(Draft)'"></div>
          <div class="search__match-details">
            <div v-if="match.policyNumbers" v-html="`Policy number: ${displayPolicyNumber(match)}`"></div>
            <div v-if="match.dot" v-html="`DOT: ${match.dot}`"></div>
            <div
              v-if="match.contactNames || match.primaryContactName"
              v-html="`Rep: ${displayContactName(match)}`"
            ></div>
            <div v-if="match.address" v-html="`Address: ${match.address}`"></div>
            <div
              v-if="match.phoneNumbers || match.primaryPhoneNumber"
              v-html="`Phone: ${displayPhoneNumber(match)}`"
            ></div>
          </div>
        </template>
      </SearchResources>
    </div>

    <!-- Right -->
    <div class="topbar__right">
      <!-- New client button -->
      <button
        v-if="me && me.permissions['create-clients']"
        type="button"
        class="topbar__btn-create-client btn btn-primary"
        @click="createClient"
      >
        <img class="icon left" src="/img/icons/person_add-24px.svg" />Add a Client
      </button>

      <!-- New followup button -->
      <DropdownAddFollowup v-if="me.permissions['create-non-client-followups']" />

      <!-- Divider -->
      <div class="divider"></div>

      <!-- Notifications -->
      <Notifications />

      <DefaultMailboxNotifications v-if="defaultAccount" :count="defaultAccount.unread_count" />

      <!-- User dropdown container -->
      <Dropdown placement="bottom" container-class="user">
        <template #trigger>
          <i class="fas fa-user-circle icon"></i>
        </template>
        <template #menu>
          <p>Logged in as: {{ me.name }}</p>
          <a class="dropdown-item" href="#"> Profile </a>
          <form method="post" action="/logout" @submit="onLogout">
            <input type="hidden" name="_token" :value="csrf" />
            <button class="dropdown-item" type="submit">Logout</button>
          </form>
        </template>
      </Dropdown>

      <!-- Toggle sidebar button -->
      <button class="topbar__right-menu" @click="toggleSidebarRight">
        <i class="fas fa-bars" />
      </button>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import { useGetters } from 'vuex-composition-helpers';

import useLoader from '@/js/Composables/useLoader.js';

import Dropdown from '@/js/Common/Dropdown.vue';
import SearchResources from '@/js/Components/SearchResources.vue';

import Notifications from './Notifications.vue';
import DefaultMailboxNotifications from './DefaultMailboxNotifications.vue';
import DropdownAddFollowup from './DropdownAddFollowup.vue';

export default {
  components: {
    Dropdown,
    SearchResources,
    Notifications,
    DropdownAddFollowup,
    DefaultMailboxNotifications,
  },

  setup() {
    const { defaultAccount } = useGetters({
      defaultAccount: 'mailbox/DEFAULT_ACCOUNT',
    });

    // Tools
    const router = useRouter();
    const { loader } = useLoader();

    // Logged in user
    const store = useStore();
    const { me } = store.state.global;

    // Go to client
    const goToClient = (match) => {
      const id = match.id.replaceAll('<em>', '').replaceAll('</em>', '');
      router.push({ path: `/clients/${id}` });
    };

    // Logout
    const csrf = ref(null);
    onMounted(() => {
      csrf.value = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    });

    // Toggle sidebar
    const toggleSidebar = () => {
      const sidebarLeftEl = document.querySelector('.sidebar-left');
      if (sidebarLeftEl) sidebarLeftEl.classList.toggle('sidebar-left--open');
    };

    const toggleSidebarRight = () => {
      const sidebarRightEl = document.querySelector('.sidebar-right');
      if (sidebarRightEl) sidebarRightEl.classList.toggle('sidebar-right--open');
    };

    // Create client
    const createClient = () => {
      loader.start();
      axios
        .post('/clients')
        .then((response) => {
          router.push({ path: `/clients/${response.data}/edit` });
        })
        .finally(() => {
          loader.end();
        });
    };

    const displayPolicyNumber = (match) => {
      const policyNumbers = match.policyNumbers.split('|');
      const highlightedPolicyNumber = policyNumbers.find((p) => p.includes('<em>'));
      if (highlightedPolicyNumber) return highlightedPolicyNumber;
      if (policyNumbers.length) return policyNumbers[0];
      return null;
    };

    const displayContactName = (match) => {
      const contactNames = match.contactNames.split('|');
      const highlightedContactName = contactNames.find((p) => p.includes('<em>'));
      if (highlightedContactName) return highlightedContactName;
      if (match.primaryContactName) return match.primaryContactName;
      if (contactNames.length) return contactNames[0];
      return null;
    };

    const displayPhoneNumber = (match) => {
      const phoneNumbers = match.phoneNumbers.split('|');
      const highlightedPhoneNumber = phoneNumbers.find((p) => p.includes('<em>'));
      if (highlightedPhoneNumber) return highlightedPhoneNumber;
      if (match.primaryPhoneNumber) return match.primaryPhoneNumber;
      if (phoneNumbers.length) return phoneNumbers[0];
      return null;
    };

    return {
      me,
      csrf,
      defaultAccount,
      toggleSidebar,
      toggleSidebarRight,
      goToClient,
      createClient,
      displayContactName,
      displayPhoneNumber,
      displayPolicyNumber,
    };
  },
};
</script>

<style lang="scss">
.topbar__btn-create-client,
.dropdown--add-followup__btn {
  border: rem(1px) solid #7692b2 !important;
}
</style>
<style lang="scss" scoped>
.topbar {
  height: rem($topbarHeightPX);
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  column-gap: rem(40px);
  padding: 0 rem(20px);
  background-color: color('primary');
  z-index: 2;

  &.sticky {
    position: sticky;
    top: 0;
  }
}

.topbar__right-menu {
  all: unset;
  @include font-size(18px);

  padding: rem(10px);
  margin-left: rem(10px);
  cursor: pointer;
}

// Left
.topbar__left {
  display: flex;
  align-items: center;

  .topbar__menu-icon {
    all: unset;
    @include font-size(18px);

    padding: rem(10px);
    margin-right: rem(10px);
    cursor: pointer;
    color: white;
  }

  .topbar__branding {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    text-decoration: none;
    padding: 0 rem(10px);

    &-title {
      @include font-size(20px);
    }

    &-desc {
      @include font-size(14px);
    }
  }
}

// Center
.topbar__center {
  // Search
  :deep(.search) {
    max-width: rem(961px);
    width: 100%;
    margin: 0 auto;

    .search__input {
      background-color: color('primary', 4);
      border: none;
      box-shadow: none;
      color: white;
      padding: rem(10px) rem(22px);

      &::placeholder {
        color: white;
      }
    }

    .search__matches {
      padding: rem(22px);
    }

    .search__match-details {
      flex-wrap: wrap;
    }

    .search__match-details > *:not(:last-child)::after {
      content: '';
      display: inline-block;
      height: rem(15px);
      width: rem(1px);
      background-color: color('gray', 3);
      margin: 0 rem(5px);
      transform: translateY(rem(3px));
    }
  }
}

// Right
.topbar__right {
  display: flex;
  align-items: center;

  .topbar__btn-create-client {
    margin-right: rem(30px);
  }

  .divider {
    height: rem($topbarHeightPX - 35px);
    width: rem(1px);
    background-color: #7692b2;
    margin: 0 rem(35px);
  }

  .dropdown.user {
    .dropdown-trigger .icon {
      color: white;
      font-size: rem(25px);
    }

    .dropdown-menu p {
      color: $text;
      font-size: rem(14px);
      margin: 0;
      padding: rem(7px) 0;
      text-align: center;
      border-bottom: rem(1px) solid color('gray', 3);
    }
  }

  &-menu i {
    color: white;
  }
}
</style>
