import axios, { AxiosError } from 'axios';
import { z } from 'zod';
import * as Sentry from '@sentry/vue';
import { UserSchema } from './User';
import { Client, ClientSchema } from './Client';
import { TicketSchema, Ticket } from './Ticket';
import { TicketUnsavedTextSchema } from './TicketUnsavedText';
import { Attachment } from './Storage';
import { TicketNoteAssigneeSchema } from './TicketNoteAssignee';

export const BaseTicketNoteSchema = z.object({
  id: z.number(),
  client_id: z.number().nullable(),
  ticket_id: z.number().nullable(),
  note_id: z.number().nullable(),
  quote_group_id: z.number().nullable(),
  created_by_id: z.number(),
  content: z.string(),
  attachment_paths: z.array(z.string()).nullable(),
  is_followup: z.boolean(),
  callback: z.boolean().nullable(),
  status: z.enum(['Open', 'Resolved']).nullable(),
  assigned_to_id: z.number().nullable(),
  priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]).nullable(),
  weight: z.number().nullable(),
  due: z.string().nullable(),
  purpose: z.string().nullable(),
  followup_rescheduled_on: z.string().nullable(),
  followup_assigned_on: z.string().nullable(),
  followup_created_at: z.string().nullable(),
  followup_created_by_id: z.number().nullable(),
  followup_resolved_on: z.string().nullable(),
  followup_resolved_by_id: z.number().nullable(),
  merged_note_id: z.number().nullable(),
  created_at: z.string(),
  updated_at: z.string(),

  // Appends
  priority_readable: z.enum(['Low', 'Medium', 'High', 'Urgent']).nullable(),
  permissions: z.object({
    edit: z.boolean(),
  }),

  // Relations
  created_by: UserSchema.nullish(),
  followup_created_by: UserSchema.nullish(),
  assigned_to: TicketNoteAssigneeSchema.nullish(),
  unsaved_text: TicketUnsavedTextSchema.nullish(),
  watchers: UserSchema.array().nullish(),

  // UI properties
  pulse: z.boolean().or(z.undefined()),
});

export type TicketNote = z.infer<typeof BaseTicketNoteSchema> & {
  client?: Client | null;
  note?: TicketNote | null;
  notes?: TicketNote[] | null;
  ticket?: Ticket | null;
};

export const TicketNoteSchema: z.ZodType<TicketNote> = BaseTicketNoteSchema.extend({
  client: z.lazy(() => ClientSchema.nullish()),
  note: z.lazy(() => TicketNoteSchema.nullish()),
  notes: z.lazy(() => TicketNoteSchema.array().nullish()),
  ticket: z.lazy(() => TicketSchema.nullish()),
});

// ***************************************************
// Find
// ***************************************************

type FindArgs = {
  id: number;
};

async function find({ id }: FindArgs) {
  const response = await axios.get(`/api/notes/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: TicketNoteSchema,
    })
    .parseAsync(response.data);
}

// ***************************************************
// Create
// ***************************************************

export type CreateArgs = {
  note_id?: number | null;
  ticket_id?: number | null;
  content: string;
  is_followup: boolean;
  due?: string;
  priority?: 1 | 2 | 3 | 4;
  watcher_ids?: number[];
  assignee_id?: number;
  assignee_type?: 'User' | 'Quote team';
  attachments?: Attachment[];
};

async function create(args: CreateArgs) {
  const response = await axios.post(`/api/notes`, args);
  return z.object({ data: TicketNoteSchema }).parseAsync(response.data);
}

// ***************************************************
// Update
// ***************************************************

type UpdateArgs = {
  id: number;
  body: {
    is_followup?: boolean;
    content?: string;
    status?: 'Open' | 'Resolved';
    due?: string;
    priority?: 1 | 2 | 3 | 4;
    watcher_ids?: number[];
    assignee_id?: number;
    assignee_type?: 'User' | 'Quote team';
    attachments: Attachment;
  };
};

async function update({ id, body }: UpdateArgs) {
  const response = await axios.put(`/api/notes/${id}`, body).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: TicketNoteSchema,
    })
    .parseAsync(response.data);
}

// ***************************************************
// Autosave
// ***************************************************

type AutosaveArgs = {
  id: number;
  name: string;
  value: any;
};

async function autosave({ id, name, value }: AutosaveArgs) {
  const response = await axios.put(`/api/notes/${id}/autosave`, { name, value }).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: TicketNoteSchema,
    })
    .parseAsync(response.data);
}

// ***************************************************
// Soft delete
// ***************************************************

async function softDelete({ id }: { id: number }) {
  const response = await axios.delete(`/api/notes/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.literal('').parseAsync(response.data);
}

// ***************************************************
// Transfer
// ***************************************************

type TransferArgs = {
  id: number;
  toTicket: number | 'New sales ticket' | 'New service ticket';
};

async function transfer({ id, toTicket }: TransferArgs) {
  const response = await axios.post(`/api/notes/${id}/transfer`, { to_ticket: toTicket }).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: z.object({
        note: TicketNoteSchema,
        toTicket: TicketSchema,
      }),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Watch
// ***************************************************

type WatchArgs = {
  id: number;
  watcherIds: number[];
};

async function watch({ id, watcherIds }: WatchArgs) {
  const response = await axios
    .post(`/api/notes/${id}/watch`, { watcher_ids: watcherIds })
    .catch((error: AxiosError) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: UserSchema.array(),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Get history
// ***************************************************

type GetHistoryArgs = {
  id: number;
};

async function getHistory({ id }: GetHistoryArgs) {
  const response = await axios.get(`/api/notes/${id}/history`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: z.any(), // FIXME
    })
    .parseAsync(response.data);
}

// ***************************************************
// Notify
// ***************************************************

type NotifyArgs = {
  id: number;
};

async function notify({ id }: NotifyArgs) {
  const response = await axios.post(`/api/notes/${id}/notify`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.literal('').parseAsync(response.data);
}

// ***************************************************
// Clear
// ***************************************************

type ClearArgs = {
  id: number;
  clearType: 'close' | 'reschedule';
};

async function clear({ id, clearType }: ClearArgs) {
  const response = await axios.post(`/api/notes/${id}/clear`, { clear_type: clearType }).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.literal('').parseAsync(response.data);
}

export default {
  find,
  create,
  update,
  autosave,
  softDelete,
  transfer,
  watch,
  getHistory,
  notify,
  clear,
};
