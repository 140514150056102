import { z } from 'zod';
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';
import { ClientSchema } from './Client';
import { queryBuilderToQueryString } from '@/js/Helpers';

const CertificateDOOAcordSchema = z.object({
  agency_customer_id: z.string().optional(),
  loc: z.string().optional(),
  page_num: z.string().optional(),
  total_pages_num: z.string().optional(),
  policy_number: z.string().optional(),
  carrier: z.string().optional(),
  naic: z.string().optional(),
  effective_date: z.string().optional(),
  form_number: z.string().optional(),
  form_title: z.string().optional(),
  additional_remarks: z.string().optional(),
});

export const CertificateDOOSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  name: z.string(),
  value: z.string(),
  starred: z.boolean(),
  created_at: z.string(),
  updated_at: z.string(),
  acord: CertificateDOOAcordSchema.nullable(),

  // Relations
  client: ClientSchema.nullish(),
});

export type CertificateDOO = z.infer<typeof CertificateDOOSchema>;
export type CertificateDOOAcord = z.infer<typeof CertificateDOOAcordSchema>;

// ***************************************************
// Index
// ***************************************************

type IndexArgs = {
  queryBuilder: {
    page: number;
    filters?: {
      search?: string;
      client_id?: number | null;
    };
  };
};

async function index({ queryBuilder }: IndexArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/certificates/doos?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return response;
}

// ***************************************************
// Create
// ***************************************************

type CreateArgs = {
  client_id?: number | null;
  name: CertificateDOO['name'];
  value: CertificateDOO['value'];
  acord?: CertificateDOOAcord;
};

async function create(args: CreateArgs) {
  const response = await axios.post('/api/certificates/doos', args).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: CertificateDOOSchema }).parseAsync(response.data);
}

// ***************************************************
// Clone
// ***************************************************

async function clone({ id }: { id: number }) {
  const response = await axios.post(`/api/certificates/doos/${id}/clone`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: CertificateDOOSchema }).parseAsync(response.data);
}

// ***************************************************
// Autosave
// ***************************************************

async function autosave({ id, name, value }: { id: number; name: string; value: any }) {
  const response = await axios
    .post(`/api/certificates/doos/${id}/autosave`, { _method: 'PUT', name, value })
    .catch((error: AxiosError) => {
      Sentry.captureException(error);
      throw error;
    });

  return z.object({ data: CertificateDOOSchema }).parseAsync(response.data);
}

// ***************************************************
// Update
// ***************************************************

type UpdateArgs = {
  id: number;
  form: {
    name: CertificateDOO['name'];
    value: CertificateDOO['value'];
  };
};

async function update({ id, form }: UpdateArgs) {
  const response = await axios
    .post(`/api/certificates/doos/${id}`, { _method: 'PUT', ...form })
    .catch((error: AxiosError) => {
      Sentry.captureException(error);
      throw error;
    });

  return z.object({ data: CertificateDOOSchema }).parseAsync(response.data);
}

// ***************************************************
// Destroy
// ***************************************************

async function destroy({ id }: { id: number }) {
  const response = await axios
    .post(`/api/certificates/doos/${id}`, { _method: 'DELETE' })
    .catch((error: AxiosError) => {
      Sentry.captureException(error);
      throw error;
    });

  return z.literal('').parseAsync(response.data);
}

// ***************************************************
// Star
// ***************************************************

async function star({ id }: { id: number }) {
  const response = await axios.post(`/api/certificates/doos/${id}/star`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.literal('').parseAsync(response.data);
}

export default {
  index,
  create,
  clone,
  update,
  autosave,
  destroy,
  star,
};
