<template>
  <Dropdown
    ref="DropdownRef"
    strategy="fixed"
    class="input-select"
    :class="{
      'input-select--multivalue': multivalue,
    }"
    placement="bottom-start"
    :disabled="disabled"
    :close-on-inside-click="false"
    @shown="onDropdownShown"
    @hidden="onDropdownHidden"
  >
    <!-- Drodpown trigger -->
    <template #trigger>
      <slot name="trigger">
        <FormGroupDisplay :disabled="disabled">
          <template #label>
            <slot name="label-container">
              <div class="form-group__label-container">
                <div class="form-group__label">
                  <slot name="label">
                    {{ labelComputed }}
                  </slot>
                  <CopyBtn v-if="copyBtn" @click.stop="copyValue" />
                </div>
                <div class="form-group__controls">
                  <slot name="controls" />
                </div>
              </div>
            </slot>
          </template>
          <slot name="selected-option" :item="modelValue">
            <template v-if="multivalue && modelValue">
              <span
                v-for="(selectedOption, i) in optionModelValue"
                :key="i"
                class="input-select__selected-option"
                @click.stop
              >
                {{ selectedOption[labelMethod] }}
                <SvgIcon name="close" @click="onSelect(selectedOption, false)" />
              </span>
            </template>
            <template v-else-if="optionModelValue">
              {{ optionModelValue[labelMethod] }}
            </template>
          </slot>
        </FormGroupDisplay>
      </slot>
    </template>

    <!-- Dropdown body -->
    <template #menu>
      <!-- Search -->
      <div class="input-select__search">
        <SvgIcon name="search" />
        <input
          ref="searchInputRef"
          v-model="searchQuery"
          class="input-select__search-input"
          type="text"
          :format-value="(_, val) => (val === null ? undefined : val)"
          @keydown.stop="onSearchKeydown"
        />
      </div>

      <!-- Filters -->
      <template v-if="filterCheckboxes?.length">
        <InputCheckbox
          v-for="(checkbox, i) in filterCheckboxes"
          :key="i"
          v-model="filters[checkbox.name]"
          :label="checkbox.label"
          class="input-select__filter"
          @update:model-value="
            (value) => (value === null ? (filters[checkbox.name] = undefined) : (filters[checkbox.name] = value))
          "
        />
      </template>

      <!-- Options -->
      <div class="input-select__options">
        <slot name="prefix"></slot>
        <InputCheckbox
          v-for="(option, i) in optionsComputed"
          :key="checkboxRerenderKey(option)"
          :model-value="isChecked(option)"
          class="input-select__option"
          :class="currentFocus === i ? 'input-select__option--focused' : ''"
          :validator="(e) => beforeUpdate(option, e.target.checked)"
          @update:model-value="(checked) => onSelect(option, checked)"
        >
          <template #label>
            <slot name="item" :item="option">
              {{ option[labelMethod] }}
              <span class="input-select__option-description">
                {{ option.description }}
              </span>
            </slot>
          </template>
        </InputCheckbox>
      </div>
    </template>
  </Dropdown>
</template>

<script setup>
import set from 'lodash.set';
import { FormContextKey } from 'vee-validate';
import { ref, computed, useAttrs, inject, toRaw } from 'vue';
import Clipboard from '@/js/Helpers/Clipboard';
import Dropdown from '@/js/Common/Dropdown.vue';
import CopyBtn from '@/js/Components/CopyBtn.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import InputCheckbox from '@/js/Components/Form/Checkbox.vue';
import FormGroupDisplay from '@/js/Common/Form/FormGroupDisplay.vue';

const props = defineProps({
  modelValue: {
    type: null,
    default: null,
  },
  options: {
    type: Array,
    required: true,
  },
  multivalue: {
    type: Boolean,
    default: false,
  },
  labelMethod: {
    type: String,
    default: 'label',
  },
  label: {
    type: String,
    default: null,
  },
  copyBtn: {
    type: Boolean,
    default: true,
  },
  filterCheckboxes: {
    type: Array,
    default: null,
  },
  disabled: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['update:model-value', 'selected']);

const attrs = useAttrs();

// State
const searchQuery = ref();
const filters = ref({});
const currentFocus = ref(-1);
const DropdownRef = ref(null);
const searchInputRef = ref(null);
const optionRerenderKey = ref(1);

const optionModelValue = computed(() => {
  if (Array.isArray(props.modelValue))
    return props.modelValue.map((value) => props.options.find((option) => option.value === value));
  return props.options.find((option) => option.value === props.modelValue);
});

const optionsComputed = computed(() => {
  if (!searchQuery.value) return props.options;
  const queryLowerCase = searchQuery.value.toLowerCase();
  return props.options.filter((option) => {
    if (!option.value) return false;
    return option.value.toLowerCase().search(queryLowerCase) !== -1;
  });
});

const onDropdownShown = () => {
  searchInputRef.value.focus();
};

const onDropdownHidden = () => {
  currentFocus.value = -1;
};

const removeOptionAndReturnOptions = (optionValue) => {
  const newModelValue = [...props.modelValue];
  const index = newModelValue.findIndex((value) => value === optionValue);
  newModelValue.splice(index, 1);
  return newModelValue;
};

// Validation
const FormCtx = inject(FormContextKey, null);
const getNewModelValue = (option, checked) => {
  let newModelValue = null;
  if (!props.multivalue && checked) newModelValue = option.value;
  else if (!props.multivalue && !checked) newModelValue = null;
  else if (props.multivalue && checked) newModelValue = props.modelValue.concat(option.value);
  else if (props.multivalue && !checked) newModelValue = removeOptionAndReturnOptions(option.value);
  return newModelValue;
};
const beforeUpdate = async (option, checked) => {
  if (FormCtx === null) return true;
  const newModelValue = getNewModelValue(option, checked);
  const formValues = structuredClone(toRaw(FormCtx.values));
  set(formValues, attrs.name, newModelValue);
  const res = await FormCtx.schema.parse(formValues);
  const isInvalid = res.errors.find((e) => e.path === attrs.name && e.errors.find((t) => t === 'Required'));
  return !isInvalid;
};

// Update
const onSelect = (option, checked) => {
  const newModelValue = getNewModelValue(option, checked);
  emit('update:model-value', newModelValue);
  emit('selected', { option, checked });
};

const onSearchKeydown = (e) => {
  if (![40, 38, 13].includes(e.keyCode)) return;

  e.preventDefault();

  if (e.keyCode === 40) {
    currentFocus.value += 1;
  } else if (e.keyCode === 38) {
    currentFocus.value -= 1;
  } else if (e.keyCode === 13) {
    if (currentFocus.value < 0 || currentFocus.value > optionsComputed.value - 1) return;
    const optionEls = Array.from(
      DropdownRef.value.$el.querySelectorAll(
        ':scope > .dropdown__menu > .input-select__options > .input-select__option',
      ),
    );
    optionEls[currentFocus.value].click();
    searchInputRef.value.focus();
    return;
  }

  if (currentFocus.value <= -1) currentFocus.value = 0;
  else if (currentFocus.value >= optionsComputed.value.length) currentFocus.value = optionsComputed.value.length - 1;
};

const multivalueIdsMap = computed(() => {
  if (!props.modelValue) return null;
  return props.modelValue.reduce((acc, curr) => {
    acc[curr.value] = true;
    return acc;
  }, {});
});

const isChecked = (option) => {
  if (!props.modelValue) return false;
  if (!props.multivalue) return option.value === props.modelValue;
  return !!multivalueIdsMap.value[option.value];
};

const labelComputed = computed(() => {
  if (!props.label) return null;
  if (props.multivalue && props.modelValue) return `${props.label} (${props.modelValue.length})`;
  return props.label;
});

const checkboxRerenderKey = (option) => {
  if (props.multivalue) return option.value;
  if (!props.modelValue) return option.value;
  return `${optionRerenderKey.value}-${option.value}-${props.modelValue}`;
};
const rerenderCheckboxes = () => (optionRerenderKey.value += 1);

const copyValue = () => {
  if (!props.modelValue) return null;
  if (props.multivalue) Clipboard.copy(props.modelValue.map((v) => v[props.labelMethod]).join(', '));
  else Clipboard.copy(props.modelValue[props.labelMethod]);
};

defineExpose({
  rerenderCheckboxes,
});
</script>

<style lang="scss">
$option-padding-x: 0.8em;
$search-icon-width: 1.25em;

.input-select {
  // Trigger
  .dropdown__trigger {
    width: 100%;
    .form-group-display {
      width: 100%;
      min-height: rem(48px);
      margin-bottom: 0;
      .form-group__label-container,
      .form-group__label,
      .form-group__controls {
        display: flex;
        align-items: center;
      }
      .form-group__label-container {
        @include form-label;
        padding-top: rem(4px);
        justify-content: space-between;
      }
    }
  }

  // Search
  &__search {
    position: relative;

    .svg-icon {
      top: 0.25em;
      left: $option-padding-x;
      color: #999;
      width: $search-icon-width;
      position: absolute;
    }

    &-input {
      width: 100%;
      font-family: inherit;
      font-size: 1.1em;
      padding: 0.3em 0.3em 0.3em #{$option-padding-x + $search-icon-width + 0.25em};
      border-radius: rem(3px);
      border: rem(1px) solid color('gray', 3);
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

      &:focus {
        outline: 0;
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
      }
    }
  }

  // Filters
  &__filter {
    justify-content: center;
    padding: 1em $option-padding-x;
    border-bottom: rem(1px) solid #ccc;
  }

  .dropdown__menu {
    //width: 100%;
    max-height: unset;
  }

  &__options {
    max-height: 50vh;
    overflow: auto;
  }

  &__option {
    display: flex;
    padding: 0.5em $option-padding-x;
    transition: 0.1s background-color;

    &:not(:last-child) {
      border-bottom: rem(1px) solid #ccc;
    }

    &:hover,
    &--focused {
      background-color: #e3e3e3;
    }

    .checkbox__label {
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-description {
      display: block;
      color: color('gray', 5);
      line-height: 1;
    }
  }

  &__infinite-scroll-trigger {
    display: flex;
    justify-content: center;
    padding: 0.5em 1em;
  }

  // Multivalue
  &--multivalue {
    // Trigger
    .dropdown__trigger {
      .form-group-display__content {
        padding-top: rem(7.5px);
        padding-bottom: rem(5px);
      }

      .form-group-display__content-value {
        overflow: unset;
        white-space: unset;
        text-overflow: unset;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .input-select__selected-option {
        display: flex;
        align-items: center;
        font-size: rem(16px);
        padding: 0 rem(7.5px);
        background-color: color('gray', 2);
        cursor: default;

        .svg-icon {
          cursor: pointer;
          width: rem(15px);
          margin-left: rem(10px);
        }

        &:not(:first-child) {
          margin-top: rem(7px);
        }
      }
    }
  }
}
</style>
