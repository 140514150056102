<template>
  <template v-if="history">
    <div v-for="row in history" :key="row.id" :class="`note-history-row note-history-row--${row.type}`">
      <!-- Icon -->
      <div class="note-history-row__icon-container">
        <SvgIcon v-if="row.type === 'created'" name="plus-circle" />
        <SvgIcon v-else-if="row.type === 'followup'" name="sticky-note" />
        <SvgIcon v-else-if="row.type === 'due'" name="calendar" color="#999" />
        <SvgIcon v-else-if="row.type === 'callback'" name="callback" />
        <SvgIcon v-else-if="row.type === 'assigned_to_id'" name="user" />
        <SvgIcon v-else-if="row.type === 'status-open'" name="clock-arrow" />
        <SvgIcon v-else-if="row.type === 'status-resolved'" name="checkmark-circle" />
        <SvgIcon v-else-if="row.type === 'priority'" name="fire" />
        <SvgIcon v-else-if="row.type === 'merged'" name="merge" />
        <UserCircle v-else-if="row.type === 'subnote'" :initials="row.causer.initials" />
      </div>

      <!-- Body -->
      <div class="note-history-row__body">
        <!-- Text -->
        <div class="note-history-row__text">
          <template v-if="row.type === 'created' && row.properties.attributes.is_followup">
            Followup created by {{ row.causer.name }}
          </template>
          <template v-if="row.type === 'created' && !row.properties.attributes.is_followup">
            Note created by {{ row.causer.name }}
          </template>
          <template v-if="row.type === 'merged'"> {{ note.is_followup ? 'Followup' : 'Note' }} merged </template>
          <template v-if="row.type === 'subnote'">
            {{ row.properties.attributes.content }}
          </template>
          <template v-if="row.type === 'followup'"> Note is converted to followup by {{ row.causer.name }} </template>
          <template v-if="row.type === 'due'">
            Followup due date is changed from
            {{ new Date(row.properties.old.due).toDateSlash() }}
            to
            {{ new Date(row.properties.attributes.due).toDateSlash() }}
            by
            {{ row.causer.name }}
          </template>
          <template v-if="row.type === 'callback'">
            Callback is {{ row.properties.attributes.callback ? 'added' : 'removed' }} by {{ row.causer.name }}
          </template>
          <template v-if="row.type === 'assigned_to_id'">
            Followup assigned to {{ row.properties.attributes['assigned_to.name'] }} by {{ row.causer.name }}
          </template>
          <template v-if="row.type === 'status-open'"> Followup reopened by {{ row.causer.name }} </template>
          <template v-if="row.type === 'status-resolved'"> Followup resolved by {{ row.causer.name }} </template>
          <template v-if="row.type === 'priority'">
            Followup priority changed from
            <SvgIcon
              :name="`followup-${row.properties.old.priority_readable.toLowerCase()}`"
              class="svg-icon--priority"
            />
            to
            <SvgIcon
              :name="`followup-${row.properties.attributes.priority_readable.toLowerCase()}`"
              class="svg-icon--priority"
            />
            by {{ row.causer.name }}
          </template>
        </div>

        <!-- Timestamp -->
        <span class="note-history-row__timestamp">
          <template v-if="row.type === 'subnote'"> Subnote added on </template>
          {{ new Date(row.created_at).toDateTime() }}
        </span>
      </div>
    </div>
  </template>
</template>

<script setup>
import { ref } from 'vue';
import TicketNoteApi from '@/js/Api/TicketNote';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import UserCircle from '@/js/Components/UserCircle.vue';

const props = defineProps({
  note: {
    type: Object,
    required: true,
  },
});

const history = ref(null);

// Fetch
TicketNoteApi.getHistory({ id: props.note.id }).then(({ data }) => {
  history.value = data;
});
</script>

<style lang="scss">
.note-history-row {
  display: flex;

  &:nth-last-child(2) {
    margin-bottom: rem(20px);
  }

  &__body {
    display: flex;
    margin-left: rem(8px);
  }

  // Icon
  &__icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .svg-icon {
      display: block;
      color: color('gray', 5);
    }

    &::after {
      content: '';
      display: block;
      width: rem(1px);
      height: rem(12px);
      margin: rem(2px) 0;
      background-color: color('gray', 3);
    }
  }

  &:nth-last-child(2) &__icon-container::after {
    display: none;
  }

  // Text
  &__text {
    font-size: rem(14px);
    color: color('gray', 10);
  }

  // Timestamp
  &__timestamp {
    font-size: rem(12px);
    color: color('gray', 5);
    margin-left: rem(5px);
    padding-top: rem(3px);
  }

  // Priority row
  .svg-icon--priority {
    width: rem(15px);
    height: rem(15px);
    margin: 0 rem(3px);
    transform: translateY(#{rem(3px)});
  }

  &--priority &__timestamp {
    padding-top: rem(2px);
  }

  // Subnote row
  &--subnote .user-circle {
    margin-top: rem(3px);
    margin-left: rem(-2px);
  }

  &--subnote &__body {
    display: block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    line-height: 0.9;
  }

  &--subnote &__text {
    font-size: rem(15px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--subnote &__timestamp {
    font-size: rem(12px);
    margin-left: 0;
  }
}
</style>
