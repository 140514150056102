<template>
  <div :class="getClass('groupClass')">
    <label v-if="labelText" class="bold">{{ getLabelText }}</label>

    <div
      :class="{
        'form-checks': true,
        'vertical': orientation === 'vertical',
        'horizontal': orientation === 'horizontal',
      }">
        <div class="form-check" v-for="(checkbox, i) in checkboxes" :key="i">

          <label v-if="multiple === ''" class="form-check-label switch">
            <input
              :ref="`checkbox-${checkbox.inputValue}`"
              type="checkbox"
              :name="inputName"
              :id="checkbox.inputID"
              class="form-check-input"
              :value="checkbox.inputValue"
              v-model="valueMultiple"
              :checked="valueMultiple.includes(checkbox.inputValue)"
              :disabled="isDisabled"
              @change="onChange($event, i)">
                {{ checkbox.labelText }}
            <span class="slider round"></span>
          </label>

          <label v-else class="form-check-label switch">
            <input
              :ref="`checkbox-${checkbox.inputValue}`"
              type="checkbox"
              :name="inputName"
              :id="checkbox.inputID"
              class="form-check-input"
              :value="checkbox.inputValue"
              :checked="modelValue == checkbox.inputValue"
              :disabled="isDisabled"
              @change="onChange($event, i)">
                {{ checkbox.labelText ? checkbox.labelText : getLabelText }}
            <span class="slider round"></span>
          </label>

        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checkboxes: {
      type: Array,
      required: true
    },
    inputName: String,
    groupClass: [Object, String, Array],
    labelText: String,
    orientation: {
      type: String,
      default: 'horizontal'
    },
    modelValue: null,
    autosaveURL: String,
    autosave: {
      type: Boolean,
      default: true
    },
    beforeUpdate: [Function, Array],
    multiple: String,

    disabled: [Boolean, String, Number],
  },

  emits: ['update:modelValue', 'change'],

  data() {
    return {
      valueMultiple: []
    }
  },

  computed: {
    isDisabled() {
      return (typeof this.disabled === 'string' ? true : this.disabled)
    },
    getLabelText() {
      if (this.labelText)
        return this.labelText
      else if (this.inputName)
        return this.inputName.split('_').join(' ').capitalize()
    }
  },

  methods: {
    async onChange(e, i) {
      let
        checked = e.target.checked,
        checkbox = this.checkboxes[i]

      if (this.beforeUpdate)
        if (typeof this.beforeUpdate === 'function') {
          let result = await this.beforeUpdate(e)
          if (result === false)
            return e.target.checked = !checked
        } else {
          for (let i = 0; i < this.beforeUpdate.length; i++) {
            let result = await this.beforeUpdate[i](e)
            if (result === false)
              return e.target.checked = !checked
          }
        }

      let value, multiple = this.multiple === ''
      if (multiple)
        value = this.valueMultiple
      else if (e.target.checked)
        value = checkbox.inputValue
      else
        value = checkbox.hasOwnProperty('uncheckedValue')?
          checkbox.uncheckedValue : null

      this.$emit('update:modelValue', value)
      this.$emit('change', e)

      let autosaveURL
      if (e.target.form)
        autosaveURL = e.target.form.getAttribute('autosaveurl')
      if (this.autosaveURL)
        autosaveURL = this.autosaveURL

      if (autosaveURL && this.autosave && !multiple)
        axios.post(autosaveURL, {
          _method: 'PUT',
          value: value,
          name: this.inputName,
          [this.inputName]: value
        })
    },
    getClass(targetProp) {
      let classString = '', defaultClass = this.getDefaultClass(targetProp)
      if (typeof this[targetProp] === 'object')
        for (let property in this[targetProp])
          classString += this[targetProp][property] ? (classString === '' ? '' : ' ') + property : ''
      else if (typeof this[targetProp] === 'string')
        classString = this[targetProp]
      else if (Array.isArray(this[targetProp]))
        classString = this[targetProp].join(' ')

      if (defaultClass)
        classString += !classString.includes(defaultClass) ? ' ' + defaultClass : ''

      if (targetProp === 'inputClass' && !this.isValid)
        classString += ' is-invalid'

      return classString
    },
    getDefaultClass(targetProp) {
      if (targetProp === 'groupClass')
        return 'form-check-container' + (this.checkboxes.length === 1 ? ' flex-align-center' : '')
    }
  }
}
</script>
<style lang="scss" scoped>
  .switch {
    position: relative;
    display: inline-block;
    width: rem(30px);
    height: rem(15px);
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: rem(9px);
    width: rem(9px);
    left: rem(3px);
    bottom: rem(3px);
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #1A497F;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: rem(34px);
  }

  .slider.round:before {
    border-radius: 50%;
  }
</style>
