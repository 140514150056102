const MS_PER_DAY = 1000 * 60 * 60 * 24;

function diffDays(a, b) {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

export function getDashboardDateType(dateStr) {
  const today = new Date();
  const date = new Date(dateStr);
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
    return 'today';
  if (today < date) return 'future';
  return 'past';
}

export function getDashboardDue(dateStr) {
  if (!dateStr) return null;
  const dateType = getDashboardDateType(dateStr);
  if (dateType === 'today') return 'Today';
  if (dateType === 'future') return new Date(dateStr).toDateSlashShort();
  const diff = diffDays(new Date(dateStr), new Date());
  return `- ${diff} Day${diff > 1 ? 's' : ''}`;
}

export default {
  getDashboardDateType,
  getDashboardDue,
};
