<template>
  <div v-if="metrics" class="goals">

    <div class="goal">
      <div class="goal__current">
        <div class="goal__text goal__text--current">Followups assigned<br>to user</div>
        <div class="goal__number goal__number--current">
          {{ metrics.followupsAssignedCount }}
          <img class="goal__icon" src="/img/icons/goal-reached.svg"/>
        </div>
      </div>
      <div class="goal__target">
        <div class="goal__text goal__text--target">Target</div>
        <div class="goal__number goal__number--target">#</div>
      </div>
    </div>

    <div class="goal">
      <div class="goal__current">
        <div class="goal__text goal__text--current">Followups resolved<br>by user</div>
        <div class="goal__number goal__number--current">
          {{ metrics.followupsResolvedCount }}
          <img class="goal__icon" src="/img/icons/goal-in-progress.svg"/>
        </div>
      </div>
      <div class="goal__target">
        <div class="goal__text goal__text--target">Target</div>
        <div class="goal__number goal__number--target">#</div>
      </div>
    </div>

    <div class="goal">
      <div class="goal__current">
        <div class="goal__text goal__text--current">Clients handled<br>today</div>
        <div class="goal__number goal__number--current">
          {{ metrics.clientsCreatedCount }}
          <img class="goal__icon" src="/img/icons/goal-not-reached.svg"/>
        </div>
      </div>
      <div class="goal__target">
        <div class="goal__text goal__text--target">Target</div>
        <div class="goal__number goal__number--target">#</div>
      </div>
    </div>

  </div>
</template>

<script>
import useSWR from '@/js/Composables/useSWR.js';

export default {
  setup() {
    const { metrics } = useSWR('dashboard/fetchMetrics', 'dashboard/metrics');

    return {
      metrics
    };
  }
};
</script>

<style lang="scss" scoped>
.goals {
  display: flex;
}
.goal {
  display: flex;
  padding: 0 rem(40px);

  // Separator
  &:not(:last-of-type) {
    border-right: rem(1px) solid #CCCCCC;
  }

  // Containers
  &__current, &__target {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  &__current {
    margin-right: rem(11px);
  }
  &__target {
    color: #CCCCCC;
  }

  // Number
  &__number {
    width: rem(51px);
    height: rem(51px);
    font-size: rem(30px);
    line-height: rem(17px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &--current {
      color: #BA9D4E;
      background-color: white;
      box-shadow: 0px rem(3px) rem(12px) #00000014;
    }

    &--target {
      border: rem(1px) solid #CCCCCC;
    }

  }

  // Icon
  &__icon {
    position: absolute;
    top: 0;
    right: 0;
  }

  // Text
  &__text {
    font-size: rem(11px);
    line-height: rem(13px);
    text-align: center;
    margin-bottom: rem(3px);

    &--current {
      color: #BEA35A;
    }

  }

}
</style>
