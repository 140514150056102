<template>
  <!-- Warnings -->
  <ProducerWarning v-if="producer" :producer-id="producer.id" :producer-type="producer.model_type" />

  <div class="client-layout__main">
    <!-- Main panel -->
    <MainPanel ref="mainPanel" :client="client" :client-id="clientId" />

    <!-- Policies panel -->
    <PoliciesPanel :client-id="clientId" />

    <!-- Quotes panel -->
    <QuotesPanel :client-id="clientId" />
  </div>
</template>

<script setup lang="ts">
import { Client } from '@/js/Api/Client';
import useClientStore from '@/js/store/pinia/useClientStore';
import ProducerWarning from '@/js/Components/Producers/Warning.vue';
import MainPanel from './MainPanel/MainPanel.vue';
import PoliciesPanel from './PoliciesPanel/PoliciesPanel.vue';
import QuotesPanel from './QuotesPanel/QuotesPanel.vue';

interface Props {
  client?: Client;
  clientId: Client['id'];
}

const props = defineProps<Props>();

const { ref: producer } = useClientStore().swrProducer({ id: props.clientId });
</script>

<style lang="scss" scoped>
.client-layout__main {
  flex: 1;
  display: grid;
  grid-row-gap: rem(22px);
  grid-column-gap: rem(30px);
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'mainpanel policiespanel'
    'mainpanel quotespanel';
}
</style>
