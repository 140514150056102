<template>
  <FormGroup
    v-model="relatedClients"
    type="ajax-select"
    :multivalue="true"
    label-method="name"
    label="Related clients"
    :disabled="disabled"
    :on-select="onSelect"
    :fetch-options="fetchOptions"
  />
</template>

<script setup>
import api from '@/js/Api/Client';
import FormGroup from '@/js/Components/Form/FormGroup.vue';
import useClientStore from '@/js/store/pinia/useClientStore';

const props = defineProps({
  clientId: {
    type: Number,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: true,
  },
});

const clientStore = useClientStore();
const { ref: relatedClients } = clientStore.swrRelatedClients({ id: props.clientId });

const fetchOptions = (query, page) => {
  return api.index({
    queryBuilder: {
      page,
      filters: {
        search: query,
      },
    },
  });
};

const onSelect = async (option, checked) => {
  const apiMethod = checked ? api.relatedClients.add : api.relatedClients.remove;
  return apiMethod({ id: props.clientId, clientId: option.id });
};
</script>
