<template>
  <div class="renewal-status">
    <!-- Trigger -->
    <div class="renewal-status__trigger" :class="{ 'renewal-status__trigger--missing-input': isInputMissing }">
      <a @click="ModalRef.show()">{{ lineStatus }}</a>
      <span v-for="(line, i) in linesChildren" :key="i" class="renewal-status__status">
        {{ line }}
      </span>
      <span v-if="lineParent" class="renewal-status__status">
        {{ lineParent }}
      </span>
    </div>

    <!-- Popup -->
    <Modal ref="ModalRef" :is-loading="loading">
      <template #title>Renewal status</template>
      <template #footer-btn-text>Close</template>

      <!-- Renewal status checkboxes -->
      <div class="renewal-status__checkboxes">
        <FormGroupCheckbox
          v-model="localState.status"
          :checkboxes="checkboxes"
          orientation="horizontal"
          @update:model-value="onCheckboxCheck"
        />
      </div>

      <Divider v-if="isListDisplayed" />

      <p v-if="isListDisplayed">Please select a policy:</p>

      <!-- Policy list -->
      <div v-if="isListDisplayed" class="renewal-status__policy-list">
        <div
          v-for="selectablePolicy in selectablePolicies"
          :key="selectablePolicy.id"
          class="renewal-status__policy-list-item"
        >
          <InputCheckbox v-model="localState.selectedIdsMap[selectablePolicy.id]" />
          <PolicyListItem :policy="selectablePolicy" @click="onPolicyCheck(selectablePolicy.id)" />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import useClientStore from '@/js/store/pinia/useClientStore';
import { toast, toastError } from '@/js/Helpers/Alert';
import PolicyApi, { Policy } from '@/js/Api/Policy';
import { PARENT_RENEWAL_STATUS, PARENT_RENEWAL_STATUSES } from '@/js/Presenters/Policy';
import Modal from '@/js/Common/Modal.vue';
import Divider from '@/js/Components/Divider.vue';
import InputCheckbox from '@/js/Common/Form/Input/Checkbox.vue';
import FormGroupCheckbox from '@/js/Common/Form/FormGroup/Checkbox.vue';
import PolicyListItem from '@/js/Components/Policies/ListItem.vue';

interface Props {
  policy: Policy;
}

interface Emits {
  (e: 'update:renewal-status-as-parent', status: PARENT_RENEWAL_STATUS | null): void;
  (e: 'update:renews-policies', policies: Policy[]): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const ModalRef = ref<InstanceType<typeof Modal> | null>(null);
const loading = ref<boolean>(false);

// Local state
const localState = ref<{ status: PARENT_RENEWAL_STATUS | null; selectedIdsMap: Record<number, boolean> }>({
  status: props.policy.renewal_status_as_parent,
  selectedIdsMap: props.policy.renews_policies.reduce((carry, p) => {
    carry[p.id] = true;
    return carry;
  }, {}),
});

// Fetch policies
const { ref: allPolicies } = useClientStore().swrPolicies({ id: props.policy.client_id });

// Display policies which are not this policy and that don't renew/rewrite this policy
const selectablePolicies = computed(() => {
  if (!allPolicies.value) return null;
  return allPolicies.value.filter((p) => p.id !== props.policy.id);
});

// Update
const update = async () => {
  loading.value = true;
  const ids = Object.keys(localState.value.selectedIdsMap)
    .filter((id) => localState.value.selectedIdsMap[id])
    .map((id) => Number(id));
  PolicyApi.updateRenewalStatus({
    id: props.policy.id,
    clientId: props.policy.client_id,
    parentRenewalStatus: localState.value.status,
    renewingPolicyIds: ids,
  })
    .then(({ data: policy }) => {
      emit('update:renewal-status-as-parent', policy.renewal_status_as_parent);
      emit('update:renews-policies', policy.renews_policies);
    })
    .catch((error) => {
      if (error.response.status === 400) toast({ icon: 'warning', title: error.response.data.message });
      else toastError();
    })
    .finally(() => {
      loading.value = false;
    });
};

const isInputMissing = computed(() => {
  if (props.policy.renewal_status_as_parent === null) return true;
  if (['Renewal (paid)', 'Renewal (unpaid)', 'Rewrite'].includes(props.policy.renewal_status_as_parent))
    return props.policy.renews_policies.length < 0;
  return false;
});

// Checkboxes
const checkboxes = PARENT_RENEWAL_STATUSES.map((status) => ({
  label: status,
  checkedValue: status,
}));

// Policy list
const isListDisplayed = computed(() => {
  if (localState.value.status === 'Rewrite') return true;
  if (localState.value.status === 'Renewal (paid)') return true;
  if (localState.value.status === 'Renewal (unpaid)') return true;
  return false;
});

const onPolicyCheck = (policyId: number) => {
  localState.value.selectedIdsMap[policyId] = !localState.value.selectedIdsMap[policyId];
  update();
};

const onCheckboxCheck = (status: PARENT_RENEWAL_STATUS) => {
  if (status === null || status === 'New business') localState.value.selectedIdsMap = {};
  update();
};

// Line 1
const lineStatus = computed(() => {
  return `Current status: ${props.policy.renewal_status_as_parent || 'Not set'}`;
});

// Line 2
const linesChildren = computed(() => {
  const status = props.policy.renewal_status_as_parent;
  if (!status) return [];
  return props.policy.renews_policies.map((p) => `${status} of: ${p.number}`);
});

// Line 3
const lineParent = computed(() => {
  const status = props.policy.renewal_status_as_child;
  if (!status) return null;
  const number = props.policy.renewed_by_policy?.number || '';
  return `${status} by: ${number}`;
});
</script>

<style lang="scss">
.renewal-status {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  max-height: 6.2em;
  overflow: hidden;

  &__trigger {
    white-space: nowrap;
    font-size: rem(16px);
    border-radius: rem(6px);
    padding: rem(5.5px) rem(12px);
    background-color: white;
    &--missing-input {
      @include invalid;
    }
    label {
      cursor: unset;
    }
  }

  &__status {
    display: block;
    color: black;
    line-height: 1.2em;
  }

  &__checkboxes {
    display: flex;
    justify-content: space-between;
  }

  &__selected-policy-item {
    cursor: unset;
    &:hover {
      opacity: unset;
    }
  }

  // Policy list
  &__policy-list {
    margin-top: 0.5em;
    height: 20em;
    overflow: auto;
    padding-right: 0.5em;
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 0.5em;
      .policy-list-item {
        flex: 1;
        margin-bottom: 0;
      }
    }
  }

  &__save-btn {
    margin-top: 1em;
    align-self: flex-end;
  }
}
</style>
