<template>
  <div class="create-ticket-box" :class="{ 'create-ticket-box--expanded': isExpanded }" @click="isExpanded = true">
    <!-- Not expanded -->
    <div v-show="!isExpanded" class="create-ticket-box__placeholder">Type a new note, followup or ticket</div>

    <!-- Expanded -->
    <div v-show="isExpanded" class="create-ticket-box__body">
      <!-- Followup form -->
      <FollowupCreateForm v-show="isFollowupFormShown" @submit="submit" />

      <!-- Confirm -->
      <div v-show="!isFollowupFormShown" class="create-ticket-box__bottom">
        <Btn variant="outline" size="sm" @click="submit()"> Confirm </Btn>
      </div>

      <!-- Text -->
      <TextareaAutoExpandable
        ref="TextareaAutoExpandableRef"
        :key="isExpanded"
        v-model="content"
        group-class="create-ticket-box__text-group"
        name="text"
        :update-on-error="true"
        :autosave="textShouldAutosave"
        :autosave-params="{ client_id: clientId }"
        :autosave-url="'/api/tickets/text/autosave'"
        @paste="(event) => NoteAttachmentsRef.onPaste(event)"
        @update:model-value="TextareaAutoExpandableRef.$refs.FormGroupRef.$refs.input.classList.remove('is-invalid')"
      />

      <!-- Attachments -->
      <NoteAttachments ref="NoteAttachmentsRef" />

      <!-- Top -->
      <div class="create-ticket-box__top">
        <!-- Select tickets -->
        <TicketSelect
          :tickets="tickets"
          :ticket="selectedTicket"
          :client-id="clientId"
          trigger-theme="text"
          @update:ticket="(value) => (selectedTicket = value)"
        />

        <!-- Divider -->
        <div class="create-ticket-box__top-divider"></div>

        <!-- Followup icon -->
        <div class="create-ticket-box__icon-container">
          <SvgIcon
            :name="isFollowupFormShown ? 'sticky-note-checked' : 'sticky-note'"
            class="create-ticket-box__icon-followup"
            @click="isFollowupFormShown = !isFollowupFormShown"
          />
        </div>

        <!-- Divider -->
        <div class="create-ticket-box__top-divider"></div>

        <!-- Minimize icon -->
        <a class="create-ticket-box__minimize-icon" @click.stop="onMinimize"></a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { toastError, toastSuccess } from '@/js/Helpers/Alert';
import TicketApi, { Ticket } from '@/js/Api/Ticket';
import TicketNoteApi from '@/js/Api/TicketNote';
import Loader from '@/js/Helpers/Loader';
import Btn from '@/js/Components/Btn.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import TextareaAutoExpandable from '@/js/Components/TextareaAutoExpandable.vue';
import TicketSelect from './TicketSelect.vue';
import FollowupCreateForm, { Form } from './FollowupCreateForm.vue';
import NoteAttachments from './NoteAttachments.vue';

interface Props {
  ticket: Ticket | null;
  tickets: Ticket[];
  clientId: number;
}

const props = defineProps<Props>();

const emit = defineEmits(['note:created', 'ticket:created']);

// State
const isExpanded = ref(false);
const isFollowupFormShown = ref(true);
const selectedTicket = ref(props.ticket ?? 'New service ticket');

// Refs
const NoteAttachmentsRef = ref<InstanceType<typeof NoteAttachments> | null>(null);
const TextareaAutoExpandableRef = ref<InstanceType<typeof TextareaAutoExpandable> | null>(null);

// Update selected ticket when props changes
onMounted(() =>
  watch(
    () => props.ticket,
    () => {
      selectedTicket.value = props.ticket ?? 'New service ticket';
      isFollowupFormShown.value = typeof selectedTicket.value === 'string';
    },
    { immediate: true },
  ),
);

// Text content
const content = ref<string | null>(null);
const textShouldAutosave = ref(true);

// Pull the unsaved text
TicketApi.getUnsavedText({ client_id: props.clientId }).then(({ data }) => {
  const dbUnsavedText = data?.text ?? null;
  isExpanded.value = !!dbUnsavedText;
  content.value = content.value || dbUnsavedText;
});

const onMinimize = () => {
  isExpanded.value = false;
};

// Submit
const submit = (form?: Form) => {
  if (!NoteAttachmentsRef.value) return;
  if (!content.value) return TextareaAutoExpandableRef.value.$refs.FormGroupRef.$refs.input.classList.add('is-invalid');

  textShouldAutosave.value = false;

  // Create ticket
  if (typeof selectedTicket.value === 'string') {
    Loader.start();
    TicketApi.create({
      client_id: props.clientId,
      type: selectedTicket.value === 'New service ticket' ? 'Service' : 'Sales',
      note: {
        is_followup: false,
        ...form,
        content: content.value,
        attachments: NoteAttachmentsRef.value.pastedAttachments,
      },
    })
      .then(({ data: ticket }) => {
        emit('ticket:created', ticket);
        toastSuccess('A new ticket was created');
      })
      .catch(() => {
        toastError();
      })
      .finally(() => {
        setTimeout(() => {
          textShouldAutosave.value = true;
        }, 2000);
        Loader.end();
      });

    // Create note
  } else {
    Loader.start();
    TicketNoteApi.create({
      is_followup: false,
      ...form,
      ticket_id: selectedTicket.value.id,
      content: content.value,
      attachments: NoteAttachmentsRef.value.pastedAttachments,
    })
      .then(({ data: note }) => {
        const verbiage = note.is_followup ? 'followup' : 'note';
        emit('note:created', note);
        toastSuccess(`A new ${verbiage} was created`);
      })
      .catch(() => {
        toastError();
      })
      .finally(() => {
        setTimeout(() => {
          textShouldAutosave.value = true;
        }, 2000);
        Loader.end();
      });
  }
};
</script>

<style lang="scss">
.create-ticket-box {
  display: flex;
  background-color: #ebebeb;
  padding: rem(9px) rem(11px);
  border-radius: rem(6px);

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    max-width: 100%;
  }

  // Cursor
  &:not(&--expanded) {
    cursor: pointer;
  }

  // Expanded
  &--expanded {
    padding: rem(20px);
    padding-top: rem(16px);
  }

  // Placeholder
  &__placeholder {
    font-size: rem(16px);
    color: #505355;
  }

  // Minimize icon
  &__minimize-icon {
    width: rem(15px);
    height: rem(15px);
    cursor: pointer;
    position: relative;

    &::after,
    &::before {
      position: absolute;
      content: '';
      display: block;
      width: rem(2px);
      height: rem(18px);
      background-color: color('gray', 8);
    }

    &::after {
      left: rem(7px);
      transform: rotate(45deg);
    }

    &::before {
      left: rem(7px);
      transform: rotate(-45deg);
    }
  }

  // Top
  &__top {
    display: flex;
    align-items: center;

    // Tickets search
    .dropdown--tickets-search {
      flex: 1;
    }

    // Divider
    &-divider {
      height: rem(20px);
      width: rem(1px);
      margin-left: rem(16px);
      margin-right: rem(13px);
      background-color: color('gray', 3);
    }

    // Followup icon
    .create-ticket-box__icon-container {
      height: rem(24px);
      width: rem(24px);
    }

    .create-ticket-box__icon-followup {
      cursor: pointer;
      color: color('gray', 7);
    }
  }

  // Bottom
  &__bottom {
    display: flex;
    justify-content: flex-end;
  }

  // Attachments
  .note-attachments {
    margin: rem(15px) 0;

    &__item {
      background-color: white;
    }
  }

  // Text
  &__text-group {
    margin: rem(10px) 0;
    background-color: white;

    .form-group__input {
      min-height: rem(70px);
      background: none;
      font-size: rem(16px);
      max-height: 35vh;
    }
  }

  // Search input (generally for all components)
  .search__input {
    font-size: rem(14px);
    height: rem(25px);
  }

  .search__group-name {
    padding-top: rem(10px);
    padding-bottom: rem(5px);
  }

  .search__match {
    line-height: 1.3;
    font-size: rem(14px);
    padding: rem(5px) rem(10px);
  }
}
</style>
