<template>
  <Modal
    ref="ModalRef"
    class="quote-update-modal"
    :show-footer="!disabled && !isCongratulationCardShown"
    :is-loading="loading"
  >
    <!-- Header -->
    <template #title>
      <Header
        v-if="quote && pastStatuses"
        :quote="quote"
        :disabled="disabled || autosaving"
        :past-statuses="pastStatuses"
        @update:status="updateStatus"
      />
    </template>

    <!-- Body -->
    <div v-if="quote" class="quote-update-modal__body">
      <!-- Update form -->
      <Form
        v-if="pastStatuses.length && !isCongratulationCardShown"
        ref="FormRef"
        :quote="quote"
        :disabled="disabled"
        :next-status="nextStatus"
        :available-coverage-types="availableCoverageTypes"
        @autosaving="(state) => (autosaving = state)"
        @loading="(state) => (loading = state)"
        @update:quote="updateQuote"
        @update:status="updateStatus"
      />

      <!-- Notice card -->
      <NoticeCard v-if="!isCongratulationCardShown && isNoticeCardShown" class="mt-2" />

      <!-- Congratulation card -->
      <CongratulationCard v-if="isCongratulationCardShown" :quote="quote" @leave="isCongratulationCardShown = false" />
    </div>

    <!-- Footer -->
    <template #footer>
      <Btn variant="outline" @click="emit('click:create')">
        <SvgIcon name="plus" class="mr-1" />
        Add another Submission
      </Btn>
      <Btn @click="hide"> Close </Btn>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Swal from 'sweetalert2';
import { ref } from 'vue';
import { toastError } from '@/js/Helpers/Alert';
import QuoteApi, { QuotePastStatus, Quote as BaseQuote } from '@/js/Api/Quote';
import { CoverageType } from '@/js/Api/CoverageType';
import { STATUSES } from '@/js/Presenters/Quote';
import Modal from '@/js/Common/Modal.vue';
import Btn from '@/js/Components/Btn.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import Header from './Header.vue';
import Form from './Form/Main.vue';
import NoticeCard from './NoticeCard.vue';
import CongratulationCard from './CongratulationCard.vue';

type Quote = Awaited<ReturnType<typeof QuoteApi.find>>['data'];

interface Props {
  disabled: boolean;
  availableCoverageTypes: CoverageType[];
}

type UpdateStatusResponse = Awaited<ReturnType<typeof QuoteApi.updateStatus>>['data'];

interface Emits {
  (e: 'click:create'): void;
  (e: 'update:quote', quote: Quote): void;
  (e: 'updated:status', response: UpdateStatusResponse, markAllAsNotSold: boolean): void;
}

defineProps<Props>();

const emit = defineEmits<Emits>();

// State
const loading = ref<boolean>(false);
const autosaving = ref<boolean>(false);
const quote = ref<Quote | null>(null);
const pastStatuses = ref<QuotePastStatus[]>([]);
const nextStatus = ref<Quote['status'] | undefined>();
const isNoticeCardShown = ref<boolean>(false);
const isCongratulationCardShown = ref<boolean>(false);

// Refs
const ModalRef = ref<InstanceType<typeof Modal> | null>(null);
const FormRef = ref<InstanceType<typeof Form> | null>(null);

// Show
const show = async (q: BaseQuote) => {
  if (!ModalRef.value) return;

  quote.value = null;
  isNoticeCardShown.value = false;
  isCongratulationCardShown.value = false;

  ModalRef.value.show();

  loading.value = true;

  const data = await Promise.all([
    QuoteApi.find({ id: q.id }).then((r) => r.data),
    QuoteApi.pastStatuses({ id: q.id }).then((r) => r.data),
  ]);

  [quote.value, pastStatuses.value] = data;

  loading.value = false;
};

// Hide
const hide = () => {
  if (!ModalRef.value) return;
  ModalRef.value.hide();
};

// Update quote
const updateQuote = (newQuote: Quote, newPastStatuses?: QuotePastStatus[]) => {
  quote.value = newQuote;
  if (newPastStatuses) pastStatuses.value = newPastStatuses;
  emit('update:quote', newQuote);
};

// Update status
const updateStatus = async (newStatus: Quote['status']) => {
  if (!FormRef.value || !quote.value) return;

  nextStatus.value = newStatus;

  const { valid } = await FormRef.value.validate();
  if (!valid) return;

  // FIXME: this isn't necessary if quote group is already at "Ready to quote"
  // Confirm "Sold" status
  let markAllAsNotSold = false;
  if (newStatus === 'Sold') {
    const swalSettings = {
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#E90001',
      confirmButtonColor: '#19497F',
      title: 'This quote group will transition to "Sold (processing)"',
      text: 'The Quote will be marked as Sold, a Policy and Processing will be created.',
      // FIXME: this isn't necessary if there are no other quotes that aren't sold
      inputValue: 1,
      input: 'checkbox',
      inputPlaceholder: 'Mark all other quote group quotes as not sold',
    };
    const { isConfirmed, value } = await Swal.fire(swalSettings);
    if (!isConfirmed) return;
    markAllAsNotSold = value;
  }

  loading.value = true;

  // Send request
  QuoteApi.updateStatus({ id: quote.value.id, status: newStatus, markAllAsNotSold })
    .then(({ data }) => {
      // Show notice or congratulations
      const oldStatusPos = STATUSES.findIndex((s) => s === quote.value?.status);
      const newStatusPos = STATUSES.findIndex((s) => s === data.quote.status);
      const statusWentForward = oldStatusPos < newStatusPos;
      if (statusWentForward) {
        if (newStatus === 'Sold') isCongratulationCardShown.value = true;
        else isNoticeCardShown.value = true;
      }

      // Update state
      updateQuote(data.quote, data.pastStatuses);
      emit('updated:status', data, markAllAsNotSold);
    })
    .catch(() => {
      toastError('An error occurred while changing status');
    })
    .finally(() => {
      loading.value = false;
    });
};

defineExpose({
  show,
  hide,
  loading,
  FormRef,
});
</script>

<style lang="scss">
.quote-update-modal {
  // Header
  .modal__header-main {
    align-items: flex-start;
    .modal__header-title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }
    .progress-bar {
      width: 63%;
      margin-left: rem(30px);
    }
    .modal__header-btn {
      margin-top: rem(5px);
      margin-left: rem(30px);
    }
  }

  // Body
  .modal__body {
    padding: rem(20px) rem(20px);
  }

  // Footer
  .modal__footer {
    justify-content: space-between;
  }
}
</style>
