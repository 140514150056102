<template>
  <div class="sidebar-right">
    <component :is="component" v-if="component" :key="clientId" :client-id="clientId" />
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import SidebarRightClient from './SidebarRightClient/SidebarRightClient.vue';
// import SidebarRightMailboxContainer from './SidebarRightMailbox/SidebarRightMailboxContainer.vue';

const route = useRoute();
const component = computed(() => {
  return /^\/clients\/[0-9]+/.test(route.path) ? SidebarRightClient : '';
});

const clientId = computed(() => Number(route.params.clientId));
</script>

<style lang="scss">
.sidebar-right {
  width: 560px;
  height: $sidebarHeight;
  background-color: white;
  border-left: 1px solid color('gray', 3);
  display: flex;
  flex-direction: column;

  @media (max-width: 1300px) {
    width: 0;
    visibility: hidden;
    position: fixed;
    right: 0;
  }

  &--open {
    @media (min-width: 1300px) {
      width: 0;
      visibility: hidden;
      position: fixed;
      right: 0;
    }

    width: 560px;
    visibility: visible;
  }

  &.focused {
    outline: rem(5px) solid #80bdff;
    box-shadow: 0 0 40px 20px rgba(0, 123, 255, 0.25);
  }
}
</style>
