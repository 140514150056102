<template>
  <div class="quote-group-intake-buttons">
    <Btn :disabled="!canEdit || activeTab.isComplete" @click="markAsCompleted">Mark intake as Completed</Btn>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios';
import { QuoteGroup } from '@/js/Api/QuoteGroup';
import { Tab } from '@/js/Components/Intakes/Tabs.vue';
import { toastSuccess, toastError } from '@/js/Helpers/Alert';
import Loader from '@/js/Helpers/Loader';
import Btn from '@/js/Components/Btn.vue';

interface Props {
  canEdit: boolean;
  quoteGroup: QuoteGroup;
  activeTab: Tab;
}

const props = defineProps<Props>();

const markAsCompleted = () => {
  Loader.start();
  axios
    .post(`/api/clients/${props.quoteGroup.client_id}/quotegroups/${props.quoteGroup.id}/intakes/mark`, {
      _method: 'PUT',
      abbreviation: props.activeTab.abbreviation,
    })
    .then(() => {
      const intakeBody = props.quoteGroup.intakes.find(
        (i) => i.coverage_type.abbreviation === props.activeTab.abbreviation
      ).intake_body;
      intakeBody.is_complete = true;
      props.activeTab.isComplete = true;
      toastSuccess('Intake marked as Complete.');
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      Loader.end();
    });
};
</script>

<style lang="scss">
.quote-group-intake-buttons {
  margin-top: rem(15px);
  display: flex;
  justify-content: flex-end;
  gap: 0.5em;
}
</style>
