<template>
  <div class="quotes-table">
    <TableListHaystack
      :columns="[
        {
          slotName: 'star',
          tdClass: 'table-list__td--star',
        },
        {
          name: 'Carrier',
          get: (item) => item.carrier?.name,
          sortBy: (item) => item.carrier?.name,
        },
        {
          name: 'Agency',
          get: (item) => item.agency?.name,
          sortBy: (item) => item.agency?.name,
        },
        {
          name: 'Label',
          slotName: 'label',
          tdClass: 'table-list__td--label',
          sortBy: (item) => item.label,
        },
        {
          name: 'Status',
          slotName: 'status',
          tdClass: 'table-list__td--status',
          thClass: 'table-list__th--status',
          sortBy: (item) => item.status,
        },
        {
          name: 'Submission date',
          tdClass: 'table-list__td--date',
          sortBy: (item) => item.submission_date,
          get: (item) => (item.submission_date ? toDateString(item.submission_date) : null),
        },
        {
          name: 'Effective date',
          tdClass: 'table-list__td--date',
          sortBy: (item) => item.effective_date,
          get: (item) => (item.effective_date ? toDateString(item.effective_date) : null),
        },
        {
          name: 'Price',
          slotName: 'price',
          thClass: 'table-list__th--price',
          tdClass: 'table-list__td--price',
          get: (item) => (item.price !== null ? formatCents(item.price) : '$TBA'),
          sortBy: (item) => item.price,
        },
        {
          slotName: 'dropdown-statuses',
          tdClass: 'table-list__td--3-dots',
        },
      ]"
      :haystack="quotes"
      theme="embedded"
      initial-sort-order="desc"
      initially-sorted-by="Submission date"
      grid-template-columns="35px 3fr 3fr 2fr minmax(20em, 3.5fr) 2fr 1.5fr 1.5fr 35px"
      @item:click="(quote) => emit('click:item', quote)"
    >
      <!-- No quotes -->
      <template #noitems> No submissions yet </template>

      <!-- Star -->
      <template #star="{ item: quote }">
        <SvgIcon
          name="star"
          :class="{
            'svg-icon--star--preferred': quote.is_preferred,
          }"
          @click.stop="togglePreferred(quote)"
        />
      </template>

      <!-- Label-->
      <template #label="{ item: quote }">
        <ColumnLabel :label="quote.label" @update:label="(label) => updateLabel(label, quote)" />
      </template>

      <!-- Status -->
      <template #status="{ item: quote }">
        <SvgIcon v-if="quote.status === 'Sold'" name="checkmark-double" color="#55ba3d" />
        <SvgIcon v-if="quote.status === 'Not Sold'" name="close" />
        <div class="table-list__td--status__status">
          {{ quote.status }}
          <template v-if="quote.status === 'Sold'"> (Processing) </template>
        </div>
        <router-link
          v-if="quote.policy"
          :to="`/clients/${quote.client_id}/policies/${quote.policy.id}/processing`"
          class="button button--primary button--regular button--size-sm"
          @click.stop
        >
          To Processing
        </router-link>
      </template>

      <!-- Price -->
      <template #price="{ item: quote }">
        {{ quote.price !== null ? formatCents(quote.price) : '$TBA' }}
        <SvgIcon v-if="quote.id === cheapestQuoteId" name="dollar" />
      </template>

      <!-- 3 dots menu -->
      <template #dropdown-statuses>
        <Dropdown class="quotes-panel__dropdown" placement="right-start" strategy="fixed" @click.stop>
          <!-- Dropdown trigger -->
          <template #trigger>
            <SvgIcon name="3-dots" />
          </template>

          <!-- Dropdown body -->
          <template #menu>
            <DropdownItem> <i class="fas fa-envelope icon"></i>Email underwriter </DropdownItem>
            <DropdownItem> <i class="fas fa-phone icon"></i>Call underwriter </DropdownItem>
          </template>
        </Dropdown>
      </template>
    </TableListHaystack>
  </div>
</template>

<script setup lang="ts">
import { computed, toRaw } from 'vue';
import { toastError } from '@/js/Helpers/Alert';
import { toDateString, formatCents } from '@/js/Helpers';
import QuoteApi, { Quote } from '@/js/Api/Quote';
import TableListHaystack from '@/js/Components/TableListHaystack.vue';
import Dropdown from '@/js/Common/Dropdown.vue';
import DropdownItem from '@/js/Common/DropdownItem.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import ColumnLabel from './ColumnLabel.vue';

interface Props {
  canEdit: boolean;
  quotes: Quote[];
}

interface Emits {
  (e: 'click:item', quote: Quote): void;
  (e: 'update:quotes', quotes: Quote[]): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

// Cheapest Quote (Dollar Icon)
const cheapestQuoteId = computed(() => {
  if (!props.quotes) return null;
  const cheapestQuote = props.quotes.reduce((prev, curr) => {
    if (prev.price === null && curr.price === null) return prev;
    if (prev.price === null) return curr;
    if (curr.price === null) return prev;
    if (prev.price < curr.price) return prev;
    return curr;
  }, props.quotes[0]);

  if (cheapestQuote.price === null) return null;

  return cheapestQuote.id;
});

// Mark preferred
const togglePreferred = (quote: Quote) => {
  QuoteApi.togglePreferred({ id: quote.id })
    .then(({ data }) => {
      const quotes = structuredClone(toRaw(props.quotes));
      quotes.forEach((q) => (q.is_preferred = false));
      const quoteToUpdate = quotes.find((q) => q.id === quote.id);
      if (quoteToUpdate) Object.assign(quoteToUpdate, data);
      emit('update:quotes', quotes);
    })
    .catch(() => {
      toastError();
    });
};

// Update label
const updateLabel = (label: string | null, quote: Quote) => {
  QuoteApi.update({
    id: quote.id,
    form: {
      label,
    },
  }).then(({ data }) => {
    const quotes = structuredClone(toRaw(props.quotes));
    const quoteToUpdate = quotes.find((q) => q.id === quote.id);
    if (quoteToUpdate) Object.assign(quoteToUpdate, data);
  });
};
</script>

<style lang="scss">
.quotes-table {
  // Table
  .table-list {
    // thead
    &__thead {
      border-bottom: none !important;
    }

    // tbody
    &__tbody {
      border: none;
      &:not(&--empty) {
        padding: 0;
      }
    }

    // tr
    &__tr {
      border-bottom: none !important;
      border-radius: rem(6px);
      background-color: color('gray', 2);
      margin-bottom: rem(10px);
      padding-left: rem(6px);
      padding-right: rem(6px);
      transition: 0.15s background-color;
      &:hover {
        background-color: color('gray', 3);
      }
    }

    // td
    &__td {
      height: rem(44px);
    }

    // Star
    &__td--star {
      .svg-icon {
        color: #b3b3b3;
        &:hover,
        &--star--preferred {
          color: #fec33f;
        }
      }
    }

    // status
    &__th--status {
      width: 11em;
      margin-left: 4em;
      justify-content: center;
      padding: 0;
    }
    &__td--status {
      position: relative;
      overflow: visible;
      overflow: visible;
      display: flex;
      padding-left: 3em;
      &__status {
        width: 9em;
        text-align: center;
      }
      .button {
        position: absolute;
        left: 14em;
      }
      .svg-icon {
        position: absolute;
        left: 1em;
        &--close {
          color: white;
          background-color: color('gray', 6);
          padding: 0.2em;
          width: 1.3em;
          height: 1.3em;
          left: 3.5em;
        }
      }
    }

    // td date
    &__td--date {
      color: color('gray', 5);
    }

    // price
    &__th--price {
      justify-content: flex-end;
      padding-right: 3.5em;
    }
    &__td--price {
      font-weight: bold;
      font-size: rem(20px);
      justify-content: flex-end;
      padding-right: 1.5em;
      position: relative;
      .svg-icon {
        position: absolute;
        right: 0;
        color: white;
        padding: 0.2em;
        background-color: #c3e68b;
        border-radius: 50%;
      }
    }
  }
}
</style>
