<template>
  <FormGrid v-if="addresses" class="addresses" :disabled="disabled" @click:create="create">
    <template #header>Addresses</template>
    <FormGridGroup v-for="(address, i) in addresses" :key="address.id" :with-numbers="withNumbers">
      <FormGridRow
        :autosave-url="`/api/addresses/${address.id}/autosave`"
        :style="{ 'grid-template-columns': !disabled ? '2fr 1.6fr 0.3fr 0.5fr 1.2fr' : '2fr 1fr 0.3fr 1fr 1fr' }"
      >
        <!-- Columns -->
        <FormGroup
          v-model.uppercase="address.address"
          type="text"
          name="address"
          label-text="Address"
          :disabled="disabled"
        />
        <FormGroup v-model.uppercase="address.city" type="text" name="city" label-text="City" :disabled="disabled" />
        <FormGroup
          v-model.uppercase="address.province"
          type="select"
          name="province"
          label-text="State"
          :disabled="disabled"
        >
          <option value=""></option>
          <option v-for="(state, i) in usStates" :key="i" :value="state.abbr">
            {{ state.abbr }}
          </option>
        </FormGroup>
        <FormGroup v-model="address.zip" type="zip" name="zip" label-text="ZIP" :disabled="disabled" />

        <SelectText
          v-model:text.uppercase="address.type"
          v-model:select="address.preset_type"
          :options="{
            MAILING: 'MAILING',
            GARAGING: 'GARAGING',
            SHIPPING: 'SHIPPING',
            OTHER: null,
          }"
          label-select="Type"
          name-select="preset_type"
          name-text="type"
          :autosave-url="`/api/addresses/${address.id}/autosave`"
        />

        <!-- Dots -->
        <template #dots-links-prepend>
          <a class="dropdown-item" @click="goToGoogle(address)">
            <i class="fab fa-google icon"></i>
            Google
          </a>
          <a class="dropdown-item" @click.stop="copyAddress(address)">
            <SvgIcon name="copy" />
            Copy address
            <Tooltip show-on="click">Information has been copied</Tooltip>
          </a>
          <a v-if="!disabled && addresses.length > 1" class="dropdown-item delete" @click="destroy(i)">
            <i class="fas fa-trash-alt icon"></i>
            Delete Address
          </a>
        </template>
      </FormGridRow>
    </FormGridGroup>
  </FormGrid>
</template>

<script>
import axios from 'axios';
import { useStore } from 'vuex';
import { computed, onBeforeUnmount } from 'vue';

import Clipboard from '@/js/Helpers/Clipboard';
import useAlert from '@/js/Composables/useAlert';
import useLoader from '@/js/Composables/useLoader';

import Tooltip from '@/js/Common/Tooltip.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import FormGrid from '@/js/Common/Form/FormGrid/FormGrid.vue';
import FormGridGroup from '@/js/Common/Form/FormGrid/FormGridGroup.vue';
import FormGridRow from '@/js/Common/Form/FormGrid/FormGridRow.vue';
import FormGroup from '@/js/Common/Form/FormGroup/FormGroup.vue';
import SelectText from '../SelectText.vue';

export default {
  components: {
    SvgIcon,
    Tooltip,
    FormGrid,
    FormGridGroup,
    FormGridRow,
    FormGroup,
    SelectText,
  },

  props: {
    addresses: {
      type: Array,
      required: true,
    },
    parentType: {
      type: String,
      required: true,
    },
    parentID: {
      type: [Number, String],
      required: true,
    },
    withNumbers: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    // Tools
    const { loader } = useLoader();
    const { toast, prompt } = useAlert();

    // USA states
    const store = useStore();
    const { usStates } = store.state.global.config;

    // Helpers
    const getFullAddress = (data) => {
      if (!data) return null;

      const street = data.address ? ` ${data.address}` : '';
      const city = data.city ? ` ${data.city}` : '';
      const state = data.province ? `, ${data.province}` : '';
      const zip = data.zip ? ` ${data.zip}` : '';
      let result = (street + city + state + zip).toUpperCase();

      if (data.type)
        if (data.type === 'OTHER') result = data.custom_type + (result ? ' - ' : '') + result;
        else result = data.type + (result ? ' - ' : '') + result;

      return result;
    };

    const copyAddress = async (address) => {
      Clipboard.copy(getFullAddress(address));
    };

    const goToGoogle = (data) => {
      if (!data) return;

      const street = data.address ? `${data.address} ` : '';
      const city = data.city ? `${data.city}, ` : '';
      const state = data.province ? `${data.province} ` : '';
      const zip = data.zip ? data.zip : '';
      const query = `${street}${city}${state}${zip}`
        .replaceAll(' ', '+')
        .replaceAll('#', '%23')
        .replaceAll(',', '%2C')
        .toUpperCase();

      window.open(`https://www.google.com/search?q=${query}`);
    };

    // Actions
    const create = () => {
      loader.start();
      axios
        .post('/api/addresses', {
          parent_type: props.parentType,
          parent_id: props.parentID,
        })
        .then((response) => {
          loader.end();
          props.addresses.splice(props.addresses.length, 0, response.data);
        })
        .catch(() => {
          toast({ title: 'An error occurred while creating address', icon: 'error' });
        })
        .finally(() => {
          loader.end();
        });
    };
    const destroy = async (i) => {
      const address = props.addresses[i];
      const fullAddress = getFullAddress(address);
      let text = fullAddress ? `Please confirm you want to delete the address ${fullAddress}. ` : '';
      text += 'Please type "delete" to proceed deleting or cancel.';

      const promptResult = await prompt({ text });

      if (!promptResult.value || promptResult.value.toLowerCase() !== 'delete') return;

      loader.start();
      axios
        .post(`/api/addresses/${address.id}`, { _method: 'DELETE' })
        .then(() => {
          props.addresses.splice(i, 1);
          toast({ title: 'The address has been deleted.', icon: 'success' });
        })
        .catch(() => {
          toast({ title: 'An error occurred while deleting address.', icon: 'error' });
        })
        .finally(() => {
          loader.end();
        });
    };

    return {
      create,
      destroy,
      usStates,
      goToGoogle,
      copyAddress,
    };
  },
};
</script>
