<template>
  <Modal ref="ModalRef" :show-footer="false">
    <!-- Title -->
    <template #title> Status history </template>

    <!-- Creation date -->
    <div>
      <div><strong>Creation date:</strong> {{ toDateString(policy.created_at) }}</div>
    </div>

    <hr v-if="policy.cancellations && policy.cancellations.length" />

    <!-- Cancellations -->
    <template v-for="(cancellation, i) in policy.cancellations" :key="cancellation.id">
      <div>
        <div><strong>Cancellation date:</strong> {{ toDateString(cancellation.date) }}</div>
        <div><strong>Cancellation reasons:</strong> {{ cancellation.reasons.replace(',', ', ') }}</div>
        <div v-if="cancellation.amount">
          <strong>Cancellation amount:</strong> {{ formatCents(cancellation.amount) }}
        </div>
        <div v-if="cancellation.carrier_underwrighting_reason">
          <strong>Carrier/underwriting reason:</strong> {{ cancellation.carrier_underwrighting_reason }}
        </div>
        <div v-if="cancellation.reinstatement">
          <strong>Reinstatement date:</strong> {{ toDateString(cancellation.reinstatement) }}
        </div>
      </div>
      <hr v-if="i + 1 !== policy.cancellations.length" />
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { formatCents, toDateString } from '@/js/Helpers';
import { Policy } from '@/js/Api/Policy';
import Modal from '@/js/Common/Modal.vue';

interface Props {
  policy: Policy;
}

defineProps<Props>();

const ModalRef = ref<InstanceType<typeof Modal> | null>(null);

const show = () => ModalRef.value.show();

defineExpose({
  show,
});
</script>
