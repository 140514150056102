import { z } from 'zod';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { VehicleSchema } from './Vehicle';
import { CoverageTypeCASchema } from './CoverageType';
import { CargoTypeSchema } from './CargoType';
import { DriverSchema } from './Driver';
import { PriorExistingInsuranceSchema } from './PriorExistingInsurance';
import { EmailSchema } from './Email';
import { OwnerPartnerSchema } from './OwnerPartner';

export const IntakeCASchema = z.object({
  id: z.number(),
  client_id: z.number(),
  policy_id: z.number().nullable(),
  quote_group_id: z.number().nullable(),
  is_complete: z.boolean(),
  business_description: z.string().nullable(),
  vehicles_count: z.string().nullable(),
  vehicles_description: z.string().nullable(),
  general_liability: z.boolean().nullable(),
  general_liability_limits: z.string().nullable(),
  auto_liability: z.boolean().nullable(),
  auto_liability_limits: z.string().nullable(),
  auto_liability_limits_other: z.string().nullable(),
  non_trucking_liability: z.boolean().nullable(),
  non_trucking_liability_limits: z.string().nullable(),
  cargo_liability: z.boolean().nullable(),
  cargo_liability_limits: z.string().nullable(),
  cargo_liability_limits_other: z.string().nullable(),
  refrigeration_breakdown: z.boolean().nullable(),
  cargo_deductible: z.string().nullable(),
  cargo_deductible_other: z.string().nullable(),
  physical_damage: z.boolean().nullable(),
  trailer_interchange: z.boolean().nullable(),
  trailer_interchange_limits: z.string().nullable(),
  trailer_interchange_deductible: z.string().nullable(),
  trailer_interchange_deductible_other: z.string().nullable(),
  uiia: z.boolean().nullable(),
  other_ai: z.boolean().nullable(),
  other_ai_text: z.string().nullable(),
  pei: z.boolean().nullable(),
  ifta_reports_available: z.boolean().nullable(),
  eld: z.boolean().nullable(),
  eld_provider: z.string().nullable(),
  contact_email_type: z.string().nullable(),
  contact_email_id: z.number().nullable(),
  desired_coverage_start_date: z.string().nullable(),
  operation_radius: z.enum(['YES', 'NO']),
  is_new_venture: z.enum(['YES', 'NO']),
  is_reefer: z.enum(['YES', 'NO']),
  is_sag: z.enum(['YES', 'NO']),
  is_target: z.enum(['YES', 'NO']),
  is_towing: z.enum(['YES', 'NO']),
  is_reefer_ca: z.enum(['YES', 'NO']),
  is_new_venture_ca: z.enum(['YES', 'NO']),
  years_in_biz: z.number(),
  section_fee: z.number(),
  pd_debits: z.string().nullable(),
  cargo_debits: z.string().nullable(),
  sec_fee: z.string().nullable(),
  pd_premium: z.string().nullable(),
  pd_mga: z.string().nullable(),
  pd_stamping: z.string().nullable(),
  pd_tax: z.string().nullable(),
  pd_total: z.string().nullable(),
  cargo_premium: z.string().nullable(),
  cargo_mga: z.string().nullable(),
  cargo_stamping: z.string().nullable(),
  cargo_tax: z.string().nullable(),
  cargo_total: z.string().nullable(),
  both_premium: z.string().nullable(),
  both_mga: z.string().nullable(),
  both_stamping: z.string().nullable(),
  both_tax: z.string().nullable(),
  both_total: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullable(),
  coverage_type: CoverageTypeCASchema,
  contact_email: EmailSchema.nullish(),
  drivers: z.array(DriverSchema).nullish(),
  vehicles: z.array(VehicleSchema).nullish(),
  cargo_types: z.array(CargoTypeSchema).nullish(),
  owners_partners: z.array(OwnerPartnerSchema).nullish(),
  prior_existing_insurances: z.array(PriorExistingInsuranceSchema).nullish(),
  // Operations
  local: z.boolean().nullable(),
  intermediate: z.boolean().nullable(),
  long_haul: z.boolean().nullable(),
  intermodal_port: z.boolean().nullable(),
  dump_truck_other: z.boolean().nullable(),
  dump_truck_sand_gravel: z.boolean().nullable(),
  end_dumper: z.boolean().nullable(),
  logging: z.boolean().nullable(),
  refrigerated: z.boolean().nullable(),
  hot_shot: z.boolean().nullable(),
  oversized_overnight: z.boolean().nullable(),
  automobile_hauler: z.boolean().nullable(),
  household_goods: z.boolean().nullable(),
  tanker: z.boolean().nullable(),
  ineligible_operations: z.boolean().nullable(),
  filings_al_federal: z.boolean().nullable(),
  filings_al_state: z.boolean().nullable(),
  prior_insurance_cancelled_nonrenewed: z.boolean().nullable(),
});

export type IntakeCA = z.infer<typeof IntakeCASchema>;

async function find({ clientId, id }: { clientId: number; id: number }) {
  const response = await axios.get(`/api/clients/${clientId}/intakes/ca/${id}`).catch((error) => {
    Sentry.captureException(error);
  });

  if (!response) return undefined;

  return z
    .object({
      data: IntakeCASchema,
    })
    .parseAsync(response.data);
}

export default {
  find,
};
