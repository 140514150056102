<template>
  <SlidePanel v-model:is-active="isDailyPanelActive" class="daily-activity">
    <template #header-left>
      Daily activity
      <template v-if="count != null"> ({{ count }}) </template>
    </template>

    <!-- Table -->
    <TableList
      :columns="[
        {
          name: 'Checked',
          value: 'cleared',
          slot: true,
          slotName: 'checkbox',
        },
        {
          name: 'Client name',
          value: 'client_name',
          slot: true,
          slotName: 'client_name',
          tdClass: 'table-list__td--clientname',
        },
        {
          name: 'User time',
          value: 'user_time',
          tdClass: 'table-list__td--center',
          thClass: 'table-list__th--center',
          get: (item) => secondsToHHMMSS(item.user_time),
        },
        {
          name: 'Users',
          slot: true,
          slotName: 'users',
        },
        {
          name: 'Total time',
          value: 'total_time',
          tdClass: 'table-list__td--center',
          thClass: 'table-list__th--center',
          get: (item) => secondsToHHMMSS(item.total_time),
        },
        {
          name: 'Updated',
          value: 'client_updated_at',
          tdClass: 'table-list__td--center',
          thClass: 'table-list__th--center',
          get: (item) => new Date(item.client_updated_at).toDateTime(),
        },
        //{
        //  name: 'Followups',
        //  value: 'followups_total_open_count',
        //  thClass: 'table-list__th--total-open',
        //  tdClass: 'table-list__td--total-open table-list__td--separator',
        //  slot: true,
        //  slotName: 'followups',
        //},
        //{
        //  name: 'New',
        //  value: 'followups_daily_new_count',
        //  thClass: 'table-list__th--center',
        //  tdClass: 'table-list__td--separator table-list__td--center',
        //},
        //{
        //  name: 'Resched.',
        //  value: 'followups_daily_rescheduled_count',
        //  thClass: 'table-list__th--center',
        //  tdClass: 'table-list__td--separator table-list__td--center',
        //},
        //{
        //  name: 'Resolved',
        //  value: 'followups_daily_resolved_count',
        //  thClass: 'table-list__th--center',
        //  tdClass: 'table-list__td--separator table-list__td--center',
        //},
      ]"
      :api-filters="filters"
      :api-endpoint="'/api/dashboard/daily'"
      :api-vuex-property="'dashboard/dailyActivityTable'"
      :api-vuex-mutation="'dashboard/setDailyActivityTable'"
      :api-sorted-by="$store.state.dashboard.dailyActivityPanelSettings.sortedBy"
      :api-sort-order="$store.state.dashboard.dailyActivityPanelSettings.sortOrder"
      :is-row-clickable="false"
      :tr-class="(item) => ({ 'table-list__tr--cleared': item.cleared })"
      grid-template-columns="0.5fr 4fr 1fr 2fr 1fr 2fr 1.25fr"
      @sort="commitSort"
    >
      <!-- Checkbox -->
      <template #checkbox="{ item }">
        <InputCheckbox type="circle" :model-value="item.cleared" @update:model-value="onCheckboxClick(item)" />
      </template>

      <!-- Client name -->
      <template #client_name="{ item }">
        <RouterLink :to="`/clients/${item.client_id}`" class="link" @click.stop>
          {{ item.client_name || '(Draft)' }}
        </RouterLink>
      </template>

      <!-- Users -->
      <template #users="{ item }">
        <Tooltip v-if="item.users.length > 1" show-on="hover">
          <template v-for="user in item.users" :key="user.id"> ({{ user.initials }}) {{ user.name }} <br /></template>
        </Tooltip>
        <div class="flex">
          <UserCircle v-for="user in item.users" :key="user.id" :initials="user.initials" :full-name="user.name" />
        </div>
      </template>

      <!-- Followups -->
      <template #followups="{ item }"> {{ item.followups_total_open_count }} active </template>
    </TableList>
  </SlidePanel>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import { RouterLink } from 'vue-router';
import { toastError } from '@/js/Helpers/Alert';
import api from '@/js/Api/Dashboard';
import { secondsToHHMMSS } from '@/js/Helpers/DateAndTime';
import Tooltip from '@/js/Common/Tooltip.vue';
import TableList from '@/js/Components/TableList.vue';
import SlidePanel from '@/js/Components/SlidePanel.vue';
import UserCircle from '@/js/Components/UserCircle.vue';
import InputCheckbox from '@/js/Common/Form/Input/Checkbox.vue';

const store = useStore();

const filters = computed(() => store.state.dashboard.filters);

const count = computed(() => store.state.dashboard.dailyActivityPanelCount);

const isDailyPanelActive = computed({
  get: () => store.state.dashboard.dailyActivityPanelSettings.active,
  set: (isActive) =>
    store.commit('dashboard/setSetting', {
      value: isActive,
      property: 'active',
      panel: 'dailyActivity',
    }),
});

const commitSort = (column, order) => {
  store.commit('dashboard/setSetting', {
    value: column,
    property: 'sortedBy',
    panel: 'dailyActivity',
  });
  store.commit('dashboard/setSetting', {
    value: order,
    property: 'sortOrder',
    panel: 'dailyActivity',
  });
};

const onCheckboxClick = (item) => {
  store.commit('dashboard/dailyActivityTableClearClient', {
    cleared: !item.cleared,
    clientID: item.client_id,
  });
  api.dailyClearClient({ client_id: item.client_id }).catch(() => {
    store.commit('dashboard/dailyActivityTableClearClient', {
      cleared: !item.cleared,
      clientID: item.client_id,
    });
    toastError();
  });
};
</script>

<style lang="scss">
.daily-activity {
  // Hover
  .table-list__tr {
    transition: 0.15s;

    &:hover {
      background-color: #d9ebff;
    }

    &--cleared {
      opacity: 0.5;
    }
  }

  .checkbox {
    cursor: pointer;
    font-size: rem(20px);
    color: color('gray', 3);

    &__checkmark-container {
      box-shadow: none !important;
      border: 0.1em solid color('gray', 3);
    }

    &__checkmark {
      width: 80%;
      height: 80%;
      transition: none;
    }
  }

  .user-circle {
    font-size: rem(16px);
    width: rem(31px);
    height: rem(31px);
    color: color('primary');
    background-color: white;
    border: rem(1px) solid color('primary');

    &:not(:first-child) {
      margin-left: rem(10px);
    }
  }

  .table-list__th--center,
  .table-list__td--center {
    justify-content: center;
  }

  .table-list__td--separator {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: block;
      height: 70%;
      width: rem(1px);
      margin: auto 0;
      background-color: color('gray', 3);
    }
  }

  // Client name
  .table-list__td--clientname a {
    display: flex;
    align-items: center;
  }

  .table-list__td--total-open,
  .table-list__th--total-open {
    padding-left: 10%;
  }
}
</style>
