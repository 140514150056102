<template>
  <div class="quotes-panel">
    <!-- Panel header -->
    <div class="quotes-panel__header">
      <h4>Quotes ({{ quoteGroups ? quoteGroups.length : 0 }})</h4>
      <Btn v-if="canEditClient" variant="outline" @click="goToCreatePage">
        <i class="fas fa-plus mr-1" />
        Add Quote Group
      </Btn>
    </div>

    <!-- Panel body -->
    <QuoteGroupList v-if="quoteGroups" :quote-groups="quoteGroups" @quote-group:click="goToEditPage" />
  </div>
</template>

<script setup lang="ts">
import { inject, ComputedRef } from 'vue';
import { useRouter } from 'vue-router';
import { Client } from '@/js/Api/Client';
import { QuoteGroup } from '@/js/Api/QuoteGroup';
import useClientStore from '@/js/store/pinia/useClientStore';
import Btn from '@/js/Components/Btn.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import QuoteGroupList from '@/js/Components/QuoteGroups/List.vue';

interface Props {
  client?: Client;
  clientId: Client['id'];
}

const props = defineProps<Props>();

const canEditClient = inject('canEditClient') as ComputedRef<boolean>;

const { ref: quoteGroups } = useClientStore().swrQuoteGroups({ id: props.clientId });

// Links
const router = useRouter();
const goToCreatePage = () => {
  router.push({ name: 'ClientQuoteGroupCreate' });
};
const goToEditPage = (quoteGroup: QuoteGroup) => {
  router.push({ name: 'ClientQuoteGroupEdit', params: { quoteGroupId: quoteGroup.id } });
};
</script>

<style lang="scss">
.quotes-panel {
  grid-area: quotespanel;
  display: flex;
  flex-direction: column;
  border: rem(1px) solid $border;
  border-radius: rem(12px);
  box-shadow: 0 rem(1px) rem(6px) #00000014;
  background-color: white;

  // Panel header
  .quotes-panel__header {
    height: rem(66px);
    padding: 0 rem(24px);
    background-color: #e5e9ed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: rem(12px) rem(12px) 0 0;

    h4 {
      font-size: rem(20px);
      line-height: rem(27px);
    }

    .btn .svg-icon {
      margin-right: 0.3em;
    }
  }

  // Panel body
  .quote-group-list {
    flex: 1 1 auto;
    overflow: auto;
    height: 100px;
    background-color: white;
  }
}
</style>
