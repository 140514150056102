function constructFilterPart(filters, chain = [], result = []) {
  Object.keys(filters).forEach((key) => {
    let value = filters[key];
    if (value === undefined) return;
    if (Array.isArray(value) && !value.length) return;
    if (Array.isArray(value)) value = value.join(',');
    else if (value === null) value = value;
    else if (typeof value === 'object') return constructFilterPart(value, chain.concat(key), result);
    const stringChain = chain
      .concat(key)
      .map((field) => `[${field}]`)
      .join('');
    result.push(`filter${stringChain}=${value}`);
  });
  if (chain.length === 0) return result.join('&');
}

export default function queryBuilderToQueryString(queryBuilder) {
  const result = [];

  // Limit
  if (queryBuilder.limitPerPage) result.push(`limit=${queryBuilder.limitPerPage}`);

  // Page
  if (queryBuilder.page) result.push(`page=${queryBuilder.page}`);

  // Filters
  if (queryBuilder.filters) result.push(constructFilterPart(queryBuilder.filters));

  // Includes
  if (queryBuilder.includes) result.push(`include=${queryBuilder.includes.join(',')}`);

  // Sorts
  if (queryBuilder.sorts) {
    const sortString = queryBuilder.sorts.map((sort) => `${sort.order === 'desc' ? '-' : ''}${sort.name}`).join(',');
    result.push(`sort=${sortString}`);

    // @DEPRECATED
  } else if (queryBuilder.sort?.order && queryBuilder.sort?.column) {
    result.push(`sort=${queryBuilder.sort.order === 'desc' ? '-' : ''}${queryBuilder.sort.column}`);
  }

  return result.join('&');
}
