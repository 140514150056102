<template>
  <div
    ref="el"
    class="form-grid__group"
    :class="{ nested: isNested }"
    :autosaveURL="autosaveUrl"
    @scroll="onTabScroll"
    @submit.prevent
  >
    <!-- Number -->
    <slot name="col-number">
      <div class="col-number" v-if="!isNested && withNumbers">
        <div class="circle">
          <slot name="col-number-prepend"></slot>
          <div class="number">
            {{ groupIndex + 1 }}
          </div>
        </div>
      </div>
    </slot>

    <!-- Rows -->
    <div class="form-grid__rows-container">
      <slot />
    </div>
  </div>
</template>

<script>
import { ref, provide, inject, watch, getCurrentInstance, onMounted } from 'vue';
import useSlide from '@/js/Composables/useSlide.js';

export default {
  name: 'FormGridGroup',

  props: {
    withNumbers: {
      type: Boolean,
      default: true,
    },
    autosaveUrl: {
      type: String,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    // Is nested?
    const instance = getCurrentInstance();
    let isNested = false;
    let parent = instance.parent;
    while (parent) {
      isNested = parent.type.name === 'FormGridRow';
      if (isNested) break;
      parent = parent.parent;
    }

    // Return if nested
    if (isNested)
      return {
        isNested,
      };

    const el = ref(null);

    const expandedMap = inject('expandedMap');

    // Determine index
    const groupIndex = ref(null);
    const getGroupIndex = () => Array.from(el.value.parentElement.children).findIndex((group) => group === el.value);
    onMounted(() => {
      groupIndex.value = getGroupIndex();
    });

    // Add index to expanded map
    onMounted(() => (expandedMap[groupIndex.value] = props.expanded));

    // Overflow map
    const overflowMap = inject('overflowMap');
    const getGroupOverflown = () => el.value.scrollHeight > el.value.clientHeight;
    onMounted(() => (overflowMap[groupIndex.value] = getGroupOverflown()));

    // Provide to FormGridRow
    provide('groupIndex', groupIndex);

    // Slide
    let firstTimeRun = true;
    const { slideToggle } = useSlide();
    watch(
      () => expandedMap[groupIndex.value],
      () => {
        if (firstTimeRun) return (firstTimeRun = false);
        slideToggle(el.value, expandedMap[groupIndex.value], '2.834rem');
      }
    );

    // Recalculate stuff when sibling group is removed
    const groupRemovedTrigger = inject('groupRemovedTrigger');
    watch(groupRemovedTrigger, () => {
      const oldExpanded = expandedMap[groupIndex.value];
      groupIndex.value = getGroupIndex();
      overflowMap[groupIndex.value] = getGroupOverflown();
      expandedMap[groupIndex.value] = oldExpanded;
    });

    // Observer
    onMounted(() => {
      const observer = new MutationObserver((mutationsList) => {
        if (!overflowMap[groupIndex.value])
          overflowMap[groupIndex.value] = el.value.scrollHeight > el.value.clientHeight;
        if (mutationsList[0].addedNodes.length && !expandedMap[groupIndex.value]) expandedMap[groupIndex.value] = true;
      });
      Array.from(el.value.querySelectorAll('.form-grid__rows-container')).forEach((rowsContainer) =>
        observer.observe(rowsContainer, { childList: true })
      );
    });

    // On tab on last input
    const onTabScroll = () => {
      expandedMap[groupIndex.value] = true;
    };

    // If props.expanded === true
    if (props.expanded) onMounted(() => (el.value.style.maxHeight = 'none'));

    return {
      el,
      isNested,
      groupIndex,
      onTabScroll,
    };
  },
};
</script>

<style lang="scss">
.form-grid__group {
  display: flex;

  // For expanding!
  &:not(.nested) {
    max-height: calc(#{rem($inputHeight)} + #{rem(3px)});
    overflow: hidden;
  }

  // Padding and relative for dots menu!
  &:not(.nested) {
    position: relative;
    padding-right: rem($dotsTriggerDimension);
  }

  // Border
  &:not(.nested) {
    border-left: rem(1px) solid color('gray', 3);
    &:last-child {
      position: relative;
      &::after {
        content: '';
        display: block;
        height: rem(1px);
        background-color: color('gray', 3);
        position: absolute;
        left: 0;
        bottom: 0;
        width: calc(100% - #{rem($dotsTriggerDimension)});
      }
    }
  }
  .col-number {
    border-top: rem(1px) solid color('gray', 3);
  }
  .form-grid__row:not(.nested) {
    border-right: rem(1px) solid color('gray', 3);
    &:first-child {
      border-top: rem(1px) solid color('gray', 3);
    }
    .form-grid__row {
      border-top: none;
    }
  }

  // Highlighted
  &.highlighted {
    &:not(.nested) {
      border-left: rem(2.5px) solid $form-grid-group-highlight-color;
      &:last-child::after {
        height: rem(2px);
        background-color: $form-grid-group-highlight-color;
      }
    }
    .form-grid__row:not(.nested) {
      border-right: rem(2.5px) solid $form-grid-group-highlight-color;
      &:first-child {
        border-top: rem(2.5px) solid $form-grid-group-highlight-color;
      }
      .form-group:nth-last-child(2)::after {
        display: none;
      }
    }

    .col-number {
      border-top: rem(2.5px) solid $form-grid-group-highlight-color;
      .circle {
        border-color: $form-grid-group-highlight-color;
        &::after {
          content: '';
          display: block;
          width: rem(10px);
          height: rem(10px);
          position: absolute;
          top: rem(-2px);
          right: rem(-2px);
          background-color: $form-grid-group-highlight-color;
          border-radius: 50%;
        }
        .number {
          color: $form-grid-group-highlight-color;
        }
      }
    }
  }

  // Removed
  &.removed {
    .col-number {
      opacity: 0.5;
    }
    .form-grid__row > *:not(.dropdown.dots) {
      opacity: 0.5;
    }
  }

  // Separators
  .form-grid__row > .form-group {
    position: relative;
    &::after {
      @include form-grid-separator;
      right: -1px;
    }
    // &:nth-last-child(3)::after {
    //   display: none;
    // }
    // &:first-child::before {
    //   @include form-grid-separator;
    //   left: 0;
    // }
  }
  .form-grid__row .form-grid__group .form-group:first-child::before {
    @include form-grid-separator;
    left: 0;
  }
  .col-number::after {
    @include form-grid-separator;
    right: -1px;
  }

  .form-grid__rows-container {
    flex-grow: 1;
  }

  // Column number
  .col-number {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: rem(10px);
    background-color: white;
    .circle {
      @include center-content;
      position: relative;
      min-width: rem(27px);
      min-height: rem(27px);
      border: rem(1px) solid color('gray', 3);
      border-radius: 50%;
      background-color: white;
      .number {
        color: color('text', 3);
        font-size: rem(16px);
        line-height: rem(19px);
      }
    }
  }
}
</style>
