<template>
  <!-- Top -->
  <div class="sidebar-right__top">
    <NavSwiwtcher :items="navItems" @selected="(item) => (componentName = item.value)" />
  </div>

  <!-- Tickets, emails or all -->
  <KeepAlive v-if="ticketsSorted">
    <component
      :is="componentsMap[componentName]"
      :client-id="clientId"
      :tickets="ticketsSorted"
      :ticket="currentTicket"
      @ticket:selected="(ticket) => (currentTicket = ticket)"
    >
    </component>
  </KeepAlive>

  <!-- Bottom -->
  <div class="sidebar-right__bottom">
    <CreateTicketOrNoteBox
      v-if="ticketsSorted && canEditClient"
      :key="createTicketBoxKey"
      :ticket="currentTicket"
      :tickets="ticketsSorted"
      :client-id="clientId"
      @note:created="pushNewNote"
      @ticket:created="pushNewTicket"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { Ticket } from '@/js/Api/Ticket';
import { TicketNote } from '@/js/Api/TicketNote';
import useClientStore from '@/js/store/pinia/useClientStore';
import NavSwiwtcher from '@/js/Common/NavSwitcher.vue';
import CreateTicketOrNoteBox from '@/js/Components/Tickets/CreateTicketOrNoteBox.vue';
import PanelAll from './All/PanelAll.vue';
import PanelTickets from './Tickets/SidebarRightTickets.vue';

interface Props {
  clientId: number;
}

const props = defineProps<Props>();

// Component
const componentsMap = { PanelAll, PanelTickets };
const componentName = ref<keyof typeof componentsMap>('PanelTickets');

// Navigation
const navItems = ref([
  { value: 'PanelTickets', text: 'Tickets', active: true },
  { value: 'PanelAll', text: 'View all' },
]);

const store = useClientStore();

// Client permission
const canEditClient = computed(() => store.client && store.client.canThisUserEdit);

// Tickets
const { ref: tickets, promise } = store.swrTickets({ id: props.clientId });

const sort = (t1: Ticket, t2: Ticket) => {
  const date1 = new Date(t1.updated_at);
  const date2 = new Date(t2.updated_at);
  if (date1 < date2) return 1;
  if (date1 > date2) return -1;
  return 0;
};

const ticketsSorted = computed(() => {
  if (!tickets.value) return null;
  const openTickets = tickets.value.filter((t) => t.status === 'Open');
  const resolvedTickets = tickets.value.filter((t) => t.status === 'Resolved');

  openTickets.sort(sort);
  resolvedTickets.sort(sort);

  return [...openTickets, ...resolvedTickets];
});

// CreateTicketBox key
const createTicketBoxKey = ref(0);

// Current ticket
const currentTicket = ref<Ticket | null>(null);
const currentTicketIdInState = computed(() => store.currentTicketId);
watch(currentTicketIdInState, () => {
  if (!tickets.value) return;
  const ticket = tickets.value.find((t) => t.id === currentTicketIdInState.value);
  if (ticket) currentTicket.value = ticket;
});

// Open ticket from url
const route = useRoute();
if (route.query.ticket_id)
  promise.then(() => {
    const ticketId = Number(route.query.ticket_id);
    currentTicket.value = tickets.value.find((t) => t.id === ticketId) ?? null;
  });

// Add note or ticket
const pushNewNote = (note: TicketNote) => {
  store.addOrUpdateNote(note);
  createTicketBoxKey.value += 1;
};
const pushNewTicket = (ticket: Ticket) => {
  createTicketBoxKey.value += 1;
  store.addTicket(ticket);
};
</script>

<style lang="scss">
// Top
.sidebar-right__top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: rem(24px);
  padding-bottom: rem(20px);
}

// Bottom
.sidebar-right__bottom {
  padding: rem(21px);
}
</style>
