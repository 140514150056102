export const shortenUserName = (name: string) => {
  const words = name.split(' ');
  if (words.length === 1) return name;
  return `
    ${words[0]}
    ${words
      .splice(1, words.length - 1)
      .map((w) => `${w.charAt(0).toUpperCase()}.`)
      .join(' ')}
  `;
};

export default {
  shortenUserName,
};
