<template>
  <!-- Back arrow -->
  <Teleport to=".sidebar-right__top">
    <div class="sidebar-right-panel--notes__back" @click="emit('back')">
      <SvgIcon class="sidebar-right-panel--notes__back-icon" name="arrow-left" />
      All tickets
    </div>
  </Teleport>

  <!-- Note modal -->
  <NoteModal
    v-if="noteInModal"
    ref="NoteModalRef"
    :tickets="tickets"
    :note="noteInModal"
    :can-edit="canEditClient"
    :prompt-to-resolve-tickets="false"
    @hidden:modal="noteInModal = null"
    @update:note="store.updateNote"
    @update:subnote="store.updateNote"
    @create:subnote="store.addNote"
    @deleted:note="store.deleteNote"
    @deleted:subnote="store.deleteNote"
  />

  <SidebarRightPanel class="sidebar-right-panel--notes">
    <!-- Header -->
    <template #header>
      <!-- TicketDetails -->
      <PanelNotesTicketDetails :ticket="ticket" :tickets="tickets" />

      <!-- Divider -->
      <div class="sidebar-right-panel--notes__divider"></div>

      <!-- Controls -->
      <PanelNotesControls
        v-model:sort="controlSort"
        v-model:display="controlDisplay"
        v-model:only-open-followups="controlOnlyOpenFollowups"
      />
    </template>

    <!-- Body -->
    <template #body>
      <!-- Notes list -->
      <NoteItem
        v-for="note in notesSorted"
        :key="note.id"
        :note="note"
        :expanded="controlDisplay === 'expanded'"
        :can-edit="canEditClient"
        followup-update-form-type="small"
        @click:more-details="openNote"
        @update:note="store.updateNote"
        @deleted:note="store.deleteNote"
        @update:subnote="store.updateNote"
        @create:subnote="store.addNote"
      />
    </template>
  </SidebarRightPanel>
</template>

<script setup lang="ts">
import { watch, ref, computed, nextTick } from 'vue';
import { confirm, toastError } from '@/js/Helpers/Alert';
import useClientStore from '@/js/store/pinia/useClientStore';
import TicketApi, { Ticket } from '@/js/Api/Ticket';
import { TicketNote } from '@/js/Api/TicketNote';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import NoteItem from '@/js/Components/Tickets/NoteItem/NoteItem.vue';
import NoteModal from '@/js/Components/Tickets/NoteModal/NoteModal.vue';
import PanelNotesTicketDetails from './PanelNotesTicketDetails.vue';
import PanelNotesControls from './PanelNotesControls.vue';
import SidebarRightPanel from '../../SidebarRightPanel.vue';

interface Props {
  ticket: Ticket;
  tickets: Ticket[];
}

const props = defineProps<Props>();

const emit = defineEmits(['back']);

const store = useClientStore();

const canEditClient = computed(() => store.client && store.client.canThisUserEdit);

const controlSort = ref('createdAt');
const controlDisplay = ref('expanded');
const controlOnlyOpenFollowups = ref(false);

const createSortFunction = (col: 'updated_at' | 'created_at') => (n1: TicketNote, n2: TicketNote) => {
  const date1 = new Date(n1[col]);
  const date2 = new Date(n2[col]);
  if (date1 < date2) return -1;
  if (date1 > date2) return 1;
  return 0;
};

// Modal
const noteInModal = ref(null);
const NoteModalRef = ref(null);

// Notes
const notesSorted = computed(() => {
  let { notes } = props.ticket;
  if (controlOnlyOpenFollowups.value)
    notes = notes.filter((n) => n.status === 'Open' || n.notes.find((n) => n.status === 'Open'));
  if (controlSort.value === 'updatedAt') notes.sort(createSortFunction('updated_at'));
  if (controlSort.value === 'createdAt') notes.sort(createSortFunction('created_at'));
  return notes;
});

// Open note
const openNote = (clickedNote) => {
  let note = clickedNote;
  if (clickedNote.note_id) note = props.ticket.notes.find((n) => n.id === clickedNote.note_id);
  noteInModal.value = note;
  nextTick(() => NoteModalRef.value.$refs.ModalRef.show());
};

// Resolve ticket
const resolveTicket = (ticket) => {
  TicketApi.update({
    id: ticket.id,
    body: {
      status: 'Resolved',
    },
  }).then(({ data: ticket }) => {
    store.updateTicketStatus(ticket);
  });
};

const notesStatuses = computed(() => {
  return props.ticket.notes.reduce((acc, note) => {
    acc += note.is_followup ? note.status : '';
    acc += note.notes.filter((n) => n.is_followup).reduce((c, n) => c + n.status, '');
    return acc;
  }, '');
});

watch(notesStatuses, async () => {
  // Get ticket status
  const ticketStatus = await TicketApi.getStatus({ id: props.ticket.id })
    .then(({ data }) => data)
    .catch(() => {
      toastError('An error occurred while fetching ticket status.');
    });

  // If user resolved last followup in an open ticket
  if (ticketStatus.status === 'Open' && ticketStatus.open_followups_count === 0) {
    const confirmResult = await confirm({
      icon: 'question',
      allowOutsideClick: false,
      confirmButtonText: 'Resolve ticket',
      text: "This ticket doesn't have any open followups anymore, do you want to close it now?",
    });

    if (confirmResult.isConfirmed) resolveTicket(props.ticket);
  }
});
</script>

<style lang="scss">
.sidebar-right-panel--notes {
  // Back arrow
  &__back {
    position: absolute;
    top: 40%;
    left: 0;
    display: flex;
    font-size: rem(14px);
    color: color('gray');
    display: flex;
    align-items: center;
    padding-left: rem(17px);
    cursor: pointer;

    // Hover
    &:hover {
      color: black;
    }

    &:hover &-icon {
      color: black;
    }

    &-icon {
      color: color('gray');
      margin-right: rem(6px);
    }
  }

  // Divider
  &__divider {
    height: rem(1px);
    margin: rem(10px) 0;
    width: calc(100% + #{rem(44px)});
    transform: translateX(#{rem(-22px)});
    background-color: color('gray', 3);
  }

  // Panel body
  .sidebar-right-panel__body {
    padding-left: rem(20px);
  }
}
</style>
