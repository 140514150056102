import axios, { AxiosError } from 'axios';
import { z } from 'zod';
import * as Sentry from '@sentry/vue';
import { queryBuilderToQueryString } from '@/js/Helpers';

export const InvoiceFeeTypeSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  retail_commission_percentage: z.number(),
  mga_commission_percentage: z.number(),
  show_commission_on_pdf: z.boolean(),
  created_at: z.string(),
  updated_at: z.string(),
  model_type: z.string().nullish(),
});

export type InvoiceFeeType = z.infer<typeof InvoiceFeeTypeSchema>;

type InvoiceFeeTypePayload = {
  id: number;
  form: Omit<InvoiceFeeType, 'id' | 'created_at' | 'updated_at' | 'model_type'>;
  params: Record<string, any>;
};

async function index({ params }: Pick<InvoiceFeeTypePayload, 'params'>) {
  return axios.get(`/api/invoice-fee-types?${queryBuilderToQueryString(params)}`).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });
}

async function create({ form }: Pick<InvoiceFeeTypePayload, 'form'>) {
  const response = await axios.post(`/api/invoice-fee-types`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: InvoiceFeeTypeSchema,
    })
    .parseAsync(response.data);
}

async function find({ id }: Pick<InvoiceFeeTypePayload, 'id'>) {
  const response = await axios.get(`/api/invoice-fee-types/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: InvoiceFeeTypeSchema,
    })
    .parseAsync(response.data);
}
async function update({ id, form }: Omit<InvoiceFeeTypePayload, 'params'>) {
  const response = await axios.put(`/api/invoice-fee-types/${id}`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: InvoiceFeeTypeSchema,
    })
    .parseAsync(response.data);
}

async function destroy({ id }: Pick<InvoiceFeeTypePayload, 'id'>) {
  return axios.delete(`/api/invoice-fee-types/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });
}

export default {
  index,
  create,
  find,
  update,
  destroy,
};
