import { z } from 'zod';
import * as Sentry from '@sentry/vue';
import axios, { AxiosError } from 'axios';

export const ExternalProducerDocumentSchema = z.object({
  id: z.number(),
  external_producer_id: z.number(),
  type: z.string().nullable(),
  license_number: z.string().nullable(),
  valid_until: z.string().nullable(),
  filename: z.string().nullable(),
  created_at: z.string().nullable(),
  updated_at: z.string().nullable(),
});

export type ExternalProducerDocument = z.infer<typeof ExternalProducerDocumentSchema>;

export default {};
