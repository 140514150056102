<template>
  <div :class="{ 'policy-list-item': true, 'policy-list-item--deleted': policy.deleted_at !== null }">
    <!-- Row 1 -->
    <div class="policy-list-item__row">
      <!-- Policy intakes -->
      <div v-if="policy.intakes" class="policy-list-item__row-left">
        <template v-for="(intake, i) in policy.intakes" :key="intake.id">
          <!-- Display intakes only if they are not deleted or the whole policy is deleted -->
          <template v-if="intake.intake_body !== null || policy.deleted_at !== null">
            <div class="policy-list-item__intake">
              <SvgIcon :name="intake.coverage_type.icon_name" />
              {{ intake.coverage_type.name }}
            </div>
            <div v-if="i !== policy.intakes.length - 1" class="policy-list-item__intake-separator"></div>
          </template>
        </template>
      </div>

      <!-- Status circle, cancellation, invoice_total, premium -->
      <div class="policy-list-item__row-right">
        <!-- Renewal status -->
        <div
          class="policy-list-item__renewal-status"
          :class="{ 'policy-list-item__renewal-status--grey': policy.renewed_by_policy }"
        >
          {{ getRenewalStatusText(policy) }}
        </div>

        <!-- Cancellation -->
        <div v-if="policy.cancellation && !policy.cancellation.reinstatement">
          <template v-if="policy.cancellation?.date">
            {{ toDateString(policy.cancellation.date) }}
            &nbsp;
          </template>
          <template v-if="policy.cancellation?.reasons">
            {{ policy.cancellation.reasons }}
            &nbsp;
            <template v-if="policy.cancellation.amount">
              {{ formatCents(policy.cancellation.amount) }}
              &nbsp;
            </template>
          </template>
        </div>

        <StatusCircle :status="status" />

        <div class="policy-list-item__premium">
          {{ policy.premium ? formatCents(policy.premium) : '$TBA' }}
        </div>
      </div>
    </div>

    <!-- Row 2 -->
    <div class="policy-list-item__row">
      <div class="policy-list-item__row-left">
        {{ policy?.carrier?.name }}
        {{ policy.carrier && policy.agency ? '/' : '' }}
        {{ policy?.agency?.name }}
      </div>
      <div class="policy-list-item__row-right">
        <template v-if="policy.effective_date && policy.expiration_date">
          ({{ toDateString(policy.effective_date) }} - {{ toDateString(policy.expiration_date) }})
        </template>
      </div>
    </div>

    <!-- Row 3 -->
    <div class="policy-list-item__row">
      <div class="policy-list-item__row-left">
        <template v-if="policy.billing_type === 'Agency Bill - Financed'">
          {{ policy?.finance_company?.name }}
          {{ policy.finance_account_number ? `(#${policy.finance_account_number})` : '' }}
        </template>
      </div>
      <div class="policy-list-item__row-right">
        <template v-if="policy.number"> #{{ policy.number }} </template>
      </div>
    </div>

    <!-- Row 4 -->
    <div v-if="policy.label" class="policy-list-item__row">
      {{ policy.label }}
    </div>

    <!-- Delete indicator -->
    <div v-if="policy.deleted_at" class="policy-list-item__delete-indicator">Deleted</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import StatusCircle from '@/js/Components/Policies/StatusCircle.vue';
import { Policy } from '@/js/Api/Policy';
import { formatCents, toCurrencyString, toDateString } from '@/js/Helpers';

const props = defineProps<{
  policy: Policy;
}>();

const status = computed(() => props.policy.status);

const getRenewalStatusText = (policy: Policy) => {
  const textArray = [];
  if (policy.renewed_by_policy && policy.renewal_status_as_child)
    textArray.push(`${policy.renewal_status_as_child} by #${policy.renewed_by_policy.number}`);
  else if (policy.renewal_status_as_parent) textArray.push(policy.renewal_status_as_parent);

  if (policy.non_renewed) textArray.push('Non renewed / Final cancel');

  return textArray.join(' | ');
};
</script>

<style lang="scss">
.policy-list-item {
  color: white;
  cursor: pointer;
  font-size: rem(16px);
  border-radius: rem(6px);
  padding: rem(7px) rem(11px);
  background-color: color('primary');
  margin-bottom: rem(10px);
  transition: 0.1s opacity;
  position: relative;

  &--deleted {
    opacity: 0.7 !important;
  }

  &:hover {
    opacity: 0.9;
  }

  &__row,
  &__row-left,
  &__row-right {
    display: flex;
    align-items: center;
  }

  &__row {
    justify-content: space-between;
  }

  // Intakes
  &__intake {
    display: flex;
    align-items: center;
    color: #8ca9cb;
    font-size: rem(12px);

    &-separator {
      width: rem(1px);
      height: rem(15px);
      margin: 0 rem(8px);
      background-color: #8ca9cb;
    }

    .svg-icon {
      width: rem(22px);
      height: rem(22px);
      color: #8ca9cb;
      margin-right: rem(5px);
    }
  }

  // Renewal status
  &__renewal-status {
    margin: 0 0.5em;
    &--grey {
      color: #8ca9cb;
    }
  }

  // Status
  .policy-status-circle {
    margin-right: rem(8px);
  }

  // Premium
  &__premium {
    font-weight: bold;
    font-size: rem(18px);
  }

  // Delete indicator
  &__delete-indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    color: white;
    font-weight: bold;
    background-color: red;
    width: 7em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
