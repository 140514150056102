<template>
  <Modal ref="ModalRef" :is-loading="loading">
    <template #title>{{ financeCompany?.name }}</template>
    <template #footer-btn-text>Close</template>
    <FinanceCompanyShow v-if="financeCompany" :finance-company="financeCompany" />
  </Modal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { toastError } from '@/js/Helpers/Alert';
import FinanceCompanyApi, { FinanceCompany } from '@/js/Api/FinanceCompany';
import Modal from '@/js/Common/Modal.vue';
import FinanceCompanyShow from './Show.vue';

const loading = ref<boolean>(false);
const financeCompany = ref<FinanceCompany | null>(null);
const ModalRef = ref<InstanceType<typeof Modal> | null>(null);

const open = (id: number) => {
  loading.value = true;
  ModalRef.value.show();
  FinanceCompanyApi.find({ id })
    .then(({ data }) => {
      financeCompany.value = data;
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      loading.value = false;
    });
};

defineExpose({
  open,
});
</script>
