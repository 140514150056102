<template>
  <ProgressBar
    v-if="progressSteps"
    class="mg-b-17 large st-16 ss-12"
    :class="[quote.status === 'Not Sold' || quote.status === 'Declined' ? 'disabled' : 'gray']"
    :steps="progressSteps"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ProgressBar from '@/js/Components/ProgressBar.vue';
import { Quote, QuotePastStatus } from '@/js/Api/Quote';
import { STATUSES } from '@/js/Presenters/Quote';

interface Props {
  quote: Quote;
  pastStatuses: QuotePastStatus[];
}

const props = defineProps<Props>();

const progressSteps = computed(() => {
  return STATUSES.filter((status) => status !== 'Not Sold' && status !== 'Declined').map((status) => {
    const isActive = props.quote.status === status;
    const pastStatus = props.pastStatuses.find((s) => s.name === status);
    return {
      title: status,
      active: !!isActive,
      subtitle: pastStatus ? pastStatus.created_at : '',
    };
  });
});
</script>
