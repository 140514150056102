<template>
  <div class="pending-approval-policies">
    <!-- Title -->
    <h1 class="pending-approval-policies__title">Policies pending approval ({{ templatesCount }})</h1>

    <!-- Table -->
    <TableList
      ref="TableListRef"
      :columns="[
        {
          name: 'Requested on',
          value: 'non_renewed_requested_on',
          get: (item) => toDateString(item.non_renewed_requested_on),
        },
        {
          name: 'Requested by',
          value: 'non_renewed_requested_by',
          get: (item) => item.non_renewed_requested_by_user.name,
        },
        {
          name: 'Client name',
          value: 'client.name',
        },
        {
          name: 'Carrier',
          value: 'carrier.name',
        },
        {
          name: 'MGA/Agency',
          value: 'agency.name',
        },
        {
          name: 'Producer',
          get: (item) => item.client.producer?.name,
        },
        {
          name: 'Effective date',
          value: 'effective_date',
          get: (item) => toDateString(item.effective_date),
        },
        {
          name: 'Expiration date',
          value: 'expiration_date',
          get: (item) => toDateString(item.expiration_date),
        },
        {
          name: 'Policy type',
          get: (item) => item.intakes.map((intake) => intake.coverage_type.abbreviation).join(', '),
        },
      ]"
      :api-filters="{ non_renewed_requested: true }"
      :api-sort-order="'asc'"
      :api-sorted-by="'non_renewed_requested_on'"
      :api-endpoint="'/api/policies'"
      :api-includes="['client.producer', 'carrier', 'agency', 'intakes', 'non_renewed_requested_by_user']"
      height-element=".layout__page"
      grid-template-columns="2fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr"
      :new-tab-on-row-click="(policy) => `/clients/${policy.client_id}/policies/${policy.id}`"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { toDateString } from '@/js/Helpers';
import TableList from '@/js/Components/TableList.vue';
import StatusCircle from '@/js/Components/CertificateTemplates/StatusCircle.vue';

const TableListRef = ref<InstanceType<typeof TableList> | null>(null);
const templatesCount = computed(() => TableListRef.value?.currentResponse?.total ?? null);
</script>

<style lang="scss">
.pending-approval-policies {
  &__title {
    color: #000;
    font-size: rem(24px);
    line-height: rem(29px);
    margin-bottom: rem(40px);
  }
}
</style>
