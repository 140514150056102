import { Ticket } from '@/js/Api/Ticket';
import { TicketNote } from '@/js/Api/TicketNote';
import { Ref } from 'vue';

// Open ticket
function openTicket(ticket: Ticket) {
  if (ticket.status === 'Open') return;
  if (!ticket.notes) return;

  let openFollowupCount = 0;

  ticket.notes.forEach((note) => {
    if (note.status === 'Open') openFollowupCount += 1;
    note?.notes?.forEach((subnote) => {
      if (subnote.status === 'Open') openFollowupCount += 1;
    });
  });

  if (openFollowupCount > 0) ticket.status = 'Open';
}

export default (tickets: Ref<Ticket[] | null>) => ({
  deleteNote(note: TicketNote) {
    if (!tickets.value) return;

    const ticketInState = tickets.value.find((t) => t.id === note.ticket_id);

    if (!ticketInState?.notes) return;

    if (note.note_id) {
      const noteInState = ticketInState.notes.find((n) => n.id === note.note_id);
      if (!noteInState?.notes) return;
      const i = noteInState.notes.findIndex((s) => s.id === note.id);
      if (i !== -1) noteInState.notes.splice(i, 1);
    } else {
      const i = ticketInState.notes.findIndex((n) => n.id === note.id);
      if (i !== -1) ticketInState.notes.splice(i, 1);
    }

    // ticketInState.updated_at = note.updated_at;
  },

  addTicket(ticket: Ticket) {
    if (!tickets.value) return;
    tickets.value.push(ticket);
  },

  updateTicket(newTicket: Ticket) {
    if (!tickets.value) return;
    const ticket = tickets.value.find((t) => t.id === newTicket.id);
    if (!ticket) return;
    Object.assign(ticket, newTicket);
    ticket.updated_at = newTicket.updated_at;
  },

  updateTicketStatus(ticket: Ticket) {
    if (!tickets.value) return;
    const ticketInState = tickets.value.find((t) => t.id === ticket.id);
    if (!ticketInState) return;
    ticketInState.status = ticket.status;
    ticketInState.updated_at = ticket.updated_at;
    ticketInState.resolved_on = ticket.resolved_on;
  },

  addNote(note: TicketNote) {
    if (!tickets.value) return;

    const ticket = tickets.value.find((t) => t.id === note.ticket_id);
    if (!ticket?.notes) return;

    if (!note.note_id) {
      ticket.notes.push(note);
      note = ticket.notes.find((n) => n.id === note.id); // @FIXME - note.pulse = false doesn't work without this?
    } else {
      const parentNote = ticket.notes.find((n) => n.id === note.note_id);
      if (!parentNote?.notes) return;
      parentNote.notes.push(note);
      parentNote.unsaved_text = null;
      parentNote.updated_at = note.updated_at;
      note = parentNote.notes.find((n) => n.id === note.id); // @FIXME - note.pulse = false doesn't work without this?
    }

    note.pulse = true;
    setTimeout(() => (note.pulse = false), 4000);

    ticket.updated_at = note.updated_at;
    ticket.status = note.status === 'Open' && ticket.status === 'Resolved' ? 'Open' : ticket.status;

    openTicket(ticket);
  },

  updateNote(note: TicketNote, pulse: boolean = false) {
    if (!tickets.value) return;

    const ticketInState = tickets.value.find((t) => t.id === note.ticket_id);
    if (!ticketInState?.notes) return;

    let targetNote: TicketNote | undefined;

    if (note.note_id) {
      const noteInState = ticketInState.notes.find((n) => n.id === note.note_id);
      if (!noteInState?.notes) return;
      targetNote = noteInState.notes.find((s) => s.id === note.id);
    } else {
      targetNote = ticketInState.notes.find((n) => n.id === note.id);
    }

    if (!targetNote) return;

    Object.assign(targetNote, note);

    if (pulse) {
      targetNote.pulse = true;
      setTimeout(() => (targetNote.pulse = false), 4000);
    }

    ticketInState.updated_at = note.updated_at;
    openTicket(ticketInState);
  },

  updateNoteProperty({ note, property, value }: { note: TicketNote; property: keyof TicketNote; value: any }) {
    const noteCopy = { ...note };
    noteCopy[property] = value;
    this.updateNote(noteCopy);
  },

  addOrUpdateNote(note: TicketNote, pulse: boolean = false) {
    if (!tickets.value) return;
    const ticket = tickets.value.find((t) => t.id === note.ticket_id);
    if (!ticket?.notes) return;
    const noteExists = !!ticket.notes.find((n) => n.id === note.id);

    if (!noteExists) this.addNote(note);
    else this.updateNote(note, pulse);
  },

  transferNote({ note, toTicket, fromTicketId }: { note: TicketNote; toTicket: Ticket; fromTicketId: number | null }) {
    if (!tickets.value) return;

    const toTicketInState = tickets.value.find((t) => t.id === toTicket.id);

    if (!toTicketInState) tickets.value.push(toTicket);
    else Object.assign(toTicketInState, toTicket);

    note.pulse = true;
    setTimeout(() => (note.pulse = false), 6000);

    if (!fromTicketId) return;

    const fromTicketInState = tickets.value.find((t) => t.id === fromTicketId);
    if (!fromTicketInState?.notes) return;

    const i = fromTicketInState.notes.findIndex((n) => n.id === note.id);
    if (i === -1) return;

    fromTicketInState.notes.splice(i, 1);
    fromTicketInState.updated_at = note.updated_at;
  },
});
