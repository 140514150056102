<template>
  <div class="user-circle">
    <Tooltip v-if="fullName" show-on="hover">{{ fullName }}</Tooltip>
    <span class="user-circle__text">
      {{ initials.length > 2 ? initials.slice(0, 2) : initials }}
    </span>
  </div>
</template>

<script>
import Tooltip from '@/js/Common/Tooltip.vue';

export default {
  components: {
    Tooltip,
  },
  props: {
    initials: {
      type: String,
      required: true,
    },
    fullName: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.user-circle {
  // Dimensions
  min-width: rem(29px);
  min-height: rem(29px);

  // Alignment
  display: flex;
  align-items: center;
  justify-content: center;

  // Font
  font-size: rem(15px);

  // Color
  color: white;
  background-color: color('primary');

  // Border
  border-radius: 50%;

  // Hover
}
</style>
