<template>
  <div class="quote-groups-create">
    <!-- Coverage types -->
    <SelectCoverages v-model:coverage-type-ids="coverageTypeIds" :columns="4" />

    <div class="quote-groups-create__divider"></div>

    <!-- Submit btn -->
    <Btn class="quote-groups-create__btn" @click="onComplete">Complete</Btn>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref } from 'vue';
import useClientStore from '@/js/store/pinia/useClientStore';
import { useRouter } from 'vue-router';
import { toast } from '@/js/Helpers/Alert';
import Btn from '@/js/Components/Btn.vue';
import Loader from '@/js/Helpers/Loader';
import QuoteGroupApi from '@/js/Api/QuoteGroup';
import SelectCoverages from '@/js/Common/Form/SelectCoverages.vue';

const props = defineProps<{
  clientId: number;
}>();

const store = useClientStore();

const router = useRouter();

const coverageTypeIds: Ref<number[]> = ref([]);

const onComplete = () => {
  if (!coverageTypeIds.value.length) return;

  Loader.start();
  QuoteGroupApi.create({ clientId: props.clientId, coverageTypeIds: coverageTypeIds.value })
    .then(({ quoteGroup, note }) => {
      store.addOrUpdateNote(note, true);
      store.currentTicketId = note.ticket_id;
      router.push({ path: `/clients/${props.clientId}/quotegroups/${quoteGroup.id}/edit` });
    })
    .catch(() => {
      toast({ title: 'An error has occurred while creating a quote group.', icon: 'error' });
    })
    .finally(() => {
      Loader.end();
    });
};
</script>

<style lang="scss">
.quote-groups-create {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__btn {
    align-self: flex-end;
  }

  &__divider {
    width: 100%;
    height: 1px;
    background-color: color('gray', 3);
    margin: 1em 0;
  }

  // Coverage types
  .select-coverages {
    font-size: 1.1em;
    &__submit-container {
      display: none;
    }
  }
}
</style>
