<template>
  <FormContainer
    ref="FormContainerRef"
    :class="{
      'update-followup-form': true,
      [`update-followup-form--${type}`]: true,
      'update-followup-form--disabled': isDisabled,
    }"
    @submit="submit"
  >
    <!-- Created by, assigned to (big) -->
    <div v-if="type === 'big'" class="update-followup-form__users">
      <!-- Created by -->
      <div v-if="me.permissions['ticket_notes.created_by_id.update']" class="update-followup-form__user">
        <UserCircle :initials="noteLocal.created_by.initials" />
        <CreatedByInput v-model:note="noteLocal" :disabled="isDisabled" />
      </div>
      <div v-else class="update-followup-form__user update-followup-form__user--created-by">
        <UserCircle :initials="noteLocal.followup_created_by.initials" />
        <FormGroupDisplay :label="`Created (${createdOn})`" theme="text">
          {{ noteLocal.followup_created_by.name }}
        </FormGroupDisplay>
      </div>

      <!-- Arrow -->
      <SvgIcon class="update-followup-form__assign-arrow" name="arrow-right" />

      <!-- Assignee -->
      <div class="update-followup-form__user">
        <UserCircle :initials="noteLocal.assigned_to.initials" />
        <AssigneeInput v-model:note="noteLocal" :disabled="isDisabled" />
      </div>
    </div>

    <!-- Assignee (small) -->
    <AssigneeInput v-if="type === 'small'" v-model:note="noteLocal" :disabled="isDisabled" />

    <div class="update-followup-form__separator"></div>

    <!-- Due (big) -->
    <div v-if="type === 'big'" class="update-followup-form__due">
      <!-- Date and calendar -->
      <FormGroupDisplay label="Due" theme="text">
        {{ new Date(noteLocal.due).toDateSlash() }}
        <input
          v-if="!isDisabled"
          :value="noteLocal.due"
          type="date"
          class="update-followup-form__due-calendar"
          @input="
            (e) => {
              noteLocal.due = e.target.value;
              autosave('due', e.target.value);
            }
          "
        />
      </FormGroupDisplay>

      <!-- Move to tomorrow -->
      <Btn
        v-if="!isDisabled && !isDueTomorrow"
        class="update-followup-form__tomorrow-btn"
        @click="
          () => {
            noteLocal.due = new Date().addDays(1).toDateInput();
            autosave('due', noteLocal.due);
          }
        "
      >
        Move to<br />Tomorrow
      </Btn>
    </div>

    <!-- Due (small) -->
    <button v-if="type === 'small'" class="update-followup-form__due" :disabled="isDisabled">
      <span
        class="update-followup-form__due-text"
        :class="{ 'update-followup-form__due-text--date': /\d/.test(dateTextSmall) }"
      >
        {{ dateTextSmall }}
      </span>
      <span class="update-followup-form__due-arrow"> 🢓 </span>
      <input
        v-model="noteLocal.due"
        type="date"
        class="update-followup-form__due-calendar"
        :disabled="isDisabled"
        @update:model-value="(due) => autosave('due', due)"
      />
    </button>

    <div class="update-followup-form__separator"></div>

    <!-- Priority picker -->
    <PriorityPicker
      v-model="noteLocal.priority"
      :weight="noteLocal.weight"
      :disabled="isDisabled"
      @update:model-value="(value) => autosave('priority', value)"
    />

    <div class="update-followup-form__separator"></div>

    <!-- Callback -->
    <div class="update-followup-form__callback">
      <SvgIcon
        name="callback"
        :class="{
          'svg-icon--active': noteLocal.callback,
        }"
        :disabled="isDisabled"
        @click="() => autosave('callback', !noteLocal.callback)"
      />
    </div>

    <div class="update-followup-form__separator"></div>

    <!-- Watch list -->
    <WatchList v-if="!isDisabled" :key="noteLocal.updated_at" v-model:note="noteLocal" />

    <!-- Watch tooltip -->
    <WatchTooltip v-else :watchers="noteLocal.watchers" />

    <div class="update-followup-form__separator"></div>

    <!-- Status -->
    <Dropdown
      placement="bottom-start"
      :disabled="isDisabled"
      :class="{
        'dropdown--status': true,
        'update-followup-form__status': true,
        'update-followup-form__status--disabled': isDisabled,
      }"
    >
      <template #trigger>
        <FormGroupDisplay label="Status" theme="text" :class="`form-group-display--${noteLocal.status.toLowerCase()}`">
          {{ noteLocal.status }}
          <SvgIcon v-if="noteLocal.status === 'Resolved'" name="checkmark-circle" />
        </FormGroupDisplay>
      </template>
      <template #menu>
        <a
          v-if="noteLocal.status === 'Open'"
          class="dropdown-item"
          @click="
            () => {
              noteLocal.status = 'Resolved';
              autosave('status', 'Resolved');
            }
          "
        >
          Resolve followup
        </a>
        <a
          v-if="noteLocal.status === 'Resolved'"
          class="dropdown-item"
          @click="
            () => {
              noteLocal.status = 'Open';
              autosave('status', 'Open');
            }
          "
        >
          Open followup
        </a>
      </template>
    </Dropdown>
  </FormContainer>
</template>

<script setup>
import { computed } from 'vue';
import { me } from '@/js/Helpers/Session';
import { toDateString } from '@/js/Helpers';
import TicketNoteApi from '@/js/Api/TicketNote';
import Btn from '@/js/Components/Btn.vue';
import Dropdown from '@/js/Common/Dropdown.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import UserCircle from '@/js/Components/UserCircle.vue';
import WatchList from '@/js/Components/Tickets/WatchList.vue';
import WatchTooltip from '@/js/Components/Tickets/WatchTooltip.vue';
import PriorityPicker from '@/js/Components/Tickets/PriorityPicker.vue';
import FormGroupDisplay from '@/js/Common/Form/FormGroupDisplay.vue';
import FormContainer from '@/js/Common/Form/FormContainer.vue';
import AssigneeInput from '@/js/Components/TicketNotes/AssigneeInput.vue';
import CreatedByInput from '@/js/Components/TicketNotes/CreatedByInput.vue';

const props = defineProps({
  type: {
    type: String,
    default: 'small',
    validator: (value) => ['small', 'big'].includes(value),
  },
  note: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:note', 'submit']);

const createdOn = computed(() => toDateString(props.note.followup_created_at));

// Local state
const noteLocal = computed({
  get: () => ({ ...props.note }),
  set: (note) => emit('update:note', note),
});

const isDisabled = computed(() => props.disabled || !props.note.permissions.edit);

const autosave = (field, value) => {
  TicketNoteApi.autosave({ id: props.note.id, name: field, value }).then(({ data }) => {
    noteLocal.value = data;
  });
};

// Is tomorrow
const isDueTomorrow = computed(() => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const due = new Date(noteLocal.value.due);
  return (
    due.getDate() === tomorrow.getDate() &&
    due.getMonth() === tomorrow.getMonth() &&
    due.getFullYear() === tomorrow.getFullYear()
  );
});

// Date text small
const MS_PER_DAY = 1000 * 60 * 60 * 24;
const dateDiffDays = (a, b) => {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

const dateTextSmall = computed(() => {
  const due = new Date(noteLocal.value.due);
  const diffDays = dateDiffDays(new Date(), due);
  switch (diffDays) {
    case 0:
      return 'TODAY';
    case 1:
      return 'TOMORROW';
    case -1:
      return 'YESTERDAY';
    default:
      return new Date(noteLocal.value.due).toDateSlash();
  }
});

const submit = () => {
  emit('submit', {
    is_followup: true,
    due: noteLocal.value.due,
    callback: noteLocal.value.callback,
    priority: noteLocal.value.priority,
    assignee_id: noteLocal.value.assigned_to_id,
    assignee_type: 'User',
    watcher_ids: noteLocal.value.watchers.map((w) => w.id),
  });
};
</script>

<style lang="scss">
.update-followup-form {
  display: flex;
  align-items: center;

  &--big {
    height: rem(60px);
  }

  // Separator
  &__separator {
    content: '';
    display: block;
    min-width: rem(1px);
    min-height: rem(28px);
    background-color: color('gray', 3);
  }

  &--big &__separator {
    margin: 0 rem(15px);
  }

  &--small &__separator {
    margin: 0 rem(10px);
  }

  .form-group-display {
    width: 100%;
    margin-bottom: 0;
    background: unset;
  }

  // Users (big)
  &--big &__users {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &--big &__user {
    flex: 1;
    display: flex;
    align-items: center;

    .user-circle {
      margin-right: rem(9px);
    }
  }

  &--big &__assign-arrow {
    color: #c3c3c3;
    margin: 0 rem(5px);
  }

  // Assign (small)
  &--small .dropdown--assign {
    flex: 1;

    .form-group-display__content {
      white-space: nowrap;
    }
  }

  // Due (big)
  &--big &__due {
    display: flex;
    align-items: center;

    &-calendar {
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 135px;
      opacity: 0;
    }

    .update-followup-form__tomorrow-btn {
      margin-left: rem(14px);
      font-size: rem(12px);
      background-color: white;
      color: color('gray');
      border: rem(1px) solid color('gray');
      border-radius: rem(5px);
      padding: rem(3px) rem(5px);
      white-space: normal;
      line-height: 1.15;

      &:hover {
        color: white;
        background-color: color('gray');
      }
    }

    .form-group-display {
      position: relative;
    }
  }

  // Due (small)
  &--small &__due {
    border: none;
    outline: none;
    position: relative;
    padding: rem(4px) rem(7px);
    padding-right: rem(20px);
    border-radius: rem(5px);
    background-color: color('gray', 3);

    &-calendar {
      top: 0;
      left: 0;
      opacity: 0;
      position: absolute;
      height: 100%;
      max-width: 100%;
      border: none;
      outline: none;
      font-size: 100px;
    }

    &-text {
      font-weight: 700;
      font-size: rem(12px);
      color: color('gray', 7);

      &--date {
        letter-spacing: rem(0.5px);
      }
    }

    &-arrow {
      top: rem(6px);
      right: rem(5px);
      position: absolute;
      margin-left: rem(5px);
      color: color('gray', 7);
      font-size: rem(20px);
    }
  }

  // Callback
  &__callback {
    display: flex;

    .svg-icon {
      color: #c3c3c3;

      &--active {
        color: #5ac10e;
      }
    }

    &:not(&--disabled) .svg-icon {
      cursor: pointer;
    }
  }

  // Watch list
  .dropdown--watch {
    display: flex;
    align-items: center;
  }

  // Status
  &__status {
    flex: 0.2;

    .form-group-display {
      position: relative;

      &--resolved {
        .form-group-display__content {
          color: #5ac10e;
        }
      }

      .svg-icon {
        color: #5ac10e;
        margin-left: rem(5px);
      }
    }

    .dropdown {
      width: 100%;

      &__trigger {
        width: 100%;
      }

      &__menu {
        border: rem(1px) solid color('gray', 3);
      }

      &__item {
        color: black;
        padding-top: rem(15px);
        padding-bottom: rem(15px);
        font-size: rem(14px);
      }
    }
  }
}
</style>
