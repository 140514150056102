<template>
  <div class="policy-header">
    <div class="flex-align-start flex-between">
      <!-- Left box -->
      <div class="flex-align-center policy-header__left-box">
        <!-- Coverages -->
        <div class="flex-align-center">
          <div class="policy-header__left-box-coverages">
            <template v-if="nonEmptyIntakes.length === 0"> No coverages </template>
            <template v-else>
              <template v-for="(intake, i) in nonEmptyIntakes" :key="intake.id">
                <span>{{ intake.coverage_type.abbreviation }}</span>
                <SvgIcon v-if="intake.intake_body" :name="intake.coverage_type.icon_name" class="svg-icon--coverage" />
                <SvgIcon v-if="i !== nonEmptyIntakes.length - 1" name="plus" />
              </template>
            </template>
          </div>
        </div>

        <!-- Divider -->
        <div class="divider"></div>

        <!-- Attachments button -->
        <a v-if="inAcordView" class="policy-header__left-box-attachments-btn link" @click="emit('click:attachments')">
          <SvgIcon name="paperclip" />
          Policy Attachments
        </a>
        <router-link
          v-else
          :to="{
            name: 'ClientAttachments',
            query: { policy: policy.id },
            params: { clientId: policy.client_id },
          }"
          class="policy-header__left-box-attachments-btn link"
        >
          <SvgIcon name="paperclip" />
          Policy Attachments
        </router-link>

        <!-- divider -->
        <!-- <div class="divider"></div> -->

        <!-- <a class="link attachments flex" @click="createInvoice"> -->
        <!--   <img src="/img/icons/attach_file-24px.svg" /> -->
        <!--   Create Invoice -->
        <!-- </a> -->
      </div>

      <!-- Policy status -->
      <PolicyStatus :policy="policy" @update:policy="(policy) => emit('update:policy', policy)" />
    </div>

    <!-- to processing -->
    <Btn v-if="!inAcordView" variant="outline" @click="goToProcessing"> To Processing </Btn>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { Policy } from '@/js/Api/Policy';
import InvoiceApi from '@/js/Api/Invoice';
import Btn from '@/js/Components/Btn.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import PolicyStatus from './PolicyStatus.vue';

interface PolicyWithIntakes extends Policy {
  intakes: Exclude<Policy['intakes'], undefined>;
}

interface Props {
  policy: PolicyWithIntakes;
  inAcordView?: boolean;
}

interface Emits {
  (e: 'click:attachments'): void;
  (e: 'update:policy', policy: Policy): void;
}

const props = withDefaults(defineProps<Props>(), {
  inAcordView: false,
});

const emit = defineEmits<Emits>();

const router = useRouter();

const goToProcessing = () => router.push(`/clients/${props.policy.client_id}/policies/${props.policy.id}/processing`);

const createInvoice = async () => {
  const clientId = props.policy.client_id;

  const res = await InvoiceApi.create({
    clientId,
    form: { policy_id: props.policy.id },
  });

  if (res) {
    router.push({
      name: 'ClientInvoice',
      params: { id: clientId, invoiceId: res.data.id },
    });
  }
};

const nonEmptyIntakes = computed(() => props.policy.intakes.filter((intake) => intake.intake_body));
</script>

<style lang="scss">
.policy-header {
  // Left box
  &__left-box {
    display: flex;
    align-items: center;
    border-radius: rem(6px);
    padding: rem(11px) rem(15px);
    background-color: white;
    margin-right: rem(23px);

    &-coverages {
      display: flex;
      align-items: center;
      margin-right: rem(17px);
      .svg-icon {
        transform: translateY(rem(-1.5px));
      }
      .svg-icon--plus {
        margin: 0 rem(10px);
      }
      .svg-icon--coverage {
        margin-left: rem(5px);
      }
    }

    .divider {
      width: rem(1px);
      background-color: #cccccc;
      margin: 0 rem(12px);
      height: rem(29px);
    }

    &-attachments-btn {
      color: #1a497f;
      font-size: rem(16px);
      line-height: rem(16px);
      display: flex;
      align-items: center;
      .svg-icon {
        margin-right: rem(2px);
      }
    }
  }
}
</style>
