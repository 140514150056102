import { z } from 'zod';
import * as Sentry from '@sentry/vue';
import axios, { AxiosError } from 'axios';
import { CoverageTypeSchema } from './CoverageType';
import { IntakeCASchema } from './IntakeCA';
import { IntakeCPSchema } from './IntakeCP';
import { IntakeHOSchema } from './IntakeHO';
import { IntakeWCSchema } from './IntakeWC';
import { IntakeCGLSchema } from './IntakeCGL';
import { IntakeGenericSchema } from './IntakeGeneric';

const IntakeBodySchema = z.union([
  IntakeCASchema,
  IntakeCPSchema,
  IntakeHOSchema,
  IntakeWCSchema,
  IntakeCGLSchema,
  IntakeGenericSchema,
]);

export const IntakeSchema = z.object({
  id: z.number(),
  policy_id: z.number(),
  intake_body_type: z.string(),
  intake_body_id: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  coverage_type: CoverageTypeSchema,
  intake_body: IntakeBodySchema.nullish(),
});

export type Intake = z.infer<typeof IntakeSchema>;

async function softDelete({ intakeId, intakeType }: { intakeId: number; intakeType: string }) {
  const response = await axios
    .post(`/api/intakes/${intakeId}/${intakeType}`, {
      _method: 'DELETE',
    })
    .catch((error: AxiosError) => {
      Sentry.captureException(error);
      throw error;
    });

  return z.literal('').parseAsync(response.data);
}

async function restore({ intakeId, intakeType }: { intakeId: number; intakeType: string }) {
  const response = await axios
    .post(`/api/intakes/${intakeId}/${intakeType}/restore`, {
      _method: 'PUT',
    })
    .catch((error: AxiosError) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: IntakeBodySchema,
    })
    .parseAsync(response.data);
}

export default {
  softDelete,
  restore,
};
