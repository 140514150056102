<template>

  <!-- Email address -->
  <FormGroup
    v-model.uppercase="emailLocal.email"
    type="email"
    name="email"
    label-text="Email"
    :disabled="disabled"
    :input-style="{ 'text-transform': 'uppercase' }"
    @update:model-value="emitUpdateDebounced"
  >
    <template #label-append>
      <img v-if="email.is_primary" class="icon verified" src="/img/icons/verified_blue-24px.svg"/>
    </template>
  </FormGroup>

  <!-- Email description -->
  <FormGroup
    v-model.uppercase="emailLocal.description"
    type="text"
    name="description"
    :disabled="disabled"
    label-text="Email description"
    @update:model-value="emitUpdateDebounced"
  />

</template>

<script>
import { watch, ref } from 'vue';
import debounce from 'debounce';
import FormGroup from '@/js/Common/Form/FormGroup/FormGroup.vue';

export default {

  components: {
    FormGroup
  },

  props: {
    email: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },

  emits: [
    'update:email'
  ],

  setup(props, { emit }) {

    // Email (local copy)
    const emailLocal = ref({ ...props.email });

    // Override local copy when props change
    watch(() => props.email, () => emailLocal.value = { ...props.email });

    // Emit event
    const
      emitUpdate = () => emit('update:email', emailLocal.value),
      emitUpdateDebounced = debounce(emitUpdate, 1000);



    return {
      emailLocal,
      emitUpdate,
      emitUpdateDebounced
    };

  }

};
</script>
