<template>
  <div class="watch-tooltip">
    <SvgIcon name="eye" :color="watchers.length ? '#1A497F' : '#CCCCCC'" />
    <Tooltip show-on="hover" :offset="[0, 5]" placement="bottom" strategy="absolute">
      <template v-if="!watchers.length"> No watchers </template>
      <template v-else>
        <template v-for="user in watchers" :key="user.id">
          {{ user.name }}
          <br />
        </template>
      </template>
    </Tooltip>
  </div>
</template>

<script>
import Tooltip from '@/js/Common/Tooltip.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';

export default {
  components: {
    Tooltip,
    SvgIcon,
  },
  props: {
    watchers: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.watch-tooltip {
  display: flex;
}
</style>
