<template>
  <div>
    <SlidePanel :is-active="true">
      <!-- Header -->
      <template #header-left>
        <div class="slide-panel__header-left">
          <img class="slide-panel__header-left-icon" src="/img/icons/lightbulb.svg" />
          <h5 class="slide-panel__header-left-text">Quote suggestions</h5>

          <div v-if="suggestionLoading" style="margin-left: 10px">
            <i class="fa fa-spinner fa-spin" />
          </div>
        </div>
      </template>

      <div>
        <div v-for="(quote, index) in quotes" :key="index" class="quote row-2-3">
          <!-- Left column -->
          <div class="quote-left row-1-2-100x-0_1" :class="quote.status === 'QUOTED' ? 'status-quoted' : ''">
            <div class="quote-title">{{ quote.title }}</div>

            <!-- Quote icons and titles -->
            <div class="quote-icons flex">
              <!-- Icons and titles container -->
              <div v-for="(coverage, i) in quote.coverages" :key="i" class="flex">
                <i :class="`fas fa-${coverage.icon}`"></i>
                <i v-if="i !== quote.coverages.length - 1" class="fas fa-plus light"></i>

                <div class="quote-icons__title flex">
                  <h5>{{ coverage.title }}</h5>
                  <i v-if="i !== quote.coverages.length - 1" class="fas fa-plus"></i>
                </div>
              </div>
            </div>

            <Btn
              v-if="me.permissions['quote.start']"
              :loading="loading"
              class="quote-status flex"
              variant="outline"
              style="border: none; background: transparent"
              @click="createQuote"
            >
              <span v-if="!loading">New Quote</span>
              <i v-if="!loading" class="fas fa-plus-circle add-new flex"></i>
            </Btn>

            <!-- Quote add new button -->
          </div>

          <!-- Right column -->
          <div class="quote-right">
            <div v-if="quote.status === 'START' && props.quoteGroup?.cover_whale_quote_id">
              <Btn variant="outline" @click="fetchCoverWhaleQuote">
                Pull Existing Quote <i class="fa-solid fa-rotate-right"></i>
              </Btn>
            </div>
            <div v-else class="flex">
              <a
                class="quote-status"
                :href="`https://uat.coverwhale.dev/transportation/${quote.submission_number}`"
                target="_blank"
              >
                {{ quote.status === 'START' ? '' : quote.status }}
                <i v-if="quote.status === 'Quoted'" class="fas fa-check-double" style="color: #55ba3d"></i>
                <i v-if="quote.status === 'Declined'" class="fas fa-times" style="color: #c31919"></i>
              </a>
            </div>

            <div>
              <h5>Premium</h5>
              <p>{{ currency(quote.premium).format() }}</p>
            </div>

            <button v-if="errors.length" class="icon-btn" style="margin-left: 10px" @click="toggleShowErrors">
              <i class="fas fa-exclamation-circle" style="color: #c31919"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Errors -->
      <div v-if="showErrors" class="errors">
        <ul v-for="(err, index) in errors" :key="index" style="display: flex">
          <li style="color: red">{{ err }}</li>
        </ul>
      </div>
    </SlidePanel>
  </div>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue';
import currency from 'currency.js';
import SlidePanel from '@/js/Components/SlidePanel.vue';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import Btn from '@/js/Components/Btn.vue';
import { me } from '@/js/Helpers/Session';
import { QuoteGroup } from '@/js/Api/QuoteGroup';

type Props = {
  quoteGroup: QuoteGroup;
};

const clientID = inject('clientID');
const props = defineProps<Props>();
const showErrors = ref(false);
const errors = ref([]);

const toggleShowErrors = () => {
  showErrors.value = !showErrors.value;
};

const suggestionLoading = ref(false);
const loading = ref(false);

const quotes = ref({
  coverWhale: {
    title: 'CoverWhale',
    coverages: [{ title: 'Commercial Auto', icon: 'truck' }],
    status: 'START',
    submission_number: '',
    premium: 0,
  },
});

// On mount, check if we have quoteGroup.cover_whale_quote_id, if we do, fetch the quote from cover whale
// and add it to the quotes array
function fetchCoverWhaleQuote() {
  if (props.quoteGroup && props.quoteGroup.cover_whale_quote_id) {
    suggestionLoading.value = true;
    axios
      .get(`/api/cover-whale/${props.quoteGroup.cover_whale_quote_id}`)
      .then(({ data }) => {
        let total = 0;

        Object.keys(data.coverages).forEach((key) => {
          if (data.coverages[key] && data.coverages[key].premium) {
            total += Number.parseFloat(data.coverages[key].premium);
          }
        });

        quotes.value.coverWhale = {
          title: 'CoverWhale',
          coverages: [{ title: 'Commercial Auto', icon: 'truck' }],
          status: data.submissionStatus.status,
          submission_number: data.submissionStatus.submission_number,
          premium: total,
        };
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        suggestionLoading.value = false;
      });
  }
}

async function createQuote() {
  loading.value = true;
  errors.value = [];

  axios
    .post(`/api/cover-whale`, {
      client_id: clientID.value,
      quote_group_id: props.quoteGroup.id,
    })
    .then(({ data }) => {
      quotes.value.coverWhale.status = data.status;
      quotes.value.coverWhale.submission_number = data.submission_number;

      let total = 0;

      if (data.coverages) {
        Object.keys(data.coverages).forEach((key) => {
          if (data.coverages[key] && data.coverages[key].totalCost) {
            total += data.coverages[key].totalCost;
          }
        });
      }

      if (data.reason) {
        // Reason is an error object, with 0, 1, 2, and so on keys, plus notes keys
        // First remove notes key, then get the values of the rest of the keys
        delete data.reason.notes;

        errors.value = Object.values(data.reason);
      }

      quotes.value.coverWhale.premium = total;
    })
    .catch((error) => {
      loading.value = false;
      const responseData = error.response.data;

      if (responseData.type === 'APIException') {
        errors.value = [responseData.error];
      } else if (responseData.type === 'ValidationException') {
        errors.value = responseData.errors;
      } else {
        errors.value = ['Something went wrong. Please try again.'];
      }

      Sentry.captureException(error);
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>

<style lang="scss" scoped>
.slide-panel__header-left {
  &-text {
    color: #b79b08;
  }

  &-icon {
    color: #b79b08;
  }
}

:deep(.slide-panel) {
  border-color: #b79b08;

  .info {
    color: #b79b08;
  }

  &.active {
    background: #fdf7db;
    border-color: transparent;

    input:checked + .slider {
      background-color: #5d500e;
    }
  }
}

.row-2-3 {
  column-gap: 1.5rem;
}

.quote {
  margin-bottom: rem(7px);

  .quote-status {
    display: flex;
    white-space: nowrap;
    padding-left: rem(10px);
    font-size: rem(14px);
    justify-content: flex-start;
    align-items: center;
    color: #1a497f;
    cursor: pointer;

    i {
      margin-left: rem(6px);
      line-height: 16px;
    }
  }

  .row-1-2-100x-0_1 {
    display: grid;
    grid-column-gap: 0.3rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr) 100px minmax(0, 0.1fr);
  }

  /* QUOTE LEFT COLUMN */
  .quote-left {
    border: 1px solid #e0d8b2;
    border-radius: rem(6px);
    align-self: center;
    padding: rem(9px) 0 rem(9px) rem(13px);
    background: white;

    &.status-quoted {
      background: transparent;
    }

    .quote-title {
      width: 98%;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: rem(14px);
      transform: translateY(4px);
    }

    .add-new {
      place-self: center;
      height: rem(20px);
      width: rem(20px);
      margin-left: rem(13px);
      font-size: rem(20px);
      border-radius: 50%;
      color: #1a497f;
      background: white;
    }

    .quote-icons {
      overflow: hidden;
      display: flex;
      justify-content: flex-end;

      > div {
        background: #ececec;
        border-radius: rem(6px);
        padding: rem(6px) rem(8px);
        width: fit-content;
        justify-content: flex-start;
      }

      i {
        color: orange;

        &.fa-plus {
          color: #999;
          font-size: rem(8px);
          padding: 0 rem(1px);
          margin-bottom: rem(-5px);

          &.light {
            color: #ccc;
          }
        }

        &:not(.fa-plus) {
          margin-right: rem(5px);
        }

        &.fa-truck {
          color: #8b8bdb;
        }
      }

      h5 {
        color: #999;

        &:first-of-type {
          margin-left: rem(3px);
        }
      }
    }
  }

  /* QUOTE RIGHT COLUMN */
  .quote-right {
    display: grid;
    grid-template-columns: 1fr auto 40px;
    align-self: center;
    padding: rem(7px) 0 rem(7px) rem(13px);
    position: relative;

    .flex-column {
      display: flex;
      justify-content: space-between;
    }

    .details {
      display: flex;

      .flex-column:not(:last-of-type) {
        p::after {
          content: '|';
          color: #ccc;
          padding-left: rem(5px);
        }
      }
    }

    .historical {
      display: flex;
      justify-content: flex-end;
      width: rem(500px);
      padding-right: rem(7px);

      .flex-column {
        margin-right: rem(10px);
      }
    }

    h5 {
      color: #84783f;
      font-size: rem(12px);
      line-height: rem(16px);
    }

    p {
      margin-bottom: 0;
      padding-right: rem(5px);
      font-size: rem(16px);
      line-height: rem(18px);
      color: #000;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .quote-icons__title {
    display: none;
  }
}

.icon-btn {
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
