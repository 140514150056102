<template>
  <div :class="{ timer: true, timer__expanded: isExpanded }">
    <!-- Arrow -->
    <div v-if="me.permissions['view-additional-timer-data']" class="timer__arrow" @click="isExpanded = !isExpanded">
      <div class="icon"></div>
    </div>

    <!-- Clock icon -->
    <div class="icon-col">
      <img src="/img/icons/timer_black_24dp.svg" />
    </div>

    <!-- Session -->
    <div class="divider"></div>
    <div class="col session">
      <span class="desc">Session</span>
      <span class="value">{{ timeSessionString }}</span>
    </div>

    <!-- Today -->
    <div class="divider"></div>
    <div :class="{ col: true, today: true, red: timeTodaySeconds > 1800 }">
      <span class="desc">Time today</span>
      <span class="value">{{ timeTodayString }}</span>
    </div>

    <template v-if="me.permissions['view-additional-timer-data']">
      <!-- Total -->
      <div class="divider"></div>
      <div class="col total">
        <span class="desc">Total time</span>
        <span class="value">{{ timeTotalString }}</span>
      </div>

      <!-- Client cost -->
      <div class="divider"></div>
      <div class="col cost">
        <span class="desc">Client cost</span>
        <span class="value">${{ clientCostString }}</span>
      </div>
    </template>

    <!-- Inactivity modal -->
    <dialog ref="ModalRef" class="timer-dialog" @close="onModalHidden">
      <div class="timer-dialog__wrapper">
        <header class="timer-dialog__header">
          <h4>Are you still working on this {{ parentType }}?</h4>

          <Btn variant="ghost" icon @click="ModalRef.close()">
            <i class="fas fa-times" />
          </Btn>
        </header>

        <main class="timer-dialog__main">
          <p>Confirm to unfreeze time on the {{ parentVerbiageMap[parentType] }}.</p>
        </main>

        <footer class="timer-dialog__footer">
          <Btn class="timer__include-time-btn mr-2" variant="outline" @click="includeInactiveTime">
            Include inactive time
          </Btn>

          <Btn @click="ModalRef.close()">Resume</Btn>
        </footer>
      </div>
    </dialog>
  </div>
</template>

<script setup>
import axios from 'axios';
import { computed, watch, onMounted, onBeforeUnmount, ref } from 'vue';
import { useStore } from 'vuex';
import { onBeforeRouteUpdate } from 'vue-router';
import { useIdle } from '@vueuse/core';
import Btn from '@/js/Components/Btn.vue';
import { me } from '@/js/Helpers/Session';
import { toast } from '@/js/Helpers/Alert';
import useClientStore from '@/js/store/pinia/useClientStore';

const props = defineProps({
  parent: {
    type: Object,
    required: true,
  },
  parentId: {
    type: Number,
    required: true,
  },
  parentType: {
    type: String,
    required: true,
  },
});

const isExpanded = ref(false);

const parentVerbiageMap = {
  client: 'account',
  followup: 'followup',
};

const store = useStore();
const ModalRef = ref(null);

// Seconds -> HH:MM:SS
const secondsToHHMMSS = (totalSeconds) => {
  if (totalSeconds == null) return null;

  let modulo = null;
  let hours = Math.floor(totalSeconds / 3600);
  modulo = totalSeconds % 3600;
  let minutes = Math.floor(modulo / 60);
  let seconds = modulo % 60;

  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;

  const result = `${hours !== '00' ? `${hours}:` : ''}${minutes}:${seconds}`;

  return result;
};

// Session time
const timeSessionSeconds = ref(0);
const timeSessionString = computed(() => secondsToHHMMSS(timeSessionSeconds.value));

// Time today
const timeTodaySeconds = ref(0);
const timeTodayString = computed(() => secondsToHHMMSS(timeTodaySeconds.value));

// Total time
const timeTotalSeconds = ref(0);
const timeTotalString = computed(() => secondsToHHMMSS(timeTotalSeconds.value));

// Client cost
const clientCost = ref(0);
const clientCostString = computed(() => clientCost.value.toFixed(2));
const secondlyRate = me.hourly_rate / 3600;

// Inactivity popup
const inactiveAfterMintues = 9;
const { idle } = useIdle(inactiveAfterMintues * 60 * 1000);
const inactivityPopupPresent = computed(() => store.state.global.inactivityPopupPresent);

// Freeze time and launch popup on idle = true
watch(idle, (idle) => {
  if (!idle || inactivityPopupPresent.value) return;
  ModalRef.value.showModal();
  onModalShow();
  store.state.inactivityPopupPresent = true;
});

let inactiveTimeStart = null;
const includeInactiveTime = () => {
  const inactiveTimeSeconds =
    Math.floor((new Date().getTime() - inactiveTimeStart.getTime()) / 1000) + inactiveAfterMintues * 60;
  timeSessionSeconds.value += inactiveTimeSeconds;
  timeTodaySeconds.value += inactiveTimeSeconds;
  timeTotalSeconds.value += inactiveTimeSeconds;
  clientCost.value += inactiveTimeSeconds * secondlyRate;
  inactiveTimeStart = null;
  axios.post('/api/timer/add', {
    parent_id: props.parentId,
    parent_type: props.parentType,
    seconds: inactiveTimeSeconds,
  });
  ModalRef.value.close();
};

// Start time incrementing
const startTimeIncrementInterval = () => {
  return setInterval(() => {
    timeSessionSeconds.value++;
    timeTodaySeconds.value++;
    timeTotalSeconds.value++;
    clientCost.value += secondlyRate;
  }, 1000);
};
let timeIncrementIntervalID = startTimeIncrementInterval();

// Start pinging
const ping = (options) => {
  if (inactivityPopupPresent.value) return;
  // if (import.meta.env.VITE_APP_ENV !== 'local')
  axios.post('/logs/timer/ping', {
    parent_id: props.parentId,
    parent_type: props.parentType,
    ...options,
  });
};
ping({ initial: true });
let pingIntervalID = setInterval(ping, 5000);

// Fetch timer data
axios
  .get(`/logs/timer?parent_type=${props.parentType}&parent_id=${props.parentId}`)
  .then((response) => {
    timeSessionSeconds.value = response.data.timeSessionSeconds;
    timeTodaySeconds.value = response.data.timeTodaySeconds;
    if (response.data.timeTotalSeconds) timeTotalSeconds.value = response.data.timeTotalSeconds;
    if (response.data.clientCost) clientCost.value = response.data.clientCost;
  })
  .catch(() => {
    toast({ title: 'An error occurred while fetching data for timer.', icon: 'error' });
  });

// Events
const onModalShow = () => {
  inactiveTimeStart = new Date();
  clearInterval(pingIntervalID);
  pingIntervalID = 0;
  clearInterval(timeIncrementIntervalID);
  timeIncrementIntervalID = 0;
};
const onModalHidden = () => {
  timeIncrementIntervalID = startTimeIncrementInterval();
  ping();
  pingIntervalID = setInterval(ping, 5000);

  store.state.inactivityPopupPresent = false;
};
const onClientDeleted = () => {
  clearInterval(pingIntervalID);
};

const clientStore = useClientStore();
watch(() => clientStore.deleted, onClientDeleted);

// Update width of the columns
let invisibleEl = null;
let isMounted = false;
onMounted(() => (isMounted = true));
const getColumnWidth = (elClass, content) => {
  if (!invisibleEl) {
    const el = document.createElement('div');
    el.style.opacity = 0;
    el.style.position = 'absolute';
    el.style.top = '-50px';
    el.style.fontSize = window.getComputedStyle(document.querySelector(`.col.${elClass} .value`)).fontSize;
    document.body.append(el);
    invisibleEl = el;
  }
  invisibleEl.innerHTML = content;
  const widthStr = window.getComputedStyle(invisibleEl).width;
  return `${parseInt(widthStr) + 5}px`;
};
const assignWidth = (elClass, newVal, oldVal) => {
  if (!isMounted) return;
  if (oldVal === '00:00' || oldVal.length < newVal.length) {
    const colEl = document.querySelector(`.col.${elClass}`);
    if (colEl) colEl.style.minWidth = getColumnWidth(elClass, newVal);
  }
};
watch(timeSessionString, (newVal, oldVal) => assignWidth('session', newVal, oldVal));
watch(timeTodayString, (newVal, oldVal) => assignWidth('today', newVal, oldVal));
watch(timeTotalString, (newVal, oldVal) => assignWidth('total', newVal, oldVal));
watch(clientCostString, (newVal, oldVal) => assignWidth('cost', newVal, oldVal));

// Clear all intervals when route changes
const clearAllIntervals = () => {
  clearInterval(pingIntervalID);
  clearInterval(timeIncrementIntervalID);
};
onBeforeRouteUpdate((from, to, next) => {
  if (to.matched.length < 2) clearAllIntervals();
  next();
});
onBeforeUnmount(clearAllIntervals);
</script>

<style lang="scss" scoped>
.modal {
  p {
    margin-bottom: rem(50px);
  }
}

.timer {
  $self: &;

  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  padding: rem(4px) rem(5px);
  border: rem(1px) solid color('gray', 3);
  border-radius: rem(6px);

  .col.total,
  .col.cost,
  .divider:nth-child(7),
  .divider:nth-child(9) {
    display: none;
  }

  &__expanded {
    #{$self}__arrow .icon {
      transform: rotate(180deg);
    }

    .col.total,
    .col.cost,
    .divider:nth-child(7),
    .divider:nth-child(9) {
      display: block;
    }
  }

  // Arrow
  #{$self}__arrow {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: rem(-11px);
    width: rem(20px);
    height: rem(20px);
    background-color: white;
    border-radius: 50%;
    transform: rotate(-45deg);
    transition: transform 0.4s ease;
    border-top: rem(1px) solid color('gray', 3);
    border-left: rem(1px) solid color('gray', 3);
    cursor: pointer;

    .icon {
      transition: transform 0.4s ease;
      width: rem(6px);
      height: rem(6px);
      border-top: rem(2px) solid color('text', 4);
      border-left: rem(2px) solid color('text', 4);
    }
  }

  // Icon
  .icon-col {
    display: flex;
    align-items: center;
    padding: rem(2px) rem(5px);
  }

  // Divider
  .divider {
    width: rem(1.5px);
    height: rem(36px);
    background-color: color('gray', 3);
    margin: 0 rem(5px);
  }

  // Columns
  .col {
    box-sizing: content-box;
    text-align: left;
    border-radius: rem(3px);
    padding: rem(2px) rem(5px);

    &.today {
      background-color: white;

      &.red {
        .desc {
          color: white;
        }

        color: white;
        background-color: #fa3c03;
      }
    }

    .desc {
      color: color('gray', 5);
      white-space: nowrap;
      display: block;
      font-size: rem(14px);
      line-height: rem(16px);
    }

    .value {
      display: block;
      font-size: rem(20px);
      line-height: rem(21px);
    }
  }
}

.timer-dialog {
  border-radius: rem(8px);
  border: none;
  padding: rem(10px) rem(20px);

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
  }

  &__wrapper {
    width: rem(600px);
    display: flex;
    flex-direction: column;
    gap: rem(14px);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(10px) 0;
    border-bottom: 1px solid #e5e5e5;

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      position: relative;
      padding: 0;
      margin-left: rem(25px);
      width: rem(24px);
      height: rem(24px);
      cursor: pointer;

      &::after,
      &::before {
        position: absolute;
        content: '';
        display: block;
        width: rem(2px);
        height: rem(18px);
        background-color: black;
      }

      &::after {
        transform: rotate(45deg);
      }

      &::before {
        transform: rotate(-45deg);
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
