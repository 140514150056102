import axios from 'axios';

let inactivityTimer;
let lifetime = import.meta.env.VITE_SESSION_LIFETIME * 60 * 1000;

function setInactivityTimer() {
  inactivityTimer = setTimeout(logout, lifetime);
}

function resetInactivityTimer() {
  if (inactivityTimer) clearTimeout(inactivityTimer);
  setInactivityTimer();
}

function logout() {
  if (window.me) location.href = '/login?inactivity=1';
}

axios.interceptors.response.use(
  (response) => {
    resetInactivityTimer(inactivityTimer);
    return Promise.resolve(response);
  },
  (error) => {
    resetInactivityTimer(inactivityTimer);
    return Promise.reject(error);
  }
);

window.axios = axios;

setInactivityTimer();
