<template>
  <FormGroup
    type="ajax-select"
    label-method="name"
    :id-only="true"
    :fetch-models="fetchModels"
    :fetch-options="fetchOptions"
  >
    <template #label="{ selected }">
      <a v-if="selected" class="link" @click.stop="openModal(selected.id)"> MGA/Wholesaler </a>
      <template v-else> MGA/Wholesaler </template>
      <AgencyShowModal ref="ModalRef" />
    </template>
    <template #controls="{ selected }">
      <a v-if="selected?.url" class="link" :href="selected.url" target="_blank">Website</a>
    </template>
  </FormGroup>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import AgencyApi from '@/js/Api/Agency';
import AgencyShowModal from '@/js/Components/Agencies/ShowModal.vue';
import FormGroup from '@/js/Components/Form/FormGroup.vue';

const fetchModels = (id: number[]) => {
  return AgencyApi.all({
    queryBuilder: {
      filters: {
        id,
      },
    },
  });
};

const fetchOptions = (query: string | undefined, page: number) => {
  return AgencyApi.index({
    queryBuilder: {
      page,
      filters: {
        name: query,
      },
    },
  });
};

const ModalRef = ref<InstanceType<typeof AgencyShowModal> | null>(null);
const openModal = (id: number) => {
  if (!ModalRef.value) return;
  ModalRef.value.open(id);
};
</script>
