<template>
  <div class="form-row" :style="{ 'grid-template-columns': props.gridTemplateColumns }">
    <slot />
  </div>
</template>

<script setup lang="ts">
interface Props {
  gridTemplateColumns: string;
}

const props = defineProps<Props>();
</script>

<style lang="scss">
.form-row {
  margin-bottom: 1em;
  display: grid;
  & > *:not(:first-child, :last-child) {
    .form-group__input,
    .form-group-display {
      border-right: none;
      border-left: none;
      border-radius: unset;
    }
  }
  & > *:not(:first-child) {
    &::before {
      content: '';
      height: rem(36px);
      background-color: color('gray', 3);
      width: 1px;
      position: absolute;
      top: 0;
      left: 0;
      margin: rem(6px) 0;
    }
  }
  & > :first-child {
    .form-group__input,
    .form-group-display {
      border-right: none;
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
    }
  }
  & > :last-child {
    .form-group__input,
    .form-group-display {
      border-left: none;
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
    }
  }
}
</style>
