import axios from 'axios';

export default {
  namespaced: true,

  state: () => ({}),

  actions: {
    fetchClientActivitySessions({ commit }) {
      return axios.get('/api/client-activity-sessions').then((response) => {
        commit('setClientActivitySessions', response.data.sessions);
      });
    },

    // Count of pending approval
    fetchPendingApprovalCount({ commit }) {
      return axios.get(`/reports/templates/pending-approval-count`).then((response) => {
        commit('setPendingApprovalCount', response.data.count);
      });
    },

    // Clients handled
    fetchClientsHandled({ commit }) {
      return axios.get('/api/reports/clients-handled').then((response) => {
        commit('setClientsHandled', response.data.clientsHandled);
      });
    },
  },

  mutations: {
    setClientActivitySessions(state, response) {
      state.clientActivitySessions = response.data;
      state.clientActivitySessionsNextPageUrl = response.next_page_url;
      state.clientActivitySessionsPrevPageUrl = response.next_prev_url;
    },

    // Count of certificates pending approval
    setPendingApprovalCount(state, count) {
      state.pendingApprovalCount = count;
    },

    // Clients handled
    setClientsHandled(state, clientsHandled) {
      state.clientsHandled = clientsHandled;
    },
  },
};
