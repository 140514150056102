<template>
  <TicketNoteAssigneeInput :model-value="note?.assigned_to" :label="label" :disabled="disabled" :on-select="onSelect" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { toDateString } from '@/js/Helpers';
import TicketNoteApi, { TicketNote } from '@/js/Api/TicketNote';
import TicketNoteAssigneeApi from '@/js/Api/TicketNoteAssignee';
import TicketNoteAssigneeInput, { Option } from '@/js/Components/TicketNoteAssignees/Input.vue';

interface Props {
  note?: TicketNote;
  disabled: boolean;
}

interface Emits {
  (e: 'update:note', note: TicketNote): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const onSelect = async (option: Option, checked: boolean, selected: Option | null) => {
  if (props.note?.is_followup && selected) {
    const r = await TicketNoteAssigneeApi.find({ id: `${selected.id}-${selected.model_type}` });
    const assignee = r.data;
    return TicketNoteApi.autosave({
      id: props.note.id,
      name: 'assignee',
      value: `${assignee.id}-${assignee.model_type}`,
    }).then(({ data }) => {
      emit('update:note', data);
    });
  }
};

const label = computed(() => {
  const isFollowup = props.note && props.note.is_followup;
  if (isFollowup) {
    const assignedOn = toDateString(props.note.followup_assigned_on);
    const resolvedOn = toDateString(props.note.followup_resolved_on);
    return props.note.status === 'Open' ? `Assigned (${assignedOn})` : `Resolved (${resolvedOn})`;
  }
  return 'Assignee';
});
</script>
