<template>
  <IMaskComponent
    :value="localValue"
    mask="$num"
    :lazy="true"
    :blocks="{
      num: {
        mask: Number,
        thousandsSeparator: ',',
        scale: 2,
        signed: false,
        padFractionalZeros: true,
        normalizeZeros: true,
        radix: '.',
        mapToRadix: ['.'],
      },
    }"
    @blur="handleBlur"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import currency from 'currency.js';
import { IMaskComponent } from 'vue-imask';

type MoneyInputProps = {
  modelValue?: string | number | null;
  fromCents?: boolean;
};

const props = withDefaults(defineProps<MoneyInputProps>(), {
  fromCents: true,
});

const emit = defineEmits(['update:model-value']);

const localValue = computed(() => {
  if (props.modelValue === undefined || props.modelValue === null) return '';

  return currency(props.modelValue, { fromCents: props.fromCents }).format();
});

const handleBlur = (event: FocusEvent) => {
  let modelValue = null;
  const inputElement = event.target as HTMLInputElement;
  if (inputElement.value) {
    const parsedValue = currency(inputElement.value);
    modelValue = props.fromCents ? parsedValue.intValue : parsedValue.value;
  }

  if (props.modelValue === modelValue) return;

  emit('update:model-value', modelValue);
};
</script>
