<template>
  <button
    ref="ButtonRef"
    class="button"
    :class="[
      `button--${props.variant}`,
      `button--${props.color}`,
      `button--size-${props.size}`,
      { 'button--icon': props.icon },
    ]"
    :type="props.type"
    v-bind="$attrs"
    @click="onClick"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
import { ref, useAttrs } from 'vue';

export interface Props {
  variant?: 'regular' | 'outline' | 'ghost' | 'link';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color?: 'primary' | 'secondary' | 'danger' | 'success' | 'warning' | 'info';
  icon?: boolean;
  type?: 'button' | 'submit';
}

interface Emits {
  (e: 'click', event: MouseEvent): void;
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'regular',
  size: 'md',
  color: 'primary',
  icon: false,
  type: 'button',
});

const emit = defineEmits<Emits>();

const ButtonRef = ref();
const attrs = useAttrs();

const focus = () => ButtonRef.value.focus();
const onClick = (e: MouseEvent) => {
  if (attrs.disabled) return;
  emit('click', e);
};

defineExpose({
  focus,
  ButtonRef,
});
</script>

<style lang="scss">
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 0.6rem;
  border: 1px solid transparent;
  font-size: rem(16px);
  pointer-events: inherit;
  opacity: 1;
  cursor: pointer;
  outline: none;
  line-height: 0.1;
  transition:
    background-color 0.15s ease-in-out,
    color 0.15s ease-in-out;
  height: 2.3em;
  padding: 0 1em;
  text-decoration: none;

  &:disabled {
    opacity: 50%;
    cursor: not-allowed;
  }

  &:focus {
    outline-width: 2px;
    outline-style: solid;
    outline-offset: 1px;
  }

  svg {
    width: 1.5em;
    height: 1.5em;
  }

  &--size-default {
    font-size: rem(16px);
  }

  &--size-xs {
    font-size: rem(12px);
  }

  &--size-sm {
    font-size: rem(14px);
  }

  &--size-lg {
    font-size: rem(18px);
  }

  &--size-xl {
    font-size: rem(20px);
  }

  &--icon {
    width: 2.3em;
    padding: unset;
  }
}

.button--primary:focus {
  outline-color: rgba(color('primary', 5), 0.5);
}

.button--secondary:focus {
  outline-color: color('secondary', 3);
}

.button--danger:focus {
  outline-color: color('red', 3);
}

.button--success:focus {
  outline-color: color('green', 3);
}

.button--warning:focus {
  outline-color: color('orange', 3);
}

.button--info:focus {
  outline-color: color('blue', 3);
}

.button--regular.button--primary {
  background-color: color('primary', 5);
  color: color('gray', 1);

  &:not(:disabled):hover {
    background-color: color('primary', 6);
  }
}

.button--regular.button--secondary {
  background-color: color('secondary', 3);
  color: color('secondary', 8);

  &:not(:disabled):hover {
    background-color: color('secondary', 4);
  }
}

.button--regular.button--danger {
  background-color: color('red', 6);
  color: color('gray', 1);

  &:not(:disabled):hover {
    background-color: color('red', 7);
  }
}

.button--regular.button--success {
  background-color: color('green', 6);
  color: color('gray', 1);

  &:not(:disabled):hover {
    background-color: color('green', 7);
  }
}

.button--regular.button--warning {
  background-color: color('orange', 5);
  color: color('gray', 1);

  &:not(:disabled):hover {
    background-color: color('orange', 6);
  }
}

.button--regular.button--info {
  background-color: color('blue', 5);
  color: color('gray', 1);

  &:not(:disabled):hover {
    background-color: color('blue', 6);
  }
}

.button--outline.button--primary {
  color: color('primary', 5);
  border-color: color('primary', 5);
  background-color: color('gray', 1);

  &:not(:disabled):hover {
    background-color: rgba(color('primary', 5), 0.05);
  }
}

.button--outline.button--secondary {
  color: color('secondary', 8);
  border-color: color('secondary', 8);
  background-color: color('secondary', 1);

  &:not(:disabled):hover {
    background-color: color('secondary', 2);
  }
}

.button--outline.button--danger {
  color: color('red', 6);
  border-color: color('red', 6);
  background-color: color('red', 1);

  &:not(:disabled):hover {
    color: color('red', 7);
    background-color: color('red', 2);
  }
}

.button--outline.button--success {
  color: color('green', 6);
  border-color: color('green', 6);
  background-color: color('green', 1);

  &:not(:disabled):hover {
    color: color('green', 7);
    background-color: color('green', 2);
  }
}

.button--outline.button--warning {
  color: color('orange', 6);
  border-color: color('orange', 6);
  background-color: color('orange', 1);

  &:not(:disabled):hover {
    color: color('orange', 6);
    background-color: color('orange', 2);
  }
}

.button--outline.button--info {
  color: color('blue', 6);
  border-color: color('blue', 6);
  background-color: color('blue', 1);

  &:not(:disabled):hover {
    color: color('blue', 6);
    background-color: color('blue', 2);
  }
}

.button--ghost.button--primary {
  color: color('primary', 6);
  background-color: transparent;

  &:not(:disabled):hover {
    background-color: rgba(color('primary', 5), 0.1);
  }
}

.button--ghost.button--secondary {
  color: color('secondary', 8);
  background-color: transparent;

  &:not(:disabled):hover {
    background-color: color('secondary', 3);
  }
}

.button--ghost.button--danger {
  color: color('red', 6);
  background-color: transparent;

  &:not(:disabled):hover {
    background-color: color('red', 1);
  }
}

.button--ghost.button--success {
  color: color('green', 6);
  background-color: transparent;

  &:not(:disabled):hover {
    background-color: color('green', 1);
  }
}

.button--ghost.button--warning {
  color: color('orange', 6);
  background-color: transparent;

  &:not(:disabled):hover {
    background-color: color('orange', 1);
  }
}

.button--ghost.button--info {
  color: color('blue', 6);
  background-color: transparent;

  &:not(:disabled):hover {
    background-color: color('blue', 1);
  }
}

.button--link.button--primary {
  color: color('primary', 6);
  background-color: transparent;
}

.button--link.button--secondary {
  color: color('secondary', 8);
  background-color: transparent;
}

.button--link.button--danger {
  color: color('red', 6);
  background-color: transparent;
}

.button--link.button--success {
  color: color('green', 6);
  background-color: transparent;
}

.button--link.button--warning {
  color: color('orange', 6);
  background-color: transparent;
}

.button--link.button--info {
  color: color('blue', 6);
  background-color: transparent;
}
</style>
