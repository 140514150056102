import { z } from 'zod';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { UserSchema } from './User';
import { CertificateHolder, CertificateHolderSchema } from './CertificateHolder';
import { CertificateTemplate, CertificateTemplateSchema } from './CertificateTemplate';

export const BaseCertificateSnapshotSchema = z.object({
  id: z.number(),
  template_id: z.number(),
  generated_by: z.number().nullable(),
  name: z.string(),
  approved: z.boolean(),
  holder_id: z.number().nullable(),
  doo_id: z.number().nullable(),
  client_id: z.number(),
  created_by: z.number(),
  approved_by: z.number().nullable(),
  approved_on: z.string().nullable(),
  acord: z.any(), // FIX ME
  created_at: z.string(),
  updated_at: z.string(),

  // Relations
  created_by_user: UserSchema.nullish(),
  approved_by_user: UserSchema.nullish(),
  generated_by_user: UserSchema.nullish(),

  // Appends
  approved_on_formatted: z.string(),
  created_at_formatted: z.string(),
});

export type CertificateSnapshot = z.infer<typeof BaseCertificateSnapshotSchema> & {
  template?: CertificateTemplate;
  holder?: CertificateHolder | null;
};

export const CertificateSnapshotSchema: z.ZodType<CertificateSnapshot> = BaseCertificateSnapshotSchema.extend({
  template: z.lazy(() => CertificateTemplateSchema.or(z.undefined())),
  holder: z.lazy(() => CertificateHolderSchema.nullish()),
});

async function create({
  clientId,
  templateId,
  holderId,
}: {
  clientId: number;
  templateId: number;
  holderId: number | undefined;
}) {
  const response = await axios
    .post(`/api/clients/${clientId}/certificates/templates/${templateId}/snapshot`, {
      holder_id: holderId,
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

  return z.object({ data: CertificateSnapshotSchema }).parseAsync(response.data);
}

export default {
  create,
};
