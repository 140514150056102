<template>
  <div class="quotes">
    <!-- Table -->
    <SlidePanel class="quotes-panel" :is-active="true">
      <template #header-left>
        <div class="slide-panel__header-left">
          <SvgIcon name="stacked-line-chart" />
          <h5 v-if="quotes" class="slide-panel__header-left-text">Quotes ({{ quotes.length }})</h5>
        </div>
      </template>
      <template #header-right="{ isActive }">
        <Btn v-if="canEdit && isActive" variant="outline" @click.stop="openCreateModal">
          <SvgIcon name="plus" />
          Add Quote Submission
        </Btn>
      </template>
      <QuotesTable v-if="quotes" v-model:quotes="quotes" :can-edit="canEdit" @click:item="openUpdateModal" />
    </SlidePanel>

    <!-- Create modal -->
    <CreateModal
      v-if="quoteGroup"
      ref="CreateModalRef"
      :quote-group-id="quoteGroup.id"
      :available-coverage-types="quoteGroupCoverageTypes"
      @created="onCreated"
    />

    <!-- Update modal -->
    <UpdateModal
      v-if="quoteGroup"
      ref="UpdateModalRef"
      :available-coverage-types="quoteGroupCoverageTypes"
      :disabled="!canEdit"
      @click:create="openCreateModal"
      @update:quote="updateQuote"
      @updated:status="onStatusUpdated"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import useClientStore from '@/js/store/pinia/useClientStore';
import { toastError } from '@/js/Helpers/Alert';
import { QuoteGroup } from '@/js/Api/QuoteGroup';
import { QuoteGroupStatus } from '@/js/Api/QuoteGroupStatus';
import QuoteApi, { Quote } from '@/js/Api/Quote';
import Btn from '@/js/Components/Btn.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import SlidePanel from '@/js/Components/SlidePanel.vue';
import QuotesTable from '@/js/Components/Quotes/Table/Main.vue';
import CreateModal from '@/js/Components/Quotes/CreateModal/Main.vue';
import UpdateModal from '@/js/Components/Quotes/UpdateModal/Main.vue';

interface QuoteGroupWithIntakes extends QuoteGroup {
  intakes: Exclude<QuoteGroup['intakes'], undefined>;
}

interface Props {
  canEdit: boolean;
  quoteGroup: QuoteGroupWithIntakes;
}

interface Emits {
  (e: 'update:quote-group-statuses', statuses: QuoteGroupStatus[]): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

// State
const quotes = ref<Quote[] | null>(null);
const quoteGroupCoverageTypes = computed(() => {
  return props.quoteGroup.intakes.map((i) => i.coverage_type);
});

// Refs
const CreateModalRef = ref<InstanceType<typeof CreateModal> | null>(null);
const UpdateModalRef = ref<InstanceType<typeof UpdateModal> | null>(null);

const clientStore = useClientStore();

// Fetch quotes
QuoteApi.all({
  queryBuilder: {
    filters: {
      quote_group_id: props.quoteGroup.id,
    },
    includes: ['agency', 'carrier', 'policy'],
    sorts: [
      {
        order: 'desc',
        name: 'submission_date',
      },
    ],
  },
})
  .then(({ data }) => {
    quotes.value = data;
  })
  .catch(() => {
    toastError('An error occurred while fetching quotes');
  });

// Open create modal
const openCreateModal = () => {
  if (UpdateModalRef.value) UpdateModalRef.value.hide();
  if (CreateModalRef.value) CreateModalRef.value.show();
};

// Open update modal
const openUpdateModal = (quote: Quote) => {
  if (!UpdateModalRef.value) return;
  if (CreateModalRef.value) CreateModalRef.value.hide();
  UpdateModalRef.value.show(quote);
};

// On created quote
type CreateResponse = Awaited<ReturnType<typeof QuoteApi.create>>['data'];
const onCreated = ({ quote, quoteGroupStatuses, followup }: CreateResponse) => {
  if (!quotes.value) return;
  quotes.value.push(quote);
  emit('update:quote-group-statuses', quoteGroupStatuses);
  clientStore.addOrUpdateNote(followup, true);
  clientStore.currentTicketId = followup.ticket_id;
  openUpdateModal(quote);
};

// On status updated
type StatusUpdateResponse = Awaited<ReturnType<typeof QuoteApi.updateStatus>>['data'];
const onStatusUpdated = (response: StatusUpdateResponse, markAllAsNotSold: boolean) => {
  if (quotes.value && markAllAsNotSold)
    quotes.value
      .filter((q) => q.status !== 'Sold' && q.id !== response.quote.id)
      .forEach((q) => {
        q.status = 'Not Sold';
      });
  emit('update:quote-group-statuses', response.quoteGroupStatuses);
};

const updateQuote = (newQuote: Quote) => {
  if (!quotes.value) return;
  const quote = quotes.value.find((q) => q.id === newQuote.id);
  if (!quote) return;
  Object.assign(quote, newQuote);
};
</script>

<style lang="scss">
.quotes {
  padding-top: rem(23px);

  // Table
  .slide-panel {
    background: none;
    box-shadow: none;
    border: rem(1px) solid #cccccc;
    &__body {
      padding: 0 rem(7px);
    }
  }
}
</style>
