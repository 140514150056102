import { z } from 'zod';
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';
import { UserSchema } from './User';
import { queryBuilderToQueryString } from '@/js/Helpers';

export const LienholderSchema = z.object({
  id: z.number(),
  name: z.string().nullable(),
  address1: z.string().nullable(),
  address2: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  zip: z.string().nullable(),
  contact_name: z.string().nullable(),
  phone: z.string().nullable(),
  fax: z.string().nullable(),
  email: z.string().nullable(),
  created_by: z.number(),
  created_at: z.string(),
  updated_at: z.string(),

  // Relations
  created_by_user: UserSchema.nullish(),
});

export type Lienholder = z.infer<typeof LienholderSchema>;

// -----------------------------------------------------------------------
//
// All
//
// -----------------------------------------------------------------------

type AllQueryBuilder = {
  sorts?: {
    order: 'desc' | 'asc';
    name: 'name';
  }[];
  filters?: {
    id: number[];
    client_id: number;
  };
};

async function all(queryBuilder: AllQueryBuilder) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/lienholders/all?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: LienholderSchema.array(),
    })
    .parseAsync(response.data);
}

// -----------------------------------------------------------------------
//
// Create
//
// -----------------------------------------------------------------------

export const CreateFormSchema = z.object({
  name: z.string().nullish(),
  address1: z.string().nullish(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  zip: z.string().nullish(),
  contact_name: z.string().nullish(),
  phone: z.string().nullish(),
  fax: z.string().nullish(),
  email: z.string().nullish(),
});

export type CreateForm = z.infer<typeof CreateFormSchema>;

async function create(form: CreateForm) {
  const response = await axios.post('/api/lienholders', form).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: LienholderSchema,
    })
    .parseAsync(response.data);
}

// -----------------------------------------------------------------------
//
// Export
//
// -----------------------------------------------------------------------

export default {
  all,
  create,
};
