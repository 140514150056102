/* eslint-disable no-redeclare */
const locale = 'fr-CA';

const formatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
} as const;

function toDateInput(date: null | undefined): null;
function toDateInput(date: string | null): string | null;
function toDateInput(date: string | undefined): string | null;
function toDateInput(date: string): string | null;
function toDateInput(date: Date): string;

function toDateInput(date: unknown): unknown {
  // If we have a date already, just use that
  if (typeof date === 'object' && date instanceof Date) {
    return new Intl.DateTimeFormat(locale, formatOptions).format(date);
  }

  // Fix time defaulting to current time if time isn't specified
  if (typeof date === 'string' && /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(date)) date += ' 00:00';

  // If its a string, check for the validity
  if (typeof date === 'string') {
    const parsedDate = Date.parse(date);

    // Check if we have a valid number
    if (!Number.isNaN(parsedDate)) {
      return new Intl.DateTimeFormat(locale, formatOptions).format(parsedDate);
    }
  }

  // Otherwhere return null
  return null;
}

export default toDateInput;
