<template>
  <div class="form-group" :class="groupClassComputed" :style="groupStyle" @click="emit('click', $event)">
    <!-- Label -->
    <slot v-if="!isSelectInput" name="label">
      <div v-if="labelText" class="form-group__label-container">
        <label class="form-group__label" @click="input?.focus()">
          {{ labelText }}
          <CopyBtn v-if="copyBtn" @click.stop="copyValue" />
        </label>
        <div class="form-group__controls">
          <i v-if="showTodayIcon" class="fas fa-calendar-day today" @click="todayIconClick"></i>
          <slot name="controls" />
          <!-- Deprecated (use controls) -->
          <slot name="label-append" />
        </div>
      </div>
    </slot>

    <!-- Ajax select -->
    <AjaxSelect
      v-if="attrs.type === 'ajax-select'"
      ref="input"
      v-bind="attrs"
      :copy-btn="copyBtn"
      :model-value="value"
      :disabled="isDisabled"
      @update:model-value="update"
    >
      <template #trigger="{ selected }">
        <slot name="trigger" :value="value" :selected="selected" />
      </template>
      <template #label="{ selected }">
        <slot name="label" :value="value" :selected="selected" />
      </template>
      <template #controls="{ selected }">
        <slot name="controls" :value="value" :selected="selected" />
      </template>

      <template #selected-option="{ item, selected }">
        <slot name="selected-option" :item="item" :value="value" :selected="selected" />
      </template>

      <template #prefix>
        <slot name="prefix" />
      </template>

      <template #item="{ item }">
        <slot name="item" :item="item" />
      </template>
    </AjaxSelect>

    <!-- Select -->
    <Select
      v-else-if="attrs.type === 'select'"
      ref="input"
      v-bind="attrs"
      :model-value="value"
      :copy-btn="copyBtn"
      :options="options"
      :disabled="isDisabled"
      @update:model-value="update"
    >
      <template #trigger>
        <slot name="trigger" :value="value" />
      </template>
      <template #label>
        <slot name="label" />
      </template>
      <template #controls>
        <slot name="controls" :value="value" />
      </template>

      <template #selected-option="{ item }">
        <slot name="selected-option" :item="item" />
      </template>

      <template #prefix>
        <slot name="prefix" />
      </template>

      <template #item="{ item }">
        <slot name="item" :item="item" />
      </template>
    </Select>

    <!-- Textarea -->
    <textarea
      v-else-if="attrs.type === 'textarea'"
      ref="input"
      v-bind="attrs"
      :style="inputStyle"
      class="form-group__input form-control"
      :class="inputClassComputed"
      :value="value"
      spellcheck="false"
      @input="update"
      @paste="onPaste"
      @focus="onFocus"
      @blur="onBlur"
    >
    </textarea>

    <!-- Date -->
    <input
      v-else-if="attrs.type === 'date' && !isDisabled"
      ref="input"
      v-bind="attrs"
      :style="inputStyle"
      class="form-group__input form-control"
      :class="inputClassComputed"
      :value="value"
      @input="update"
      @focus="onFocus"
      @blur="onBlur"
    />
    <input
      v-else-if="attrs.type === 'date' && isDisabled"
      ref="input"
      v-bind="attrs"
      type="text"
      :style="inputStyle"
      class="form-group__input form-control"
      :class="inputClassComputed"
      :value="toDateString(value)"
      @input="update"
    />

    <!-- Number -->
    <input
      v-else-if="attrs.type === 'number'"
      ref="input"
      v-bind="attrs"
      :style="inputStyle"
      class="form-group__input form-control"
      :class="inputClassComputed"
      :value="value"
      @focus="onFocus"
      @blur="onBlur"
      @input="update"
    />

    <!-- Money -->
    <MoneyInput
      v-else-if="attrs.type === 'currency'"
      ref="input"
      v-bind="attrs"
      :style="inputStyle"
      class="form-group__input form-control"
      :class="inputClassComputed"
      :model-value="value"
      autocomplete="off"
      spellcheck="false"
      @focus="onFocus"
      @blur="onBlur"
      @update:model-value="update"
    />

    <!-- Percentage -->
    <PercentageInput
      v-else-if="attrs.type === 'percentage'"
      ref="input"
      v-bind="attrs"
      :style="inputStyle"
      class="form-group__input form-control"
      :class="inputClassComputed"
      :model-value="value"
      autocomplete="off"
      spellcheck="false"
      @focus="onFocus"
      @blur="onBlur"
      @update:model-value="update"
    />

    <!-- Masked -->
    <MaskedInput
      v-else-if="attrs.type === 'masked'"
      :mask="props.mask"
      :unmasked="props.unmask"
      v-bind="attrs"
      :style="inputStyle"
      class="form-group__input form-control"
      :class="inputClassComputed"
      :model-value="value"
      autocomplete="off"
      spellcheck="false"
      @focus="onFocus"
      @blur="onBlur"
      @update:model-value="update"
    />

    <!-- Else -->
    <input
      v-else
      ref="input"
      :value="value"
      v-bind="attrs"
      :style="inputStyle"
      class="form-group__input form-control"
      :class="inputClassComputed"
      autocomplete="off"
      spellcheck="false"
      @focus="onFocus"
      @blur="onBlur"
      @change="update"
      @[updateEvent]="update"
      @paste="onPaste"
      @keypress="
        (e) => {
          if (attrs.type === 'phone') formatPhone(e);
          else if (attrs.type === 'zip') formatZIP(e);
        }
      "
      @copy="
        (e) => {
          if (attrs.type === 'phone') copyPhone(e);
        }
      "
    />

    <ErrorMessage v-if="errorMessage" :message="errorMessage" />
  </div>
</template>

<script setup>
import { useField } from 'vee-validate';
import { ref, computed, useAttrs } from 'vue';
import { confirm, toastError } from '@/js/Helpers/Alert';
import debounce from '@/js/Helpers/Debounce';
import useGlobalStore from '@/js/store/pinia/useGlobalStore';
import { toDateString } from '@/js/Helpers';
import { formatZIP, formatPhone, copyPhone } from '@/js/Common/Form/FormGroup/utils';
import MaskedInput from '@/js/Common/Form/FormGroup/MaskedInput.vue';
import CopyBtn from '@/js/Components/CopyBtn.vue';
import Clipboard from '@/js/Helpers/Clipboard';
import MoneyInput from '@/js/Common/Form/FormGroup/MoneyInput.vue';
import PercentageInput from '@/js/Common/Form/FormGroup/PercentageInput.vue';
import Select from './Select.vue';
import AjaxSelect from './AjaxSelect.vue';
import ErrorMessage from './ErrorMessage.vue';

const props = defineProps({
  modelValue: {
    type: null,
    default: null,
  },
  modelModifiers: {
    type: Object,
    default: () => ({}),
  },
  options: {
    type: [Array, null],
    default: null,
  },

  groupClass: {
    type: [Object, String, Array],
    default: null,
  },
  groupStyle: {
    type: [Object, String, Array],
    default: null,
  },

  inputClass: {
    type: [Object, String, Array],
    default: null,
  },
  inputStyle: {
    type: [Object, String, Array],
    default: null,
  },

  labelText: {
    type: String,
    default: null,
  },
  labelHidden: {
    type: [Boolean],
  },
  copyBtn: {
    type: Boolean,
    default: true,
  },

  // Date
  todayIcon: {
    type: Boolean,
    default: false,
  },

  // Autosave
  autosave: {
    type: Boolean,
    default: true,
  },
  autosaveMethod: {
    type: Function,
    default: undefined,
  },
  autosaveOnError: {
    type: Boolean,
    default: false,
  },

  // Callbacks
  formatValue: {
    type: Function,
    default: null,
  },
  beforeUpdate: {
    type: Function,
    default: null,
  },

  mask: {
    type: Function,
    default: () => () => ({}),
  },
  unmask: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'click',
  'input',
  'paste',
  'change',
  'updated',
  'autosave',
  'after-autosave',
  'selected',
  'update:model-value',
  'update:modelValue',
]);

const attrs = useAttrs();

const { errorMessage, value: formValue, handleChange } = attrs.name ? useField(attrs.name) : {};

const value = computed(() => (attrs.name ? formValue.value : props.modelValue));

const input = ref(null);

const isFocused = ref(false);
const isDisabled = computed(() => !!attrs.disabled);
const isReadOnly = computed(() => !!attrs.readonly);
const isInvalid = computed(() => !!errorMessage?.value);
const isSelectInput = computed(() => attrs.type === 'ajax-select' || attrs.type === 'select');

const groupClassComputed = computed(() => [
  props.groupClass,
  attrs.name, // Deprecated
  attrs.type, // Deprecated
  {
    'form-group--disabled': isDisabled.value,
    'form-group--readonly': isReadOnly.value,
    'form-group--invalid': isInvalid.value,
    [`form-group--${attrs.name}`]: !!attrs.name,
    [`form-group--${attrs.type}`]: !!attrs.type,
  },
]);

const inputClassComputed = computed(() => [
  props.inputClass,
  {
    'is-invalid': isInvalid.value, // Deprecated (use form-group__input--invalid)
    'form-group__input--invalid': isInvalid.value,
    'form-group__input--uppercase': props.modelModifiers.uppercase,
  },
]);

// Date
const showTodayIcon = computed(() => !isDisabled.value && attrs.type === 'date' && props.todayIcon);
const todayIconClick = async () => {
  const confirmResult = await confirm({
    text: `Confirm ${props.labelText} today`,
    confirmButtonText: 'Confirm',
    icon: 'question',
  });
  if (confirmResult.isConfirmed) {
    input.value.value = new Date().toDateInput();
    input.value.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
  }
};

const globalStore = useGlobalStore();
const setProcessingPromise = (promise) => {
  globalStore.processingPromise = promise;
};

const shouldAutosave = computed(() => {
  if (!props.autosave) return false;
  if (!props.autosaveMethod) return false;
  if (isInvalid.value) return props.autosaveOnError;
  return true;
});

let saveDebounced = null;
const save = (v) => {
  if (!shouldAutosave.value) return false;

  return props.autosaveMethod(v, attrs.name).catch((error) => {
    const { response } = error;
    if (!response) {
      throw error;
    } else if (response.status === 422) {
      toastError(response.data.errors[attrs.name][0]);
    } else {
      toastError('An error occurred while saving data.');
    }
  });
};

const updateEvent = props.beforeUpdate ? 'change' : 'input';
const update = async (arg) => {
  let v = arg instanceof Event ? arg.target.value : arg;

  // Validation before updating
  if (props.beforeUpdate) {
    const result = await props.beforeUpdate(v, attrs.name);
    if (result === false) {
      if (isSelectInput.value) input.value.rerenderCheckboxes();
      else if (arg instanceof Event) arg.target.value = value.value;
      return;
    }
  }

  // Modifiers
  if (v === '') v = null;
  if (v && props.modelModifiers.uppercase) v = v.toUpperCase();
  if (v && props.modelModifiers.max) v = v.substring(0, props.modelModifiers.max);
  if (v && props.modelModifiers.number) {
    v = Number(v);
    v = !Number.isNaN(v) ? v : 0;
  }

  // Custom formatting
  if (props.formatValue) v = props.formatValue(v, arg);

  // This prevents cursor jumping
  if (arg instanceof Event && attrs.type === 'text') {
    const inputEl = arg.target;
    const start = inputEl.selectionStart;
    const end = inputEl.selectionEnd;
    inputEl.value = v;
    inputEl.setSelectionRange(start, end);
  }

  // Update state/form
  if (handleChange) handleChange(v);
  emit('update:model-value', v);

  // Autosave
  saveDebounced = saveDebounced ?? debounce(save, 1000);
  const promise = saveDebounced(v);
  setProcessingPromise(promise);
};

const onMaskedChange = (v) => {
  emit('update:model-value', v);

  saveDebounced = saveDebounced ?? debounce(save, 1000);
  const promise = saveDebounced(v);
  setProcessingPromise(promise);
};

const onPaste = (e) => {
  // FIXME - why is item undefined?
  const item = e.clipboardData.items[0];
  if (item && item?.kind !== 'string') {
    e.preventDefault();
    emit('paste', e);
  }
};

const copyValue = () => {
  if (attrs.type === 'date') Clipboard.copy(toDateString(value.value));
  else Clipboard.copy(value.value);
};

const onFocus = () => (isFocused.value = true);
const onBlur = () => (isFocused.value = false);

defineExpose({
  input,
});
</script>
<style lang="scss">
.form-group {
  margin-bottom: rem(10px);
  position: relative;

  // Invalid
  &--invalid {
    & > .form-group__input,
    & > .dropdown > .dropdown__trigger > .form-group-display {
      border-color: $error;
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }
    }
  }

  // Label
  & > &__label-container {
    @include form-label;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: rem(4px) $inputPaddingX 0 $inputPaddingX;

    .form-group__label {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }

    // Controls
    .form-group__controls {
      display: flex;
      column-gap: rem(7.5px);
    }
  }

  // Copy icon
  .svg-icon--copy {
    margin-left: 5px;
    opacity: 0;
    width: 10px;
    height: 10px;
    cursor: pointer;
    color: color('gray', 5);
    &:hover {
      color: color('primary');
    }
  }
  &:hover .svg-icon--copy {
    opacity: unset;
  }

  // Uppercase
  &__input--uppercase {
    text-transform: uppercase;
  }

  // Disabled
  &__input[disabled] {
    pointer-events: none;
  }

  // Input
  & > .form-group__input {
    max-width: 100%;
  }
}

// Padding
.form-group__label-container ~ .form-group__input {
  padding-top: 0.8em;
}
</style>
