<template>
  <div class="column-label" @click.stop>
    <a v-if="!showInput" class="link" @click.stop="showInput = true"> + add label </a>
    <FormGroup v-else v-model="label" type="text" placeholder="Enter label" @click.stop @blur="update" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import FormGroup from '@/js/Components/Form/FormGroup.vue';

interface Props {
  label: string | null;
}

interface Emits {
  (e: 'update:label', label: string | null): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const label = ref(props.label);
const showInput = ref(props.label !== null);

const update = () => {
  if (props.label === label.value) return;
  emit('update:label', label.value);
};
</script>

<style lang="scss">
.column-label {
  .link {
    font-size: rem(12px);
  }
  .form-group {
    margin-bottom: 0;
    &__input {
      height: rem(28px);
      font-size: rem(16px);
      color: color('gray', 7);
      &::placeholder {
        color: color('gray', 4);
      }
    }
  }
}
</style>
