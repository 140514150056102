<template>
  <div class="select-coverages">
    <label class="select-coverages__label"> Select coverages </label>

    <!-- Primary coverages types -->
    <div class="select-coverages__grid" :style="{ 'grid-template-columns': `repeat(${columns}, 1fr)` }">
      <div
        v-for="(coverageType, i) in coverageTypesPrimary"
        :key="i"
        class="coverage primary"
        :class="{ highlighted: isSelected(coverageType), disabled: isDisabled(coverageType.id) }"
        @click="toggleSelected(coverageType)"
      >
        <div class="coverage__left">
          <InputCheckbox :model-value="isSelected(coverageType)" />
          <div class="coverage__details">
            <div class="name">{{ coverageType.name }}</div>
            <div v-if="coverageType.estimate_price" class="bottom">
              <div class="estimate">Estimate</div>
              <div class="price">${{ coverageType.estimate_price.low }} - ${{ coverageType.estimate_price.high }}</div>
            </div>
          </div>
        </div>
        <div class="coverage__right">
          <img class="dark" :src="coverageType.icon_url" />
          <img class="white" :src="coverageType.icon_url.replace('.svg', '') + '-white.svg'" />
        </div>
      </div>
    </div>

    <!-- Secondary coverage types -->
    <div class="select-coverages__grid secondary" :style="{ 'grid-template-columns': `repeat(${columns}, 1fr)` }">
      <div
        v-for="(coverageType, i) in coverageTypesSecondary"
        :key="i"
        class="coverage secondary"
        :class="{ highlighted: isSelected(coverageType), disabled: isDisabled(coverageType.id) }"
        @click="toggleSelected(coverageType)"
      >
        <div class="coverage__left">
          <InputCheckbox :model-value="isSelected(coverageType)" />
          <div class="name">{{ coverageType.name }}</div>
        </div>
        <div class="coverage__right" :class="[coverageType.estimate_price ? '' : 'no-price']">
          <img class="dark" :src="coverageType.icon_url" />
          <img class="white" :src="coverageType.icon_url.replace('.svg', '') + '-white.svg'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed, watch } from 'vue';
import useGlobalStore from '@/js/store/pinia/useGlobalStore';
import { CoverageType } from '@/js/Api/CoverageType';
import InputCheckbox from '@/js/Common/Form/Input/Checkbox.vue';

interface Props {
  columns?: number;
  selectedIds?: number[];
  disabledIds?: number[];
  allowedIds?: number[];
}

const props = withDefaults(defineProps<Props>(), {
  columns: 3,
  selectedIds: () => [],
  allowedIds: undefined,
  disabledIds: undefined,
});

const emit = defineEmits(['update:coverage-type-ids']);

// Coverage types
const { ref: coverageTypes } = useGlobalStore().swrCoverageTypes();
const coverageTypesPrimary = computed(() => {
  return coverageTypes.value ? coverageTypes.value.filter((c) => c.primary) : [];
});
const coverageTypesSecondary = computed(() => {
  return coverageTypes.value ? coverageTypes.value.filter((c) => !c.primary) : [];
});

// Selected ids (local)
const selectedIdsMapLocal: { [id: number]: boolean } = reactive({});
const selectedIdsArrayComputed = computed(() => Object.keys(selectedIdsMapLocal).map((id) => Number(id)));

// Update local state whenever parent state changes
watch(
  () => props.selectedIds,
  () => {
    Object.keys(selectedIdsMapLocal).forEach((id) => delete selectedIdsMapLocal[Number(id)]);
    props.selectedIds.forEach((id) => {
      selectedIdsMapLocal[Number(id)] = true;
    });
  },
  { immediate: true },
);

// Disabled ids
const disabledIdsMap = computed<Map<number, true> | undefined>(() => {
  if (!props.disabledIds) return undefined;
  return props.disabledIds.reduce((map, id) => {
    map.set(id, true);
    return map;
  }, new Map());
});

// Allowed ids map
const allowedIdsMap = computed<Map<number, true> | undefined>(() => {
  if (!props.allowedIds) return undefined;
  return props.allowedIds.reduce((map, id) => {
    map.set(id, true);
    return map;
  }, new Map());
});

const isDisabled = (id: number) => {
  if (allowedIdsMap.value) return !allowedIdsMap.value.has(id);
  if (disabledIdsMap.value) return disabledIdsMap.value.has(id);
  return false;
};

// Select
const isSelected = (coverageType: CoverageType) => !!selectedIdsMapLocal[coverageType.id];
const toggleSelected = async (coverageType: CoverageType) => {
  if (isSelected(coverageType)) delete selectedIdsMapLocal[coverageType.id];
  else selectedIdsMapLocal[coverageType.id] = !selectedIdsMapLocal[coverageType.id];
  emit('update:coverage-type-ids', selectedIdsArrayComputed.value);
};
</script>

<style lang="scss">
.select-coverages {
  $self: &;

  font-size: rem(16px);

  &__label {
    @include form-section-title;
  }

  // Submit
  #{ $self }__submit-container {
    @include center-content;
    margin-top: rem(20px);
  }

  // Grid
  #{ $self }__grid {
    display: grid;
    grid-gap: rem(20px);
    &.secondary {
      margin-top: rem(20px);
    }
  }

  // All coverages
  .coverage {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: rem(256px);
    padding: 1em;
    border-radius: rem(6px);
    border: rem(1px) solid #d5d5d5;
    background-color: white;

    // Coverage left
    &__left {
      display: flex;
    }

    // Highlighted
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &.highlighted,
    &:hover {
      background-color: color('primary');
      transition: 0.2s;
      .coverage__left .name {
        color: white;
      }
      .coverage__right img.dark {
        display: none;
      }
      .coverage__right img.white {
        display: initial;
      }
      .checkbox__checkmark-container {
        border-color: white;
        background-color: white;
        .checkbox__checkmark {
          color: color('primary');
        }
      }
    }
    .coverage__right img.white {
      display: none;
    }

    // Coverage checkbox
    .checkbox {
      font-size: rem(18px);
      &__input {
        transform: none;
      }
    }

    // Coverage name
    .name {
      font-size: rem(13px);
      line-height: rem(18px);
      color: color('text', 1);
    }
  }

  // Primary coverages
  .coverage.primary {
    height: rem(105px);
    padding-bottom: 0.5em;
    .coverage__details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 0.5em;
      font-size: 1em;
      .bottom {
        .estimate {
          font-size: 0.7em;
          line-height: 1em;
          color: color('gray', 5);
        }
        .price {
          white-space: nowrap;
          font-size: 0.9em;
          color: color('gray', 5);
        }
      }
    }
  }

  // Secondary coverages
  .coverage.secondary {
    .name {
      margin-left: 0.5em;
    }
  }
}
</style>
