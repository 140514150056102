<template>
  <Modal ref="ModalRef" class="client-merge-modal" :is-loading="modalLoading" @modalhidden="resetModal">
    <template #title> Merge clients </template>
    <template #footer>
      <Btn @click="merge">Merge clients</Btn>
    </template>

    <!-- Client search -->
    <ClientSearch v-if="!selectedClient" @item:selected="selectClient" />

    <template v-else>
      <a class="link" @click="resetModal"> Reset </a>

      <!-- Preview -->
      <div class="client-merge-modal__preview">
        <div>
          Merging from:
          <a class="link" :href="`/clients/${selectedClient.id}`" target="_blank">{{ selectedClient.name }}</a>
        </div>
        <div>
          Merging to:
          <a class="link" :href="`/clients/${client.id}`" target="_blank">{{ client.name }}</a>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import api from '@/js/Api/Client';
import Btn from '@/js/Components/Btn.vue';
import Modal from '@/js/Common/Modal.vue';
import { toast, toastError } from '@/js/Helpers/Alert';
import ClientSearch from './Search.vue';

const props = defineProps({
  client: {
    type: Object,
    required: true,
  },
});

const selectedClient = ref(null);

const ModalRef = ref(null);
const modalLoading = ref(false);

const selectClient = (item) => {
  if (item.id === props.client.id) {
    toast({ title: 'You selected the current client', icon: 'warning' });
  } else {
    modalLoading.value = true;
    api
      .find({ id: item.id })
      .then((response) => {
        selectedClient.value = response.data;
      })
      .catch(() => {
        toastError();
      })
      .finally(() => {
        modalLoading.value = false;
      });
  }
};

const resetModal = () => {
  selectedClient.value = null;
};

const router = useRouter();
const merge = () => {
  if (!selectedClient.value) {
    toast({ title: 'Please select a client', icon: 'warning' });
  } else {
    modalLoading.value = true;
    api
      .merge({
        primaryID: props.client.id,
        mergingID: selectedClient.value.id,
      })
      .then(() => {
        router.go();
      })
      .catch(() => {
        toastError();
      })
      .finally(() => {
        modalLoading.value = false;
      });
  }
};
</script>

<style lang="scss">
.client-merge-modal {
  .modal__body {
    overflow: unset;
  }

  &__preview {
    margin-top: rem(50px);
  }
}
</style>
