<template>
  <form class="filters">
    <div class="filters__text">Filter by</div>
    <slot></slot>
  </form>
</template>

<script setup lang="ts"></script>

<style lang="scss">
$paddingX: rem(7.5px);
$labelMarginRight: rem(10px);

.filters {
  // Alignment
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: rem(22px) 0;

  // Padding
  > * {
    padding: 0 $paddingX;
  }

  // Border
  > *:not(:last-child) {
    border-right: rem(1px) solid #ccc;
  }

  // Text
  .filters__text {
    padding-left: 0;
    font-size: rem(14px);
    font-weight: bold;
    color: color('gray');
    border-right: none;
  }

  // Form group
  > .form-group {
    margin: 0;
    padding: 0 $paddingX;
    display: flex;
    align-items: center;

    .form-group__label-container {
      width: unset;
      position: static;
      padding: 0 !important;
      margin-right: $labelMarginRight;

      .form-group__label {
        font-size: rem(14px);
        position: static;
        flex-direction: row-reverse;
        .copy-btn {
          margin: 0;
          margin-right: rem(5px);
        }
      }
    }

    .form-group__input {
      cursor: pointer;
      padding: 0 !important;
      height: unset;
      border: none;
      background: none;
    }
  }

  // Text
  > .form-group.text .form-group__input {
    border-bottom: rem(1px) solid black;
  }

  // Number
  > .form-group.number .form-group__input {
    width: #{$paddingX * 3};
  }

  > .form-group.ajax-select,
  > .form-group.select {
    > .dropdown {
      > .dropdown__trigger {
        > .form-group-display {
          padding: 0;
          margin: 0;
          border: unset;
          height: unset;
          min-height: unset;
          background-color: unset;
          flex-direction: row;
          align-items: center;
          .form-group-display__label {
            font-size: rem(14px);
            display: flex;
            flex-direction: row-reverse;
            margin-right: $labelMarginRight;
            .copy-btn {
              margin: 0;
              margin-right: rem(5px);
            }
          }
          .svg-icon--caret-down {
            width: rem(13px);
            height: rem(13px);
            margin-left: rem(5px);
          }
          .form-group-display__content {
            padding: 0;
            .form-group-display__content-value {
              font-size: rem(14px);
              align-items: center;
              flex-direction: row;
            }
          }
        }
      }
    }
  }
}
</style>
