<template>
  <div
    :class="{
      'tree-explorer-node': true,
      'tree-explorer-node--nested': nested,
      'tree-explorer-node--active': isActive,
    }"
  >
    <div class="tree-explorer-node__main" @click="emit('click:node', node)">
      <SvgIcon v-if="node.type === 'file'" name="file" />
      <SvgIcon v-else name="folder" />
      <div class="tree-explorer-node__name">
        {{ node.name }}
        <template v-if="node.type === 'folder'"> ({{ node.tree.length }}) </template>
        <div
          v-if="node.policy && (node.policy.number || node.policy.effective_date || node.policy.expiration_date)"
          class="tree-explorer-node__details"
        >
          Policy: {{ getPolicyDetails(node.policy) }}
        </div>
      </div>
    </div>
    <div v-if="node.type === 'folder'" class="tree-explorer-node__children">
      <slot />
    </div>
  </div>
</template>

<script setup>
import SvgIcon from '@/js/Components/SvgIcon.vue';
import { toDateString } from '@/js/Helpers';

defineProps({
  node: {
    type: Object,
    required: true,
  },
  nested: {
    type: Boolean,
    default: false,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['click:node']);

const getPolicyDetails = (policy) => {
  return [
    policy.number,
    [toDateString(policy.effective_date), toDateString(policy.expiration_date)].filter((el) => el).join(' - '),
  ]
    .filter((el) => el)
    .join(', ');
};
</script>

<style lang="scss">
.tree-explorer-node {
  margin-top: rem(5px);
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  // Vertical line
  &:not(&--nested)::after {
    content: '';
    display: block;
    background-color: #ccc;
    position: absolute;
    top: rem(28px);
    left: rem(21px);
    height: calc(100% - #{rem(60px)});
    width: rem(1px);
  }

  // Main
  &__main {
    display: inline-flex;
    align-items: center;
    color: color('gray', 9);
    padding: rem(3px) rem(10px);
    border-radius: rem(15px);
    transition: 0.1s background-color;
    cursor: pointer;
    font-size: rem(15px);
  }

  &--active > &__main,
  &__main:hover {
    background-color: #dfe2e6;
    color: black;
  }

  &--nested &__main {
    position: relative;
    &::before {
      content: '';
      display: block;
      background-color: #ccc;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: rem(-23px);
      height: rem(1px);
      width: rem(22px);
    }
  }

  // Icon
  .svg-icon {
    min-width: rem(30px);
    min-height: rem(30px);
    color: #757575;
    margin-right: rem(7px);
    &--file {
      min-width: rem(20px);
      max-width: rem(20px);
      margin-left: rem(3px);
      margin-right: rem(15px);
    }
  }
  &__main:not(&--nested &__main) .svg-icon {
    margin-right: rem(20px);
  }

  // Name
  &__name {
    max-width: rem(350px);
    margin-right: rem(5px);
  }

  // Details
  &__details {
    color: #999999;
  }

  // Children
  & > &__children {
    padding-left: rem(45px);
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__children:not(&--nested &__children) {
    margin-top: rem(5px);
    margin-bottom: rem(20px);
  }
}
</style>
