<template>
  <FormGridGroup>
    <FormGridRow
      v-for="(phone, i) in phones"
      :key="phone.id"
      class="phone"
      :style="{ 'grid-template-columns': '1fr 1fr' }"
      :autosave-url="`${endpointBase}/phones/${phone.id}/autosave`"
    >
      <!-- Phones row -->
      <ContactsPhonesRow :disabled="disabled" :phone="phone" @update:phone="(phone) => update(phone, i)" />

      <!-- Dots menu -->
      <template #dots-links-prepend>
        <a v-if="isContactPrimary && !phone.is_primary" class="dropdown-item" @click.prevent="makePrimary(phone.id)">
          <img class="icon verified" src="/img/icons/verified_blue-24px.svg" />
          Make primary
        </a>
        <a v-if="phone.number" class="dropdown-item" :href="`tel:${phone.number}`">
          <img class="icon" src="/img/icons/dial-green.svg" />
          Dial number
        </a>
        <a v-if="phones.length > 1" class="dropdown-item delete" @click.prevent="destroy(phone.id)">
          <i class="fas fa-trash-alt icon"></i>
          Delete Phone
        </a>
      </template>
    </FormGridRow>
  </FormGridGroup>
</template>

<script>
import useAlert from '@/js/Composables/useAlert';
import useLoader from '@/js/Composables/useLoader';

import FormGridGroup from '@/js/Common/Form/FormGrid/FormGridGroup.vue';
import FormGridRow from '@/js/Common/Form/FormGrid/FormGridRow.vue';

import ContactsPhonesRow from './ContactsPhonesRow.vue';

export default {
  components: {
    FormGridGroup,
    FormGridRow,
    ContactsPhonesRow,
  },

  props: {
    phones: {
      type: Array,
      required: true,
    },
    contactId: {
      type: Number,
      required: true,
    },
    isContactPrimary: {
      type: [Boolean, Number],
      default: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['update:phones'],

  setup(props, { emit }) {
    // Endpoint base
    const endpointBase = `/api/contacts/${props.contactId}`;

    // Tools
    const { loader } = useLoader();
    const { toast, confirm } = useAlert();

    // Delete phone
    const destroy = async (phoneID) => {
      const result = await confirm();
      if (!result.isConfirmed) return;
      loader.start();
      axios
        .post(`${endpointBase}/phones/${phoneID}`, { _method: 'DELETE' })
        .then(() => {
          emit(
            'update:phones',
            props.phones.filter((p) => p.id !== phoneID)
          );
          toast({ title: 'Success', icon: 'success' });
        })
        .catch(() => {
          toast({ title: 'An error occurred.', icon: 'error' });
        })
        .finally(() => {
          loader.end();
        });
    };

    // Update phone
    const update = (phone, i) => {
      const phones = [...props.phones];
      phones[i] = phone;
      emit('update:phones', phones);
    };

    // Make phone primary
    const makePrimary = (phoneID) => {
      loader.start();
      axios
        .post(`${endpointBase}/phones/${phoneID}/primary`, { _method: 'PUT' })
        .then(() => {
          emit(
            'update:phones',
            props.phones.map((p) => ({
              ...p,
              is_primary: p.id === phoneID,
            }))
          );
          toast({ title: 'Success', icon: 'success' });
        })
        .catch(() => {
          toast({ title: 'An error occurred.', icon: 'error' });
        })
        .finally(() => {
          loader.end();
        });
    };

    return {
      update,
      destroy,
      makePrimary,
      endpointBase,
    };
  },
};
</script>
