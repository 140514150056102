<template>
  <div class="processing-header">

    <!-- Back to quoting -->
    <router-link :to="`/clients/${policy.client_id}`" class="navigation-link">
      <i class="fas fa-arrow-left icon nav-prev"></i>
      Back to<br>
      quoting
    </router-link>

    <!-- Progress bar -->
    <ProgressBar
      :steps="[
        { title: `Endorsement info`, active: false},
        { title: 'Quote requested', active: false},
        { title: 'Items pending', active: false},
        { title: 'Quoting', active: false},
        { title: 'To present', active: false},
        { title: 'Working', active: false},
        { title: 'Sold (Processing)', active: policy.status === 'Sold (Processing)' },
        { title: 'Sold', active: policy.status === 'Sold' },
      ]"/>

    <!-- Go to policy -->
    <router-link :to="`/clients/${policy.client_id}/policies/${policy.id}`" class="navigation-link">
      Continue<br>
      to policy
      <i class="fas fa-arrow-right icon nav-next"></i>
    </router-link>

  </div>
</template>

<script>
import ProgressBar from '@/js/Components/ProgressBar.vue';

export default {
  components: {
    ProgressBar
  },

  props: {
    policy: {
      type: Object,
      required: true
    }
  },
};
</script>

<style lang="scss" scoped>
.processing-header {
  margin-top: rem(14px);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  // Navigation link
  .navigation-link {
    display: flex;
    align-items: center;
    color: #1A497F;
    font-size: rem(14px);
    line-height: rem(16px);
    text-decoration: none;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #CCCCCC;
      border-radius: 50%;
      width: rem(36px);
      height: rem(36px);
      font-size: rem(16px);
      background-color: white;
      &.nav-prev {
        margin-right: rem(10px);
      }
      &.nav-next {
        margin-left: rem(10px);
      }
    }
  }

  // Progress bar
  .progress-bar {
    padding: 0 rem(77px);
    :deep(.step) {
      &:nth-of-type(1) {
        .step-title {
          transform: translateX(-50%) !important;
        }
      }
      .step-title {
        max-width: rem(65px);
        text-align: center;
      }
    }
  }
}
</style>
