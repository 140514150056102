<template>
  <div class="policy-list">
    <PolicyListItem v-for="policy in policies" :key="policy.id" :policy="policy" @click="emit('clicked', policy)" />
  </div>
</template>

<script setup lang="ts">
import { Policy } from '@/js/Api/Policy';
import PolicyListItem from './ListItem.vue';

defineProps<{
  policies: Policy[];
}>();

const emit = defineEmits(['clicked']);
</script>
