<template>
  <div class="form-group" :class="groupClassComputed" :style="groupStyle" @click="(e) => emit('click', e)">
    <!-- Label -->
    <slot v-if="attrs.type !== 'ajax-select' && !attrs.multivalue" name="label">
      <div v-if="labelText" class="form-group__label-container">
        <label class="form-group__label" @click="input?.focus()">
          {{ labelText }}
          <CopyBtn v-if="copyBtn" @click.stop="copyValue" />
        </label>
        <div class="form-group__controls">
          <i v-if="showTodayIcon" class="fas fa-calendar-day today" @click="todayIconClick"></i>
          <slot name="controls" />
          <!-- Deprecated (use controls) -->
          <slot name="label-append" />
        </div>
      </div>
    </slot>

    <!-- Ajax select -->
    <AjaxSelect
      v-if="attrs.type === 'ajax-select'"
      :model-value="modelValue"
      :multivalue="attrs.multivalue"
      :fetch-options="attrs['fetch-options']"
      :label-method="attrs['label-method']"
      :label="attrs['label']"
      :disabled="isDisabled"
      :required="!!attrs.required"
      :on-select="attrs['on-select']"
      :filter-checkboxes="attrs['filter-checkboxes']"
      @update:model-value="(payload) => emit('update:model-value', payload)"
      @selected="(payload) => emit('selected', payload)"
    >
      <template #trigger>
        <slot name="trigger" />
      </template>
      <template #label>
        <slot name="label" />
      </template>
      <template #controls>
        <slot name="controls" />
      </template>

      <template #selected-option="{ item }">
        <slot name="selected-option" :item="item" />
      </template>

      <template #prefix>
        <slot name="prefix" />
      </template>

      <template #item="{ item }">
        <slot name="item" :item="item" />
      </template>
    </AjaxSelect>

    <!-- New select (only to be used when type="select" and :multivalue="true") -->
    <Select
      v-else-if="attrs.type === 'select' && attrs.multivalue"
      :model-value="modelValue"
      :multivalue="attrs.multivalue"
      :options="options"
      :label-method="attrs['label-method']"
      :label="attrs['label']"
      :disabled="isDisabled"
      :required="!!attrs.required"
      :on-select="attrs['on-select']"
      :filter-checkboxes="attrs['filter-checkboxes']"
      @update:model-value="(payload) => emit('update:model-value', payload)"
      @selected="(payload) => emit('selected', payload)"
    >
      <template #trigger>
        <slot name="trigger" />
      </template>
      <template #label>
        <slot name="label" />
      </template>
      <template #controls>
        <slot name="controls" />
      </template>

      <template #selected-option="{ item }">
        <slot name="selected-option" :item="item" />
      </template>

      <template #prefix>
        <slot name="prefix" />
      </template>

      <template #item="{ item }">
        <slot name="item" :item="item" />
      </template>
    </Select>

    <!-- Select -->
    <select
      v-else-if="attrs.type === 'select'"
      ref="input"
      v-bind="bindAttrs"
      :style="inputStyle"
      class="form-group__input form-control"
      :class="inputClassComputed"
      :value="options ? options.find((o) => o.value === modelValue).label : modelValue"
      @input="onInput"
    >
      <slot>
        <option v-for="option in options" :key="option.label" :value="option.label">
          {{ option.label }}
        </option>
      </slot>
    </select>

    <!-- Textarea -->
    <textarea
      v-else-if="attrs.type === 'textarea'"
      ref="input"
      v-bind="bindAttrs"
      :style="inputStyle"
      class="form-group__input form-control"
      :class="inputClassComputed"
      :value="modelValue"
      spellcheck="false"
      @input="onInput"
      @paste="onPaste"
    >
    </textarea>

    <!-- Date -->
    <input
      v-else-if="attrs.type === 'date' && !isDisabled"
      ref="input"
      v-bind="bindAttrs"
      :style="inputStyle"
      class="form-group__input form-control"
      :class="inputClassComputed"
      :value="modelValue"
      @input="onInput"
    />
    <input
      v-else-if="attrs.type === 'date' && isDisabled"
      ref="input"
      v-bind="bindAttrs"
      type="text"
      :style="inputStyle"
      class="form-group__input form-control"
      :class="inputClassComputed"
      :value="toDateString(modelValue)"
      @input="onInput"
    />

    <!-- Money -->
    <MoneyInput
      v-else-if="attrs.type === 'currency'"
      ref="input"
      v-bind="bindAttrs"
      :style="inputStyle"
      class="form-group__input form-control"
      :class="inputClassComputed"
      :model-value="modelValue"
      autocomplete="off"
      spellcheck="false"
      @update:model-value="onCurrencyChange"
    />

    <!-- Percentage -->
    <PercentageInput
      v-else-if="attrs.type === 'percentage'"
      ref="input"
      v-bind="bindAttrs"
      :style="inputStyle"
      class="form-group__input form-control"
      :class="inputClassComputed"
      :model-value="modelValue"
      autocomplete="off"
      spellcheck="false"
      @update:model-value="onCurrencyChange"
    />

    <!-- Masked -->
    <MaskedInput
      v-else-if="attrs.type === 'masked'"
      :mask="props.mask"
      :unmasked="props.unmask"
      v-bind="bindAttrs"
      :style="inputStyle"
      class="form-group__input form-control"
      :class="inputClassComputed"
      :model-value="modelValue"
      autocomplete="off"
      spellcheck="false"
      @update:model-value="onMaskedChange"
    />

    <!-- Else -->
    <input
      v-else
      ref="input"
      v-bind="bindAttrs"
      :style="inputStyle"
      class="form-group__input form-control"
      :class="inputClassComputed"
      :value="modelValue"
      autocomplete="off"
      spellcheck="false"
      @change="
        (e) => {
          if (attrs.type === 'money') formatMoney(e, modelModifiers);
          update(e);
        }
      "
      @input="onInput"
      @paste="onPaste"
      @keypress="
        (e) => {
          if (attrs.type === 'phone') formatPhone(e);
          else if (attrs.type === 'zip') formatZIP(e);
        }
      "
      @copy="
        (e) => {
          if (attrs.type === 'phone') copyPhone(e);
        }
      "
    />

    <span v-if="errorMessage" class="error">{{ errorMessage }}</span>
  </div>
</template>

<script setup>
import { useField } from 'vee-validate';
import axios from 'axios';
import { inject, ref, computed, useAttrs } from 'vue';
import { confirm, toastError, toastSuccess } from '@/js/Helpers/Alert';
import debounce from '@/js/Helpers/Debounce';
import useGlobalStore from '@/js/store/pinia/useGlobalStore';
import { toDateString } from '@/js/Helpers';
import MaskedInput from '@/js/Common/Form/FormGroup/MaskedInput.vue';
import CopyBtn from '@/js/Components/CopyBtn.vue';
import Clipboard from '@/js/Helpers/Clipboard';
import MoneyInput from '@/js/Common/Form/FormGroup/MoneyInput.vue';
import PercentageInput from '@/js/Common/Form/FormGroup/PercentageInput.vue';
import Select from './Select.vue';
import AjaxSelect from './AjaxSelect.vue';
import { formatZIP, formatPhone, formatMoney, copyPhone, applyModifers } from './utils';

const props = defineProps({
  modelValue: {
    type: null,
    default: null,
  },
  modelModifiers: {
    type: Object,
    default: () => ({}),
  },
  options: {
    type: [Array, null],
    default: null,
  },

  groupClass: {
    type: [Object, String, Array],
    default: null,
  },
  groupStyle: {
    type: [Object, String, Array],
    default: null,
  },

  inputClass: {
    type: [Object, String, Array],
    default: null,
  },
  inputStyle: {
    type: [Object, String, Array],
    default: null,
  },

  labelText: {
    type: String,
    default: null,
  },
  labelHidden: {
    type: [Boolean],
  },
  copyBtn: {
    type: Boolean,
    default: true,
  },

  // Date
  todayIcon: {
    type: Boolean,
    default: false,
  },

  // Autosave
  autosave: {
    type: Boolean,
    default: true,
  },
  autosaveUrl: {
    type: String,
    default: null,
  },
  autosaveParams: {
    type: Object,
    default: () => ({}),
  },
  autosaveNotification: {
    type: [Boolean, Function],
    default: false,
  },
  afterAutosave: {
    type: Function,
    default: null,
  },

  autosaveOnError: {
    type: Boolean,
    default: false,
  },

  // Callbacks
  formatValue: {
    type: Function,
    default: null,
  },
  beforeUpdate: {
    type: Function,
    default: null,
  },

  mask: {
    type: Function,
    default: () => () => ({}),
  },
  unmask: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'click',
  'input',
  'paste',
  'change',
  'updated',
  'autosave',
  'after-autosave',
  'selected',
  'update:model-value',
  'update:modelValue',
]);

const attrs = useAttrs();

const { errorMessage } = attrs.name ? useField(attrs.name, undefined) : {};

const bindAttrs = computed(() => {
  const attrsTemp = { ...attrs };
  if (!['text', 'number', 'date', 'email'].includes(attrs.type)) delete attrsTemp.type;
  return attrsTemp;
});

const input = ref(null);

const isDisabled = computed(() => !!attrs.disabled);
const isRequired = computed(() => !!attrs.required);
const isReadOnly = computed(() => !!attrs.readonly);
const isInvalid = computed(() => (errorMessage ? Boolean(errorMessage.value) : false));

const groupClassComputed = computed(() => {
  return [
    props.groupClass,
    attrs.type,
    attrs.name,
    {
      disabled: isDisabled.value,
      required: isRequired.value,
      readonly: isReadOnly.value,
    },
  ];
});

const inputClassComputed = computed(() => [
  props.inputClass,
  {
    'is-invalid': isInvalid.value, // Deprecated (use form-group__input--invalid)
    'form-group__input--invalid': isInvalid.value,
    'form-group__input--uppercase': props.modelModifiers.uppercase,
  },
]);

// Date
const showTodayIcon = computed(() => !isDisabled.value && attrs.type === 'date' && props.todayIcon);
const todayIconClick = async () => {
  const confirmResult = await confirm({
    text: `Confirm ${props.labelText} today`,
    confirmButtonText: 'Confirm',
    icon: 'question',
  });
  if (confirmResult.isConfirmed) {
    input.value.value = new Date().toDateInput();
    input.value.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
  }
};

const autosaveUrlFromFormContainer = inject('FormContainer-autosaveUrl', false);

const globalStore = useGlobalStore();
const setProcessingPromise = (promise) => {
  globalStore.processingPromise = promise;
};

const autosaveEndpoint = computed(() => props.autosaveUrl || autosaveUrlFromFormContainer.value);

const canAutosave = () => {
  const invalid = input.value ? input.value.classList?.contains('is-invalid') : false;
  return (invalid ? props.autosaveOnError : true) && !!autosaveEndpoint.value && props.autosave && !!attrs.name;
};

let saveDebounced = null;
const save = (value) => {
  if (!canAutosave()) return false;

  const body = {
    _method: 'PUT',
    value,
    name: attrs.name,
    ...props.autosaveParams,
  };

  if (attrs.name !== 'name') body[attrs.name] = value;

  return axios
    .post(autosaveEndpoint.value, body)
    .then((response) => {
      if (
        (typeof props.autosaveNotification === 'boolean' && props.autosaveNotification) ||
        (typeof props.autosaveNotification === 'function' && props.autosaveNotification(input.value.value))
      )
        toastSuccess('Changes have been saved.');

      if (props.afterAutosave) props.afterAutosave(response);
      else emit('after-autosave', response, input.value);
    })
    .catch(() => {
      toastError('An error occurred while saving data.');
    });
};

const update = async (e) => {
  // Don't do anything if input is invalid
  if (!e.target.validity.valid) return;
  if (attrs.type === 'email' && e.target.value && !/^.+@.+\..+$/.test(e.target.value)) return;

  if (props.beforeUpdate) {
    const result = await props.beforeUpdate(e);
    if (result === false) {
      e.target.value = props.modelValue;
      return;
    }
  }

  let { value } = e.target;
  value = value === '' ? null : value;

  if (attrs.type === 'select' && props.options) {
    const input = e.target;
    const selectedOptionLabel = input.options[input.selectedIndex].text.trim();
    const selectedPropOption = props.options.find((o) => o.label === selectedOptionLabel);
    value = selectedPropOption.value;
  }

  value = applyModifers(value, props.modelModifiers);

  if (props.formatValue) value = props.formatValue(e, value);

  emit('update:model-value', value);
  emit('updated', e, value);
  emit(e.type, e, value);

  // Autosave
  if (canAutosave()) {
    saveDebounced = saveDebounced ?? debounce(save, 1000);
    const promise = saveDebounced(value);
    setProcessingPromise(promise);
  }
};

const onInput = async (e) => {
  if (attrs.type === 'money') return emit('input', e);

  return update(e);
};

const onCurrencyInput = (e) => {
  emit('input', e);
};

const onCurrencyChange = (value) => {
  emit('update:model-value', value);

  if (canAutosave()) {
    saveDebounced = saveDebounced ?? debounce(save, 1000);
    const promise = saveDebounced(value);
    setProcessingPromise(promise);
  }
};

const onMaskedChange = (value) => {
  emit('update:model-value', value);

  if (canAutosave()) {
    saveDebounced = saveDebounced ?? debounce(save, 1000);
    const promise = saveDebounced(value);
    setProcessingPromise(promise);
  }
};

const onPaste = (e) => {
  // FIXME - why is item undefined?
  const item = e.clipboardData.items[0];
  if (item && item?.kind !== 'string') {
    e.preventDefault();
    emit('paste', e);
  }
};

const copyValue = () => {
  if (attrs.type === 'date') Clipboard.copy(toDateString(props.modelValue));
  else Clipboard.copy(props.modelValue);
};

defineExpose({
  input,
});
</script>
<style lang="scss">
.form-group {
  margin-bottom: rem(10px);
  position: relative;

  // Required
  &--required {
    .form-group__label::after {
      content: '*';
      color: red;
      margin-left: rem(6px);
    }
  }

  // Label
  & > &__label-container {
    @include form-label;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: rem(4px) $inputPaddingX 0 $inputPaddingX;

    .form-group__label {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }

    // Controls
    .form-group__controls {
      display: flex;
      column-gap: rem(7.5px);
    }
  }

  // Copy icon
  .svg-icon--copy {
    margin-left: 5px;
    opacity: 0;
    width: 10px;
    height: 10px;
    cursor: pointer;
    color: color('gray', 5);
    &:hover {
      color: color('primary');
    }
  }
  &:hover .svg-icon--copy {
    opacity: unset;
  }

  // Uppercase
  &__input--uppercase {
    text-transform: uppercase;
  }

  // Disabled
  &__input[disabled] {
    pointer-events: none;
  }

  // Input
  & > .form-group__input {
    max-width: 100%;
  }
}

// Padding
.form-group__label-container ~ .form-group__input {
  padding-top: 0.8em;
}
</style>
