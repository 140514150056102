<template>
  <FormGridGroup>
    <FormGridRow
      v-for="(email, i) in emails"
      :key="email.id"
      class="email"
      :style="{ 'grid-template-columns': '1fr 1fr' }"
      :autosave-url="`${endpointBase}/emails/${email.id}/autosave`"
    >
      <!-- Emails row -->
      <ContactsEmailsRow :disabled="disabled" :email="email" @update:email="(email) => update(email, i)" />

      <!-- Dots menu -->
      <template #dots-links-prepend>
        <a
          v-if="isContactPrimary && !email.is_primary"
          href="#"
          class="dropdown-item"
          @click.prevent="makePrimary(email.id)"
        >
          <img class="icon verified" src="/img/icons/verified_blue-24px.svg" />
          Make primary
        </a>
        <a v-if="email.email" class="dropdown-item" @click="openClientCompose(email)">
          <i class="fas fa-envelope-open-text icon"></i>
          Send email
        </a>
        <a v-if="emails.length > 1" href="#" class="dropdown-item delete" @click.prevent="destroy(email.id)">
          <i class="fas fa-trash-alt icon"></i>
          Delete Email
        </a>
      </template>
    </FormGridRow>
  </FormGridGroup>
</template>

<script>
import { useStore } from 'vuex';
import useClientStore from '@/js/store/pinia/useClientStore';

import useAlert from '@/js/Composables/useAlert';
import useLoader from '@/js/Composables/useLoader';

import FormGridGroup from '@/js/Common/Form/FormGrid/FormGridGroup.vue';
import FormGridRow from '@/js/Common/Form/FormGrid/FormGridRow.vue';

import ContactsEmailsRow from './ContactsEmailsRow.vue';

export default {
  components: {
    FormGridGroup,
    FormGridRow,
    ContactsEmailsRow,
  },

  props: {
    emails: {
      type: Array,
      required: true,
    },
    contactId: {
      type: Number,
      required: true,
    },
    isContactPrimary: {
      type: [Boolean, Number],
      default: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['update:emails'],

  setup(props, { emit }) {
    // Endpoint base
    const endpointBase = `/api/contacts/${props.contactId}`;

    // Tools
    const store = useStore();
    const clientStore = useClientStore();
    const { loader } = useLoader();
    const { toast, confirm } = useAlert();

    // Compose
    const openClientCompose = (email) => {
      store.dispatch('draft/openCompose', {
        action: 'new',
        payload: {
          receivers: [
            {
              email: email.email,
              personal: '',
              full: `<${email.email}>`,
            },
          ],
          client_id: email.client_id,
          // FIX ME
          subject: `INSURANCE: ${clientStore.client.name}`,
        },
      });
    };

    // Delete email
    const destroy = async (emailID) => {
      const result = await confirm();
      if (!result.isConfirmed) return;
      loader.start();
      axios
        .post(`${endpointBase}/emails/${emailID}`, { _method: 'DELETE' })
        .then(() => {
          emit(
            'update:emails',
            props.emails.filter((e) => e.id !== emailID)
          );
          toast({ title: 'Success', icon: 'success' });
        })
        .catch(() => {
          toast({ title: 'An error occurred.', icon: 'error' });
        })
        .finally(() => {
          loader.end();
        });
    };

    // Update email
    const update = (email, i) => {
      const emails = [...props.emails];
      emails[i] = email;
      emit('update:emails', emails);
    };

    // Make email primary
    const makePrimary = (emailID) => {
      loader.start();
      axios
        .post(`${endpointBase}/emails/${emailID}/primary`, { _method: 'PUT' })
        .then(() => {
          emit(
            'update:emails',
            props.emails.map((e) => ({
              ...e,
              is_primary: e.id === emailID,
            }))
          );
          toast({ title: 'Success', icon: 'success' });
        })
        .catch(() => {
          toast({ title: 'An error occurred.', icon: 'error' });
        })
        .finally(() => {
          loader.end();
        });
    };

    return {
      update,
      destroy,
      makePrimary,
      endpointBase,
      openClientCompose,
    };
  },
};
</script>
