<template>
  <div class="default-mailbox-notifications">
    <span v-if="count" class="default-mailbox-notifications__count">
      {{ count }}
    </span>

    <i class="fas fa-envelope-open-text"></i>
  </div>
</template>

<script>
export default {
  name: 'DefaultMailboxNotifications',

  props: {
    count: {
      type: Number,
      default: 0,
    }
  }
}
</script>

<style lang="scss">
.default-mailbox-notifications {
  color: white;
  display: inline-flex;
  align-items: center;
  margin-right: 1.66667rem;
  position: relative;

  &__count {
    background-color: red;
    border-radius: 100%;
    height: 1.1rem;
    width: 1.1rem;
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    top: -8px;
    right: -8px;
  }

  i {
    font-size: 20px;
  }
}
</style>
