<template>
  <div v-if="policy" class="policy-form">
    <!-- Carrier -->
    <CarrierInput name="carrier_id" :disabled="!canEdit" :initial-options="policy.carrier" :autosave-method="save" />

    <!-- Agency -->
    <AgencyInput name="agency_id" :disabled="!canEdit" :initial-options="policy.agency" :autosave-method="save" />

    <!-- Number -->
    <FormGroup
      type="text"
      name="number"
      label-text="Policy number"
      :disabled="!canEdit"
      :model-modifiers="{ uppercase: true }"
      :autosave-method="save"
    />

    <!-- Effective / Expiration dates -->
    <FormGridSimple
      grid-template-columns="1fr 1fr"
      :class="{ 'form-grid-simple--invalid': !values.effective_date || !values.expiration_date }"
    >
      <FormGroup
        type="date"
        name="effective_date"
        label-text="Effective date"
        placeholder="-"
        :today-icon="true"
        :disabled="!canEdit"
        :autosave-method="save"
      />
      <FormGroup
        type="date"
        name="expiration_date"
        label-text="Expiration date"
        placeholder="-"
        :disabled="!canEdit"
        :autosave-method="save"
      >
        <template #controls>
          <span v-if="values.effective_date" class="policy-form__plus-btn" role="button" @click="onPlusBtnClick(6)"
            >+6</span
          >
          <span v-if="values.effective_date" class="policy-form__plus-btn" role="button" @click="onPlusBtnClick(12)"
            >+12</span
          >
        </template>
      </FormGroup>
    </FormGridSimple>

    <!-- Premium -->
    <FormGroup type="currency" name="premium" label-text="Premium" :disabled="!canEdit" :autosave-method="save">
<!--      <template #controls>-->
<!--        <router-link-->
<!--          class="link"-->
<!--          :to="{-->
<!--            name: 'ClientInvoices',-->
<!--            params: { clientId: policy.client_id },-->
<!--            query: { policy_id: policy.id },-->
<!--          }"-->
<!--        >-->
<!--          Invoices-->
<!--        </router-link>-->
<!--      </template>-->
    </FormGroup>

    <!-- Billing type -->
    <FormGroup
      type="select"
      name="billing_type"
      label="Billing type"
      :disabled="!canEdit"
      :options="BILLING_TYPE_OPTIONS"
      :autosave-method="save"
    />

    <!-- Finance company -->
    <FinanceCompanyInput
      v-if="values.billing_type === 'Agency Bill - Financed'"
      name="finance_company_id"
      :disabled="!canEdit"
      :autosave-method="save"
    />

    <!-- Finance account # -->
    <FormGroup
      v-if="values.billing_type === 'Agency Bill - Financed'"
      type="text"
      name="finance_account_number"
      label-text="Finance account #"
      :disabled="!canEdit"
      :autosave-method="save"
    />

    <div></div>

    <!-- Remarks -->
    <FormGroup type="text" name="remarks" label-text="Remarks" :disabled="!canEdit" :autosave-method="save" />

    <!-- Label -->
    <FormGroup type="text" name="label" label-text="Label" :disabled="!canEdit" :autosave-method="save" />
  </div>
</template>

<script setup lang="ts">
import { z } from 'zod';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import { confirm, toastError } from '@/js/Helpers/Alert';
import { toDateInput } from '@/js/Helpers';
import PolicyApi, { Policy } from '@/js/Api/Policy';
import { BILLING_TYPES, BILLING_TYPE_OPTIONS } from '@/js/Presenters/Policy';
import FormGridSimple from '@/js/Common/Form/FormGrid/FormGridSimple.vue';
import FormGroup from '@/js/Components/Form/FormGroup.vue';
import CarrierInput from '@/js/Components/Carriers/Input.vue';
import AgencyInput from '@/js/Components/Agencies/Input.vue';
import FinanceCompanyInput from '@/js/Components/FinanceCompanies/Input.vue';

interface Props {
  canEdit: boolean;
  policy: Policy;
}

const props = defineProps<Props>();

const FormSchema = z.object({
  carrier_id: z.number().nullable(),
  agency_id: z.number().nullable(),
  number: z.string(),
  effective_date: z.string(),
  expiration_date: z.string(),
  premium: z.number(),
  finance_company_id: z.number().nullable(),
  billing_type: z.enum(BILLING_TYPES).nullable(),
  finance_account_number: z.string().nullable(),
  remarks: z.string().nullable(),
  label: z.string().nullable(),
});

type Form = z.infer<typeof FormSchema>;

const { values, setFieldValue } = useForm({
  validateOnMount: true,
  validationSchema: toTypedSchema(FormSchema),
  initialValues: {
    carrier_id: props.policy.carrier_id,
    agency_id: props.policy.agency_id,
    number: props.policy.number ?? undefined,
    effective_date: props.policy.effective_date ?? undefined,
    expiration_date: props.policy.expiration_date ?? undefined,
    premium: props.policy.premium ?? undefined,
    finance_company_id: props.policy.finance_company_id,
    billing_type: props.policy.billing_type,
    finance_account_number: props.policy.finance_account_number,
    remarks: props.policy.remarks,
    label: props.policy.label,
  },
});

const save = async (value: Form[keyof Form], name: keyof Form) => {
  return PolicyApi.update({
    id: props.policy.id,
    form: { [name]: value },
  }).then(() => {
    if (name === 'billing_type' && value === 'Agency Bill - Financed') {
      setFieldValue('finance_company_id', null);
      setFieldValue('finance_account_number', null);
    }
  });
};

const onPlusBtnClick = async (months: number) => {
  if (!values.effective_date) return;

  const result = await confirm({
    confirmButtonText: 'Confirm',
    text: `This will add ${months} months to effective date`,
  });
  if (!result.isConfirmed) return;

  let date = new Date(values.effective_date);
  date = new Date(date.setMonth(date.getMonth() + months));
  const value = toDateInput(date);

  setFieldValue('expiration_date', value);

  PolicyApi.update({
    id: props.policy.id,
    form: {
      expiration_date: value,
    },
  }).catch(() => {
    toastError();
  });
};
</script>

<style lang="scss">
.policy-form {
  display: grid;
  align-items: start;
  grid-gap: rem(25px);
  margin-bottom: rem(25px);
  grid-template-columns: repeat(5, minmax(0, 1fr));

  .form-group,
  .form-group-display,
  .form-grid {
    margin-bottom: 0;
  }

  // Dates
  .form-group.expiration_date {
    min-width: 8.9em;
  }

  .form-group.remarks {
    grid-column: 1 / span 3;
  }

  .form-group.label {
    grid-column: 4 / span 2;
  }

  .dropdown {
    .dropdown-item.red {
      color: #ef2323;
    }
  }

  &__plus-btn {
    font-weight: bold;
    cursor: pointer;
    &:hover {
      color: black;
      user-select: none;
    }
  }
}
</style>
