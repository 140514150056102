<template>
  <!-- Header top -->
  <div class="modal--note__header-top">
    <div class="modal--note__header-left">
      <router-link
        v-if="note.client_id"
        class="modal--note__title"
        :to="`/clients/${note.client_id}?ticket_id=${note.ticket_id}`"
      >
        {{ clientName ? clientName : '(Draft)' }}
      </router-link>
      <div v-else class="modal--note__title">(Non-client followup)</div>
      <div v-if="primaryContact" class="modal--note__details">
        <span v-if="primaryContact.name" class="modal--note__detail">{{ primaryContact.name }}</span>
        <span v-if="primaryContact.number" class="modal--note__detail">{{ primaryContact.number }}</span>
        <span v-if="primaryContact.email" class="modal--note__detail">{{ primaryContact.email }}</span>
        <router-link
          :to="`/clients/${note.client_id}/attachments`"
          class="modal--note__detail modal--note__detail--attachments link"
          @click="emit('modal:hide')"
        >
          <SvgIcon name="paperclip" />
          Client attachments
        </router-link>
      </div>
    </div>
    <button class="modal--note__close-icon" @click="emit('modal:hide')"></button>
  </div>

  <!-- Update followup form -->
  <FollowupUpdateForm v-if="noteLocal.is_followup" v-model:note="noteLocal" type="big" :disabled="mode === 'View'" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { PrimaryContact } from '@/js/Api/Client';
import { TicketNote } from '@/js/Api/TicketNote';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import FollowupUpdateForm from '@/js/Components/Tickets/FollowupUpdateForm.vue';

interface Props {
  mode: 'View' | 'Edit';
  note: TicketNote;
  clientName: string | null;
  primaryContact: PrimaryContact | null;
}

interface Emits {
  (e: 'modal:hide'): void;
  (e: 'update:note', note: TicketNote): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const noteLocal = computed({
  get: () => ({ ...props.note }),
  set: (newValue) => emit('update:note', newValue),
});
</script>

<style lang="scss">
.modal--note {
  &__header-top {
    padding: 0 rem(28px);
    height: rem(87px);
    display: flex;
    align-items: center;
    background-color: #f4f4f4;
    justify-content: space-between;
    border-top-left-radius: rem(8px);
    border-top-right-radius: rem(8px);
  }

  // Title
  &__title {
    font-size: rem(22px);
    text-decoration: none;
  }

  // Header details
  &__details {
    display: flex;
    align-items: center;
    color: color('gray');
    font-size: rem(12px);
  }
  &__detail:not(:last-child)::after {
    content: '';
    width: rem(1px);
    height: rem(12px);
    margin: 0 rem(7.5px);
    display: inline-block;
    transform: translateY(#{rem(1px)});
    background-color: color('gray', 4);
  }
  &__detail--attachments {
    display: flex;
    align-items: center;
    .svg-icon {
      width: rem(16px);
      height: rem(16px);
      color: color('primary');
      margin-right: rem(2px);
    }
  }

  // Close icon
  &__close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    position: relative;
    padding: 0;
    width: rem(24px);
    height: rem(24px);
    cursor: pointer;
    &::after,
    &::before {
      position: absolute;
      content: '';
      display: block;
      width: rem(2px);
      height: rem(18px);
      background-color: black;
    }
    &::after {
      transform: rotate(45deg);
    }
    &::before {
      transform: rotate(-45deg);
    }
  }

  // Update followup form
  .modal__header .update-followup-form {
    padding: 0 rem(28px);
  }
}
</style>
