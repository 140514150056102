<template>
  <SearchResources
    api-endpoint="/search"
    placeholder="Search for insured..."
    :api-params="{ 'highlight[0]': '*' }"
    :min-search-chars="me.permissions['search-chars']"
    @item:selected="emitSelected"
  >
    <template #match-inner="{ match }">
      <div v-html="match.name ?? '(Draft)'"></div>
      <div class="search__match-details">
        <div
          v-if="match.policyNumbers"
          v-html="'Policy number:' + match.policyNumbers.split('|').find((p) => p.includes('<em>'))"
        ></div>
        <div v-if="match.dot" v-html="`DOT: ${match.dot}`"></div>
        <div
          v-if="match.contactNames"
          v-html="'Rep:' + match.contactNames.split('|').find((p) => p.includes('<em>'))"
        ></div>
        <div v-else-if="match.primaryContactName" v-html="`Rep: ${match.primaryContactName}`"></div>
        <div v-if="match.address" v-html="`Address: ${match.address}`"></div>
        <div
          v-if="match.phoneNumbers"
          v-html="'Phone:' + match.phoneNumbers.split('|').find((p) => p.includes('<em>'))"
        ></div>
        <div v-else-if="match.primaryPhoneNumber" v-html="`Phone: ${match.primaryPhoneNumber}`"></div>
      </div>
    </template>
  </SearchResources>
</template>

<script setup>
import { me } from '@/js/Helpers/Session';
import SearchResources from '@/js/Components/SearchResources.vue';

const emit = defineEmits(['item:selected']);

const emitSelected = (item) => {
  const itemCopy = { ...item };
  itemCopy.id = Number(itemCopy.id.replaceAll('<em>', '').replaceAll('</em>', ''));
  emit('item:selected', itemCopy);
};
</script>
