import { z } from 'zod';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { CertificateSnapshotSchema } from './CertificateSnapshot';
import { UserSchema } from './User';

export const CertificateHolderSchema = z.object({
  id: z.number(),
  client_id: z.number().nullable(),
  name: z.string(),
  address1: z.string().nullable(),
  address2: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  zip: z.string().nullable(),
  contact_name: z.string().nullable(),
  phone: z.string().nullable(),
  fax: z.string().nullable(),
  email: z.string().nullable(),
  shared: z.boolean(),
  created_by: z.number(),
  created_at: z.string(),
  updated_at: z.string(),

  // Relations
  created_by_user: UserSchema.nullish(),
  snapshot: CertificateSnapshotSchema.nullish(),

  // Appends
  acord_text: z.string(),
  full_address: z.string(),
});

export type CertificateHolder = z.infer<typeof CertificateHolderSchema>;

async function create({ clientId, form }: { clientId: number | null; form: object }) {
  const endpoint = clientId ? `/api/clients/${clientId}/certificates/holders` : '/api/certificates/holders';
  const response = await axios.post(endpoint, form).catch((error) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: CertificateHolderSchema }).parseAsync(response.data);
}

async function importGlobal({ clientId, globalHolderId }: { clientId: number; globalHolderId: number }) {
  const response = await axios
    .post(`/api/clients/${clientId}/certificates/holders/import`, {
      global_holder_id: globalHolderId,
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

  return z.object({ data: CertificateHolderSchema }).parseAsync(response.data);
}

export default {
  create,
  importGlobal,
};
