<template>
  <div v-if="contactsComputed" class="main-panel-contacts">
    <template v-if="contactsComputed.length">
      <h6 class="main-panel-contacts__title">Contacts</h6>
      <!-- Contacts -->
      <div class="main-panel-contacts__items">
        <div v-for="contact in contactsComputed" :key="contact.id" class="main-panel-contacts__item">
          <div class="main-panel-contacts__fields">
            <!-- Title -->
            <p v-if="contact.title" class="main-panel-contacts__field main-panel-contacts__field--title">
              {{ contact.title }}
            </p>

            <!-- Name -->
            <p v-if="contact.name" class="main-panel-contacts__field" @click="copyString(contact.name)">
              {{ contact.name }}
              <SvgIcon name="copy" />
              <Tooltip show-on="click">Copied!</Tooltip>
            </p>

            <!-- Phones -->
            <template v-for="(phone, i) in contact.phones" :key="i">
              <p v-if="phone.number" class="main-panel-contacts__field" @click="copyString(phone.number)">
                {{ phone.number }}
                <span v-if="phone.description" class="main-panel-contacts__field-desc">
                  ({{ phone.description }})
                </span>
                <SvgIcon name="copy" />
                <Tooltip show-on="click">Copied!</Tooltip>
              </p>
            </template>

            <!-- Email -->
            <template v-for="(email, i) in contact.emails" :key="i">
              <p v-if="email.email" class="link main-panel-contacts__field" @click="copyString(email.email)">
                {{ email.email }}
                <span v-if="email.description" class="main-panel-contacts__field-desc">
                  ({{ email.description }})
                </span>
                <SvgIcon name="copy" />
                <Tooltip show-on="click">Copied!</Tooltip>
              </p>
            </template>

            <!-- Popup -->
            <Popper
              v-if="contact.name || contact.phones.length || contact.emails.length"
              class="main-panel-contacts__popup"
              show-on="hover"
              placement="top-start"
              :offset="[0, -1]"
            >
              <div class="main-panel-contacts__popup-field">
                {{ contact.name }}
              </div>
              <template v-for="(phone, i) in contact.phones" :key="i">
                <a class="main-panel-contacts__popup-field link" :href="`tel:${phone.number}`">
                  {{ phone.number }}
                </a>
              </template>
              <template v-for="(email, i) in contact.emails" :key="i">
                <a class="main-panel-contacts__popup-field link" :href="`mailto:${email.email}`">
                  {{ email.email }}
                </a>
              </template>
            </Popper>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="main-panel-contacts__noitems">No contacts yet</div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Tooltip from '@/js/Common/Tooltip.vue';
import Popper from '@/js/Components/Popper.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import ClipboardHelper from '@/js/Helpers/Clipboard';
import useClientStore from '@/js/store/pinia/useClientStore';

const props = defineProps({
  clientId: {
    type: Number,
    required: true,
  },
});

const clientStore = useClientStore();
const { ref: contacts } = clientStore.swrContacts({ id: props.clientId });

const contactsComputed = computed(() => {
  if (!contacts.value) return null;
  return contacts.value.filter(
    (contact) =>
      contact.name ||
      contact.phones.filter((phone) => phone.number).length ||
      contact.emails.filter((email) => email.email).length
  );
});

const copyString = (string) => {
  ClipboardHelper.copy(string);
};

// Open compose
const store = useStore();
const openCompose = (emailAddress) => {
  store.dispatch('draft/openCompose', {
    action: 'new',
    payload: {
      receivers: [
        {
          email: emailAddress,
          personal: '',
          full: `<${emailAddress}>`,
        },
      ],
      client_id: props.clientId,
      subject: `INSURANCE: ${clientStore.client.name}`,
    },
  });
};
</script>

<style lang="scss">
.main-panel-contacts {
  padding-top: rem(20px);
  border-bottom: rem(1px) solid #ccc;

  &__noitems {
    padding-bottom: rem(20px);
  }

  &__title {
    margin-bottom: rem(15px);
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    position: relative;
    min-width: 0;
    margin-bottom: rem(20px);

    &:nth-child(2n + 1) {
      padding-right: rem(7.5px);
      border-right: rem(1px) solid #ccc;
    }

    &:nth-child(2n) {
      padding-left: rem(17.5px);
    }
  }

  &__fields {
    flex: 1;
    overflow: hidden;
  }

  &__field {
    margin: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    padding: 0.2em 0.5em;
    position: relative;

    &--title,
    &-desc {
      color: #777;
      font-size: rem(13px);
    }

    &-desc {
      margin-left: 0.5em;
    }

    .svg-icon {
      margin-left: rem(10px);
      color: #a3b6cc;
      width: rem(18px);
      height: rem(18px);
      display: none;
      position: absolute;
      right: 0;
      background-color: #f0f0f0;
    }

    &:hover {
      background-color: #f0f0f0;

      .svg-icon {
        display: unset;
      }
    }
  }

  &__popup {
    z-index: 1;
    min-width: rem(300px);
    background-color: white;
    border: rem(1px) solid #777;
    padding: rem(15px) rem(25px);
    padding-left: rem(20px);
  }

  &__popup-field {
    display: flex;
    align-items: center;
    padding-left: rem(5px);
  }
}
</style>
