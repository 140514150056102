<template>
  <Teleport to="body" :disabled="!teleport">
    <div ref="root" :key="rerenderKey" class="modal" :class="{ 'modal--loading': isLoading }" v-bind="attrs">
      <div class="modal__dialog">
        <div class="modal__content">
          <!-- Header -->
          <div class="modal__header">
            <slot name="header" :show="show" :hide="hide">
              <div class="modal__header-main">
                <!-- Title -->
                <div class="modal__header-title">
                  <slot name="title" />
                </div>

                <!-- Icon -->
                <div class="modal__controls">
                  <slot name="controls" />
                  <button class="modal__header-btn" @click="hide"></button>
                </div>
              </div>

              <!-- Header bottom -->
              <slot name="header-bottom" />
            </slot>
          </div>

          <!-- Body -->
          <div class="modal__body">
            <slot :show="show" :hide="hide" :is-shown="isShown" />
          </div>

          <!-- Footer -->
          <div v-if="showFooter" class="modal__footer">
            <slot name="footer" :hide="hide">
              <button class="btn btn-primary" @click="onButtonClick">
                <slot name="footer-btn-text"> Save </slot>
              </button>
            </slot>
          </div>

          <!-- Loading -->
          <Transition name="opacity">
            <div v-if="isLoading" class="modal__loading-container">
              <SvgIcon name="loading" color="#1a497f" />
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { ref, useAttrs } from 'vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';

const props = defineProps({
  showFooter: {
    type: Boolean,
    default: true,
  },
  teleport: {
    type: Boolean,
    default: true,
  },
  closeOnButtonClick: {
    type: Boolean,
    default: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  rerenderOnHidden: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['modalshow', 'modalshown', 'modalhide', 'modalhidden', 'button:click', 'click:button']);

const attrs = useAttrs();

const root = ref(null);
const isShown = ref(false);
const rerenderKey = ref(1);

// Show modal
const show = async (data = null) => {
  if (root.value.style.display === 'block') return false;
  emit('modalshow', data);
  root.value.classList.add('show');
  root.value.classList.remove('hide');
  root.value.style.display = 'block';

  // FIXME - Temporary solution (this needs to be done in vue way)
  setTimeout(() => {
    if (!root.value) return;
    const button = root.value.querySelector('button.btn');
    if (button) button.focus();
  }, 500);

  setTimeout(() => {
    root.value.style.opacity = '1';
  }, 0);
  setTimeout(() => {
    root.value.querySelector('.modal__dialog').style.transform = 'translate(0, 0)';
  }, 0);

  return new Promise((resolve) => {
    setTimeout(() => {
      isShown.value = true;
      resolve('resolved');
      emit('modalshown', data);
    }, 300);
  });
};

// Hide modal
const hide = (data = null) => {
  if (root.value.style.display === 'none') return false;
  emit('modalhide', data);
  root.value.classList.remove('show');
  root.value.classList.add('hide');
  root.value.style.opacity = '0';
  const modalDialog = root.value.querySelector('.modal__dialog');
  if (modalDialog) modalDialog.style.transform = 'translate(0, -50px)';

  return new Promise((resolve) => {
    setTimeout(() => {
      isShown.value = false;
      if (root.value) root.value.style.display = 'none';
      if (props.rerenderOnHidden) rerenderKey.value++;
      resolve('resolved');
      emit('modalhidden', data);
    }, 300);
  });
};

const onButtonClick = () => {
  if (props.closeOnButtonClick) hide();
  emit('button:click');
  emit('click:button');
};

defineExpose({
  show,
  hide,
});
</script>

<style lang="scss">
$padding-x: rem(40px);

// Body class when modal is open
body.modal-open {
  padding-right: 0 !important;
  overflow: auto !important;
}

// Modal
.modal {
  display: none;
  width: 100%;
  position: fixed;
  z-index: 998;
  left: 0;
  top: 0;
  background-color: rgb(0 0 0 / 50%);
  transition: opacity 0.15s linear;
  opacity: 0;

  // Dialog (1st container)
  &__dialog {
    height: 100vh;
    width: 1024px;
    margin: 0 auto;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition:
      transform 0.3s ease-out,
      -webkit-transform 0.3s ease-out;
    display: flex;
    align-items: center;
    padding: rem(50px) 0;
    transform: translate(0, -50px);
  }

  // Content (2nd container)
  &__content {
    @include box-shadow-hard;

    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    background-color: white;
    border-radius: rem(8px);
    position: relative;
  }

  &__loading-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255 255 255 / 50%);
    z-index: 2;

    .svg-icon--loading {
      width: rem(50px);
      height: rem(50px);
    }
  }

  // Header
  &__header {
    padding: rem(35px) $padding-x rem(26px) $padding-x;
    border-bottom: 1px solid #dee2e6;

    &-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    // Controls
    .modal__controls {
      display: flex;
      align-items: center;
    }

    // Icon
    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      position: relative;
      padding: 0;
      margin-left: rem(25px);
      width: rem(24px);
      height: rem(24px);
      cursor: pointer;

      &::after,
      &::before {
        position: absolute;
        content: '';
        display: block;
        width: rem(2px);
        height: rem(18px);
        background-color: black;
      }

      &::after {
        transform: rotate(45deg);
      }

      &::before {
        transform: rotate(-45deg);
      }
    }
  }

  // Body
  &__body {
    flex: 1;
    overflow: auto;
    padding: rem(35px) $padding-x;
  }

  // Footer
  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #dee2e6;
    padding: rem(31px) $padding-x rem(26px) $padding-x;
  }
}
</style>
