<template>
  <div class="create-followup-form">
    <!-- Assignee -->
    <AssigneeInput name="assignee" :disabled="false" />

    <!-- Divider -->
    <div class="create-followup-form__divider"></div>

    <!-- Due -->
    <button class="create-followup-form__due">
      <span
        class="create-followup-form__due-text"
        :class="{ 'create-followup-form__due-text--date': dateText ? /\d/.test(dateText) : false }"
      >
        {{ dateText }}
      </span>
      <span class="create-followup-form__due-arrow"> 🢓 </span>
      <FormGroup type="date" name="due" />
    </button>

    <!-- Divider -->
    <div class="create-followup-form__divider"></div>

    <!-- Priority -->
    <PriorityPicker
      :model-value="values.priority"
      @update:model-value="(priority) => setFieldValue('priority', priority)"
    />

    <!-- Divider -->
    <div class="create-followup-form__divider"></div>

    <!-- Callback -->
    <div class="create-followup-form__callback">
      <SvgIcon
        name="callback"
        :class="{
          'svg-icon--active': values.callback,
        }"
        @click="setFieldValue('callback', !values.callback)"
      />
    </div>

    <!-- Divider -->
    <div class="create-followup-form__divider"></div>

    <!-- Watch list -->
    <WatchList :model-value="values.watchers" name="watchers" />

    <!-- Divider -->
    <div v-if="showSubmit" class="create-followup-form__divider"></div>

    <!-- Confirm -->
    <Btn v-if="showSubmit" class="create-followup-form__btn-confirm" variant="outline" size="sm" @click="submit">
      Confirm
    </Btn>
  </div>
</template>

<script setup lang="ts">
import { z } from 'zod';
import { computed } from 'vue';
import { useForm } from 'vee-validate';
import { me } from '@/js/Helpers/Session';
import { toDateInput, toDateString } from '@/js/Helpers';
import { toTypedSchema } from '@vee-validate/zod';
import { UserSchema } from '@/js/Api/User';
import Btn from '@/js/Components/Btn.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import AssigneeInput from '@/js/Components/TicketNotes/AssigneeInput.vue';
import FormGroup from '@/js/Components/Form/FormGroup.vue';
import WatchList from './WatchList.vue';
import PriorityPicker from './PriorityPicker.vue';

const TODAY = toDateInput(new Date());

const FormSchema = z.object({
  due: z.string().default(TODAY),
  callback: z.boolean().nullable().default(null),
  priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]).default(2),
  assignee: z
    .object({
      id: z.number(),
      name: z.string(),
      model_type: z.union([z.literal('User'), z.literal('Quote team')]),
    })
    .default(me),
  watchers: z.array(UserSchema).default([]),
});

export type Form = {
  is_followup: true;
  due: z.infer<typeof FormSchema>['due'];
  callback: z.infer<typeof FormSchema>['callback'];
  priority: z.infer<typeof FormSchema>['priority'];
  assignee_id: z.infer<typeof FormSchema>['assignee']['id'];
  assignee_type: z.infer<typeof FormSchema>['assignee']['model_type'];
  watcher_ids: z.infer<typeof FormSchema>['watchers'][number]['id'][];
};

interface Props {
  showSubmit?: boolean;
}

withDefaults(defineProps<Props>(), {
  showSubmit: true,
});

const emit = defineEmits<{
  (e: 'submit', form: Form): void;
}>();

const { handleSubmit, resetForm, values, setFieldValue } = useForm({
  validationSchema: toTypedSchema(FormSchema),
});

// Due
const TOMORROW = toDateInput(new Date().addDays(1));
const YESTERDAY = toDateInput(new Date().subtractDays(1));
const dateText = computed(() => {
  switch (values.due) {
    case TODAY:
      return 'TODAY';
    case TOMORROW:
      return 'TOMORROW';
    case YESTERDAY:
      return 'YESTERDAY';
    default:
      return values.due ? toDateString(new Date(values.due)) : null;
  }
});

// Submit
const submit = handleSubmit((form) => {
  emit('submit', {
    is_followup: true,
    due: form.due,
    callback: form.callback,
    priority: form.priority,
    assignee_id: form.assignee.id,
    assignee_type: form.assignee.model_type,
    watcher_ids: form.watchers.map((w) => w.id),
  });
});

defineExpose({
  submit,
  resetForm,
});
</script>

<style lang="scss">
.create-followup-form {
  display: flex;
  align-items: center;

  .form-group--assignee {
    margin-bottom: 0;
    flex: 1;

    .form-group-display__content {
      font-size: rem(15px);
    }
  }

  .search__input {
    font-size: rem(14px);
    height: rem(25px);
  }

  .search__match {
    line-height: 1.3;
    font-size: rem(14px);
    padding: rem(5px) rem(10px);
  }

  .form-group__input {
    background-image: none;
  }

  // Divider
  &__divider {
    min-width: rem(1px);
    min-height: rem(25px);
    margin: 0 rem(10px);
    background-color: color('gray', 4);
  }

  // Due
  &__due {
    border: none;
    outline: none;
    position: relative;
    padding: rem(4px) rem(7px);
    padding-right: rem(20px);
    border-radius: rem(5px);
    background-color: color('gray', 3);

    .form-group {
      position: unset;
      height: unset;
      margin-bottom: 0;
      &__input {
        opacity: 0;
        padding: 0;
        height: unset;
        border: none;
        outline: none;
        top: 0;
        left: 0;
        position: absolute;
        font-size: 100px;
      }
    }

    &-text {
      font-weight: 700;
      font-size: rem(12px);
      color: color('gray', 7);

      &--date {
        letter-spacing: rem(0.5px);
      }
    }

    &-arrow {
      top: rem(6px);
      right: rem(5px);
      position: absolute;
      margin-left: rem(5px);
      color: color('gray', 7);
      font-size: rem(20px);
    }
  }

  // Callback
  &__callback {
    display: flex;

    .svg-icon {
      color: #c3c3c3;

      &--active {
        color: #5ac10e;
      }
    }

    &:not(&--disabled) .svg-icon {
      cursor: pointer;
    }
  }
}
</style>
