<template>
  <Modal ref="ModalRef" class="quote-create-modal" :is-loading="loading" @modalhidden="resetForm">
    <template #title> Create a quote </template>

    <!-- Coverage types -->
    <SelectCoverages
      :selected-ids="values.coverage_type_ids"
      :allowed-ids="availableCoverageTypes.map((ct) => ct.id)"
      @update:coverage-type-ids="(ids) => setFieldValue('coverage_type_ids', ids)"
    />

    <!-- Footer -->
    <template #footer>
      <Btn @click="submit">Create quote</Btn>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { z } from 'zod';
import { ref } from 'vue';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import { toastError } from '@/js/Helpers/Alert';
import { QuoteGroup } from '@/js/Api/QuoteGroup';
import { CoverageType, CoverageTypeIdSchema } from '@/js/Api/CoverageType';
import QuoteApi from '@/js/Api/Quote';
import Modal from '@/js/Common/Modal.vue';
import Btn from '@/js/Components/Btn.vue';
import SelectCoverages from '@/js/Common/Form/SelectCoverages.vue';

interface Props {
  quoteGroupId: QuoteGroup['id'];
  availableCoverageTypes: CoverageType[];
}

type CreateResponse = Awaited<ReturnType<typeof QuoteApi.create>>['data'];

interface Emits {
  (e: 'created', response: CreateResponse): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

// Refs
const ModalRef = ref<InstanceType<typeof Modal> | null>(null);

// State
const loading = ref(false);

// Form
const FormSchema = z.object({
  coverage_type_ids: CoverageTypeIdSchema.array(),
});
const { handleSubmit, resetForm, values, setFieldValue } = useForm({
  validationSchema: toTypedSchema(FormSchema),
});

// Show
const show = () => {
  if (!ModalRef.value) return;
  ModalRef.value.show();
};

// Hide
const hide = () => {
  if (!ModalRef.value) return;
  ModalRef.value.hide();
};

// Submit
const submit = handleSubmit((form) => {
  loading.value = true;
  QuoteApi.create({
    form: {
      ...form,
      quote_group_id: props.quoteGroupId,
    },
  })
    .then(({ data }) => {
      emit('created', data);
      resetForm();
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      loading.value = false;
    });
});

defineExpose({
  show,
  hide,
});
</script>

<style lang="scss">
.quote-create-modal {
}
</style>
