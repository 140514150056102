import axios from 'axios';
import queryBuilderToQueryString from '@/js/Helpers/queryBuilderToQueryString';

export default {
  referralSources: {
    index({ queryBuilder }) {
      return axios.get(`/api/search/referral-sources?${queryBuilderToQueryString(queryBuilder)}`);
    },
  },
  customerServiceEntities: {
    index({ queryBuilder }) {
      return axios.get(`/api/search/customer-service-entities?${queryBuilderToQueryString(queryBuilder)}`);
    },
  },
  quotingSpecialists: {
    index({ queryBuilder }) {
      return axios.get(`/api/search/quoting-specialists?${queryBuilderToQueryString(queryBuilder)}`);
    },
  },
  generalSettings() {
    return axios.get('/api/settings/general');
  },
};
