import {
  Chart,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  TimeSeriesScale,
  Filler,
  Legend,
  Title,
  Tooltip,
} from 'chart.js';
import autocolors from 'chartjs-plugin-autocolors';

export const setChartjsConfig = () => {
  Chart.register(
    // Elements
    PointElement,
    LineElement,

    // Scales
    CategoryScale,
    LinearScale,
    TimeSeriesScale,

    // Plugins
    Filler,
    Legend,
    Title,
    Tooltip,
    autocolors,
  );

  // Point element
  Chart.defaults.elements.point.pointStyle = false;
  // Chart.defaults.elements.point.backgroundColor = 'rgba(75, 192, 192, 1)';
  // Chart.defaults.elements.point.borderColor = 'rgba(75, 192, 192, 1)';

  // Line element
  // Chart.defaults.elements.line.borderColor = 'rgba(75, 192, 192, 1)';
  // Chart.defaults.elements.line.backgroundColor = 'rgba(75, 192, 192, 0.2)';

  // Tooltip
  Chart.defaults.plugins.tooltip.mode = 'nearest';
};

export default {
  setChartjsConfig,
};
