<template>
  <div class="form-check-container" :class="{ disabled: !!disabled }">
    <label v-if="label">{{ getLabelText }}</label>

    <div
      :class="{
        'form-checks': true,
        vertical: orientation === 'vertical',
        horizontal: orientation === 'horizontal',
      }"
    >
      <InputCheckbox
        v-for="(checkbox, i) in checkboxes"
        :key="i"
        :model-value="
          multivalue ? multiValueLocal.includes(checkbox.checkedValue) : modelValue == checkbox.checkedValue
        "
        :name="$attrs.name"
        :label="checkbox.label"
        :disabled="disabled || checkbox.disabled"
        :autosave="false"
        @change="(event) => onChange(event, checkbox)"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { inject, ref } from 'vue';
import InputCheckbox from '@/js/Common/Form/Input/Checkbox.vue';

export default {
  components: {
    InputCheckbox,
  },

  props: {
    modelValue: {
      type: null,
      default: null,
    },
    checkboxes: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    orientation: {
      type: String,
      default: 'vertical',
      validator: (v) => ['vertical', 'horizontal'].includes(v),
    },
    validator: {
      type: Function,
      default: null,
    },
    multivalue: {
      type: Boolean,
      default: false,
    },
    autosave: {
      type: Boolean,
      default: true,
    },
    autosaveEndpoint: {
      type: String,
      default: null,
    },
    autosaveNotification: {
      type: [Boolean, Function],
      default: false,
    },
  },

  emits: ['change', 'after-autosave', 'update:model-value'],

  setup(props) {
    const multiValueLocal = ref(props.multivalue ? props.modelValue : []);
    // watch(() => props.modelValue, () => multiValueLocal.value = props.modelValue || []);

    return {
      multiValueLocal,
      autosaveEndpointFromFormContainer: inject('FormContainer-autosaveUrl', false),
    };
  },

  computed: {
    getLabelText() {
      if (this.label) return this.label;
      if (this.$attrs.name) return this.$attrs.name.split('_').join(' ').capitalize();
      return null;
    },
  },

  methods: {
    async onChange(e, checkbox) {
      // Validate
      if (this.validator) {
        const result = await this.validator(e, checkbox);
        if (result === false) return (e.target.checked = !e.target.checked);
      }

      let value = null;

      // Multivalue
      if (this.multivalue) {
        if (e.target.checked) this.multiValueLocal.push(checkbox.checkedValue);
        else this.multiValueLocal = this.multiValueLocal.filter((v) => v !== checkbox.checkedValue);

        value = this.multiValueLocal;

        // Not multivalue
      } else if (e.target.checked) value = checkbox.checkedValue;
      else value = checkbox.uncheckedValue ?? null;

      this.$emit('update:model-value', value, e);
      this.$emit('change', e);

      // Autosave endpoint (get from props or from FormContainer)
      const autosaveEndpoint = this.autosaveEndpoint || this.autosaveEndpointFromFormContainer;

      if (!autosaveEndpoint || !this.autosave || !this.$attrs.name) return;

      const body = {
        _method: 'PUT',
        value,
        name: this.$attrs.name,
        [this.$attrs.name]: value,
      };

      // Autosave
      axios
        .post(autosaveEndpoint, body)
        .then((response) => {
          // Autosave notification
          if (
            (typeof this.autosaveNotification === 'boolean' && this.autosaveNotification) ||
            (typeof this.autosaveNotification === 'function' && this.autosaveNotification(e.target.value))
          )
            this.$toast({ title: 'Changes have been saved.', icon: 'success' });

          // After autosave event
          this.$emit('after-autosave', response, e.target);
        })

        // Errors
        .catch((error) => {
          const { response } = error;
          if (!response) {
            throw error;
          } else if (response.status === 422) {
            this.$toast({ title: response.data.errors[this.$attrs.name][0], icon: 'error' });
          } else {
            this.$toast({ title: 'An error occurred while saving data.', icon: 'error' });
          }
        });
    },
  },
};
</script>

<style lang="scss">
.form-check-container > label {
  & > label {
    display: block;
    font-size: rem(16px);
    margin-bottom: 0.4em;
  }
}

.form-checks.horizontal {
  display: flex;

  > :not(:first-child) {
    margin-left: 1.1em;
  }
}
</style>
