<template>
  <div class="panel-notes-controls">
    <!-- Only open followups -->
    <InputCheckbox
      :model-value="onlyOpenFollowups"
      label="Show only open followups"
      @update:model-value="(v) => $emit('update:only-open-followups', v)"
    />

    <div class="panel-notes-controls__right">
      <!-- Sort -->
      <div class="panel-notes-controls__control-container">
        <label class="panel-notes-controls__control-label">Sort by</label>
        <NavSwitcher>
          <a
            :class="{ 'nav-switcher__item': true, 'nav-switcher__item--active': sort === 'updatedAt' }"
            @click="$emit('update:sort', 'updatedAt')"
          >
            <Tooltip placement="top" show-on="hover"> Sort by latest updated </Tooltip>
            <div class="nav-switcher__item-text">
              <SvgIcon name="list-pyramid" />
            </div>
          </a>
          <a
            :class="{ 'nav-switcher__item': true, 'nav-switcher__item--active': sort === 'createdAt' }"
            @click="$emit('update:sort', 'createdAt')"
          >
            <Tooltip show-on="hover"> Sort by latest created </Tooltip>
            <div class="nav-switcher__item-text">
              <SvgIcon name="clock-arrow" />
            </div>
          </a>
        </NavSwitcher>
      </div>

      <!-- Divider -->
      <div class="panel-notes-controls__divider"></div>

      <!-- Display -->
      <div class="panel-notes-controls__control-container">
        <label class="panel-notes-controls__control-label">Display</label>
        <NavSwitcher>
          <a
            :class="{ 'nav-switcher__item': true, 'nav-switcher__item--active': display === 'compact' }"
            @click="$emit('update:display', 'compact')"
          >
            <div class="nav-switcher__item-text">
              <SvgIcon name="list-compact" />
            </div>
          </a>
          <a
            :class="{ 'nav-switcher__item': true, 'nav-switcher__item--active': display === 'expanded' }"
            @click="$emit('update:display', 'expanded')"
          >
            <div class="nav-switcher__item-text">
              <SvgIcon name="list-expanded" />
            </div>
          </a>
        </NavSwitcher>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from '@/js/Common/Tooltip.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import NavSwitcher from '@/js/Common/NavSwitcher.vue';
import InputCheckbox from '@/js/Common/Form/Input/Checkbox.vue';

export default {
  components: {
    SvgIcon,
    Tooltip,
    NavSwitcher,
    InputCheckbox,
  },

  props: {
    sort: {
      type: String,
      required: true,
      validator: (v) => ['updatedAt', 'createdAt'].includes(v),
    },
    display: {
      type: String,
      required: true,
      validator: (v) => ['compact', 'expanded'].includes(v),
    },
    onlyOpenFollowups: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['update:sort', 'update:display', 'update:only-open-followups'],
};
</script>

<style lang="scss">
.panel-notes-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__right {
    display: flex;
    align-items: center;
  }

  &__divider {
    width: rem(1px);
    height: rem(40px);
    margin: 0 rem(19px);
    background-color: color('gray', 3);
  }

  // Only followups
  .checkbox__label {
    font-size: rem(15px);
  }

  &__control-label {
    font-size: rem(12px);
    display: block;
    text-align: center;
  }

  .nav-switcher__item {
    padding: rem(1.5px);

    &-text {
      justify-content: center;
    }

    // Icon color
    .svg-icon {
      color: #666;
    }

    &--active .svg-icon {
      color: black;
    }

    // List expanded icon
    .svg-icon--list-expanded {
      width: 19px;
      margin: 0 rem(4px);
    }

    // Padding
    &:last-child {
      padding-right: rem(3px);
    }
  }
}
</style>
