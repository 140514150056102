// Slide down
function slideDown(element, duration) {
  // Get the current height
  const prevHeight = element.getBoundingClientRect().height + 'px';

  // Set max-height: none and get the new height
  element.style.maxHeight = 'none';
  const newHeight = element.getBoundingClientRect().height + 'px';

  // That temporary height is never rendered in the browser
  // Restore the original height before any rendering
  element.style.maxHeight = prevHeight;

  // Start Animation in next Animation frame from original max-height to new max-height
  requestAnimationFrame(() => {
    element.style.transition = 'max-height ' + duration / 1000 + 's';
    element.style.maxHeight = newHeight;
    setTimeout(() => {
      element.style.maxHeight = 'none';
    }, duration);
  });
}

// Slide up
function slideUp(element, height, duration) {
  const prevHeight = element.getBoundingClientRect().height + 'px';
  element.style.maxHeight = prevHeight;
  requestAnimationFrame(() => {
    element.style.transition = 'max-height ' + duration / 1000 + 's';
    element.style.maxHeight = height;
  });
}

// Slide toggle
function slideToggle(element, expand, slideUpHeight = 0, duration = 300) {
  if (!expand) slideUp(element, slideUpHeight, duration);
  else slideDown(element, duration);
}

export default () => ({
  slideToggle,
  slideDown,
  slideUp,
});
