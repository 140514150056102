<template>
  <label
    :class="{
      checkbox: true,
      [`checkbox--${type}`]: true,
      'checkbox--disabled': disabled,
    }"
  >
    <div class="checkbox__container">
      <!-- Input -->
      <input
        v-bind="$attrs"
        ref="checkbox"
        type="checkbox"
        :name="$attrs.name"
        class="checkbox__input"
        :value="1"
        :checked="modelValue"
        :disabled="disabled"
        @change="onChange($event, i)"
      />

      <!-- Checkmark -->
      <div class="checkbox__checkmark-container">
        <svg class="checkbox__checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path fill="none" stroke="currentColor" stroke-width="4" d="M1.73 12.91l6.37 6.37L22.79 4.59" />
        </svg>
      </div>
    </div>

    <!-- Label -->
    <div class="checkbox__label">
      <slot name="label">
        {{ label }}
      </slot>
    </div>
  </label>
</template>

<script>
import { inject } from 'vue';

export default {
  props: {
    modelValue: {
      type: null,
      default: null,
    },
    uncheckedValue: {
      type: null,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autosave: {
      type: Boolean,
      default: true,
    },
    autosaveEndpoint: {
      type: String,
      default: null,
    },
    autosaveNotification: {
      type: [Boolean, Function],
      default: false,
    },
    validator: {
      type: Function,
      default: null,
    },
    type: {
      type: String,
      default: 'square',
      validator: (type) => ['circle', 'square'].includes(type),
    },
  },

  emits: ['update:model-value', 'input', 'change', 'after-autosave'],

  setup() {
    return {
      autosaveEndpointFromFormContainer: inject('FormContainer-autosaveUrl', false),
    };
  },

  methods: {
    async onChange(e) {
      // Validate
      if (this.validator) {
        const result = await this.validator(e);
        if (result === false) return (e.target.checked = !e.target.checked);
      }

      // Value
      const value = e.target.checked ? e.target.checked : this.uncheckedValue;

      // Events
      this.$emit('update:model-value', value);
      this.$emit('input', e);
      this.$emit('change', e);

      // Autosave endpoint (get from props or from FormContainer)
      const autosaveEndpoint = this.autosaveEndpoint || this.autosaveEndpointFromFormContainer;

      if (!autosaveEndpoint || !this.autosave || !this.$attrs.name) return;

      const body = {
        _method: 'PUT',
        value,
        name: this.$attrs.name,
        [this.$attrs.name]: value,
      };

      // Autosave
      axios
        .post(autosaveEndpoint, body)
        .then((response) => {
          // Autosave notification
          if (
            (typeof this.autosaveNotification === 'boolean' && this.autosaveNotification) ||
            (typeof this.autosaveNotification === 'function' && this.autosaveNotification(e.target.value))
          )
            this.$toast({ title: 'Changes have been saved.', icon: 'success' });

          // After autosave event
          this.$emit('after-autosave', response, e.target);
        })

        // Errors
        .catch((error) => {
          const { response } = error;
          if (!response) {
            throw error;
          } else if (response.status === 422) {
            this.$toast({ title: response.data.errors[this.$attrs.name][0], icon: 'error' });
          } else {
            this.$toast({ title: 'An error occurred while saving data.', icon: 'error' });
          }
        });
    },
  },
};
</script>

<style lang="scss">
.checkbox {
  display: flex;
  align-items: center;
  font-size: rem(16px);

  // Cursor
  cursor: pointer;

  &--disabled {
    cursor: default;
    pointer-events: none;
  }

  // Left
  &__container {
    display: flex;
    position: relative;
    width: 1.125em;
    height: 1.125em;
    min-width: 1.125em;
    min-height: 1.125em;
  }

  // Right
  &__label {
    margin-left: rem(10px);
  }

  // Input
  &__input {
    opacity: 0;
    margin: 0;
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  // Box
  &__checkmark-container {
    display: inline-grid;
    border-radius: 0.15em;
    border: 0.15em solid currentcolor;
    width: 100%;
    height: 100%;
  }

  // Show/hide checkmark
  &__checkmark {
    width: 100%;
    height: 100%;
    margin: auto;
    transform: scale(0);
    transform-origin: bottom left;
    transition: transform 0.1s ease-in 25ms;
  }

  &__input:checked + &__checkmark-container &__checkmark {
    transform: scale(1);
  }

  // Colors
  &__input:checked + &__checkmark-container {
    border-color: color('primary');
    background-color: color('primary');
  }

  &__input:checked + &__checkmark-container &__checkmark {
    color: white;
  }

  // Box shadow on focus
  &__input:focus + &__checkmark-container {
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }

  // Square
  &--circle &__checkmark-container {
    border-radius: 50%;
  }
}
</style>
