<template>
  <Dropdown
    ref="DropdownRef"
    class="dropdown--coverages"
    strategy="fixed"
    placement="bottom-start"
    :close-on-inside-click="false"
    :disabled="disabled"
    @hidden="key++"
  >
    <template #trigger>
      <SvgIcon name="plus" />
      {{ buttonText }}
    </template>
    <template #menu>
      <SelectCoverages
        :key="key"
        v-model:coverage-type-ids="coverageTypeIds"
        :columns="3"
        :allowed-ids="allowedIds"
        :selected-ids="selectedIds"
        :disabled-ids="disabledIds"
      />
      <Btn @click="onConfirm">Confirm</Btn>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import Btn from '@/js/Components/Btn.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import Dropdown from '@/js/Common/Dropdown.vue';
import SelectCoverages from '@/js/Common/Form/SelectCoverages.vue';

interface Props {
  disabled?: boolean;
  selectedIds?: number[];
  allowedIds?: number[];
  disabledIds?: number[];
  buttonText?: string;
}

interface Emits {
  (e: 'click:confirm', coverageTypeIds: number[]): void;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  selectedIds: undefined,
  allowedIds: undefined,
  disabledIds: undefined,
  buttonText: 'Add Coverage',
});

const emit = defineEmits<Emits>();

const key = ref(1);
const coverageTypeIds = ref(props.selectedIds ? [...props.selectedIds] : []);
const DropdownRef = ref<InstanceType<typeof Dropdown> | null>(null);

const onConfirm = () => {
  emit('click:confirm', coverageTypeIds.value);
  if (DropdownRef.value) DropdownRef.value.hide();
};
</script>

<style lang="scss">
.dropdown--coverages {
  // Trigger
  .dropdown__trigger .link {
    display: flex;
    align-items: center;
    font-size: rem(14px);
    .svg-icon {
      margin-right: rem(5px);
    }
  }

  // Menu
  .dropdown__menu {
    padding: rem(16px) rem(16px);
    padding-right: rem(1px);

    .select-coverages {
      max-height: 40vh;
      overflow: auto;
      padding-right: rem(16px);
    }
  }
}
</style>
