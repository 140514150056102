<template>
  <div class="ticket" :class="`ticket--${ticket.status.toLowerCase()}`">
    <!-- Header -->
    <div class="ticket__header">
      <div class="ticket__header-left">
        <!-- Type -->
        <div class="ticket__type">
          {{ ticket.type }}
        </div>

        <!-- # of followups -->
        <div
          v-if="ticket.status === 'Open'"
          :set="(openFollowupCount = getOpenFollowupCount(ticket.notes))"
          class="ticket__flag"
        >
          {{ openFollowupCount || 'No' }} open followup{{ openFollowupCount === 1 ? '' : 's' }}
        </div>

        <!-- # of merged tickets -->
        <div v-if="ticket.merged_tickets.length" class="ticket__flag">
          {{ ticket.merged_tickets.length }} ticket{{ ticket.merged_tickets.length === 1 ? '' : 's' }} merged
        </div>

        <!-- Merged flag -->
        <div v-if="ticket.target_tickets.length" class="ticket__flag">Merged</div>
      </div>

      <!-- Updated at -->
      <div class="ticket__timestamp">
        <template v-if="ticket.status === 'Open'"> Updated {{ new Date(ticket.updated_at).toDateSlash() }} </template>
        <template v-else>
          <img class="ticket__icon-completed" src="/img/icons/checked-circle-grey.svg" />
          Completed {{ new Date(ticket.resolved_on).toDateSlash() }}
        </template>
      </div>
    </div>

    <!-- Subject -->
    <div class="ticket__subject">
      {{ ticket.subject.shorten(240) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ticket } from '@/js/Api/Ticket';
import { TicketNote } from '@/js/Api/TicketNote';

interface Props {
  ticket: Ticket;
}

defineProps<Props>();

const getOpenFollowupCount = (notes: TicketNote[]) => {
  return notes.reduce((carry, note) => {
    if (note.is_followup && note.status === 'Open') carry += 1;
    return carry + note.notes.filter((n) => n.is_followup && n.status === 'Open').length;
  }, 0);
};
</script>

<style lang="scss">
.ticket {
  $self: &;

  border-radius: rem(8px);
  padding: rem(10px);
  border: rem(1px) solid transparent;

  // Hover
  cursor: pointer;
  transition: border-color 0.2s;

  &:hover {
    border-color: color('primary', 4);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: rem(12px);
  }

  &__header-left {
    display: flex;
    align-items: center;
  }

  &__timestamp {
    display: flex;
    align-items: center;
  }

  &__icon-completed {
    margin-right: rem(2.5px);
  }

  &__subject {
    font-size: rem(16px);
  }

  &__flag {
    line-height: 1.3;
    padding: 0 rem(3.5px);
    border-radius: rem(2px);
    color: white;
    background-color: color('gray');
    margin-left: rem(7.5px);
  }

  // Open ticket
  &--open {
    background-color: #e8e9ea;

    #{$self}__type {
      color: color('primary');
    }

    #{$self}__timestamp {
      color: color('gray');
    }

    #{$self}__subject {
      color: black;
    }
  }

  // Resolved ticket
  &--resolved {
    color: color('gray');
    border-color: color('gray', 3);

    #{$self}__flag {
      background-color: color('gray', 4);
    }
  }
}
</style>
