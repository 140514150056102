import axios, { AxiosError } from 'axios';
import { z } from 'zod';
import * as Sentry from '@sentry/vue';
import { InvoiceFeeSchema } from './InvoiceFee';
import { BILLING_TYPES, PolicySchema } from './Policy';
import queryBuilderToQueryString from '../Helpers/queryBuilderToQueryString';

export const InvoiceSchema = z.object({
  id: z.number(),
  billing_type: z.enum(BILLING_TYPES).nullable(),
  total_amount: z.number().int().nullish(),
  paid_amount: z.number().int().nullish(),
  due_amount: z.number().int().nullish(),
  due_date: z.string().nullable(),
  policy_id: z.number().nullable(),
  client_id: z.number(),
  fees: z.array(InvoiceFeeSchema).nullish(),
  policy: PolicySchema.nullish(),
  model_type: z.string().nullish(),
  client_due: z.number().nullish(),
  show_commissions: z.boolean(),
  show_mga_commissions: z.boolean(),
  note: z.string().nullish(),
  created_at: z.string(),
  pivot: z
    .object({
      invoice_id: z.number().nullish(),
      client_payment_id: z.number().nullish(),
      amount: z.number().int(),
    })
    .passthrough()
    .nullish(),
});

export type Invoice = z.infer<typeof InvoiceSchema>;

type InvoicePayload = {
  id: number;
  form: Partial<Invoice>;
  params: Record<string, any>;
};

async function index({ params }: Pick<InvoicePayload, 'params'>) {
  const response = await axios.get(`/api/invoices?${queryBuilderToQueryString(params)}`).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: z.object({
        data: z.array(InvoiceSchema),
      }),
    })
    .parseAsync(response);
}

async function find({ id }: Pick<InvoicePayload, 'id'>) {
  const response = await axios.get(`/api/invoices/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: InvoiceSchema,
    })
    .parseAsync(response.data);
}

async function create({ form }: Pick<InvoicePayload, 'form'>) {
  const response = await axios.post(`/api/invoices`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: InvoiceSchema,
    })
    .parseAsync(response.data);
}

async function update({ id, form }: Pick<InvoicePayload, 'id' | 'form'>) {
  const response = await axios.put(`/api/invoices/${id}`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: InvoiceSchema,
    })
    .parseAsync(response.data);
}

async function trash({ id }: Pick<InvoicePayload, 'id'>) {
  const response = await axios.delete(`/api/invoices/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  // Check if the status is 204
  return response.status === 204;
}

export default {
  index,
  find,
  create,
  update,
  trash,
};
