import { z } from 'zod';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { EmailSchema } from './Email';
import { PhoneSchema } from './Phone';

export const ContactSchema = z.object({
  id: z.number(),
  client_id: z.number().nullable(),
  parent_id: z.number(),
  parent_type: z.string(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  title: z.string().nullable(),
  is_primary: z.boolean().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  emails: z.array(EmailSchema).nullish(),
  phones: z.array(PhoneSchema).nullish(),

  // $appends
  name: z.string().nullable(),
});

export type Contact = z.infer<typeof ContactSchema>;
