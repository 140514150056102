import { createStore } from 'vuex';
import global from './modules/global';
import settings from './modules/settings';
import client from './modules/client';
import reports from './modules/reports';
import dashboard from './modules/dashboard';
import certificates from './modules/certificates';

export default createStore({
  modules: {
    global,
    settings,
    client,
    reports,
    dashboard,
    certificates,
  },
});
