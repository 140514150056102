<template>
  <div class="sidebar-left__section">
    <!-- Main link -->
    <a class="sidebar-left__link" @click="isExpanded = !isExpanded">
      <SvgIcon name="search" />
      Search
    </a>

    <!-- Slidebable menu -->
    <div v-if="isExpanded" class="sidebar-left__section-links">
      <!-- Policies -->
      <router-link v-if="me.permissions['policies.index']" :to="{ name: 'SearchPolicies' }" class="sidebar-left__section-link">
        Policies
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { me } from '@/js/Helpers/Session';
import { useRoute, useRouter } from 'vue-router';
import SvgIcon from '@/js/Components/SvgIcon.vue';

// Sliding
const isExpanded = ref(false);

const route = useRoute();
const router = useRouter();

// Is directory page
const isDirectoryPage = (path: string) => /^\/directory\//.test(path);

// Open menu if visiting directly or refreshing a directory page
if (isDirectoryPage(route.fullPath)) isExpanded.value = !isExpanded.value;

// Collapse menu if user is navigating away from directory
watch(
  () => route.fullPath,
  () => {
    const isNextPageDirectoryPage = isDirectoryPage(route.fullPath);
    const wasPreviousPageDirectoryPage = isDirectoryPage(router.options.history.state.back);

    if (isExpanded.value && !isNextPageDirectoryPage && wasPreviousPageDirectoryPage)
      isExpanded.value = !isExpanded.value;
  }
);
</script>
