import { z } from 'zod';
import axios from 'axios';
import * as Sentry from '@sentry/vue';

export const AddressSchema = z.object({
  id: z.number(),
  client_id: z.number().nullable(),
  parent_id: z.number(),
  parent_type: z.string(),
  address: z.string().nullable(),
  type: z.string().nullable(),
  preset_type: z.string().nullable(),
  custom_type: z.string().nullable(),
  city: z.string().nullable(),
  province: z.string().nullable(),
  zip: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});

export type Address = z.infer<typeof AddressSchema>;
