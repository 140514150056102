<template>
  <FormGroup
    v-model="selectedOptions"
    type="ajax-select"
    label="User:"
    label-method="name"
    :multivalue="multivalue"
    :disabled="disabled"
    :fetch-options="fetchOptions"
    @update:model-value="onUpdate"
  >
    <template #selected-option>
      {{ selectedOptionText }}
      <SvgIcon name="caret-down" />
    </template>
  </FormGroup>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import UserApi, { User } from '@/js/Api/User';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import FormGroup from '@/js/Common/Form/FormGroup/FormGroup.vue';

type Option = {
  id: User['id'];
  name: User['name'];
  model_type: 'User';
};

type ModelValue = User['id'][] | User['id'] | undefined;

interface Props {
  modelValue?: ModelValue;
  disabled?: boolean;
  multivalue?: boolean;
}

interface Emits {
  (e: 'update:model-value', modelValue: ModelValue): void;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  multivalue: true,
});

const emit = defineEmits<Emits>();

const initialValue = props.multivalue ? [] : undefined;
const selectedOptions = ref<Option[] | Option | undefined>(initialValue);

const selectedOptionText = computed(() => {
  if (Array.isArray(selectedOptions.value))
    return selectedOptions.value.length ? selectedOptions.value.map((o) => o.name).join(', ') : 'All';
  return selectedOptions.value?.name ?? null;
});

// If initial value is set, fetch those options
const modelValueExists = Array.isArray(props.modelValue) ? props.modelValue.length > 0 : props.modelValue;
if (modelValueExists)
  UserApi.all({
    queryBuilder: {
      filters: {
        id: props.modelValue,
      },
    },
  }).then(({ data }) => {
    if (props.multivalue) selectedOptions.value = data;
    else selectedOptions.value = data[0];
  });

function fetchOptions(query: string | undefined, page: number) {
  return UserApi.index({
    queryBuilder: {
      page,
      filters: {
        search: query,
      },
    },
  });
}

function onUpdate(options: Option[] | Option) {
  if (Array.isArray(options)) emit('update:model-value', options.length ? options.map((c) => c.id) : undefined);
  else emit('update:model-value', options?.id ?? undefined);
}
</script>
