<template>
  <div class="form-group form-group--select-text">
    <!-- Select (label)-->
    <FormGroup
      type="select"
      model-value="label"
      :model-modifiers="selectModifiers"
      group-class="form-group--type"
      :disabled="$attrs.disabled"
      :label-hidden="true"
      :name="nameSelect"
      :format-value="formatValue"
      :autosave-url="autosaveUrl"
      @input="onSelectInput"
    >
      <option value="label" :style="{ display: 'none' }">
        {{ labelSelect }}
        <template v-if="isText"> - OTHER </template>
        ▼
      </option>
      <option v-for="(option, i) in Object.keys(options)" :key="i" :value="options[option]">
        {{ option }}
      </option>
    </FormGroup>

    <!-- Text -->
    <FormGroup
      v-model="tvalLocal"
      class="form-group--text"
      type="text"
      :model-modifiers="textModifiers"
      :disabled="$attrs.disabled || !isText"
      label-text=""
      :name="nameText"
      :autosave-url="autosaveUrl"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import FormGroup from '@/js/Common/Form/FormGroup/FormGroup.vue';

export default {
  components: {
    FormGroup,
  },

  inheritAttrs: false,

  props: {
    options: {
      type: Object,
      required: true,
    },
    labelSelect: {
      type: String,
      required: true,
    },
    nameSelect: {
      type: String,
    },
    nameText: {
      type: String,
    },
    selectModifiers: {
      default: () => ({}),
    },
    textModifiers: {
      default: () => ({}),
    },
    text: null,
    select: null,
    autosaveUrl: {
      type: String,
      default: null,
    },
  },

  emits: ['update:text', 'update:select'],

  setup(props, { emit }) {
    const isText = ref(props.select === '<text>' || !props.select);

    const svalLocal = computed({
      get: () => props.select,
      set: (val) => emit('update:select', val),
    });

    const tvalLocal = computed({
      get: () => (isText.value ? props.text : svalLocal.value),
      set: (val) => emit('update:text', val),
    });

    const onSelectInput = (event, key) => {
      const select = event.target;
      svalLocal.value = key;
      isText.value = props.options[key] === '<text>' || !props.options[key];
      select.value = 'label';
    };

    let helper = null;
    const formatValue = (event) => {
      if (event.target.value === 'label') return helper;
      const value = Object.keys(props.options).find((key) => props.options[key] === event.target.value);
      helper = value;
      return helper;
    };

    return {
      isText,
      svalLocal,
      tvalLocal,
      formatValue,
      onSelectInput,
    };
  },
};
</script>

<style lang="scss">
.form-group--select-text {
  position: relative;

  .form-group--type {
    position: absolute;
    top: rem(5px);
    left: rem(1px);
    z-index: 1;
    width: calc(100% - #{rem(2px)});
    margin-bottom: 0;

    // Input
    .form-control {
      height: auto;
      padding-top: 0;
      border: none;
      box-shadow: none;
      cursor: pointer;
      background: none;
      @include form-label;

      &:hover {
        color: color('primary');
      }

      option {
        font-size: rem(18px);
        line-height: rem(23px);
        color: color('text', 1);
      }
    }
  }

  .form-group--text .form-group__input {
    padding-top: rem(11px);
  }
}
</style>
