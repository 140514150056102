<template>
  <Dropdown
    :key="rerenderKey"
    placement="bottom-end"
    :offset="[0, 10]"
    :close-on-inside-click="false"
    class="dropdown--add-followup"
  >
    <template #trigger>
      <Btn class="dropdown--add-followup__btn">
        <SvgIcon name="sticky-note" />
        Add a Followup
      </Btn>
    </template>

    <template #menu>
      <InputCheckbox v-model="isNonClient" label="Non-client followup" />

      <!-- Select client -->
      <FormGroup
        v-show="!isNonClient"
        :model-value="selectedClient"
        type="ajax-select"
        label="Select client:"
        label-method="name"
        :fetch-options="fetchClients"
        :on-select="onSelectClient"
      />

      <!-- Select ticket -->
      <TicketSelect
        v-if="clientTickets && !isNonClient"
        :ticket="selectedTicket"
        :tickets="clientTickets"
        @update:ticket="(value) => (selectedTicket = value)"
      />

      <TextareaAutoExpandable
        ref="TextareaAutoExpandableRef"
        v-model="content"
        label="Note"
        @input="(e) => e.target.classList.remove('is-invalid')"
      />

      <!-- Base form -->
      <FollowupCreateForm @submit="submit" />
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import ClientApi, { Client } from '@/js/Api/Client';
import TicketApi, { Ticket } from '@/js/Api/Ticket';
import TicketNoteApi from '@/js/Api/TicketNote';
import { toast, toastError, toastSuccess } from '@/js/Helpers/Alert';
import Loader from '@/js/Helpers/Loader';
import Btn from '@/js/Components/Btn.vue';
import Dropdown from '@/js/Common/Dropdown.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import InputCheckbox from '@/js/Common/Form/Input/Checkbox.vue';
import FormGroup from '@/js/Components/Form/FormGroup.vue';
import TicketSelect, { Value as SelectedTicket } from '@/js/Components/Tickets/TicketSelect.vue';
import FollowupCreateForm, { Form } from '@/js/Components/Tickets/FollowupCreateForm.vue';
import TextareaAutoExpandable from '@/js/Components/TextareaAutoExpandable.vue';

const rerenderKey = ref(0);

const isNonClient = ref(true);
const content = ref<string | null>(null);
const selectedClient = ref<Client | null>(null);
const selectedTicket = ref<SelectedTicket>('New service ticket');
const clientTickets = ref<Ticket[] | null>(null);

const TextareaAutoExpandableRef = ref<InstanceType<typeof TextareaAutoExpandable> | null>(null);

const fetchClients = (query: string | undefined, page: number) => {
  return ClientApi.index({
    queryBuilder: {
      page,
      filters: {
        search: query,
      },
    },
  });
};

const onSelectClient = async (client: Client, checked: boolean) => {
  if (!checked) return;
  clientTickets.value = null;
  return TicketApi.all({
    queryBuilder: {
      filters: {
        client_id: client.id,
      },
      sorts: [
        {
          name: 'created_at',
          order: 'asc',
        },
      ],
    },
  })
    .then(({ data }) => {
      selectedClient.value = client;
      clientTickets.value = data;
    })
    .catch((error) => {
      if (error.response?.status === 403)
        toast({ icon: 'warning', title: 'You are not allowed to make followups for this client.' });
    });
};

const cleanup = () => {
  content.value = null;
  isNonClient.value = true;
  clientTickets.value = null;
  selectedClient.value = null;
  selectedTicket.value = 'New service ticket';
  rerenderKey.value += 1;
};

const submit = (form: Form) => {
  if (!selectedClient.value) return;
  if (!content.value) return TextareaAutoExpandableRef.value.$refs.FormGroupRef.$refs.input.classList.add('is-invalid');

  // Create ticket
  if (typeof selectedTicket.value === 'string') {
    Loader.start();
    TicketApi.create({
      note: {
        ...form,
        content: content.value,
      },
      client_id: selectedClient.value.id,
      type: selectedTicket.value === 'New service ticket' ? 'Service' : 'Sales',
    })
      .then(() => {
        cleanup();
        toastSuccess('Followup created');
      })
      .catch(() => {
        toastError();
      })
      .finally(() => {
        Loader.end();
      });

    // Create note
  } else {
    Loader.start();
    TicketNoteApi.create({
      ...form,
      content: content.value,
      ticket_id: selectedTicket.value?.id,
    })
      .then(() => {
        toastSuccess('Followup created');
        cleanup();
      })
      .catch(() => {
        toastError();
      })
      .finally(() => {
        Loader.end();
      });
  }
};
</script>

<style lang="scss">
.dropdown--add-followup {
  > .dropdown__trigger .svg-icon {
    color: white;
    margin-right: rem(15px);
  }

  > .dropdown__menu {
    padding: rem(15px);

    > * {
      margin-bottom: rem(10px);
    }
  }

  > .dropdown__menu > .checkbox {
    display: inline-flex;
    font-size: rem(14px);
    margin-bottom: rem(10px);

    &__label {
      white-space: nowrap;
    }
  }

  &--shown > .dropdown-menu {
    display: flex;
    flex-direction: column;
  }
}
</style>
