import { z } from 'zod';

export const OwnerPartnerSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  intake_ca_id: z.number(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  dob: z.string().nullable(),
  description: z.string().nullable(),
  insurance_score: z.string().nullable(),
  address_home_id: z.number().nullable(),
  prior_experience: z.string().nullable(),
  prior_experience_description: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});
