<template>
  <TableListFilters>
    <!-- Days filter -->
    <FormGroup
      type="number"
      min="0"
      :model-value="filters.days"
      :model-modifiers="{ number: true }"
      label-text="Days in the future"
      group-class="days-filter-group"
      @update:model-value="(value) => filter('days', value)"
    />

    <!-- User filter -->
    <UserFilter
      v-if="canViewOthersFollowups"
      :model-value="filters.assignee_id ?? me.id"
      :required="true"
      :multivalue="false"
      @update:model-value="(value) => filter('assignee_id', value)"
    />

    <!-- Role filter -->
    <TableListFilterSearch
      v-if="roles && canViewOthersFollowups"
      label-left="Role"
      :label-right="role?.name ?? 'All'"
      :search-haystack="roles"
      :initial-item="role"
      @selected="(role) => filter('assignee_role', role?.slug)"
    />
  </TableListFilters>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import useSWR from '@/js/Composables/useSWR';
import { me } from '@/js/Helpers/Session';
import FormGroup from '@/js/Common/Form/FormGroup/FormGroup.vue';
import TableListFilters from '@/js/Components/TableListFilters.vue';
import TableListFilterSearch from '@/js/Components/TableListFilterSearch.vue';
import UserFilter from '@/js/Components/Users/Filter.vue';

const store = useStore();

const { roles } = useSWR('global/fetchRoles', 'global/roles');

const filters = computed(() => store.state.dashboard.filters);

const role = computed(() =>
  roles.value && filters.value.assignee_role
    ? roles.value.find((r) => r.slug === filters.value.assignee_role)
    : undefined,
);

const filter = (property, value) => {
  if (property === 'assignee_id')
    store.commit('dashboard/updateFilter', { property: 'assignee_role', value: undefined });
  else if (property === 'assignee_role')
    store.commit('dashboard/updateFilter', { property: 'assignee_id', value: undefined });
  store.commit('dashboard/updateFilter', { property, value });
};

const canViewOthersFollowups = computed(
  () => me.permissions['see-others-followups'] || me.permissions['see-others-non-client-followups'],
);
</script>
