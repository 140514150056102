<template>
  <div v-if="addressesComputed" class="main-panel-addresses">
    <template v-if="addressesComputed.length">
      <h6 class="main-panel-addresses__title">Addresses</h6>
      <!-- Addresses -->
      <div class="main-panel-addresses__items">
        <div v-for="address in addressesComputed" :key="address.id" class="main-panel-addresses__item">
          <div class="main-panel-addresses__fields">
            <!-- Type -->
            <p class="main-panel-addresses__field main-panel-addresses__field--type">{{ address.type }}</p>

            <!-- Street -->
            <p class="main-panel-addresses__field main-panel-addresses__field--street">{{ address.address }}</p>

            <!-- City, State, ZIP -->
            <p class="main-panel-addresses__field main-panel-addresses__field--city-state-zip">
              {{ address.city }}
              {{ address.province }}
              {{ address.zip }}
            </p>

            <!-- Popup -->
            <Popper
              v-if="address.address"
              class="main-panel-addresses__popup"
              show-on="hover"
              :offset="[0, -1]"
              placement="top-start"
            >
              <div class="main-panel-addresses__popup-field" @click="copyString(address.address)">
                {{ address.address }}
                <SvgIcon name="copy" />
                <Tooltip show-on="click" placement="bottom">Copied!</Tooltip>
              </div>
              <div
                class="main-panel-addresses__popup-field"
                @click="copyString([address.city, address.province, address.zip].join(' '))"
              >
                {{ address.city }} {{ address.province }} {{ address.zip }}
                <SvgIcon name="copy" />
                <Tooltip show-on="click" placement="bottom">Copied!</Tooltip>
              </div>
            </Popper>
          </div>

          <!-- 3 dots -->
          <Dropdown>
            <template #trigger>
              <SvgIcon name="3-dots" />
            </template>
            <template #menu>
              <a class="dropdown-item" :href="AddressHelper.getGoogleAddressLink(address)" target="_blank">
                <i class="fab fa-google icon"></i>
                Google
              </a>
              <a class="dropdown-item" @click.stop="copyAddress(address)">
                <SvgIcon name="copy" />
                Copy address
                <Tooltip show-on="click">Information has been copied</Tooltip>
              </a>
            </template>
          </Dropdown>
        </div>
      </div>
    </template>
    <div v-else class="main-panel-addresses__noitems">No addresses yet</div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import Dropdown from '@/js/Common/Dropdown.vue';
import Tooltip from '@/js/Common/Tooltip.vue';
import Popper from '@/js/Components/Popper.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import AddressHelper from '@/js/Helpers/Address';
import ClipboardHelper from '@/js/Helpers/Clipboard';
import useClientStore from '@/js/store/pinia/useClientStore';

const props = defineProps({
  clientId: {
    type: Number,
    required: true,
  },
});

const clientStore = useClientStore();
const { ref: addresses } = clientStore.swrAddresses({ id: props.clientId });

const addressesComputed = computed(() => {
  if (!addresses.value) return null;
  return addresses.value.filter((a) => AddressHelper.getAddressString(a));
});

const copyAddress = async (address) => {
  ClipboardHelper.copy(AddressHelper.getAddressString(address));
};

const copyString = (string) => {
  ClipboardHelper.copy(string);
};
</script>

<style lang="scss">
.main-panel-addresses {
  padding-top: rem(20px);
  border-bottom: rem(1px) solid #ccc;

  &__noitems {
    padding-bottom: rem(20px);
  }

  &__title {
    margin-bottom: rem(15px);
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    position: relative;
    min-width: 0;
    margin-bottom: rem(20px);

    &:nth-child(2n + 1) {
      padding-right: rem(7.5px);
      border-right: rem(1px) solid #ccc;
    }

    &:nth-child(2n) {
      padding-left: rem(17.5px);
    }
  }

  &__fields {
    flex: 1;
    overflow: hidden;
  }

  &__field {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &--type {
      color: #777;
      font-size: rem(13px);
    }
  }

  .dropdown {
    .dropdown__trigger {
      border-radius: 50%;
      width: rem(34px);
      height: rem(34px);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.1s background-color;

      .svg-icon {
        color: color('primary');
        width: rem(22px);
      }
    }

    &.dropdown--shown .dropdown__trigger,
    .dropdown__trigger:hover {
      background-color: #dfe2e6;
    }
  }

  &__popup {
    z-index: 1;
    min-width: rem(300px);
    background-color: white;
    border: rem(1px) solid #777;
    padding: rem(15px) rem(25px);
    padding-left: rem(20px);
  }

  &__popup-field {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: rem(4px);
    padding-left: rem(5px);

    .svg-icon {
      margin-left: rem(10px);
      color: #a3b6cc;
      width: rem(18px);
      height: rem(18px);
      display: none;
    }
  }

  &__popup-field:hover {
    background-color: #f0f0f0;

    .svg-icon {
      display: block;
    }
  }
}
</style>
