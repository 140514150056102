import { STATUSES, PARENT_RENEWAL_STATUSES, CHILD_RENEWAL_STATUSES, BILLING_TYPES } from '@/js/Api/Policy';

export { STATUSES, PARENT_RENEWAL_STATUSES, CHILD_RENEWAL_STATUSES, BILLING_TYPES } from '@/js/Api/Policy';

// Status
export type STATUS = (typeof STATUSES)[number];
export const STATUS_OPTIONS = STATUSES.map((status) => ({
  label: status,
  value: status,
}));
export type STATUS_OPTION = (typeof STATUS_OPTIONS)[number];
export const STATUS_TO_COLOR_MAP = {
  Active: '#61CD7A',
  Expired: '#999999',
  Pending: '#ffffff',
  Cancelled: '#a35050',
  'Cancellation pending': '#EF2323',
  'Renewal (unpaid)': '#2e2071',
};

// Parent renewal status
export type PARENT_RENEWAL_STATUS = (typeof PARENT_RENEWAL_STATUSES)[number];
export const PARENT_RENEWAL_STATUS_OPTIONS = PARENT_RENEWAL_STATUSES.map((status) => ({
  label: status,
  value: status,
})).concat([
  {
    value: null,
    label: 'No input',
  },
]);
export type PARENT_RENEWAL_STATUS_OPTION = (typeof PARENT_RENEWAL_STATUS_OPTIONS)[number];

// Child renewal status
export type CHILD_RENEWAL_STATUS = (typeof CHILD_RENEWAL_STATUSES)[number];
export const CHILD_RENEWAL_STATUS_OPTIONS = CHILD_RENEWAL_STATUSES.map((status) => ({
  label: status,
  value: status,
})).concat([
  {
    value: undefined,
    label: 'All',
  },
  {
    value: null,
    label: 'No input',
  },
]);
export type CHILD_RENEWAL_STATUS_OPTION = (typeof CHILD_RENEWAL_STATUS_OPTIONS)[number];

// Billing types
export const BILLING_TYPE_OPTIONS = BILLING_TYPES.map((billingType) => ({
  value: billingType,
  label: billingType,
}));

export default {
  STATUSES,
  STATUS_OPTIONS,
  STATUS_TO_COLOR_MAP,
  PARENT_RENEWAL_STATUSES,
  PARENT_RENEWAL_STATUS_OPTIONS,
  CHILD_RENEWAL_STATUSES,
  CHILD_RENEWAL_STATUS_OPTIONS,
  BILLING_TYPE_OPTIONS,
};
