<template>
  <FormGroup
    :model-value="modelValue"
    type="ajax-select"
    label-method="name"
    label="Producer"
    class="producer-input"
    :disabled="disabled"
    :multivalue="false"
    :filter-checkboxes="filters"
    :fetch-options="fetchOptions"
    :on-select="onSelectLocal"
  >
    <template #selected-option>
      <slot name="selected-option" />
    </template>
    <template #controls="{ value }">
      <template v-if="value?.contacts">
        <a class="link" role="button" @click.stop="viewContacts"> View contacts </a>
        <Modal v-if="renderModal" ref="ModalRef">
          <template #title>
            {{ value.name }}
          </template>
          <Contacts :contacts="value.contacts" :disabled="true" />
          <template #footer-btn-text> Close </template>
        </Modal>
      </template>
    </template>
  </FormGroup>
</template>

<script setup lang="ts">
import { ref, computed, nextTick } from 'vue';
import ProducerApi, { Producer } from '@/js/Api/Producer';
import FormGroup from '@/js/Components/Form/FormGroup.vue';
import Modal from '@/js/Common/Modal.vue';
import Contacts from '@/js/Common/Form/FormGrid/Contacts/Contacts.vue';

type Option = Pick<Producer, 'id' | 'name' | 'model_type'>;

interface Props {
  modelValue?: Producer | null;
  disabled?: boolean;
  optionFilters?: boolean;
  onSelect?: Function;
}

interface Emits {
  (e: 'update:model-value', modelValue: Producer | null): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const ModalRef = ref<InstanceType<typeof Modal> | null>(null);
const renderModal = ref(false);
const viewContacts = () => {
  if (!renderModal.value) {
    renderModal.value = true;
    nextTick(() => ModalRef.value?.show());
  } else {
    ModalRef.value?.show();
  }
};

const fetchOptions = (query: string | undefined, page: number, filters) => {
  return ProducerApi.index({
    queryBuilder: {
      page,
      filters: {
        name: query,
        include_inactive_users: filters.include_inactive_users,
        include_non_producer_users: filters.include_non_producer_users,
      },
    },
  });
};

const filters = computed(() => {
  if (props.optionFilters)
    return [
      { name: 'include_inactive_users', label: 'Inactive users' },
      { name: 'include_non_producer_users', label: 'Non producers' },
    ];
  return null;
});

async function onSelectLocal(option: Option, checked: boolean) {
  let modelValue = null;
  if (checked) {
    const data = await ProducerApi.find({ id: option.id, type: option.model_type });
    modelValue = data.data;
  }
  if (props.onSelect) await props.onSelect(modelValue);
  emit('update:model-value', modelValue);
}
</script>

<style lang="scss">
.producer-input {
  &__filters {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em 0.8em;
    border-bottom: rem(1px) solid #ccc;
  }
}
</style>
