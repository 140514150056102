<template>
  <form ref="formRef" @submit.prevent="(e) => $emit('submit', e)">
    <slot />
    <button ref="submitBtn" type="submit" :style="{ display: 'none' }">Submit</button>
  </form>
</template>

<script>
import { computed, provide, ref } from 'vue';

export default {
  props: {
    autosaveUrl: {
      type: String,
      default: null,
    },
  },

  emits: ['submit'],

  setup(props) {
    // Autosave url
    provide(
      'FormContainer-autosaveUrl',
      computed(() => props.autosaveUrl)
    );

    // Refs
    const formRef = ref(null);
    const submitBtn = ref(null);

    // Submit
    const submit = () => {
      submitBtn.value.click();
    };

    // Is invalid
    const isInvalid = () => !Array.from(formRef.value.elements).every((input) => input.validity.valid);

    return {
      submit,
      formRef,
      submitBtn,
      isInvalid,
    };
  },
};
</script>
