<template>
  <div class="policies-panel">
    <!-- Panel header -->
    <div class="policies-panel__header">
      <h4>Policies ({{ policies ? policies.length : 0 }})</h4>

      <div class="policies-panel__header-right">
        <!-- Filter state -->
        <FormGroupMini
          v-model="filters.state"
          label="View:"
          :options="[
            { label: 'All', value: 'All' },
            { label: 'Active', value: 'Active' },
          ]"
        />

        <!-- Add policy btn -->
        <Btn v-if="canEditClient" variant="outline" @click="goToPolicyCreate">
          <i class="fas fa-plus mr-1" />
          Add Policy
        </Btn>
      </div>
    </div>

    <!-- Panel body -->
    <div class="policies-panel__body">
      <!-- List of policies -->
      <PolicyList v-if="policiesFiltered" :policies="policiesFiltered" @clicked="goToPolicy" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, reactive, ComputedRef } from 'vue';
import { useRouter } from 'vue-router';
import { Client } from '@/js/Api/Client';
import Btn from '@/js/Components/Btn.vue';
import PolicyList from '@/js/Components/Policies/List.vue';
import FormGroupMini from '@/js/Components/Form/FormGroupMini.vue';
import useClientStore from '@/js/store/pinia/useClientStore';

interface Props {
  clientId: Client['id'];
}

const props = defineProps<Props>();

const { ref: policies } = useClientStore().swrPolicies({ id: props.clientId });

const filters = reactive({
  state: 'All',
});

const policiesFiltered = computed(() => {
  if (!policies.value) return null;
  if (filters.state === 'Active')
    return policies.value.filter((p) => {
      return p.status === 'Active';
    });
  return policies.value;
});

const canEditClient = inject('canEditClient') as ComputedRef<boolean>;

// Routing
const router = useRouter();
const goToPolicyCreate = () => router.push(`/clients/${props.clientId}/policies/create`);
const goToPolicy = (policy) => router.push(`/clients/${props.clientId}/policies/${policy.id}`);
</script>

<style lang="scss">
.policies-panel {
  grid-area: policiespanel;
  display: flex;
  flex-direction: column;
  border: rem(1px) solid $border;
  border-radius: rem(12px);
  background-color: white;
  box-shadow: 0 rem(1px) rem(6px) #00000014;

  // Panel header
  &__header {
    height: rem(66px);
    padding: 0 rem(24px);
    background-color: #e5e9ed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: rem(12px) rem(12px) 0 0;

    h4 {
      font-size: rem(20px);
      line-height: rem(27px);
    }

    &-right {
      display: flex;
      align-items: center;

      .btn {
        padding-left: rem(7px);
        padding-right: rem(17px);

        .svg-icon {
          margin-right: rem(5px);
        }
      }
    }

    .form-group--mini {
      margin-right: rem(20px);
    }
  }

  // Panel body
  .policies-panel__body {
    flex: 1 1 auto;
    overflow: auto;
    height: 100px;
    padding: rem(10px);
  }
}
</style>
