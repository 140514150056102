import { ref } from 'vue';
import type { Ref } from 'vue';

type ZodResponse<T> = { data: T };

type Fetcher<T> = () => Promise<ZodResponse<T>>;

type Args<T> = {
  ref: Ref<T | null>;
  fetcher: Fetcher<T>;
  invalidation: number | string;
};

const promiseMap = new Map<Ref, Promise<any> | null>();
const invalidationMap = new Map<Ref, number | string>();

export default function swr<T>({ fetcher, ref: stateRef, invalidation }: Args<T>) {
  if (invalidation !== invalidationMap.get(stateRef)) stateRef.value = null;

  invalidationMap.set(stateRef, invalidation);

  const error = ref(null);
  const isValidating = ref(false);

  if (!promiseMap.get(stateRef)) {
    isValidating.value = true;
    promiseMap.set(
      stateRef,
      fetcher()
        .then((zodResponse) => {
          stateRef.value = zodResponse.data;
          return stateRef.value;
        })
        .catch((axiosError) => {
          error.value = axiosError;
          throw axiosError;
        })
        .finally(() => {
          isValidating.value = false;
          promiseMap.set(stateRef, null);
        })
    );
  }

  return {
    ref: stateRef,
    error,
    isValidating,
    promise: promiseMap.get(stateRef) as Promise<T>,
  };
}
