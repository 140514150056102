function concat(array, separator = ' ') {
  return array
    .filter((el) => el)
    .join(separator)
    .toUpperCase();
}

// Get address string
export function getAddressString(address) {
  return concat([address.address, address.city, address.province, address.zip]);
}

// Get full address (address + type)
export function getFullAddressString(address) {
  if (!address) return null;
  return concat([address.type === 'OTHER' ? address.custom_type : address.type, getAddressString(address)], ' - ');
}

// Get Google address link
function getGoogleAddressLink(address) {
  if (!address) return null;

  const query = this.getAddressString(address)
    .replaceAll(' ', '+')
    .replaceAll('#', '%23')
    .replaceAll(',', '%2C')
    .toUpperCase();

  return `https://www.google.com/search?q=${query}`;
}

export default {
  getAddressString,
  getFullAddressString,
  getGoogleAddressLink,
};
