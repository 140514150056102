<template>
  <div class="progress-bar" :class="progressBarClass">

    <!-- Title -->
    <h2>{{ title }}</h2>

    <!-- Steps -->
    <div class="steps">

      <!-- Step -->
      <div
        v-for="(step, index) in steps"
        class="step"
        :class="getStepClass(step, index)"
        :style=" index !== steps.length - 1 ? { width: `calc(100%/${steps.length - 1}` }  : {}">

        <!-- Dot & Line container -->
        <div class="dot-line" :class="getStepClass(step, index)">

          <!-- Dot -->
          <i class="fas fa-circle" :class="getStepClass(step, index)" ></i>

          <!-- Line -->
          <div
            v-if="index !== steps.length - 1"
            class="line" :class="getStepClass(step, index)">
          </div>

        </div>


        <!-- Title, above -->
        <span class="step-title" :class="getStepClass(step, index)" >{{ step.title }}</span>

        <!-- Subtitle, below -->
        <span class="step-subtitle" :class="getStepClass(step, index)">{{ step.subtitle }}</span>


      </div>
    </div>

  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    steps: {
      type: Array,
      required: true
    },
    title: String,
    progressBarClass: {
      type: [ String, Array, Object ]
    }
  },

  setup(props) {
    const
      lastActiveIndex = computed(() => {
        const reversedIndex = props.steps.slice().reverse().findIndex(step => step.active);
        return props.steps.length - 1 - reversedIndex;
      }),
      getStepClass = (step, index) => {
        let array = [];
        if (step.active)
          array.push('active');
        if (index < lastActiveIndex.value)
          array.push('passed');
        return array.join(' ');
      };

    return {
      getStepClass
    };
  }

}
</script>

<style lang="scss">
.progress-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: rem(14px);
  }
  .steps {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .step {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    /* Dot & Line container */
    .dot-line {
      display: flex;
      width: 100%;
      align-items: center;

      /* Dot */
      i {
        font-size: rem(12px);
        color: #CCCCCC;
        &.passed {
          color: #6D99CB;
        }
        &.active {
          color: #399A16;
          border-radius: 50%;
          box-shadow: 0px 0px 3px 7px rgba(57, 154, 22 , 0.1);
        }
      }

      /* Line */
      .line {
        width: 100%;
        height: rem(3px);
        background: #CCCCCC;
        &.passed {
          background: #6D99CB;
        }
      }
    }

    /* Step title */
    .step-title {
      padding-top: rem(6px);
      font-size: rem(12px);
      line-height: rem(16px);
      width: max-content;
      transform: translateX(-40%);
      color: #444444;
      &.passed {
        color: #6D99CB;
      }
      &.active {
        color: #399A16;
      }
    }

    /* Step subtitle */
    .step-subtitle {
      margin-top: rem(-6px);
      width: max-content;
      transform: translateX(-40%);
      color: #999999;
    }
  }



  /* Dynamic styling */
  &.gray {
    .dot-line {
      .line.passed {
        background: #333333;
      }
      i {
        &.active,
        &.passed {
          color: #333333;
          box-shadow: none;
        }
      }
    }

    .step-title {
      color: #CCCCCC;
      &.passed,
      &.active {
        color: #333333;
      }
    }
  }

  &.disabled {
    .dot-line {
      .line.passed {
        background: color('gray');
      }
      i {
        &.active,
        &.passed {
          color: color('gray');
          box-shadow: none;
        }
      }
    }

    .step-title {
      color: color('gray');
      &.passed,
      &.active {
        color: color('gray');
      }
    }
  }

  &.mg-b-17 {
    .steps {
      margin-bottom: rem(17.5px);
    }
  }

  &.st-16 {
    .step-title {
      font-size: rem(16px) !important;
      line-height: rem(19px);
      margin-bottom: rem(5px);
    }
  }

  &.ss-12 {
    .step-subtitle {
      font-size: rem(12px) !important;
      line-height: rem(14px);
    }
  }

  &.large {
    .steps {
      .step {

        i {
          font-size: rem(19px);
        }

        .line {
          height: rem(5px);
        }
      }
    }

  }

  &.smallest {
    .steps {
      .step {
        position: relative;
        height: rem(5px);
        i {
          z-index: 15;
          font-size: rem(5px);
        }
        .line {
          height: rem(1px);
        }
      }
    }

  }
}
</style>
