<template>
  <FormGroup
    :model-value="modelValue"
    type="ajax-select"
    label-method="name"
    class="user-input"
    :fetch-options="fetchOptions"
    @update:model-value="onUpdate"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import UserApi, { User, IndexArgs } from '@/js/Api/User';
import FormGroup from '@/js/Components/Form/FormGroup.vue';

export type ModelValue = User[] | User | null | undefined;

interface Props {
  modelValue?: ModelValue;
  staticFilters?: IndexArgs['queryBuilder']['filters'];
}

interface Emits {
  (e: 'update:model-value', modelValue: ModelValue): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const modelValue = ref<ModelValue>(props.modelValue);

const fetchOptions = (query: string, page: number) => {
  return UserApi.index({
    queryBuilder: {
      page,
      filters: {
        search: query,
        ...(props.staticFilters ?? {}),
      },
    },
  });
};

const onUpdate = (selected: User[] | User | null) => {
  modelValue.value = selected;
  emit('update:model-value', modelValue.value);
};
</script>

<style lang="scss">
.producer-input {
  &__filters {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em 0.8em;
    border-bottom: rem(1px) solid #ccc;
  }
}
</style>
