import { z } from 'zod';
import * as Sentry from '@sentry/vue';
import axios, { AxiosError } from 'axios';
import { queryBuilderToQueryString } from '@/js/Helpers';
import { AgencySchema } from './Agency';
import { CarrierSchema } from './Carrier';
import { CoverageType } from './CoverageType';
import { Policy, PolicySchema } from './Policy';
import { QuoteGroupStatusSchema } from './QuoteGroupStatus';
import { QuoteGroupIntakeSchema, QuoteGroupIntake } from './QuoteGroupIntake';
import { QuoteGroup } from './QuoteGroup';
import { TicketNoteSchema } from './TicketNote';

export const STATUSES = ['Not Sold', 'Declined', 'To Submit', 'Submitted', 'Received', 'Presented', 'Sold'] as const;
export const SUBMISSION_METHODS = ['Website', 'Email', 'Phone call', 'Post office', 'Rater'] as const;

const BaseQuoteSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  quote_group_id: z.number(),
  agency_id: z.number().nullable(),
  carrier_id: z.number().nullable(),
  price: z.number().nullable(),
  submission_method: z.enum(SUBMISSION_METHODS).nullable(),
  submission_date: z.string().nullable(),
  label: z.string().nullable(),
  status: z.enum(STATUSES),
  remarks: z.string().nullable(),
  is_preferred: z.boolean(),
  created_at: z.string(),
  updated_at: z.string(),
  effective_date: z.string().nullable(),
  expiration_date: z.string().nullable(),

  // Relations
  carrier: CarrierSchema.nullish(),
  agency: AgencySchema.nullish(),
});

// We have to tell TypeScript that 'quote_coverage' (pivot row) will always be loaded
// as it is defined in eloquent relation
const IntakesSchema = () =>
  QuoteGroupIntakeSchema.extend({
    quote_coverage: QuoteGroupIntakeSchema.shape.quote_coverage.unwrap(),
  }).array();

export type Quote = z.infer<typeof BaseQuoteSchema> & {
  policy?: Policy | null;
  intakes?: (QuoteGroupIntake & {
    quote_coverage: Exclude<QuoteGroupIntake['quote_coverage'], undefined>;
  })[];
};

export const QuoteSchema: z.ZodType<Quote> = BaseQuoteSchema.extend({
  policy: z.lazy(() => PolicySchema.nullish()),
  intakes: z.lazy(IntakesSchema).optional(),
});

// ***************************************************
// All
// ***************************************************

type AllArgs = {
  queryBuilder: {
    sorts?: {
      order: 'desc' | 'asc';
      name: 'created_at' | 'submission_date';
    }[];
    includes?: ('carrier' | 'agency' | 'policy' | 'intakes')[];
    filters?: {
      quote_group_id?: number;
    };
  };
};

async function all({ queryBuilder }: AllArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/quotes/all?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: QuoteSchema.array(),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Find
// ***************************************************

const FindResponseSchema = () =>
  BaseQuoteSchema.extend({
    carrier: CarrierSchema.nullable(),
    agency: AgencySchema.nullable(),
    policy: PolicySchema.nullable(),
    intakes: IntakesSchema(),
  });

async function find({ id }: { id: number }) {
  const response = await axios.get(`/api/quotes/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: FindResponseSchema(),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Past statuses
// ***************************************************

const QuotePastStatusSchema = z.object({
  name: z.enum(STATUSES),
  created_at: z.string(),
});

export type QuotePastStatus = z.infer<typeof QuotePastStatusSchema>;

async function pastStatuses({ id }: { id: number }) {
  const response = await axios.get(`/api/quotes/${id}/statuses`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: QuotePastStatusSchema.array(),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Create
// ***************************************************

type CreateArgs = {
  form: {
    quote_group_id: QuoteGroup['id'];
    coverage_type_ids: CoverageType['id'][];
  };
};

async function create({ form }: CreateArgs) {
  const response = await axios.post('/api/quotes', form).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: z.object({
        quote: QuoteSchema,
        followup: TicketNoteSchema,
        quoteGroupStatuses: QuoteGroupStatusSchema.array(),
      }),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Update
// ***************************************************

type UpdateArgs = {
  id: number;
  form: {
    carrier_id?: Quote['carrier_id'];
    agency_id?: Quote['agency_id'];
    price?: Quote['price'];
    submission_date?: Quote['submission_date'];
    submission_method?: Quote['submission_method'];
    effective_date?: Quote['effective_date'];
    expiration_date?: Quote['expiration_date'];
    remarks?: Quote['remarks'];
    label?: Quote['label'];
  };
};

async function update({ id, form }: UpdateArgs) {
  const response = await axios.put(`/api/quotes/${id}`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: FindResponseSchema(),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Update status
// ***************************************************

type UpdateStatusArgs = {
  id: number;
  status: Quote['status'];
  markAllAsNotSold?: boolean;
};

async function updateStatus({ id, status, markAllAsNotSold }: UpdateStatusArgs) {
  const response = await axios
    .put(`/api/quotes/${id}/status`, { status, mark_all_as_not_sold: markAllAsNotSold })
    .catch((error: AxiosError) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: z.object({
        quote: FindResponseSchema(),
        pastStatuses: QuotePastStatusSchema.array(),
        quoteGroupStatuses: z.array(QuoteGroupStatusSchema),
      }),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Update coverage types
// ***************************************************

type UpdateCoverageTypesArgs = {
  id: number;
  form: {
    coverage_type_ids: number[];
  };
};

async function updateCoverageTypes({ id, form }: UpdateCoverageTypesArgs) {
  const response = await axios.put(`/api/quotes/${id}/coverage-types`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: FindResponseSchema(),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Update coverage type
// ***************************************************

type UpdateCoverageTypeArgs = {
  id: number;
  type: CoverageType['abbreviation'];
  form: {
    description?: string | null;
    detail?: string | null;
    amount?: string | null;
  };
};

async function updateCoverageType({ id, type, form }: UpdateCoverageTypeArgs) {
  const response = await axios.put(`/api/quotes/${id}/coverage-types/${type}`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: FindResponseSchema(),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Toggle preferred
// ***************************************************

async function togglePreferred({ id }: { id: number }) {
  const response = await axios.put(`/api/quotes/${id}/toggle-preferred`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: QuoteSchema,
    })
    .parseAsync(response.data);
}

export default {
  all,
  find,
  pastStatuses,
  create,
  update,
  updateStatus,
  updateCoverageTypes,
  updateCoverageType,
  togglePreferred,
};
