import { z } from 'zod';

export const setZodConfig = () => {
  const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
    if (issue.code === z.ZodIssueCode.invalid_type && issue.received === 'null') return { message: 'Required' };
    return { message: ctx.defaultError };
  };

  z.setErrorMap(customErrorMap);
};

export default {
  setZodConfig,
};
