import { ref } from 'vue';
import { defineStore } from 'pinia';

export default defineStore('image', () => {
  const map = ref({});

  function updateImagesInDir(path) {
    const regexp = new RegExp(`^${path}`);
    Object.keys(map.value).forEach((key) => {
      if (key.match(regexp)) map.value[key] = null;
    });
  }

  return {
    map,
    updateImagesInDir,
  };
});
