Date = class extends Date {
  constructor(...args) {
    if (!args.length) super();
    else if (
      args.length === 1 &&
      typeof args[0] === 'string' &&
      /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(args[0])
    )
      super(args[0] + ' 00:00');
    else super(...args);
  }
};
Date.prototype.toTime = function (format = '24') {
  let hours = this.getHours();
  if (format === '12') {
    hours = hours === 0 ? 12 : hours;
    hours = hours > 12 ? hours - 12 : hours;
  }
  hours = hours < 10 ? '0' + hours : hours;
  let minutes = this.getMinutes() < 10 ? '0' + this.getMinutes() : this.getMinutes();
  let seconds = this.getSeconds() < 10 ? '0' + this.getSeconds() : this.getSeconds();
  return `${hours}:${minutes}:${seconds}`;
};
Date.prototype.toSQLTimestamp = function () {
  const time = this.toTime();
  const date = this.toDateInput();
  return `${date} ${time}`;
};
Date.prototype.toDateTime = function () {
  let month = this.getMonth() > 8 ? this.getMonth() + 1 : '0' + (this.getMonth() + 1);
  let day = this.getDate() > 9 ? this.getDate() : '0' + this.getDate();
  let year = this.getFullYear();
  let hours = this.getHours();
  hours = hours === 0 ? 12 : hours;
  hours = hours > 12 ? hours - 12 : hours;
  hours = hours < 10 ? '0' + hours : hours;
  let minutes = this.getMinutes() < 10 ? '0' + this.getMinutes() : this.getMinutes();
  let ampm = this.getHours() < 12 ? 'AM' : 'PM';
  return `${month}/${day}/${year} ${hours}:${minutes}${ampm}`;
};
Date.prototype.toDateSlash = function () {
  let month = this.getMonth() > 8 ? this.getMonth() + 1 : '0' + (this.getMonth() + 1);
  let day = this.getDate() > 9 ? this.getDate() : '0' + this.getDate();
  let year = this.getFullYear();
  return `${month}/${day}/${year}`;
};
Date.prototype.toDateSlashShort = function () {
  let month = this.getMonth() > 8 ? this.getMonth() + 1 : '0' + (this.getMonth() + 1);
  let day = this.getDate() > 9 ? this.getDate() : '0' + this.getDate();
  let year = String(this.getFullYear()).substr(2);
  return `${month}/${day}/${year}`;
};
Date.prototype.toMMYY = function () {
  let month = this.getMonth() > 8 ? this.getMonth() + 1 : '0' + (this.getMonth() + 1);
  let year = String(this.getFullYear()).substr(2);
  return `${month}/${year}`;
};
Date.prototype.toDateInput = function () {
  let day = this.getDate();
  day = day < 10 ? '0' + day : day;
  let month = this.getMonth() + 1;
  month = month < 10 ? '0' + month : month;
  let year = this.getFullYear();
  return year + '-' + month + '-' + day;
};
Date.prototype.toLongDate = function () {
  let dateTimeFormat = new Intl.DateTimeFormat('en', { month: 'short', day: 'numeric', year: 'numeric' });
  let [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(this);
  let suff = day === 1 ? ('st' ? day === 2 : 'nd' ? day === 3 : 'rd') : 'th';
  return `${month} ${day}${suff} ${year}`;
};
Date.prototype.toLongDateTime = function () {
  let minutes = this.getMinutes() < 10 ? '0' + this.getMinutes() : this.getMinutes();
  let hours = this.getHours() < 10 ? '0' + this.getHours() : this.getHours();
  return this.toLongDate() + ' ' + hours + ':' + minutes;
};
Date.prototype.toW3CString = function () {
  var year = this.getFullYear();
  var month = this.getMonth();
  month++;
  if (month < 10) {
    month = '0' + month;
  }
  var day = this.getDate();
  if (day < 10) {
    day = '0' + day;
  }
  var hours = this.getHours();
  if (hours < 10) {
    hours = '0' + hours;
  }
  var minutes = this.getMinutes();
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  var seconds = this.getSeconds();
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  var offset = -this.getTimezoneOffset();
  var offsetHours = Math.abs(Math.floor(offset / 60));
  var offsetMinutes = Math.abs(offset) - offsetHours * 60;
  if (offsetHours < 10) {
    offsetHours = '0' + offsetHours;
  }
  if (offsetMinutes < 10) {
    offsetMinutes = '0' + offsetMinutes;
  }
  var offsetSign = '+';
  if (offset < 0) {
    offsetSign = '-';
  }
  return (
    year +
    '-' +
    month +
    '-' +
    day +
    'T' +
    hours +
    ':' +
    minutes +
    ':' +
    seconds +
    offsetSign +
    offsetHours +
    ':' +
    offsetMinutes
  );
};
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  this.setDate(date.getDate() + days);
  return this;
};
Date.prototype.subtractDays = function (days) {
  var date = new Date(this.valueOf());
  this.setDate(date.getDate() - days);
  return this;
};
Date.prototype.addYears = function (years) {
  this.setFullYear(this.getFullYear() + years);
  return this;
};
Date.prototype.subtractYears = function (years) {
  this.setFullYear(this.getFullYear() - years);
  return this;
};
Date.prototype.addSeconds = function (seconds) {
  this.setSeconds(this.getSeconds() + seconds);
  return this;
};
Date.prototype.subtractSeconds = function (seconds) {
  this.setSeconds(this.getSeconds() - seconds);
  return this;
};
Date.getCurrentTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
Date.prototype.isToday = function () {
  const today = new Date();
  return (
    this.getDate() === today.getDate() &&
    this.getMonth() === today.getMonth() &&
    this.getFullYear() === today.getFullYear()
  );
};
