<template>
  <SlidePanel>

    <!-- Header -->
    <template #header-left>
      <div class="slide-panel__header-left">
        <i class="fas fa-info-circle slide-panel__header-left-icon"></i>
        <h5 class="slide-panel__header-left-text">
          Endorsements (0)
        </h5>
      </div>
    </template>

  </SlidePanel>
</template>

<script>
import SlidePanel from '@/js/Components/SlidePanel.vue';

export default {
  components: {
    SlidePanel
  }
}
</script>
