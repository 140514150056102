export default function() {
  return {
    loader: {

      // Start loader
      start() {
        document.getElementById('loader').style.display = 'block';

        const appEl = document.getElementById('app');
        appEl.style.pointerEvents = 'none';

        if (document.getElementsByClassName('modal show').length)
          Array.from(document.querySelectorAll('.modal.show .modal-body, .modal.show .modal-header'))
            .forEach(el => {
              el.style.opacity = '0.5';
              el.style.pointerEvents = 'none';
            });
        else
          appEl.style.opacity = '0.5';
      },

      // End loader
      end() {
        document.getElementById('loader').style.display = 'none';

        const appEl = document.getElementById('app');

        appEl.style.opacity = '1';
        appEl.style.pointerEvents = 'auto';

        Array.from(document.querySelectorAll('.modal .modal-body, .modal .modal-header'))
          .forEach(el => {
            el.style.opacity = '1';
            el.style.pointerEvents = 'auto';
          });
      }

    }
  }
};
