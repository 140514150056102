<template>
  <div class="form-check-container" :class="{ disabled: !!disabled }">
    <label v-if="label">{{ label }}</label>

    <div
      :class="{
        'form-checks': true,
        vertical: orientation === 'vertical',
        horizontal: orientation === 'horizontal',
      }"
    >
      <Checkbox
        v-for="(checkbox, i) in checkboxes"
        :key="i"
        :model-value="isChecked(checkbox)"
        :name="attrs.name"
        :label="checkbox.label"
        :disabled="disabled || checkbox.disabled"
        :autosave="false"
        @change="(event) => onChange(event, checkbox)"
      />
    </div>

    <ErrorMessage v-if="errorMessage" :message="errorMessage" />
  </div>
</template>

<script setup>
import { useField } from 'vee-validate';
import { computed, useAttrs } from 'vue';
import { toastError } from '@/js/Helpers/Alert';
import Checkbox from './Checkbox.vue';
import ErrorMessage from './ErrorMessage.vue';

const props = defineProps({
  modelValue: {
    type: null,
    default: null,
  },
  checkboxes: {
    type: Array,
    required: true,
  },
  label: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  orientation: {
    type: String,
    default: 'vertical',
    validator: (v) => ['vertical', 'horizontal'].includes(v),
  },
  validator: {
    type: Function,
    default: null,
  },
  multivalue: {
    type: Boolean,
    default: false,
  },
  autosave: {
    type: Boolean,
    default: true,
  },
  autosaveMethod: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['change', 'after-autosave', 'update:model-value']);

const attrs = useAttrs();
const { value: formValue, handleChange, errorMessage } = attrs.name ? useField(attrs.name) : {};
const value = computed(() => (attrs.name ? formValue.value : props.modelValue));

const isChecked = (checkbox) => {
  if (!props.multivalue) return value.value === checkbox.checkedValue;
  return value.value.includes(checkbox.checkedValue);
};

const onChange = async (e, checkbox) => {
  // Validate
  if (props.validator) {
    const result = await props.validator(e, checkbox);
    if (result === false) return (e.target.checked = !e.target.checked);
  }

  let localValue = value.value;

  // Multivalue
  if (props.multivalue) {
    if (e.target.checked) localValue.push(checkbox.checkedValue);
    else localValue = localValue.filter((v) => v !== checkbox.checkedValue);
  } else if (e.target.checked) localValue = checkbox.checkedValue;
  else localValue = checkbox.uncheckedValue ?? null;

  if (handleChange) handleChange(localValue);
  emit('update:model-value', localValue, e);
  emit('change', e);

  if (!props.autosaveMethod || !this.autosave) return;

  // Autosave
  props
    .autosaveMethod(attrs.name, localValue)
    .then((response) => {
      emit('after-autosave', response, e.target);
    })
    .catch((error) => {
      const { response } = error;
      if (!response) {
        throw error;
      } else if (response.status === 422) {
        toastError(response.data.errors[attrs.name][0]);
      } else {
        toastError('An error occurred while saving data.');
      }
    });
};
</script>

<style lang="scss">
.form-check-container {
  margin-bottom: rem(10px);
  & > label {
    display: block;
    font-size: rem(16px);
    margin-bottom: 0.4em;
  }
  .form-checks.vertical {
    > :not(:first-child) {
      margin-top: 0.5em;
    }
  }
  .form-checks.horizontal {
    display: flex;

    > :not(:first-child) {
      margin-left: 1.1em;
    }
  }
}
</style>
