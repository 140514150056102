<template>
  <div
    :class="{
      note__body: true,
      'note__body--update-followup': true,
      [`note__body--update-followup--${followupUpdateFormType}`]: true,
    }"
  >
    <!-- Created by -->
    <CreatedByInput
      v-if="me.permissions['ticket_notes.created_by_id.update']"
      v-model:note="noteLocal"
      :disabled="false"
    />

    <!-- Update followup form (regular) -->
    <FollowupUpdateForm
      v-if="noteLocal.is_followup"
      ref="FollowupFormRef"
      v-model:note="noteLocal"
      :type="followupUpdateFormType"
      @submit="onSubmit"
    />

    <!-- Attachments -->
    <NoteAttachments ref="NoteAttachmentsRef" :attachment-paths="noteLocal.attachment_paths" />

    <!-- Note content -->
    <TextareaAutoExpandable
      ref="TextareaAutoExpandableRef"
      v-model="noteLocal.content"
      name="content"
      :input-class="{
        note__content: true,
        'is-invalid': !noteLocal.content,
      }"
      :disabled="!me.permissions['edit-note-content']"
      :autosave-url="noteLocal.content ? autosaveEndpoint : null"
      @update:model-value="emit('update:note', noteLocal)"
      @paste="(event) => $refs.NoteAttachmentsRef.onPaste(event)"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import TicketNoteApi from '@/js/Api/TicketNote';
import TextareaAutoExpandable from '@/js/Components/TextareaAutoExpandable.vue';
import FollowupUpdateForm from '@/js/Components/Tickets/FollowupUpdateForm.vue';
import NoteAttachments from '@/js/Components/Tickets/NoteAttachments.vue';
import Loader from '@/js/Helpers/Loader';
import { toast } from '@/js/Helpers/Alert';
import { me } from '@/js/Helpers/Session';
import CreatedByInput from '@/js/Components/TicketNotes/CreatedByInput.vue';

const props = defineProps({
  note: {
    type: Object,
    required: true,
  },
  followupUpdateFormType: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['update:note', 'finished-editing']);

const NoteAttachmentsRef = ref(null);
const TextareaAutoExpandableRef = ref(null);

// Local state
const noteLocal = computed({
  get: () => ({ ...props.note }),
  set: (note) => emit('update:note', note),
});

const autosaveEndpoint = `/api/notes/${props.note.id}/autosave`;

// Submit
const onSubmit = (body) => {
  if (!noteLocal.value.content) return TextareaAutoExpandableRef.value.$refs.FormGroupRef.$refs.input.focus();

  const noteId = props.note.id;

  // Request
  Loader.start();
  return TicketNoteApi.update({
    id: noteId,
    body: {
      ...body,
      content: noteLocal.value.content,
      attachments: NoteAttachmentsRef.value.attachments,
    },
  })
    .then(({ data: note }) => {
      emit('update:note', note);
      emit('finished-editing');
      toast({ title: 'Followup updated', icon: 'success' });
    })
    .catch(() => {
      toast({ title: 'An error occurred', icon: 'error' });
    })
    .finally(() => {
      Loader.end();
    });
};

const FollowupFormRef = ref(null);
const submit = () => {
  if (props.note.is_followup) FollowupFormRef.value.$refs.FormContainerRef.submit();
  else onSubmit({});
};

defineExpose({ submit });
</script>

<style lang="scss">
.note__body--update-followup {
  $note: '.note';

  display: flex;
  flex-direction: column;
  padding: rem(10px);
  padding-top: rem(5px);

  .ticket-note-user-input {
    margin-bottom: 0;
  }

  .form-group--textarea-autoexpandable {
    margin-bottom: 0;
  }

  // Attachments
  .note-attachments {
    margin-top: rem(15px);
    align-self: flex-start;
  }

  // Note content (regular)
  &--regular .form-group--textarea-autoexpandable {
    .form-group__input {
      max-height: rem(180px);
    }
  }

  // Note content (small)
  &--small .form-group--textarea-autoexpandable {
    margin-top: rem(5px);
  }
}
</style>
