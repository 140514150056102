<template>
  <div class="main-panel">
    <!-- Panel header -->
    <div class="main-panel__header">
      <h4 class="main-panel__title">Account info</h4>
      <div v-if="canEdit" class="main-panel__controls">
        <Btn variant="ghost" @click.prevent="goToEditView">
          <SvgIcon name="pencil" class="mr-1" />
          Edit
        </Btn>

        <Btn v-if="me.permissions['delete-clients']" variant="ghost" color="danger" @click="destroyClient">
          <i class="fas fa-trash" />
        </Btn>
      </div>
    </div>

    <!-- Panel body -->
    <div class="main-panel__body">
      <!-- General -->
      <div class="main-panel__general">
        <h6>General</h6>

        <div class="main-panel__general-grid">
          <!-- Name -->
          <FormGroup :model-value="client?.name" type="text" label-text="Legal name" class="main-panel__fgd-2"
            :disabled="true" />

          <!-- Description -->
          <FormGroup :model-value="client?.description" type="text" label-text="Description"
            group-class="main-panel__fgd-2" :disabled="true" />

          <!-- Client type -->
          <FormGroup :model-value="client?.type" type="text" label-text="Entity type" />

          <!-- DOT # -->
          <FormGroup :model-value="client?.dot" type="text" label-text="DOT #" :disabled="true">
            <template #controls>
              <a v-if="client?.dot" target="_blank"
                :href="`https://safer.fmcsa.dot.gov/query.asp?query_type=queryCarrierSnapshot&query_param=USDOT&query_string=${client.dot}`"
                style="text-decoration: none">
                SAFER
              </a>
            </template>
          </FormGroup>

          <!-- MC # -->
          <FormGroup :model-value="client?.mc" type="text" label-text="MC #" :disabled="true">
            <template #controls>
              <a v-if="client?.dot" :href="`https://www.mcss.net/motor-carrier-snapshot/main/${client.dot}`"
                target="__blank" style="text-decoration: none">
                mcss.net
              </a>
            </template>
          </FormGroup>

          <!-- FEIN # -->
          <FormGroup :model-value="client?.fein" type="text" label-text="FEIN #" :disabled="true" />
        </div>
      </div>

      <!-- Addresses -->
      <Addresses :client-id="clientId" />

      <!-- Contacts -->
      <Contacts :client-id="clientId" />

      <!-- Producer -->
      <FormGroup :model-value="producer?.name" label-text="Producer" theme="text" :disabled="true" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue';
import { useRouter } from 'vue-router';
import { me } from '@/js/Helpers/Session';
import useClientStore from '@/js/store/pinia/useClientStore';
import { toastSuccess, toastError, prompt } from '@/js/Helpers/Alert';
import ClientApi, { Client } from '@/js/Api/Client';
import Loader from '@/js/Helpers/Loader';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import FormGroup from '@/js/Components/Form/FormGroup.vue';
import Addresses from './Addresses.vue';
import Contacts from './Contacts.vue';
import Btn from '@/js/Components/Btn.vue';

interface Props {
  clientId: Client['id'];
  client?: Client;
}

const props = defineProps<Props>();

const canEdit = inject('canEditClient');

const store = useClientStore();

const { ref: producer } = store.swrProducer({ id: props.clientId });

// Redirecting
const router = useRouter();
const goToEditView = () => {
  router.push({ path: `/clients/${props.clientId}/edit` });
};

// Destroy client
const destroyClient = async () => {
  const promptResult = await prompt();
  if (!promptResult.value || promptResult.value.toLowerCase() !== 'delete') return;

  Loader.start();
  ClientApi.destroy({ id: props.clientId })
    .then(() => {
      store.deleted = true;
      toastSuccess('Client has been deleted, redirecting...');
      setTimeout(() => {
        if (new RegExp(`^/clients/${props.clientId}`).test(window.location.pathname))
          router.push({ path: '/dashboard' });
      }, 2000);
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      Loader.end();
    });
};
</script>

<style lang="scss">
.main-panel {
  border: rem(1px) solid $border;
  border-radius: rem(12px);
  background-color: white;
  box-shadow: 0 rem(1px) rem(6px) #00000014;
  grid-area: mainpanel;

  &__body {
    padding: rem(17px) rem(24px);
  }

  // Panel header
  &__header {
    height: rem(66px);
    padding: 0 rem(24px);
    background-color: #e5e9ed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: rem(12px) rem(12px) 0 0;
  }

  &__title {
    font-size: rem(20px);
    line-height: rem(27px);
    font-weight: medium;
  }

  &__controls {
    display: flex;
    align-items: center;
    column-gap: rem(10px);
  }

  &__general {
    padding-bottom: rem(20px);
    border-bottom: rem(1px) solid #ccc;
  }

  &__general-grid {
    display: grid;
    grid-gap: rem(10px) rem(20px);
    grid-template-columns: repeat(4, 1fr);
  }

  &__fgd-2 {
    grid-column: span 2;
  }

  .form-group {
    &__input {
      border: none;
    }
  }

  h6 {
    font-size: rem(20px);
    line-height: rem(27px);
    margin-bottom: rem(10px);
    font-weight: bold;
  }

  .main-panel-contacts {
    margin-bottom: rem(20px);
  }
}
</style>
