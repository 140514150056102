import { z } from 'zod';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { IntakeChildStatusSchema } from '@/js/Api/IntakeChildStatus';
import { queryBuilderToQueryString } from '@/js/Helpers';
import { AddressSchema } from './Address';
import { LienholderSchema } from './Lienholder';

export const VehicleSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  vin: z.string().nullable(),
  year: z.string().nullable(),
  make: z.string().nullable(),
  model: z.string().nullable(),
  type: z.string().nullable(),
  subtype: z.string().nullable(),
  class: z.string().nullable(),
  seat_count: z.string().nullable(),
  gross_weight: z.string().nullable(),
  radius: z.string().nullable(),
  radius0_50: z.string().nullable(),
  radius51_200: z.string().nullable(),
  radius201_500: z.string().nullable(),
  radius501: z.string().nullable(),
  name_on_registration: z.string().nullable(),
  address_registration_id: z.number().nullable(),
  address_garaging_id: z.number().nullable(),
  physical_damage: z.boolean(),
  comprehensive_deductible: z.string().nullable(),
  comprehensive_deductible_other: z.string().nullable(),
  collision_deductible: z.string().nullable(),
  collision_deductible_other: z.string().nullable(),
  value: z.string().nullable(),
  primary_use: z.enum(['PLEASURE', 'COMMUTE', 'BUSINESS']).nullable(),
  anti_theft: z.enum(['PASSIVE', 'HOMING']).nullable(),
  anti_lock_breaks: z.boolean().nullable(),
  daytime_lights: z.boolean().nullable(),
  ownership_status: z.enum(['OWNED', 'LEASED', 'FINANCED']).nullable(),
  lienholder_id: z.number().nullable(),
  created_at: z.string(),
  updated_at: z.string(),

  // Relations
  latest_status: IntakeChildStatusSchema.nullish(),
  statuses: IntakeChildStatusSchema.array().or(z.undefined()),
  address_garaging: AddressSchema.nullish(),
  address_registration: AddressSchema.nullish(),
  lienholder: LienholderSchema.nullish(),

  // Appends
  is_empty: z.boolean(),
  model_type: z.literal('Vehicle'),
});

export type Vehicle = z.infer<typeof VehicleSchema>;

export const VehicleStatusSchema = IntakeChildStatusSchema.extend({
  child_type: z.literal('vehicle'),
});

export type VehicleStatus = z.infer<typeof VehicleStatusSchema>;

type QueryBuilder = {
  filters: {
    client_id: number;
  };
  includes?: ('address_garaging' | 'address_registration')[];
};

async function all(queryBuilder: QueryBuilder) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/vehicles/all?${qs}`).catch((error) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: VehicleSchema.array(),
    })
    .parseAsync(response.data);
}

async function updateLienholder({ id, lienholderId }: { id: number; lienholderId: number | null }) {
  const response = await axios
    .post(`/api/vehicles/${id}/lienholder`, { _method: 'PUT', id: lienholderId })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: LienholderSchema.nullable(),
    })
    .parseAsync(response.data);
}

type RadiusPayload = {
  id: number;
  radius: string | null;
  radius0_50: string | null;
  radius51_200: string | null;
  radius201_500: string | null;
  radius501: string | null;
};

async function updateRadius(payload: RadiusPayload) {
  const response = await axios.put(`/api/vehicles/${payload.id}/radius`, payload).catch((error) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: VehicleSchema,
    })
    .parseAsync(response.data);
}

async function destroy({ id }: { id: number }) {
  const response = await axios.post(`/api/vehicles/${id}`, { _method: 'DELETE' }).catch((error) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.literal('').parseAsync(response.data);
}

export default {
  all,
  destroy,
  updateLienholder,
  updateRadius,
};
