<template>
  <div ref="el" class="search-resources search form-group" :class="[groupClass, { disabled: disabled }]">
    <!-- Label -->
    <div v-if="label" class="form-group__label-container">
      <label class="form-group__label">
        {{ label }}
      </label>
    </div>

    <div class="search__container">
      <!-- Input -->
      <div class="search__input-container">
        <input
          ref="triggerNode"
          v-model="query"
          type="text"
          v-bind="$attrs"
          class="form-control search__input"
          spellcheck="false"
          autocomplete="off"
          :disabled="disabled"
          @focus="autocomplete"
          @input="autocomplete"
          @keydown.stop="keyboardNavigation"
        />
      </div>

      <!-- Matches -->
      <div
        v-if="!hideResults"
        v-show="showList"
        ref="popperNode"
        class="search__matches"
        :class="{ 'search__matches--dropdown': displayResultsAsDropdown }"
      >
        <!-- Scrollable container -->
        <div class="search__matches-scrollable">
          <slot name="matches" :matches="matches">
            <template v-if="matchesType === 'groups'">
              <template v-for="group in matches" :key="group.name">
                <div class="search__group">
                  <div class="search__group-name">
                    {{ group.name }}
                  </div>

                  <template v-for="match in group.matches" :key="match.id">
                    <!-- Slot for whole match -->
                    <slot name="match" :match="match">
                      <a class="search__match" @click="onItemClick(match)">
                        <!-- Slot for match content -->
                        <slot name="match-inner" :match="match">
                          {{ match[matchBy] }}
                        </slot>
                      </a>
                    </slot>
                  </template>
                </div>
              </template>
            </template>

            <template v-else>
              <template v-for="(match, i) in matches" :key="i">
                <!-- Slot for whole match -->
                <slot name="match" :match="match">
                  <a class="search__match" @click="onItemClick(match)">
                    <!-- Slot for match content -->
                    <slot name="match-inner" :match="match">
                      {{ match[matchBy] }}
                    </slot>
                  </a>
                </slot>
              </template>
            </template>
          </slot>
        </div>

        <!-- Footer -->
        <slot name="matches-footer" />
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick, onMounted, ref, watch, computed } from 'vue';
import { createPopper } from '@popperjs/core';
import { onClickOutside } from '@vueuse/core';

export default {
  inheritAttrs: false,

  props: {
    // Group
    groupClass: {
      type: [String, Array, Object],
      default: null,
    },

    // Input
    minSearchChars: {
      type: Number,
      default: null,
    },
    replaceInputValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    // Label
    label: {
      type: String,
      default: null,
    },

    // Initial values
    queryInitialValue: {
      type: String,
      default: '',
    },
    selectedItemInitialValue: {
      type: Object,
      default: null,
    },

    // API (source)
    apiEndpoint: {
      type: String,
      default: null,
    },
    apiFilters: {
      type: [Object, Function],
      default: () => ({}),
    },
    apiParams: {
      type: Object,
      default: () => ({}),
    },
    apiQueryFilterName: {
      type: String,
      default: 'q',
    },

    // Haystack (source)
    haystack: {
      type: Array,
      default: null,
    },
    emptyQueryHaystack: {
      type: Array,
      default: null,
    },

    // Results
    resultsStrategy: {
      type: String,
      default: 'absolute',
    },
    resultsPlacement: {
      type: String,
      default: 'bottom-start',
    },
    hideResults: {
      type: Boolean,
      default: false,
    },
    displayResultsAsDropdown: {
      type: Boolean,
      default: true,
    },
    emptyQueryShowsAll: {
      type: Boolean,
      default: false,
    },
    hideListOnItemClick: {
      type: Boolean,
      default: true,
    },

    // Matching logic
    matchBy: {
      type: String,
      default: 'name',
    },
    matchesType: {
      type: String,
      default: 'list',
      validator: (val) => ['list', 'groups'].includes(val),
    },
  },

  emits: ['matched', 'input:empty', 'item:selected'],

  setup(props, { emit, slots }) {
    // HTML refs
    const el = ref();
    const triggerNode = ref();
    const popperNode = ref();

    // State
    const matches = ref([]);
    const showList = ref(false);
    const query = ref(props.queryInitialValue);
    const selectedItem = ref(props.selectedItemInitialValue);
    const currentFocus = ref(-1);

    const matchesCount = computed(() => {
      if (props.matchesType === 'list') return matches.value.length;
      return matches.value.reduce((c, g) => c + g.matches.length, 0);
    });

    // On item click
    const onItemClick = (item) => {
      // Hide list
      if (props.hideListOnItemClick) showList.value = false;

      // Set selectedItem
      selectedItem.value = item;

      // Replace input value
      if (props.replaceInputValue) query.value = item[props.matchBy];

      // Emit item:selected
      emit('item:selected', item);
    };

    // Reset currentFocus
    watch(query, () => (currentFocus.value = -1));
    watch(matchesCount, () => (currentFocus.value = -1));

    // Highlight the match when currentFocus changes
    watch(currentFocus, () => {
      const items = [...popperNode.value.getElementsByClassName('search__match')];
      items.forEach((item) => item.classList.remove('focused'));
      if (currentFocus.value >= 0 && currentFocus.value <= matchesCount.value - 1)
        items[currentFocus.value].classList.add('focused');
    });

    const popperInstance = ref(null);

    watch(matches, () => {
      showList.value = slots.matches ? true : !!matchesCount.value;
      emit('matched', matches.value);
    });

    // Optimize popper when list is hidden
    watch(showList, () => {
      if (!popperInstance.value) return;
      popperInstance.value.setOptions((options) => ({
        ...options,
        modifiers: [...options.modifiers, { name: 'eventListeners', enabled: showList.value }],
      }));
      popperInstance.value.update();
    });

    onMounted(() => {
      // Create dropdown
      if (popperNode.value && props.displayResultsAsDropdown) {
        popperInstance.value = createPopper(triggerNode.value, popperNode.value, {
          strategy: props.resultsStrategy,
          placement: props.resultsPlacement,
          modifiers: [
            { name: 'flip', enabled: true },
            { name: 'offset', options: { offset: [0, 10] } },
          ],
        });
        nextTick(() => {
          popperInstance.value.update();
        });
      }

      // Hide dropdown on outside click
      onClickOutside(el.value, (event) => {
        if (event.target.classList.contains('swal2-cancel')) return;
        showList.value = false;
      });
    });

    return {
      el,
      onItemClick,
      triggerNode,
      popperNode,
      showList,
      query,
      matches,
      matchesCount,
      selectedItem,
      currentFocus,
    };
  },

  methods: {
    autocomplete(e) {
      const { query } = this;

      if (!query.length) {
        // Was item selected?
        const itemWasSelected = !!this.selectedItem;

        // Reset selected item
        this.selectedItem = null;

        // Emit null
        if (itemWasSelected && e.type !== 'focus') this.$emit('input:empty');

        // Display all
        if (this.emptyQueryShowsAll) {
          this.matches = [...this.haystack];
          return;
        }

        // Display "empty query haystack"
        if (this.emptyQueryHaystack) {
          this.matches = [...this.emptyQueryHaystack];
          return;
        }
      }

      // Prevent search if query.length < minSearchChars
      if (this.minSearchChars ? query.length < this.minSearchChars : false) return (this.matches = []);

      if (this.apiEndpoint) {
        // Filters
        let apiFilters = typeof this.apiFilters === 'function' ? this.apiFilters(this) : this.apiFilters;
        apiFilters = Object.keys(apiFilters).reduce((acc, key) => {
          acc[`filter[${key}]`] = apiFilters[key];
          return acc;
        }, {});
        apiFilters[`filter[${this.apiQueryFilterName}]`] = encodeURIComponent(query);

        let querystring = { ...apiFilters, ...this.apiParams, q: encodeURIComponent(query) };
        querystring = Object.keys(querystring)
          .reduce((acc, key) => {
            if (querystring[key]) acc.push(`${key}=${querystring[key]}`);
            return acc;
          }, [])
          .join('&');

        axios.get(`${this.apiEndpoint}?${querystring}`).then((response) => {
          const urlParams = new URLSearchParams(response.config.url);
          const requestQuery = urlParams.get('q');
          if (query && query !== requestQuery) return;

          if (this.matchesType === 'groups') {
            this.matches = response.data.groups;
          } else {
            this.matches = response.data?.matches || response.data.data;
          }
        });

        // Haystack list
      } else if (this.matchesType === 'list') {
        this.matches = this.haystack.filter(
          (item) => item[this.matchBy] && item[this.matchBy].toLowerCase().includes(query.toLowerCase())
        );
        // Haystack groups
      } else {
        this.matches = this.haystack.map((group) => ({
          ...group,
          matches: group.matches.filter(
            (m) => m[this.matchBy] && m[this.matchBy].toLowerCase().includes(query.toLowerCase())
          ),
        }));
      }
    },
    keyboardNavigation(e) {
      if (![40, 38, 13].includes(e.keyCode)) return;

      if (!this.matchesCount) return;

      e.preventDefault();

      const items = [...this.$refs.popperNode.getElementsByClassName('search__match')];

      if (e.keyCode === 40) {
        this.currentFocus++;
      } else if (e.keyCode === 38) {
        this.currentFocus--;
      } else if (e.keyCode === 13) {
        if (this.currentFocus < 0 || this.currentFocus > this.matchesCount - 1) return;
        return items[this.currentFocus].click();
      }

      if (this.currentFocus <= -1) this.currentFocus = 0;
      else if (this.currentFocus >= items.length) this.currentFocus = items.length - 1;
    },
  },
};
</script>

<style lang="scss">
.search {
  margin-bottom: 0;

  // Padding top on input only if component contains label
  .form-group__label-container + &__container &__input {
    padding-top: rem(13px);
  }

  // Group name
  &__group-name {
    padding-left: rem(10px);
    padding-top: rem(30px);
    padding-bottom: rem(20px);
    font-size: rem(12px);
    color: color('gray', 3);
  }

  // Matches
  &__matches {
    z-index: 1;
    background-color: white;
    border-radius: $border-radius;
    min-width: 100%;

    &--dropdown {
      box-shadow: 0 rem(1px) rem(19px) #00000059;
    }
  }

  // Scrollable list
  &__matches-scrollable {
    padding: rem(10px);
    max-height: 50vh;
    overflow: auto;
  }

  // Match
  &__match {
    line-height: 1.5;
    display: block;
    font-size: rem(16px);
    font-weight: normal;
    padding: rem(7.5px) rem(17.5px);
    cursor: pointer;
    transition: background-color 0.15s;
    text-decoration: none;
    color: color('text', 1);

    // Border
    &:not(:last-child) {
      border-bottom: 1px solid $border;
    }

    // Highlight
    &.focused,
    &:hover {
      background-color: color('gray', 2);
    }

    // ?
    em {
      font-style: inherit;
      background-color: #f8f884;
    }
  }

  // Match details
  &__match-details {
    display: flex;
    font-size: rem(13px);
    color: color('text', 4);
  }

  &__match-top {
    display: flex;
    align-items: center;
  }

  &__match-icon {
    margin-left: rem(20px);
  }
}
</style>
