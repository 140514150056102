
export const secondsToHHMMSS = totalSeconds => {
  if (totalSeconds == null)
    return;

  let modulo = null;
  let hours = Math.floor(totalSeconds / 3600);
  modulo = totalSeconds % 3600;
  let minutes = Math.floor(modulo / 60);
  let seconds = modulo % 60;

  if (hours < 10)
    hours = '0' + hours;
  if (minutes < 10)
    minutes = '0' + minutes;
  if (seconds < 10)
    seconds = '0' + seconds;

  const result = `${hours !== '00' ? hours + ':' : ''}${minutes}:${seconds}`;

  return result;
};
