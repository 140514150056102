import { z } from 'zod';
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';

export const PolicyCancellationSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  policy_id: z.number(),
  date: z.string(),
  reasons: z.string(),
  amount: z.number().nullable(),
  carrier_underwrighting_reason: z.string().nullable(),
  reinstatement: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});

export type PolicyCancellation = z.infer<typeof PolicyCancellationSchema>;

// ***************************************************
// Create
// ***************************************************

export type CreateArgs = {
  clientId: number;
  policyId: number;
  form: {
    date: string;
    reasons: string[];
    amount?: number | null;
    carrier_underwrighting_reason?: string | null;
  };
};

async function create({ clientId, policyId, form }: CreateArgs) {
  const response = await axios
    .post(`/api/clients/${clientId}/policies/${policyId}/cancellations`, form)
    .catch((error: AxiosError) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: PolicyCancellationSchema,
    })
    .parseAsync(response.data);
}

// ***************************************************
// Update
// ***************************************************

export type UpdateArgs = {
  id: number;
  clientId: number;
  policyId: number;
  form: {
    date?: string;
    reasons?: string[];
    amount?: number | null;
    carrier_underwrighting_reason?: string | null;
  };
};

async function update({ id, clientId, policyId, form }: UpdateArgs) {
  const response = await axios
    .put(`/api/clients/${clientId}/policies/${policyId}/cancellations/${id}`, form)
    .catch((error: AxiosError) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: PolicyCancellationSchema,
    })
    .parseAsync(response.data);
}

export default {
  create,
  update,
};
