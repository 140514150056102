import { z } from 'zod';
import axios from 'axios';
import * as Sentry from '@sentry/vue';

export const PhoneSchema = z.object({
  id: z.number(),
  client_id: z.number().nullable(),
  contact_id: z.number(),
  number: z.string().nullable(),
  description: z.string().nullable(),
  is_primary: z.boolean(),
  created_at: z.string(),
  updated_at: z.string(),
});

export type Phone = z.infer<typeof PhoneSchema>;
