<template>
  <div class="layout">
    <!-- Top-bar -->
    <Topbar />

    <!-- Main -->
    <main class="layout__main">
      <!-- Page content -->
      <div class="layout__page">
        <slot />
      </div>
    </main>
  </div>
</template>

<script>
import Topbar from './Topbar/Topbar.vue';
import SidebarLeft from './SidebarLeft/SidebarLeft.vue';

export default {
  components: {
    Topbar,
    SidebarLeft,
  },
};
</script>

<style lang="scss" scoped>
.layout {
  display: grid;
  grid-template-rows: rem(75px) 1fr;
  min-height: 100vh;

  .layout__main {
    display: flex;
    max-width: 100%;
    max-height: 100vh;
    overflow-x: hidden;

    .layout__page {
      flex: 1;
      padding: rem(14px) rem(26px) rem(30px);
      max-height: calc(100vh - #{rem($topbarHeightPX)});
      overflow: scroll;
    }
  }
}
</style>
