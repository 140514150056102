import axios from 'axios';

export default {
  namespaced: true,

  state: () => ({
    clientHolders: [],
    globalHolders: [],
  }),

  actions: {
    fetchTemplate({ commit }, { clientID, templateID, include = [] }) {
      return axios
        .get(
          `/api/clients/${clientID}/certificates/templates/${templateID}${
            include.length ? `?include=${include.join(',')}` : ''
          }`
        )
        .then((response) => {
          commit('setTemplate', response.data.template);
        });
    },
    fetchDOOs({ commit }, { clientID, include = [], noGlobal = false }) {
      const queryStringObj = {};
      if (include.length) queryStringObj.include = include.join(',');
      if (noGlobal) queryStringObj.no_global = true;
      return axios
        .get(`/api/clients/${clientID}/certificates/doos${new URLSearchParams(queryStringObj).toString()}`)
        .then((response) => {
          commit('setDOOs', response.data.doos);
        });
    },
    fetchHolders({ commit }, { clientID, include = [], noGlobal = false }) {
      const queryStringObj = {};
      if (include.length) queryStringObj.include = include.join(',');
      if (noGlobal) queryStringObj.no_global = true;
      return axios
        .get(`/api/clients/${clientID}/certificates/holders?${new URLSearchParams(queryStringObj).toString()}`)
        .then((response) => {
          commit('setHolders', response.data.holders);
        });
    },
  },

  mutations: {
    setTemplate(state, template) {
      state.template = template;
    },
    setTemplateField(state, { field, value }) {
      state.template[field] = value;
    },
    setTemplatePolicy(state, { index, policy }) {
      state.template.policies[index] = policy;
    },

    setClientDOOs(state, response) {
      state.clientDOOs = response.data;
    },
    setGlobalDOOs(state, response) {
      state.globalDOOs = response.data;
    },
    pushDOO(state, newDOO) {
      if (newDOO.client_id) state.clientDOOs.push(newDOO);
      else state.globalDOOs.push(newDOO);
    },
    updateDOO(state, newDOO) {
      const arrayName = newDOO.client_id ? 'clientDOOs' : 'globalDOOs';
      const dooInState = state[arrayName].find((d) => d.id === newDOO.id);
      Object.assign(dooInState, newDOO);
      if (state?.template?.doo?.id === newDOO.id) state.template.doo = newDOO;
    },
    starDOO(state, newDOO) {
      const arrayName = newDOO.client_id ? 'clientDOOs' : 'globalDOOs';
      state[arrayName].forEach((doo) => {
        doo.starred = false;
      });
      const dooInState = state[arrayName].find((d) => d.id === newDOO.id);
      Object.assign(dooInState, newDOO);
    },
    destroyDOO(state, deletedDOO) {
      const arrayName = deletedDOO.client_id ? 'clientDOOs' : 'globalDOOs';
      const i = state[arrayName].findIndex((doo) => doo.id === deletedDOO.id);
      state[arrayName].splice(i, 1);
    },

    setClientHolders(state, response) {
      state.clientHolders = response.data;
    },
    setGlobalHolders(state, response) {
      state.globalHolders = response.data;
    },
    pushHolder(state, newHolder) {
      if (newHolder.client_id) state.clientHolders.push(newHolder);
      else state.globalHolders.push(newHolder);
    },
    updateHolder(state, newHolder) {
      const arrayName = newHolder.client_id ? 'clientHolders' : 'globalHolders';
      const holderInState = state[arrayName].find((h) => h.id === newHolder.id);
      Object.assign(holderInState, newHolder);
      if (state.template && state.template.holder && state.template.holder.id === newHolder.id)
        state.template.holder = newHolder;
    },
    setHolderSnapshot(state, snapshot) {
      const holderInState = state.clientHolders.find((h) => h.id === snapshot.holder_id);
      holderInState.snapshot = snapshot;
    },
  },
};
