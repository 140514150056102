<template>
  <IMaskComponent
    :value="localValue"
    type="text"
    :mask="mask.mask"
    :lazy="mask.lazy"
    :eager="mask.eager"
    :expose="mask.expose"
    :blocks="mask.blocks"
    @blur="handleBlur"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { IMaskComponent } from 'vue-imask';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  mask: {
    type: Function,
    default: () => () => ({}),
  },
  lazy: {
    type: Boolean,
    default: false,
  },
  eager: {
    type: Boolean,
    default: false,
  },
  expose: {
    type: Boolean,
    default: false,
  },
  blocks: {
    type: Object,
    default: () => ({}),
  },
  unmasked: {
    type: Boolean,
    default: false,
  },
});

const mask = computed(() => props.mask());
const localValue = computed(() => props.modelValue ?? '');

const emit = defineEmits<{
  (e: 'update:modelValue', payload: string): void;
}>();

const handleBlur = (event: FocusEvent) => {
  const inputElement = event.target as HTMLInputElement;

  emit('update:modelValue', inputElement.value);
};
</script>
