<template>
  <div class="quote-update-form">
    <div :style="{ 'grid-area': 'row1' }">
      <!-- Carrier -->
      <CarrierInput name="carrier_id" :disabled="disabled" :autosave-method="save" :initial-options="quote.carrier" />

      <!-- Agency -->
      <AgencyInput name="agency_id" :disabled="disabled" :autosave-method="save" :initial-options="quote.agency" />

      <!-- Quote price -->
      <FormGroup
        type="currency"
        name="price"
        label-text="Enter quote price"
        :disabled="disabled"
        :autosave-method="save"
        @update:model-value="onPriceUpdate"
      />
    </div>

    <!-- Submission date -->
    <FormGroup
      type="date"
      placeholder="-"
      name="submission_date"
      label-text="Submission date"
      :today-icon="true"
      :disabled="disabled"
      :autosave-method="save"
    />

    <!-- Submission method -->
    <FormGroup
      type="select"
      name="submission_method"
      label="Submission method"
      :disabled="disabled"
      :autosave-method="save"
      :options="SUBMISSION_METHOD_OPTIONS"
    />

    <!-- Dates -->
    <FormRow grid-template-columns="1fr 1fr">
      <FormGroup
        type="date"
        placeholder="-"
        name="effective_date"
        label-text="Effective date"
        :today-icon="true"
        :disabled="disabled"
        :autosave-method="save"
      />
      <FormGroup
        type="date"
        placeholder="-"
        name="expiration_date"
        label-text="Expiration date"
        :disabled="disabled"
        :autosave-method="save"
      >
        <template #controls>
          <span v-if="values.effective_date" class="policy-form__plus-btn" role="button" @click="onPlusBtnClick(6)"
            >+6</span
          >
          <span v-if="values.effective_date" class="policy-form__plus-btn" role="button" @click="onPlusBtnClick(12)"
            >+12</span
          >
        </template>
      </FormGroup>
    </FormRow>

    <!-- Remarks -->
    <FormGroup
      type="text"
      name="remarks"
      label-text="Remarks"
      :disabled="disabled"
      :autosave-method="save"
      :group-style="{ 'grid-area': 'remarks' }"
    />

    <!-- Divider -->
    <div class="divider" :style="{ 'grid-area': 'divider' }"></div>

    <!-- Coverage types -->
    <CoverageTypes
      :quote="quote"
      :disabled="disabled"
      :available-coverage-types="availableCoverageTypes"
      @loading="(state) => emit('loading', state)"
      @update:quote="(quote) => emit('update:quote', quote)"
    />
  </div>
</template>

<script setup lang="ts">
import { z } from 'zod';
import { computed, watch } from 'vue';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import { confirm, toastError } from '@/js/Helpers/Alert';
import { toDateInput } from '@/js/Helpers';
import { CoverageType } from '@/js/Api/CoverageType';
import QuoteApi, { QuotePastStatus } from '@/js/Api/Quote';
import { STATUSES, SUBMISSION_METHODS, SUBMISSION_METHOD_OPTIONS } from '@/js/Presenters/Quote';
import FormRow from '@/js/Components/Form/Row.vue';
import FormGroup from '@/js/Components/Form/FormGroup.vue';
import CarrierInput from '@/js/Components/Carriers/Input.vue';
import AgencyInput from '@/js/Components/Agencies/Input.vue';
import CoverageTypes from './CoverageTypes.vue';

type Quote = Awaited<ReturnType<typeof QuoteApi.find>>['data'];

interface Props {
  quote: Quote;
  disabled: boolean;
  nextStatus?: Quote['status'];
  pastStatauses?: QuotePastStatus[];
  availableCoverageTypes: CoverageType[];
}

interface Emits {
  (e: 'loading', state: boolean): void;
  (e: 'autosaving', state: boolean): void;
  (e: 'update:quote', quote: Quote): void;
  (e: 'update:status', status: Quote['status']): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const FormSchema = z
  .object({
    carrier_id: z.number().nullable(),
    agency_id: z.number().nullable(),
    price: z.number().nullable(),
    submission_date: z.string().nullable(),
    submission_method: z.enum(SUBMISSION_METHODS).nullable(),
    effective_date: z.string().nullable(),
    expiration_date: z.string().nullable(),
    remarks: z.string().nullable(),
  })
  .superRefine((form, ctx) => {
    if (props.nextStatus === undefined) return;
    if (['Submitted', 'Received', 'Presented'].includes(props.nextStatus) && !form.carrier_id && !form.agency_id) {
      ctx.addIssue({
        path: ['carrier_id'],
        code: z.ZodIssueCode.custom,
        message: `Carrier or Agency is required in order for a quote to become ${props.nextStatus}`,
      });
      ctx.addIssue({
        path: ['agency_id'],
        code: z.ZodIssueCode.custom,
        message: 'Carrier or Agency is required in order for a quote to become Submitted',
      });
    }
    if (props.nextStatus === 'Presented' && form.price === null) {
      ctx.addIssue({
        path: ['price'],
        code: z.ZodIssueCode.custom,
        message: 'Price is required in order for a quote to become Presented',
      });
    }
    if (props.nextStatus === 'Sold' && form.carrier_id === null) {
      ctx.addIssue({
        path: ['carrier_id'],
        code: z.ZodIssueCode.custom,
        message: 'Carrier is required in order for a quote to become Sold',
      });
    }
    if (props.nextStatus === 'Sold' && form.price === null) {
      ctx.addIssue({
        path: ['price'],
        code: z.ZodIssueCode.custom,
        message: 'Price is required in order for a quote to become Sold',
      });
    }
    if (props.nextStatus === 'Sold' && form.effective_date === null) {
      ctx.addIssue({
        path: ['effective_date'],
        code: z.ZodIssueCode.custom,
        message: 'Effective date is required in order for a quote to become Sold',
      });
    }
    if (props.nextStatus === 'Sold' && form.expiration_date === null) {
      ctx.addIssue({
        path: ['expiration_date'],
        code: z.ZodIssueCode.custom,
        message: 'Expiration date is required in order for a quote to become Sold',
      });
    }
  });

type Form = z.infer<typeof FormSchema>;

const { values, setFieldValue, validate } = useForm({
  validationSchema: toTypedSchema(FormSchema),
  initialValues: props.quote,
});

watch(values, () => emit('autosaving', true));

const save = async (value: Form[keyof Form], name: keyof Form) => {
  return QuoteApi.update({
    id: props.quote.id,
    form: {
      [name]: value,
    },
  })
    .then(({ data }) => {
      emit('update:quote', data);
    })
    .finally(() => {
      emit('autosaving', false);
    });
};

const onPlusBtnClick = async (months: number) => {
  if (!values.effective_date) return;

  const result = await confirm({
    confirmButtonText: 'Confirm',
    text: `This will add ${months} months to effective date`,
  });
  if (!result.isConfirmed) return;

  let date = new Date(values.effective_date);
  date = new Date(date.setMonth(date.getMonth() + months));
  const value = toDateInput(date);

  setFieldValue('expiration_date', value);

  save(value, 'expiration_date');
};

const isBeforeReceived = computed(() => {
  const i1 = STATUSES.findIndex((s) => s === props.quote.status);
  const i2 = STATUSES.findIndex((s) => s === 'Received');
  return i1 < i2;
});

// Update status on price change
const onPriceUpdate = async () => {
  if (!values.price || !isBeforeReceived.value) return;

  const confirmResult = await confirm({
    icon: 'question',
    confirmButtonText: 'Confirm',
    text: 'Do you want to change the status of this quote to "Received"?',
  });

  if (!confirmResult.isConfirmed) return;

  emit('update:status', 'Received');
};

defineExpose({
  validate,
});
</script>

<style lang="scss" scoped>
.quote-update-form {
  display: grid;
  grid-row-gap: rem(20px);
  grid-column-gap: rem(15px);
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-areas:
    'row1    row1    row1'
    'date    method  dates'
    'remarks remarks remarks'
    'divider divider divider'
    'lines   lines   lines';
  background: #efefef;
  border-radius: rem(10px);
  padding: rem(15px);

  > :nth-child(1) {
    display: grid;
    grid-column-gap: rem(15px);
    grid-template-columns: 2fr 2fr 1fr;
  }

  .divider {
    height: rem(1px);
    background: #ccc;
  }
}
</style>
