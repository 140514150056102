<template>
  <div class="client-info-container">
    <IntakeGeneralRelatedClients :client-id="client.id" :disabled="!canEditClient" />

    <IntakeGeneralProducer :client-id="client.id" :disabled="!canEditClient" />

    <IntakeGeneralReferralSource :client-id="client.id" :disabled="!canEditClient" />

    <IntakeGeneralCustomerService :client-id="client.id" :disabled="!canEditClient" />

    <IntakeGeneralQuotingSpecialist :client-id="client.id" :disabled="!canEditClient" />

    <IntakeGeneralOriginalInput v-if="client" line="single" :client="client" />
  </div>
</template>

<script>
import { inject } from 'vue';
import IntakeGeneralReferralSource from '@/js/Common/Form/IntakeGeneral/IntakeGeneralAdditional/IntakeGeneralReferralSource.vue';
import IntakeGeneralRelatedClients from '@/js/Common/Form/IntakeGeneral/IntakeGeneralAdditional/IntakeGeneralRelatedClients.vue';
import IntakeGeneralProducer from '@/js/Common/Form/IntakeGeneral/IntakeGeneralStaff/IntakeGeneralProducer.vue';
import IntakeGeneralCustomerService from '@/js/Common/Form/IntakeGeneral/IntakeGeneralStaff/IntakeGeneralCustomerService.vue';
import IntakeGeneralQuotingSpecialist from '@/js/Common/Form/IntakeGeneral/IntakeGeneralStaff/IntakeGeneralQuotingSpecialist.vue';
import IntakeGeneralOriginalInput from '@/js/Common/Form/IntakeGeneral/IntakeGeneralOriginalInput.vue';

export default {
  components: {
    IntakeGeneralReferralSource,
    IntakeGeneralRelatedClients,
    IntakeGeneralProducer,
    IntakeGeneralCustomerService,
    IntakeGeneralQuotingSpecialist,
    IntakeGeneralOriginalInput,
  },

  props: {
    canEdit: {
      type: Boolean,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const canEditClient = inject('canEditClient');
    return {
      canEditClient,
    };
  },
};
</script>

<style lang="scss" scoped>
.client-info-container {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: rem(25px);
  margin-bottom: rem(26px);

  :deep(.original-input) {
    transform: translateY(#{rem(-17px)});
  }
}
</style>
