<template>
  <ProgressBar
    class="progress-bar__quote__group"
    :steps="steps"/>
</template>

<script>
import { computed } from 'vue';
import ProgressBar from '@/js/Components/ProgressBar.vue';

export default {
  components: {
    ProgressBar
  },

  props: {
    quoteGroup: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const allStatusNames = [ 'Lead', 'Ready to quote', 'Items pending', 'Quoting', 'To present', 'Presented', 'Sold (Processing)', 'Sold' ];

    const steps = computed(() => {
      const uniqueStatuses = props.quoteGroup.statuses.filter((status, i, self) => i === self.findIndex(s => s.name === status.name));
      return allStatusNames.map(statusName => ({
        title: statusName,
        active: !!uniqueStatuses.find(us => us.name === statusName)
      }));
    });

    return {
      steps
    };
  }
};
</script>
