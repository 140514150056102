<template>
  <div class="create-subnote-form">
    <!-- Text -->
    <TextareaAutoExpandable
      ref="TextareaAutoExpandableRef"
      v-model="content"
      name="text"
      placeholder="Add a note"
      input-class="note__content"
      :autosave="shouldAutosave"
      :autosave-url="'/api/tickets/text/autosave'"
      :autosave-params="{ client_id: note.client_id, note_id: note.id }"
      :after-autosave="updateNoteUnsavedText"
      @input="(e) => e.target.classList.remove('is-invalid')"
    />

    <!-- Buttons -->
    <div class="create-subnote-form__buttons">
      <!-- Discard -->
      <a class="create-subnote-form__discard" @click="$emit('discard')"> Discard note </a>

      <!-- Post -->
      <Btn class="create-subnote-form__post" size="sm" @click="post"> Post </Btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import useClientStore from '@/js/store/pinia/useClientStore';
import { TicketNote } from '@/js/Api/TicketNote';
import Btn from '@/js/Components/Btn.vue';
import TextareaAutoExpandable from '@/js/Components/TextareaAutoExpandable.vue';

interface Props {
  note: TicketNote;
}

const props = defineProps<Props>();

const emit = defineEmits(['post', 'discard']);

const store = useClientStore();
const content = ref(props.note.unsaved_text?.text ?? null);

const shouldAutosave = ref(true);
const TextareaAutoExpandableRef = ref(null);

// Submit subnote
const post = () => {
  shouldAutosave.value = false;
  if (!content.value) TextareaAutoExpandableRef.value.$el.querySelector('textarea').classList.add('is-invalid');
  else emit('post', content.value);
};

const updateNoteUnsavedText = (response) => {
  store.updateNoteProperty({
    note: props.note,
    property: 'unsaved_text',
    value: response.data.unsaved_text,
  });
};
</script>

<style lang="scss">
.create-subnote-form {
  $three-dots-width: rem(44px);

  width: calc(100% - #{$three-dots-width});

  // Input
  .form-group__input {
    height: rem(32px);
    font-size: rem(14px);
    background-color: #ebebeb;
    border-radius: rem(6px);

    &::placeholder {
      font-size: rem(14px);
    }

    &:not(.is-invalid) {
      border: none;
    }
  }

  // Buttons
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // Post
  &__post {
    padding: rem(1px) rem(11px);
  }

  // Discard
  &__discard {
    font-size: rem(14px);
  }
}
</style>
