<template>
  <div class="panel-notes-ticket-details">
    <!-- View mode -->
    <template v-if="mode === 'view'">
      <div class="panel-notes-ticket-details__title">
        <!-- Ticket type -->
        <div class="panel-notes-ticket-details__ticket-type">
          {{ ticket.type }}
        </div>

        <!-- # of followups -->
        <div
          class="panel-notes-ticket-details__count-followups"
          :class="{ 'panel-notes-ticket-details__count-followups--0': openFollowupCount === 0 }"
        >
          <img
            v-if="openFollowupCount === 0"
            class="panel-notes-ticket-details__count-followups-icon"
            src="/img/icons/warning-white.svg"
          />
          {{ openFollowupCount || 'No' }} open followup{{ openFollowupCount === 1 ? '' : 's' }}
        </div>

        <!-- Merged tickets -->
        <Dropdown v-if="ticket.merged_tickets.length" class="dropdown--merged-tickets">
          <template #trigger>
            {{ ticket.merged_tickets.length }} ticket{{ ticket.merged_tickets.length === 1 ? '' : 's' }} merged
          </template>
          <template #menu>
            <DropdownItem
              v-for="mergedTicket in ticket.merged_tickets"
              :key="mergedTicket.id"
              @click="openTicket(mergedTicket.id)"
            >
              <strong class="dropdown--merged-tickets__type">{{ mergedTicket.type }}</strong>
              {{ mergedTicket.subject }}
            </DropdownItem>
          </template>
        </Dropdown>

        <!-- Merged flag -->
        <div v-if="ticket.target_tickets.length" class="panel-notes-ticket-details__merged-flag">Merged</div>

        <div class="panel-notes-ticket-details__links">
          <a
            v-if="me.permissions['edit-tickets'] || (openFollowupCount === 0 && ticket.status === 'Open')"
            class="panel-notes-ticket-details__link"
            @click="mode = 'edit'"
          >
            <SvgIcon name="pencil" />
            Edit ticket
          </a>
          <div class="panel-notes-ticket-details__links-separator"></div>
          <a v-if="me.permissions['edit-tickets']" class="panel-notes-ticket-details__link" @click="openMergeModal">
            <SvgIcon name="merge" />
            Merge
          </a>
        </div>

        <!-- Merge modal -->
        <MergeModal v-if="me.permissions['edit-tickets']" ref="MergeModalRef" :ticket="ticket" :tickets="tickets" />
      </div>

      <!-- Ticket subject -->
      <div class="panel-notes-ticket-details__ticket-subject-text">
        {{ ticket.subject }}
      </div>
    </template>

    <!-- Edit mode -->
    <div v-show="mode === 'edit'">
      <div class="panel-notes-ticket-details__top">
        <!-- Ticket type -->
        <FormGroup
          :model-value="ticket.type"
          type="select"
          name="type"
          :label-hidden="true"
          :autosave-url="autosaveEndpoint"
          group-class="panel-notes-ticket-details__ticket-type-group"
          input-class="panel-notes-ticket-details__ticket-type-input"
          :after-autosave="commitTicketUpdate"
          :disabled="!me.permissions['edit-tickets']"
        >
          <option name="Sales">Sales</option>
          <option name="Service">Service</option>
        </FormGroup>
      </div>

      <!-- Ticket subject -->
      <TextareaAutoExpandable
        ref="TextareaAutoExpandableRef"
        :model-value="ticket.subject"
        name="subject"
        :autosave-url="autosaveEndpoint"
        group-class="panel-notes-ticket-details__ticket-subject-group"
        input-class="panel-notes-ticket-details__ticket-subject-input"
        :after-autosave="commitTicketUpdate"
        :disabled="!me.permissions['edit-tickets']"
        @input="(e) => e.target.classList.remove('is-invalid')"
      />

      <!-- Buttons -->
      <div class="panel-notes-ticket-details__buttons">
        <Btn
          v-if="openFollowupCount === 0 && ticket.status === 'Open'"
          size="sm"
          class="panel-notes-ticket-details__button panel-notes-ticket-details__button--resolve"
          @click="changeTicketStatus('Resolved')"
        >
          Resolve
          <SvgIcon name="checkmark-circle" />
        </Btn>
        <Btn
          v-if="me.permissions['tickets.status.update'] && ticket.status === 'Resolved'"
          size="sm"
          variant="outline"
          class="panel-notes-ticket-details__button"
          @click="changeTicketStatus('Open')"
        >
          Reopen
          <SvgIcon name="clock-arrow" />
        </Btn>
        <Btn size="sm" @click="mode = 'view'"> Done </Btn>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import TicketApi from '@/js/Api/Ticket';
import useClientStore from '@/js/store/pinia/useClientStore';
import Loader from '@/js/Helpers/Loader';
import { me } from '@/js/Helpers/Session';
import { toast, confirm } from '@/js/Helpers/Alert';
import Btn from '@/js/Components/Btn.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import Dropdown from '@/js/Common/Dropdown.vue';
import DropdownItem from '@/js/Common/DropdownItem.vue';
import FormGroup from '@/js/Common/Form/FormGroup/FormGroup.vue';
import TextareaAutoExpandable from '@/js/Components/TextareaAutoExpandable.vue';
import MergeModal from '@/js/Components/Tickets/MergeModal.vue';

const props = defineProps({
  ticket: {
    type: Object,
    required: true,
  },
  tickets: {
    type: Array,
    required: true,
  },
});

const store = useClientStore();

// Mode (view, edit)
const mode = ref('view');

// Autosave endpoint
const autosaveEndpoint = `/api/tickets/${props.ticket.id}/autosave`;

// Commit ticket update
const commitTicketUpdate = (response) => store.updateTicket(response.data.model);

// # of open followups
const openFollowupCount = computed(() => {
  return props.ticket.notes.reduce((totalCount, note) => {
    if (note.is_followup && note.status === 'Open') totalCount += 1;
    return totalCount + note.notes.filter((n) => n.is_followup && n.status === 'Open').length;
  }, 0);
});

// Merge modal
const MergeModalRef = ref(null);
const openMergeModal = () => {
  MergeModalRef.value.$refs.ModalRef.show();
};

const openTicket = (ticketID) => {
  store.currentTicketId = ticketID;
};

const changeTicketStatus = async (status) => {
  const action = status === 'Resolved' ? 'resolve' : 'reopen';

  const confirmResult = await confirm({
    icon: 'warning',
    confirmButtonText: `${action.capitalize()} ticket`,
    text: `Are you sure you want to ${action} this ticket?`,
  });

  if (!confirmResult.isConfirmed) return;

  Loader.start();
  TicketApi.update({
    id: props.ticket.id,
    body: {
      status,
    },
  })
    .then(({ data: ticket }) => {
      store.updateTicketStatus(ticket);
      const verbiage = status === 'Resolved' ? 'resolved' : 'reopened';
      toast({ title: `Ticket has been ${verbiage}`, icon: 'success' });
    })
    .catch(() => {
      toast({ title: 'An error occurred', icon: 'error' });
    })
    .finally(() => {
      Loader.end();
    });
};
</script>

<style lang="scss">
@mixin flag {
  display: flex;
  align-items: center;
  color: white;
  line-height: 1.3;
  font-size: rem(12px);
  background-color: color('gray');
  border-radius: rem(2px);
  margin-left: rem(5px);
  padding: 0 rem(5px);
}

.panel-notes-ticket-details {
  position: relative;

  // Title (view mode)
  &__title {
    display: flex;
    align-items: center;
  }

  // Ticket type (view mode)
  &__ticket-type {
    color: black;
    font-size: rem(14px);
  }

  // Links
  &__links {
    margin-left: auto;
    display: flex;
    align-items: center;

    &-separator {
      width: rem(1px);
      height: rem(15px);
      background-color: #ccc;
      margin: 0 rem(10px);
    }
  }

  // Link
  &__link {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: rem(14px);
    color: color('primary');

    .svg-icon {
      width: rem(20px);
      height: rem(20px);

      &--pencil {
        width: rem(17px);
        height: rem(17px);
        margin-right: rem(4px);
      }
    }
  }

  // # of followups (view mode)
  &__count-followups {
    @include flag;

    &--0 {
      background-color: #39bf07;
    }

    &-icon {
      width: rem(10px);
      margin-right: rem(2.5px);
    }
  }

  // Merge tickets (view mode)
  .dropdown--merged-tickets {
    .dropdown__trigger {
      @include flag;
    }

    &__type {
      margin-right: rem(5px);
    }
  }

  // Merged flag
  &__merged-flag {
    @include flag;
  }

  &__ticket-subject-text {
    // white-space: pre;
    line-height: 1.3;
    max-width: 100%;
    overflow: hidden;
    /* stylelint-disable value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  // Show more (view mode)
  &__show-more {
    cursor: pointer;
    margin-left: rem(5px);
  }

  // Show less (view mode)
  .sidebar-right-panel__header {
    position: relative;
  }

  &__show-less {
    bottom: rem(-11px);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: rem(80px);
    position: absolute;
    text-align: center;
    font-size: rem(14px);
    background-color: white;
    border: rem(1px) solid color('gray', 3);
    border-radius: rem(6px) rem(6px) 0 0;
  }

  // Top (edit)
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // Ticket type
  &__ticket-type-group {
    margin-bottom: 0;
  }

  &__ticket-type-input {
    height: rem(31px);
    width: rem(353px);
    line-height: 1;
    padding: 0 rem(11px) !important;
    font-size: rem(14px);
    background-color: #ebebeb;
    background-position-y: rem(3.5px) !important;
    border-radius: rem(6px);
    border: none;
    cursor: pointer;
  }

  // Ticket subject
  &__ticket-subject-group {
    margin-bottom: 0;
  }

  &__ticket-subject-input {
    margin-top: rem(7px);
    max-height: rem(300px);
    padding: rem(3px) rem(11px);
    font-size: rem(16px);
    background-color: #ebebeb;
    border-radius: rem(6px);

    &:not(.is-invalid) {
      border: none;
    }

    &.autoexpandable {
      min-height: rem(109px);
    }
  }

  // Buttons
  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin: rem(10px) 0;
  }

  &__button {
    margin-right: rem(10px);

    .svg-icon {
      width: rem(22px);
      margin-left: rem(4px);
    }
  }

  &__button--resolve {
    color: $success !important;
    border-color: $success !important;
    background-color: white !important;

    .svg-icon {
      color: $success !important;
    }

    &:hover {
      color: white !important;
      background-color: $success !important;

      .svg-icon {
        color: white !important;
      }
    }
  }
}
</style>
