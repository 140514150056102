<template>
  <div class="congratulations-card">
    <!-- Card -->
    <div class="congratulations-card__card">
      <div class="congratulations-card__card-content">
        <img :src="confettiUrl" />
        <i class="fas fa-trophy"></i>
        <h3><b>CONGRATULATIONS!</b></h3>
        <p>You just sold a policy</p>
        <router-link
          :to="`/clients/${quote.client_id}/policies/${quote.policy.id}/processing`"
          class="button button--regular button--secondary button--size-lg"
        >
          Go to processing
        </router-link>
      </div>
    </div>

    <!-- Go back -->
    <Btn class="congratulations-card__back-btn" variant="outline" @click="emit('leave')">
      <SvgIcon name="arrow-left" class="mr-1" />
      Return to prior screen
    </Btn>
  </div>
</template>

<script setup lang="ts">
import { Quote } from '@/js/Api/Quote';
import Btn from '@/js/Components/Btn.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';

interface Props {
  quote: Quote;
}

interface Emits {
  (e: 'leave'): void;
}

defineProps<Props>();

const emit = defineEmits<Emits>();

const confettiUrl = new URL('@/icons/confetti.svg', import.meta.url).href;
</script>

<style lang="scss">
.congratulations-card {
  &__card {
    position: relative;
    display: flex;
    justify-content: center;
    color: white;
    background: #83d5a7;
    border-radius: rem(10px);
    height: rem(460px);
    padding-top: 1em;

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 80%;
        height: auto;
        object-fit: contain;
      }

      i {
        top: 40%;
        position: absolute;
        font-size: rem(62px);
      }

      h3 {
        font-size: rem(36px);
        line-height: rem(43px);
        text-align: center;
      }
      p {
        font-size: rem(18px);
        line-height: rem(23px);
        text-align: center;
      }

      a {
        text-decoration: none;
      }
    }
  }

  &__back-btn {
    margin-top: 1em;
  }
}
</style>
