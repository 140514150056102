<template>
  <FormContainer
    ref="FormContainerInst"
    class="form-grid__row"
    :class="{ 'nested': isNested }"
    :autosave-url="autosaveUrl"
  >

    <!-- Content -->
    <slot/>

    <!-- Dots -->
    <Dropdown v-if="dots" v-show="!dropdownMenuIsEmpty" placement="right-start" strategy="fixed" :offset="[0, 10]" container-class="dots">
      <template v-slot:trigger>
        <img src="/img/icons/3_dots-24px.svg" class="dots">
      </template>
      <template v-slot:menu>

        <!-- Custom links -->
        <slot name="dots-links-prepend"></slot>

        <!-- Show all details link -->
        <template v-if="!isNested && showDetailsLink">
          <a class="dropdown-item" @click.prevent="expandGroup">
            <i v-if="isGroupExpanded" class="fas fa-eye-slash icon"></i>
            <i v-else class="fas fa-eye icon"></i>
            {{ isGroupExpanded ? 'Show less details' : 'Show all details' }}
          </a>
        </template>

        <!-- Custom links -->
        <slot name="dots-links-append"></slot>

      </template>
    </Dropdown>

  </FormContainer>
</template>

<script>
import { ref, inject, computed, getCurrentInstance, onMounted } from 'vue';
import Dropdown from '@/js/Common/Dropdown.vue';
import FormContainer from '@/js/Common/Form/FormContainer.vue';

export default {
  name: 'FormGridRow',

  components: {
    Dropdown,
    FormContainer
  },

  props: {
    autosaveUrl: {
      type: String
    },
    dots: {
      type: Boolean,
      default: true
    },
    dotsDetailsLink: {
      type: Boolean,
      default: true
    }
  },

  setup(props) {

    // Is nested?
    const instance = getCurrentInstance();
    let isNested = false, parent = instance.parent;
    while (parent) {
      isNested = parent.type.name === 'FormGridRow';
      if (isNested)
        break;
      parent = parent.parent;
    }

    const FormContainerInst = ref(null);

    // Delete the dropdown if it is empty
    const dropdownMenuIsEmpty = ref(false);
    if (props.dots)
      onMounted(() => {
        const
          dropdownMenu = FormContainerInst.value.$el.querySelector(':scope > .dropdown.dots .dropdown-menu'),
          observer = new MutationObserver((mutationsList) => {
            dropdownMenuIsEmpty.value = !dropdownMenu.children.length;
          });
        observer.observe(dropdownMenu, { childList: true });
        dropdownMenuIsEmpty.value = !dropdownMenu.children.length;
      });

    // If nested, return
    if (isNested)
      return {
        FormContainerInst,
        isNested,
        dropdownMenuIsEmpty,
        showDetailsLink: false
      };

    // Determine index
    const rowIndex = ref(null);
    onMounted(() => {
      rowIndex.value = Array.from(FormContainerInst.value.$el.parentElement.children).findIndex(row => row === FormContainerInst.value.$el);
    });

    // expandedMap (FormGrid), groupIndex (FormGridGroup)
    const
      expandedMap = inject('expandedMap'),
      groupIndex = inject('groupIndex');

    // Is parent group expanded?
    const isGroupExpanded = computed(() => groupIndex.value === null ? false : expandedMap[groupIndex.value]);

    // Actions
    const expandGroup = () => expandedMap[groupIndex.value] = !expandedMap[groupIndex.value];

    // Show details link
    const
      overflowMap = inject('overflowMap'),
      showDetailsLink = computed(() => {
        if (!props.dotsDetailsLink)
          return false;
        return rowIndex.value === 0 && overflowMap[groupIndex.value];
      });

    return {
      FormContainerInst,
      isNested,
      expandGroup,
      isGroupExpanded,
      showDetailsLink,
      dropdownMenuIsEmpty
    };
  }
};
</script>

<style lang="scss">
  .form-grid__row {
    display: grid;
    background-color: white;

    .form-control[disabled] {
      background-color: white;
    }

    // Last row padding
    &:not(.nested):not(:only-child):last-child {
      padding-bottom: rem(4px);
    }

    // Dots dropdown
    &.nested {
      position: relative;
    }
    &:not(.nested):not(:first-child) .dots {
      top: rem($inputHeight + 6px);
    }
    .dropdown.dots {
      position: absolute;
      top: rem(6px);
      right: 0;
      width: rem($dotsTriggerDimension);
      height: rem($dotsTriggerDimension);
      > .dropdown-trigger {
        @include center-content;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        &:hover {
          background-color: #0000001A;
        }
        img {
          display: block;
        }
      }
      .dropdown-menu .dropdown-item {
        display: flex;
        align-items: center;
      }
    }

  }
</style>
