import { z } from 'zod';
import { Quote, QuoteSchema } from './Quote';
import { QuoteGroupIntakeQuote, QuoteGroupIntakeQuoteSchema } from './QuoteGroupIntakeQuote';
import { CoverageTypeSchema } from './CoverageType';
import { IntakeCASchema } from './IntakeCA';
import { IntakeCPSchema } from './IntakeCP';
import { IntakeHOSchema } from './IntakeHO';
import { IntakeWCSchema } from './IntakeWC';
import { IntakeCGLSchema } from './IntakeCGL';
import { IntakeGenericSchema } from './IntakeGeneric';

const IntakeBodySchema = z.union([
  IntakeCASchema,
  IntakeCPSchema,
  IntakeHOSchema,
  IntakeWCSchema,
  IntakeCGLSchema,
  IntakeGenericSchema,
]);

const BaseQuoteGroupIntakeSchema = z.object({
  id: z.number(),
  quote_group_id: z.number(),
  intake_body_type: z.string(),
  intake_body_id: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  intake_body: IntakeBodySchema.nullish(),
  coverage_type: CoverageTypeSchema,

  // Appends
  statusObj: z.any(),

  // This is a pivot table for quotes
  quote_coverage: QuoteGroupIntakeQuoteSchema.pick({
    description: true,
    detail: true,
    amount: true,
  }).optional(),
});

export type QuoteGroupIntake = z.infer<typeof BaseQuoteGroupIntakeSchema> & {
  quotes?: Quote[];
  quote_coverage?: Pick<QuoteGroupIntakeQuote, 'description' | 'detail' | 'amount'>; // This is pivot table for quotes
};

export const QuoteGroupIntakeSchema = BaseQuoteGroupIntakeSchema.extend({
  quotes: z.lazy(() => z.array(QuoteSchema).optional()),
});
