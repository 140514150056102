import { useStore } from 'vuex';
import { computed, ref } from 'vue';

const fetchingActionsMap = {},
  fetchingActionsToPromiseMap = {};

export default (moduleAction, propertyOrProperties, actionPayload = {}, options = {}, store = null) => {
  store = store ? store : useStore();

  if (typeof moduleAction !== 'string') throw 'Argument 1 must be a string.';

  const result = {
    error: ref(false),
    isValidating: ref(false),
    promise: fetchingActionsToPromiseMap[moduleAction],
  };

  // Determine type
  const type = typeof propertyOrProperties;
  if (type !== 'string' && (type !== 'object' || !Array.isArray(propertyOrProperties)))
    throw 'Argument 2 must either be string or array.';

  // Attach computed values to be returned immediately (stale data)
  let module, property;
  if (type === 'string') {
    [module, property] = propertyOrProperties.split('/');

    if (options.fresh) store.state[module][property] = null;

    result[property] = computed(() => store.state[module][property]);
  } else
    propertyOrProperties.forEach((property) => {
      [module, property] = property.split('/');

      if (options.fresh) store.state[module][property] = null;

      result[property] = computed(() => store.state[module][property]);
    });

  if (!options.fresh && fetchingActionsMap[moduleAction]) {
    return {
      ...result,
      promise: fetchingActionsToPromiseMap[moduleAction],
    };
  }

  // If options.stale is true and stale exists
  if (options.stale && fetchingActionsToPromiseMap[moduleAction]) return result;

  // Call action
  fetchingActionsMap[moduleAction] = true;
  result.isValidating.value = true;
  result.promise = store
    .dispatch(moduleAction, actionPayload)
    .then(() => {
      fetchingActionsMap[moduleAction] = false;
    })
    .catch((error) => {
      if (import.meta.env.VITE_APP_ENV === 'local') console.log(error);
      result.error.value = error;
    });

  fetchingActionsToPromiseMap[moduleAction] = result.promise;

  // Return
  return result;
};
