import axios from 'axios';
import { z } from 'zod';
import { CoverageTypeSchema } from './CoverageType';
import * as Sentry from '@sentry/vue';

const CPPropertyTypeSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  intakes_cp_id: z.number().nullable(),
  Property_Type: z.string().nullable(),
  Other_Type: z.string().nullable(),
  Value: z.string().nullable(),
  Deductible: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});

export const IntakeCPSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  policy_id: z.number().nullable(),
  quote_group_id: z.number().nullable(),
  is_complete: z.boolean(),
  SQFT: z.string().nullable(),
  Construction_Type: z.string().nullable(),
  Occupancy: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullable(),
  coverage_type: CoverageTypeSchema,
  cp_property_types: z.array(CPPropertyTypeSchema).nullish(),
});

export type IntakeCP = z.infer<typeof IntakeCPSchema>;

async function find({ clientId, id }: { clientId: number; id: number }) {
  const response = await axios.get(`/api/clients/${clientId}/intakes/cp/${id}`).catch((error) => {
    Sentry.captureException(error);
  });

  if (!response) return undefined;

  return z
    .object({
      data: IntakeCPSchema,
    })
    .parseAsync(response.data);
}

export default {
  find,
};
