<template>
  <div class="dashboard__header">

    <!-- h3 -->
    <h3>
      Dashboard
    </h3>

    <!-- Goals -->
    <DashboardGoals />

    <!-- Exists only for alignment purposes -->
    <h3 style="opacity: 0">Dashboard</h3>

  </div>
</template>

<script>
import DashboardGoals from './DashboardGoals.vue';

export default {
  components: {
    DashboardGoals
  },
};
</script>

<style lang="scss">
.dashboard__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  h3 {
    font-size: rem(26px);
  }
}
</style>
