import { z } from 'zod';
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';
import { queryBuilderToQueryString } from '@/js/Helpers';
import { User, UserSchema } from './User';
import { ExternalProducer, ExternalProducerSchema } from './ExternalProducer';
import { LeadDistributionList, LeadDistributionListSchema } from './LeadDistributionList';

export type Producer = User | ExternalProducer | LeadDistributionList;

export const ProducerSchema: z.ZodType<Producer> = z.lazy(() =>
  z.union([UserSchema, ExternalProducerSchema, LeadDistributionListSchema]),
);

// ***************************************************
// Index
// ***************************************************

type IndexArgs = {
  queryBuilder: {
    page: number;
    filters?: {
      name?: string;
      client_id?: number | null;
      include_inactive_users?: boolean;
      include_non_producer_users?: boolean;
    };
  };
};

async function index({ queryBuilder }: IndexArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/producers?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  // FIXME:
  return response;
}

// ***************************************************
// All
// ***************************************************

type AllArgs = {
  queryBuilder: {
    filters?: {
      id?: string[];
    };
  };
};

async function all({ queryBuilder }: AllArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/producers/all?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  // FIXME:
  return response.data;
}

// ***************************************************
// Find
// ***************************************************

type FindArgs = {
  id: Producer['id'];
  type: Producer['model_type'];
};

async function find({ id, type }: FindArgs) {
  const response = await axios.get(`/api/producers/${id}-${type}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: ProducerSchema,
    })
    .parseAsync(response.data);
}

export default {
  index,
  all,
  find,
};
