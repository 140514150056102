<template>
  <SlidePanel :is-active="true">
    <!-- Header -->
    <template #header-left>
      <div class="slide-panel__header-left">
        <i class="fas fa-info-circle slide-panel__header-left-icon"></i>
        <h5 class="slide-panel__header-left-text">Policy details</h5>
      </div>
    </template>

    <!-- Tabs -->
    <IntakeTabs
      v-if="client"
      v-model:intakes="policy.intakes"
      :can-edit="canEdit"
      :client-id="policy.client_id"
      :parent-id="policy.id"
      :parent-type="'Policy'"
      @update:active-tab="(tab) => (activeTab = tab)"
    />

    <!-- Tab body -->
    <IntakeTabBody v-if="activeTab" :active-tab="activeTab" :client="client" :disabled="!canEdit" />
  </SlidePanel>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Client } from '@/js/Api/Client';
import { Policy } from '@/js/Api/Policy';
import Btn from '@/js/Components/Btn.vue';
import SlidePanel from '@/js/Components/SlidePanel.vue';
import IntakeTabs, { Tab } from '@/js/Components/Intakes/Tabs.vue';
import IntakeTabBody from '@/js/Components/Intakes/TabBody.vue';

interface Props {
  canEdit: boolean;
  client: Client;
  policy: Policy;
}

defineProps<Props>();

const activeTab = ref<Tab | null>(null);
</script>
