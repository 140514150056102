<template>
  <div class="policy-status">
    <!-- Renewal status -->
    <RenewalStatusInput
      v-model:renews-policies="policy.renews_policies"
      v-model:renewal-status-as-parent="policy.renewal_status_as_parent"
      :policy="policy"
    />

    <!-- Cancellation -->
    <div v-if="policy.cancellation && !policy.cancellation.reinstatement" class="cancellation-box">
      <div>
        <p class="cancellation-box__main-text">Cancellation date: {{ toDateString(policy.cancellation.date) }}</p>
        <div class="cancellation-box__reasons">
          <p v-for="reason in policy.cancellation.reasons.split(',')" class="cancellation-box__reason-text">
            {{ reason }}:
            <template v-if="policy.cancellation.amount">{{ formatCents(policy.cancellation.amount) }}</template>
            <template v-else-if="reason === 'Carrier/underwriting'">{{
              policy.cancellation.carrier_underwrighting_reason
            }}</template>
          </p>
        </div>
      </div>
      <div v-if="me.permissions['policy_cancellations.update']" class="cancellation-box__icon-container">
        <SvgIcon name="3-dots" class="cancellation-box__icon" @click="ReinstatementModalRef.show()" />
      </div>
    </div>

    <div>
      <!-- Status -->
      <Dropdown class="status-box">
        <template #trigger>
          <div class="status-box__status">
            <StatusCircle :status="status" />
            {{ isTransferred ? 'Transferred' : status }}
          </div>
          <SvgIcon name="caret-down" />
        </template>
        <template #menu>
          <!-- Insert cancellation -->
          <DropdownItem
            v-if="!policy.cancellation || policy.cancellation.reinstatement !== null"
            :disabled="!me.permissions['policy_cancellations.create']"
            @click="CreateCancellationModalRef.show()"
          >
            <CreateCancellationModal
              ref="CreateCancellationModalRef"
              :policy-id="policy.id"
              :client-id="policy.client_id"
              @created="
                (cancellation: PolicyCancellation) => {
                  policy.cancellation = cancellation;
                  policy.cancellations.push(cancellation);
                  CreateCancellationModalRef.hide();
                }
              "
            />
            <SvgIcon name="times-circle" />
            Insert Cancelation
          </DropdownItem>

          <!-- Edit cancellation -->
          <DropdownItem
            v-else-if="policy.cancellation && policy.cancellation.reinstatement === null"
            :disabled="!me.permissions['policy_cancellations.update']"
            @click="EditCancellationModalRef.show()"
          >
            <EditCancellationModal
              ref="EditCancellationModalRef"
              :cancellation="policy.cancellation"
              @updated="
                (cancellation: PolicyCancellation) => {
                  policy.cancellation = cancellation;
                  policy.cancellations[policy.cancellations.length - 1] = cancellation;
                }
              "
            />
            <SvgIcon name="edit-note" />
            Edit Cancellation
          </DropdownItem>

          <!-- Insert reinstatement -->
          <DropdownItem
            v-if="policy.cancellation && policy.cancellation.reinstatement === null"
            :disabled="!me.permissions['policy_cancellations.update']"
            @click="ReinstatementModalRef.show()"
          >
            <ReinstatementModal
              ref="ReinstatementModalRef"
              :cancellation="policy.cancellation"
              @update:cancellation="
                (cancellation: PolicyCancellation) => {
                  policy.cancellation = null;
                  policy.cancellations[policy.cancellations.length - 1] = cancellation;
                }
              "
            />
            <SvgIcon name="rotate-right" />
            Insert Reinstatement
          </DropdownItem>

          <!-- Verify status -->
          <DropdownItem :disabled="!canEdit" @click="verifyStatus">
            <SvgIcon name="check" />
            Verify Status
          </DropdownItem>

          <!-- Status history -->
          <DropdownItem @click="StatusHistoryModalRef.show()">
            <StatusHistoryModal ref="StatusHistoryModalRef" :policy="policy" />
            <SvgIcon name="clock-arrow" />
            View Status History
          </DropdownItem>

          <!-- Request to mark as Non renewed -->
          <DropdownItem
            v-if="!policy.non_renewed && !policy.non_renewed_requested"
            :disabled="!me.permissions['policies.non_renewed.request']"
            @click="requestNonRenewed"
          >
            <SvgIcon name="file-remove" />
            Request to mark as<br />Non renewed / final cancel
          </DropdownItem>

          <!-- Approve Non renewed -->
          <DropdownItem
            v-if="policy.non_renewed_requested"
            :disabled="!me.permissions['policies.non_renewed.approve']"
            @click="approveNonRenewed"
          >
            <SvgIcon name="file-check" />
            Approve Non renewed / final cancel
          </DropdownItem>

          <!-- Disapprove Non renewed -->
          <DropdownItem
            v-if="policy.non_renewed_requested"
            :disabled="!me.permissions['policies.non_renewed.approve']"
            @click="disapproveNonRenewed"
          >
            <SvgIcon name="file-remove" />
            Disapprove Non renewed / final cancel
          </DropdownItem>

          <!-- Unmark Non renewed -->
          <DropdownItem
            v-if="policy.non_renewed"
            :disabled="!me.permissions['policies.non_renewed.approve']"
            @click="unmarkNonRenewed"
          >
            <SvgIcon name="file-plus" />
            Unmark as Non renewed / final cancel
          </DropdownItem>
        </template>
      </Dropdown>

      <!-- Last verified on -->
      <p class="details details--last-verified">
        Last verified on {{ new Date(policy.last_verified_on).toDateTime() }}
      </p>

      <!-- Non renewed requested -->
      <p v-if="policy.non_renewed_requested" class="details details--non-renewed-requsted">
        Requested to mark this policy Non renewed / Final cancel<br />
        {{ policy.non_renewed_requested_by_user.name }},
        {{ toDateString(policy.non_renewed_requested_on) }}
      </p>

      <!-- Non renewed -->
      <p v-if="policy.non_renewed" class="details details--non-renewed">
        Non renewed / Final cancel<br />
        <template v-if="policy.non_renewed_approved_by_user">
          {{ policy.non_renewed_approved_by_user.name }},
        </template>
        {{ toDateString(policy.non_renewed_approved_on) }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, inject, computed } from 'vue';
import { me } from '@/js/Helpers/Session';
import { formatCents, toDateString } from '@/js/Helpers';
import { confirm, toastSuccess, toastError } from '@/js/Helpers/Alert';
import PolicyApi, { Policy } from '@/js/Api/Policy';
import { PolicyCancellation } from '@/js/Api/PolicyCancellation';
import Loader from '@/js/Helpers/Loader';
import Dropdown from '@/js/Common/Dropdown.vue';
import DropdownItem from '@/js/Common/DropdownItem.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import StatusCircle from '@/js/Components/Policies/StatusCircle.vue';
import RenewalStatusInput from '@/js/Components/Policies/RenewalStatus.vue';
import StatusHistoryModal from '@/js/Components/Policies/StatusHistoryModal.vue';
import EditCancellationModal from '@/js/Components/PolicyCancellations/EditModal.vue';
import CreateCancellationModal from '@/js/Components/PolicyCancellations/CreateModal.vue';
import ReinstatementModal from '@/js/Components/PolicyCancellations/ReinstatementModal.vue';

interface Props {
  policy: Policy;
}

const props = defineProps<Props>();

const emit = defineEmits(['update:policy']);

const isTransferred = computed(() => {
  const p = props.policy;
  return (
    p.carrier_id &&
    p.effective_date &&
    p.expiration_date &&
    p.premium &&
    !p.renewal_status_as_parent &&
    (!p.cancellation || (p.cancellation && p.cancellation.reinstatement)) &&
    new Date(p.created_at) < new Date('2024-01-24')
  );
});

const status = computed(() => {
  const p = props.policy;
  const isNewPolicy = new Date(p.created_at) > new Date('2024-01-24');
  const isMissingRenewalStatus = !p.renewal_status_as_parent;
  const isMissingEssentialFields = !p.carrier_id || !p.effective_date || !p.expiration_date || !p.premium;

  if (isMissingEssentialFields || (isNewPolicy && isMissingRenewalStatus)) return 'Pending';

  if (p.cancellation && !p.cancellation.reinstatement) {
    if (new Date() < new Date(p.cancellation.date)) return 'Cancellation pending';
    if (new Date(p.cancellation.date) < new Date()) return 'Cancelled';
  }

  if (p.renewal_status_as_parent === 'Renewal (unpaid)') return 'Renewal (unpaid)';

  if (new Date(p.expiration_date) < new Date()) return 'Expired';
  return 'Active';
});

const canEdit = inject('canEditClient');

const EditCancellationModalRef = ref<InstanceType<typeof EditCancellationModal> | null>(null);
const CreateCancellationModalRef = ref<InstanceType<typeof CreateCancellationModal> | null>(null);
const ReinstatementModalRef = ref<InstanceType<typeof ReinstatementModal> | null>(null);
const StatusHistoryModalRef = ref<InstanceType<typeof StatusHistoryModal> | null>(null);

const requestNonRenewed = async () => {
  const result = await confirm({
    confirmButtonText: 'Confirm',
    text: 'Request to mark this policy as Non renewed / final cancel?',
  });
  if (!result.isConfirmed) return;

  Loader.start();
  PolicyApi.requestNonRenewed({
    id: props.policy.id,
    clientId: props.policy.client_id,
  })
    .then(({ data: policy }) => {
      emit('update:policy', policy);
      toastSuccess();
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      Loader.end();
    });
};

const approveNonRenewed = async () => {
  const result = await confirm({
    confirmButtonText: 'Confirm',
    text: 'Approve this policy as Non renewed / final cancel?',
  });
  if (!result.isConfirmed) return;

  Loader.start();
  PolicyApi.approveNonRenewed({
    id: props.policy.id,
    clientId: props.policy.client_id,
  })
    .then(({ data: policy }) => {
      emit('update:policy', policy);
      toastSuccess();
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      Loader.end();
    });
};

const disapproveNonRenewed = async () => {
  const result = await confirm({
    confirmButtonText: 'Confirm',
    text: 'Disapprove this policy as Non renewed / final cancel?',
  });
  if (!result.isConfirmed) return;

  Loader.start();
  PolicyApi.disapproveNonRenewed({
    id: props.policy.id,
    clientId: props.policy.client_id,
  })
    .then(({ data: policy }) => {
      emit('update:policy', policy);
      toastSuccess();
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      Loader.end();
    });
};

const unmarkNonRenewed = async () => {
  const result = await confirm({
    confirmButtonText: 'Confirm',
    text: 'Unmark this policy as Non renewed / final cancel?',
  });
  if (!result.isConfirmed) return;

  Loader.start();
  PolicyApi.unmarkNonRenewed({
    id: props.policy.id,
    clientId: props.policy.client_id,
  })
    .then(({ data: policy }) => {
      emit('update:policy', policy);
      toastSuccess();
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      Loader.end();
    });
};

const verifyStatus = () => {
  Loader.start();
  PolicyApi.verifyStatus({ id: props.policy.id, clientId: props.policy.client_id })
    .then(({ data: policy }) => {
      props.policy.last_verified_on = policy.last_verified_on;
      toastSuccess('Status verified');
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      Loader.end();
    });
};
</script>

<style lang="scss">
.policy-status {
  display: flex;
  align-items: flex-start;
  gap: 0.75em;

  // Renewal status
  .renewal-status {
    .dropdown__trigger {
      label {
        font-size: rem(14px);
      }
      p {
        line-height: rem(19px);
      }
    }
  }

  // Cancellation box
  .cancellation-box {
    background-color: white;
    border-radius: rem(6px);
    padding: rem(5.5px) rem(12px);
    display: flex;
    align-items: center;
    &__main-text {
      margin: 0;
      color: #fa3c03;
      font-size: rem(14px);
    }
    &__reason-text {
      margin: 0;
      font-size: rem(16px);
      line-height: rem(19px);
    }
    &__icon {
      cursor: pointer;
      transform: rotate(90deg);
    }
    &__icon-container {
      border-radius: rem(5px);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e7e7ea;
      margin-left: rem(60px);
    }
  }

  // Status box
  .status-box {
    border: rem(1px) solid #cccccc;
    padding: rem(11px) rem(13px);
    border-radius: rem(6px);
    .dropdown-trigger {
      color: #000000;
      justify-content: space-between;
    }
    &__status {
      display: flex;
      align-items: center;
      margin-right: 1em;
      .policy-status-circle {
        margin-right: rem(13px);
      }
    }
    .dropdown-item {
      color: #6e6e6e;
      .icon {
        color: #6e6e6e;
        margin-right: rem(13px);
      }
    }
  }

  .details {
    margin-top: 0.5em;
    margin-bottom: 0;
    color: #999999;
    font-size: rem(12px);
    line-height: rem(14px);
    display: flex;
    &--last-verified {
      color: #999999;
    }
    &--non-renewed {
      color: $error;
    }
  }
}
</style>
