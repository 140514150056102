import axios from 'axios';
import { me } from '@/js/Helpers/Session';
import { useStorage } from '@vueuse/core';

const filters = useStorage('dashboard.filters', {
  days: 0,
  assignee_id: undefined,
  assignee_role: undefined,
});

const dailyActivityPanelSettings = useStorage('dashboard.dailyActivityPanelSettings', {
  active: true,
  sortOrder: 'desc',
  sortedBy: 'cleared',
});

const followupsPanelSettings = useStorage('dashboard.followupsPanelSettings', {
  active: true,
  sortOrder: 'desc',
  sortedBy: 'weight',
  view: 'regular',
});

export default {
  namespaced: true,

  state: () => ({
    // Global filters
    filters: filters.value,

    // Daily activity panel
    dailyActivityPanelCount: undefined,
    dailyActivityPanelSettings: dailyActivityPanelSettings.value,

    // Followups panel
    followupsPanelCount: undefined,
    followupsPanelSettings: followupsPanelSettings.value,
  }),

  actions: {
    // Metrics
    fetchMetrics({ commit }) {
      return axios.get('/api/dashboard/metrics').then((response) => {
        commit('setMetrics', response.data.metrics);
      });
    },
  },

  mutations: {
    setSetting(state, { panel, property, value }) {
      state[`${panel}PanelSettings`][property] = value;
    },

    // Daily activity
    setDailyActivityTable(state, response) {
      state.dailyActivityTable = response.data;
      state.dailyActivityPanelCount = response.total;
    },
    dailyActivityTableClearClient(state, { clientID, cleared }) {
      const row = state.dailyActivityTable.find((r) => r.client_id === clientID);
      row.cleared = cleared;
    },

    // View
    setFollowupsView(state, view) {
      state.followupsPanelSettings.view = view;
    },

    // Metrics
    setMetrics(state, metrics) {
      state.metrics = metrics;
    },

    updateFilter(state, { property, value }) {
      state.filters[property] = value;
    },

    // Update followup (in all views)
    updateFollowup(state, note) {
      let arrays = ['regularViewFollowups', 'watchViewFollowups'];
      arrays.forEach((arrayName) => {
        if (state[arrayName]) {
          const noteInState = state[arrayName].find((f) => f.id === note.id);
          if (noteInState) {
            if (noteInState.due !== note.due) noteInState.rescheduled = true;
            Object.assign(noteInState, note);
          }
        }
      });

      arrays = ['teamViewUsers', 'teamwatchViewUsers'];
      arrays.forEach((arrayName) => {
        if (state[arrayName]) {
          state[arrayName].forEach((user) => {
            const noteInState = user.followups.find((f) => f.id === note.id);
            if (noteInState) {
              if (noteInState.due !== note.due) noteInState.rescheduled = true;
              Object.assign(noteInState, note);
            }
          });
        }
      });
    },

    // Delete followup
    deleteFollowup(state, note) {
      let arrays = ['regularViewFollowups', 'watchViewFollowups'];
      arrays.forEach((arrayName) => {
        if (state[arrayName]) {
          const noteInState = state[arrayName].find((f) => f.id === note.id);
          if (noteInState) noteInState.deleted = true;
        }
      });

      arrays = ['teamViewUsers', 'teamwatchViewUsers'];
      arrays.forEach((arrayName) => {
        if (state[arrayName]) {
          state[arrayName].forEach((user) => {
            const noteInState = user.followups.find((f) => f.id === note.id);
            if (noteInState) noteInState.deleted = true;
          });
        }
      });
    },

    // Regular view
    setRegularViewFollowups(state, response) {
      state.followupsPanelCount = response.total;
      state.regularViewFollowups = response.data;
    },

    // Watch view
    setWatchViewFollowups(state, response) {
      state.followupsPanelCount = response.total;
      state.watchViewFollowups = response.data;
    },

    // Team view
    setTeamViewUsers(state, response) {
      state.teamViewUsers = response.data;
    },

    // Team watch view
    setTeamWatchViewUsers(state, response) {
      state.teamwatchViewUsers = response.data;
    },

    // Clear closed followup
    clearClosedFollowup(state, note) {
      if (state.watchViewFollowups) {
        const index = state.watchViewFollowups.findIndex((f) => f.id === note.id);
        if (index !== -1) state.watchViewFollowups.splice(index, 1);
      }

      if (state.teamwatchViewUsers) {
        state.teamwatchViewUsers.forEach((user) => {
          const index = user.followups.findIndex((f) => f.id === note.id);
          if (index !== -1) user.followups.splice(index, 1);
        });
      }
    },
  },
};
