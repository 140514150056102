<template v-if="tree">
  <div class="tree-explorer">
    <!-- Tree -->
    <TreeExplorerNode
      v-for="node in tree"
      :key="node.name"
      :node="node"
      :is-active="node === activeNode"
      @click:node="(e) => emit('click:node', e)"
    >
      <template v-if="!nonExpandedFolders.includes(node.name)">
        <TreeExplorerNode
          v-for="subnode in node.tree"
          :key="subnode.name"
          :node="subnode"
          :nested="true"
          :is-active="subnode === activeNode"
          @click:node="(e) => emit('click:node', e)"
        />
      </template>
    </TreeExplorerNode>
  </div>
</template>

<script lang="ts" setup>
import type { ClientTree, ClientTreeNode } from '@/js/Api/Storage';
import TreeExplorerNode from './TreeExplorerNode.vue';

const props = defineProps<{
  clientId: number;
  tree: ClientTree | null;
  clientName: string | null;
  activeNode: ClientTreeNode | null;
}>();

const emit = defineEmits(['click:node']);

const nonExpandedFolders = [props.clientName, 'Client documents', 'Note attachments'];
</script>

<style lang="scss">
.tree-explorer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: auto;
  max-height: 100%;

  &__node {
    display: inline-flex;
    align-items: center;
    color: color('gray', 9);
    padding: rem(3px) rem(10px);
    margin-bottom: rem(20px);
    border-radius: rem(15px);
    transition: 0.1s background-color;
    cursor: pointer;
    font-size: rem(15px);
    &:hover {
      background-color: #dfe2e6;
      color: black;
    }
    .svg-icon {
      min-width: rem(30px);
      min-height: rem(30px);
      color: #757575;
      margin-right: rem(7px);
    }
  }
}
</style>
