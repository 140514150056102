<template>
  <!-- Table list -->
  <TableList
    :columns="[
      {
        name: 'Priority',
        value: 'priority',
        slot: true,
        slotName: 'priority',
      },
      {
        name: 'Points',
        value: 'weight',
      },
      {
        name: 'Call.',
        value: 'callback',
        slot: true,
        slotName: 'callback',
      },
      {
        name: 'Due Date',
        value: 'due',
        get: (item) => getDashboardDue(item.due),
        tdClass: (item) => `table-list__td--${getDashboardDateType(item.due)}`,
      },
      {
        name: 'Client',
        value: 'client.name',
        get: getClientName,
      },
      {
        name: 'Note',
        get: (item) => item.content,
      },
      {
        name: 'Created By',
        value: 'followup_created_by.name',
      },
      {
        name: 'Assigned To',
        value: 'assigned_to.name',
      },
      {
        slot: true,
        slotName: 'watch',
      },
    ]"
    :api-filters="filters"
    :api-endpoint="'/dashboard/data/regular'"
    :api-vuex-property="'dashboard/regularViewFollowups'"
    :api-vuex-mutation="'dashboard/setRegularViewFollowups'"
    :api-sorted-by="$store.state.dashboard.followupsPanelSettings.sortedBy"
    :api-sort-order="$store.state.dashboard.followupsPanelSettings.sortOrder"
    :tr-class="(item) => ({ 'table-list__tr--updated': isUpdated(item), 'table-list__tr--deleted': item.deleted })"
    grid-template-columns="1.25fr 1.5fr 1fr 2.5fr 7fr 10fr 3fr 3fr 1fr"
    @item:click="onItemClick"
    @sort="(...args) => $emit('sort', ...args)"
  >
    <!-- No items -->
    <template #noitems> No followups yet </template>

    <!-- Priority -->
    <template #priority="{ item }">
      <SvgIcon :name="`followup-${item.priority_readable.toLowerCase()}`" />
    </template>

    <!-- Callback -->
    <template #callback="{ item }">
      <SvgIcon v-if="item.callback" name="phone-dial" color="#90D65C" />
    </template>

    <!-- Watch -->
    <template #watch="{ item }">
      <img
        class="icon item__watch-icon"
        :src="`/img/icons/watch_${item.is_watching ? 'off' : 'on'}-24px.svg`"
        @click.stop="$emit('watch:followup', item)"
      />
    </template>
  </TableList>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import TableList from '@/js/Components/TableList.vue';
import { getDashboardDue, getDashboardDateType } from '@/js/Helpers/Dashboard';

export default {
  components: {
    SvgIcon,
    TableList,
  },

  inheritAttrs: false,

  emits: ['sort', 'click:followup', 'watch:followup'],

  setup(props, { emit }) {
    const store = useStore();

    const filters = computed(() => store.state.dashboard.filters);

    const today = new Date().toDateInput();

    const getClientName = (followup) => {
      if (followup.client && followup.client.name) return followup.client.name;
      if (followup.client && !followup.client.name) return '(Draft)';
      return '-';
    };

    const isUpdated = (item) => item.status === 'Resolved' || item.rescheduled;

    const onItemClick = (item) => {
      if (!item.deleted) emit('click:followup', item);
    };

    return {
      today,
      filters,
      getClientName,
      isUpdated,
      onItemClick,

      getDashboardDue,
      getDashboardDateType,
    };
  },
};
</script>
