<template>
  <div
    ref="tooltip"
    role="tooltip"
    :class="{
      tooltip: true,
      'tooltip--shown': isShown,
      [`tooltip--placement-${placement}`]: true,
    }"
  >
    <slot />
    <div class="tooltip__arrow" data-popper-arrow></div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import { onClickOutside } from '@vueuse/core';
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';

export default {
  props: {
    placement: {
      type: String,
      default: 'top',
    },
    strategy: {
      type: String,
      default: 'fixed',
    },
    offset: {
      type: Array,
      default: () => [0, 8],
    },
    targetEl: {
      type: Object,
      default: null,
    },
    showWhen: {
      type: [Number, Boolean],
      default: false,
    },
    showOn: {
      type: String,
      default: null,
      validator: (v) => ['hover', 'click'].includes(v),
    },
  },

  emits: ['show', 'shown', 'hide', 'hidden', 'toggle', 'toggled'],

  setup(props, { emit }) {
    const tooltip = ref(null);

    const isShown = ref(false);

    let popperInstance = null;

    // Actions
    const hide = () => {
      if (!isShown.value) return;
      emit('hide');
      popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [...options.modifiers, { name: 'eventListeners', enabled: false }],
      }));
      isShown.value = false;
      emit('hidden');
    };
    const show = () => {
      if (isShown.value) return;
      emit('show');
      popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [...options.modifiers, { name: 'eventListeners', enabled: true }],
      }));
      popperInstance.update();
      isShown.value = true;
      emit('shown');
    };
    const toggle = () => {
      if (isShown.value) hide();
      else show();
    };

    let targetEl = null;

    onMounted(() => {
      // Get target el
      targetEl = props.targetEl ?? tooltip.value.parentElement;

      // Hover
      targetEl.removeEventListener('mouseenter', show);
      targetEl.removeEventListener('mouseleave', hide);
      if (props.showOn === 'hover') {
        targetEl.addEventListener('mouseenter', show);
        targetEl.addEventListener('mouseleave', hide);
      }

      // Click
      targetEl.removeEventListener('click', toggle);
      if (props.showOn === 'click') targetEl.addEventListener('click', toggle);

      // Create popper
      popperInstance = createPopper(targetEl, tooltip.value, {
        strategy: props.strategy,
        placement: props.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: props.offset,
            },
          },
          { name: 'eventListeners', enabled: false },
        ],
      });

      // Hide tooltip on outside click
      onClickOutside(targetEl, () => {
        hide();
      });
    });

    // Watch for "show" trigger that parent controls
    watch(
      () => props.showWhen,
      () => {
        show();
      }
    );

    // Cleanup
    onBeforeUnmount(() => {
      popperInstance?.destroy();
      targetEl?.removeEventListener('click', toggle);
      targetEl?.removeEventListener('mouseleave', hide);
      targetEl?.removeEventListener('mouseleave', hide);
    });

    return {
      hide,
      show,
      isShown,
      tooltip,
    };
  },
};
</script>

<style lang="scss">
.tooltip {
  cursor: default;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s, opacity 0.15s linear;
  padding: rem(2.5px) rem(10px);
  text-align: center;
  z-index: 100;
  background-color: color('gray', 9);
  color: white;
  font-size: rem(14px);
  border-radius: rem(5px);

  // Arrow
  $arrow-dim: 8px;

  &__arrow,
  &__arrow::before {
    position: absolute;
    width: $arrow-dim;
    height: $arrow-dim;
    background: inherit;
  }

  &__arrow {
    visibility: hidden;
  }

  &__arrow::before {
    content: '';
    opacity: 0;
    visibility: hidden;
    transform: rotate(45deg);
  }

  &[data-popper-placement^='top'] > &__arrow {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > &__arrow {
    top: -4px;
  }

  &[data-popper-placement^='left'] > &__arrow {
    right: -4px;
  }

  &[data-popper-placement^='right'] > &__arrow {
    left: -4px;
  }

  // Show tooltip
  &--shown {
    opacity: 1;
    visibility: visible;

    .tooltip__arrow::before {
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>
