<template>
  <FormGrid class="simple" :header="header" :details-link="false" :add-button="false">
    <template #header>
      <slot name="header"></slot>
    </template>
    <FormGridGroup :with-numbers="false">
      <FormGridRow
        :validate="validate === ''"
        :dots="false"
        :style="{ 'grid-template-columns': gridTemplateColumns }"
        :autosave-url="autosaveUrl"
      >
        <slot />
      </FormGridRow>
    </FormGridGroup>
  </FormGrid>
</template>

<script>
import { computed } from 'vue';
import FormGrid from '@/js/Common/Form/FormGrid/FormGrid.vue';
import FormGridGroup from '@/js/Common/Form/FormGrid/FormGridGroup.vue';
import FormGridRow from '@/js/Common/Form/FormGrid/FormGridRow.vue';

export default {
  components: {
    FormGrid,
    FormGridGroup,
    FormGridRow,
  },

  props: {
    gridTemplateColumns: {
      type: String,
      required: true,
    },
    autosaveUrl: {
      type: String,
    },
    validate: {
      type: String,
    },
  },

  setup(props, { slots }) {
    const header = computed(() => !!slots.header);

    return {
      header,
    };
  },
};
</script>

<style lang="scss">
.form-grid-simple--invalid {
  border: rem(1px) solid $red;
}

.form-grid.simple {
  .form-grid__group {
    // No need for dots menu padding
    padding-right: 0;

    // Fix for form-grid__group's "border" (::after) not extending all the way
    &:not(.nested):last-child::after {
      width: 100%;
    }

    // First field separator fix
    .form-grid__row > :nth-child(1) {
      &::before {
        display: none;
      }
    }
  }
}
</style>
