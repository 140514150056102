import { z } from 'zod';
import * as Sentry from '@sentry/vue';
import axios, { AxiosError } from 'axios';
import { ContactSchema } from './Contact';
import { ProducerLicense, ProducerLicenseSchema } from './ProducerLicense';
import { ExternalProducerDocumentSchema } from './ExternalProducerDocument';

const BaseExternalProducerSchema = z.object({
  id: z.number(),
  name: z.string().nullable(),
  note: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),

  // Appends
  model_type: z.literal('External producer'),

  // Relations
  contacts: ContactSchema.array().optional(),
  documents: ExternalProducerDocumentSchema.array().optional(),
});

export type ExternalProducer = z.infer<typeof BaseExternalProducerSchema> & {
  licenses?: ProducerLicense[];
};

export const ExternalProducerSchema: z.ZodType<ExternalProducer> = BaseExternalProducerSchema.extend({
  licenses: z.lazy(() => ProducerLicenseSchema.array().optional()),
});

// ***************************************************
// Create
// ***************************************************

async function create() {
  const response = await axios.post('/api/external-producers').catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: ExternalProducerSchema }).parseAsync(response.data);
}

export default {
  create,
};
