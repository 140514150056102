<template>
  <ProducerInput :model-value="producer" :disabled="disabled" :on-select="onSelect" />
</template>

<script setup lang="ts">
import TicketNoteApi from '@/js/Api/TicketNote';
import ClientApi from '@/js/Api/Client';
import ProducerInput from '@/js/Components/Producers/Input.vue';
import { confirm, toast } from '@/js/Helpers/Alert';
import useClientStore from '@/js/store/pinia/useClientStore';

interface Props {
  clientId: number;
  disabled: boolean;
}

const props = defineProps<Props>();

const store = useClientStore();

const { ref: producer } = store.swrProducer({ id: props.clientId });

const onSelect = (option) => {
  return ClientApi.producer
    .update({
      id: props.clientId,
      data: {
        id: option ? option.id : null,
        model_type: option ? option.model_type : null,
      },
    })
    .then(async (response) => {
      const newProducer = response.data.data;

      producer.value = newProducer;

      if (!producer.value) return;

      if (newProducer.model_type !== 'User') return;

      const promptResult = await confirm({
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        text: 'Assign "New client followup" to the newly assigned producer?',
      });

      const oldestTicket = [...store.tickets].sort((a, b) => (a.created_at > b.created_at ? 1 : -1))[0];
      const newClientFollowup = [...oldestTicket.notes].sort((a, b) => (a.created_at > b.created_at ? 1 : -1))[0];

      // FIXME
      if (!newClientFollowup) return;

      if (promptResult.isConfirmed)
        TicketNoteApi.autosave({ id: newClientFollowup.id, name: 'assignee', value: `${newProducer.id}-User` })
          .then(() => {
            newClientFollowup.assigned_to = newProducer;
            newClientFollowup.assigned_to_id = newProducer.id;
            store.updateNote(newClientFollowup, true);
          })
          .catch(() => {
            toast({
              icon: 'error',
              title: 'An error occurred while assigning producer to the followup.',
            });
          });
      else {
        const watcherIndex = newClientFollowup.watchers.findIndex((w) => w.id === newProducer.id);
        if (watcherIndex !== -1) return;

        const watcherIds = newClientFollowup.watchers.map((w) => w.id).concat(newProducer.id);

        TicketNoteApi.watch({ id: newClientFollowup.id, watcherIds })
          .then(({ data }) => {
            newClientFollowup.watchers = data;
            store.updateNote(newClientFollowup, true);
          })
          .catch(() => {
            toast({
              icon: 'error',
              title: 'An error occurred while assigning producer as a watcher to the followup.',
            });
          });
      }
    });
};
</script>
