<template>
  <div class="quote-group-edit">
    <!-- Progress bar -->
    <QuoteGroupProgressBar v-if="quoteGroup" :quote-group="quoteGroup" />

    <!-- Tabs -->
    <IntakeTabs
      v-if="quoteGroup"
      ref="IntakeTabsRef"
      v-model:intakes="quoteGroup.intakes"
      :can-edit="canEdit"
      :client-id="quoteGroup.client_id"
      :parent-id="quoteGroup.id"
      :parent-type="'QuoteGroup'"
      @update:active-tab="(tab) => (activeTab = tab)"
    />

    <!-- Mark intake as "Complete" button -->
    <QuoteGroupIntakeButtons
      v-if="activeTab && activeTab.intakeId"
      :can-edit="canEdit"
      :active-tab="activeTab"
      :quote-group="quoteGroup"
    />

    <!-- Quotes -->
    <Quotes
      v-if="quoteGroup"
      v-model:quote-group-statuses="quoteGroup.statuses"
      :can-edit="canEdit"
      :quote-group="quoteGroup"
    />

    <!-- Quote Suggestions -->
    <QuoteSuggestions :quote-group="quoteGroup" />

    <!-- Quote details (tab body) -->
    <SlidePanel class="slide-panel--quote-details" :is-active="true">
      <!-- Header -->
      <template #header-left>
        <div class="slide-panel__header-left">
          <img class="slide-panel__header-left-icon" src="/img/icons/info-gray.svg" />
          <h5 class="slide-panel__header-left-text">Quote details</h5>
        </div>
      </template>

      <FormGroup
        v-if="quoteGroup"
        v-model="quoteGroup.desired_effective_date"
        type="date"
        label-text="Desired effective date"
        name="desired_effective_date"
        :autosave-url="`/api/clients/${clientID}/quotegroups/${quoteGroup.id}/autosave`"
      />

      <!-- Tab body -->
      <IntakeTabBody v-if="activeTab" :active-tab="activeTab" :client="client" :disabled="!canEdit" />
    </SlidePanel>
  </div>
</template>

<script setup lang="ts">
import { Ref, provide, inject, ref } from 'vue';
import { Client } from '@/js/Api/Client';
import Api, { QuoteGroup } from '@/js/Api/QuoteGroup';

import SlidePanel from '@/js/Components/SlidePanel.vue';
import IntakeTabs, { Tab } from '@/js/Components/Intakes/Tabs.vue';
import IntakeTabBody from '@/js/Components/Intakes/TabBody.vue';

import FormGroup from '@/js/Common/Form/FormGroup/FormGroup.vue';
import Quotes from './Quotes.vue';
import QuoteSuggestions from './QuoteSuggestions/QuoteSuggestions.vue';
import QuoteGroupIntakeButtons from './QuoteGroupIntakeButtons.vue';
import QuoteGroupProgressBar from './QuoteGroupProgressBar.vue';

interface Props {
  client: Client;
  quoteGroupId: string;
}

const props = defineProps<Props>();

const clientID = inject('clientID') as Ref<number>;

const canEdit = inject('canEditClient') as Ref<boolean>;

const activeTab = ref<Tab | null>(null);
const IntakeTabsRef = ref<InstanceType<typeof IntakeTabs> | null>(null);

// Data
const quoteGroupID = Number(props.quoteGroupId);
const quoteGroup: Ref<null | QuoteGroup> = ref(null);
Api.find({ clientId: clientID.value, id: quoteGroupID }).then((data) => {
  if (data) quoteGroup.value = data.data;
});

provide('quoteGroupID', quoteGroupID);
</script>

<style lang="scss">
.quote-group-edit {
  .slide-panel--quote-details .slide-panel__body {
    display: flex;
    flex-direction: column;

    .form-group.desired_effective_date {
      margin-bottom: 1em;
      align-self: flex-end;
    }
  }
}
</style>

<style lang="scss" scoped>
// Progress bar
.progress-bar__quote__group {
  margin-bottom: rem(42px);
}

// Body
.quote-coverages-edit__tabs {
  padding-top: rem(38px);
  width: 97%;
  margin-left: 3%;

  .divider {
    grid-column: 1 / span 4;
    border-bottom: rem(1px) dashed color('gray', 3);
    margin: rem(20px) 0;
  }
}

@media only screen and (max-width: 1600px) {
  .intake__details {
    padding-left: 0 !important;
  }
}
</style>
