import { z } from 'zod';
import * as Sentry from '@sentry/vue';
import axios, { AxiosError } from 'axios';
import { queryBuilderToQueryString } from '@/js/Helpers';
import { ProducerLicenseSchema } from '@/js/Api/ProducerLicense';
import { Producer, ProducerSchema } from './Producer';

export const BaseUserSchema = z.object({
  id: z.number(),
  name: z.string(),
  initials: z.string(),
  email: z.string(),
  roles: z.any(),
  status: z.string(),
  timezone: z.string(),
  hourly_rate: z.number().nullish(),
  default_producer_type: z.string(),
  default_producer_id: z.number(),
  manager_signature: z.string().nullable(),
  can_be_quote_team_member: z.boolean(),
  default_mailbox_account_id: z.number().nullable(),
  created_at: z.string(),
  updated_at: z.string(),

  // Appends
  model_type: z.literal('User'),

  // Relations
  licenses: z.array(ProducerLicenseSchema).or(z.undefined()),
});

export type User = z.infer<typeof BaseUserSchema> & {
  default_producer?: Producer;
};

export const UserSchema: z.ZodType<User> = BaseUserSchema.extend({
  default_producer: z.lazy(() => ProducerSchema.optional()),
});

// ***************************************************
// Index
// ***************************************************

export type IndexArgs = {
  queryBuilder: {
    page?: number;
    sorts?: {
      order: 'desc' | 'asc';
      name: 'name' | 'created_at' | 'status' | 'email';
    }[];
    filters?: {
      search?: string | undefined;
      can_be_quote_team_member?: boolean;
    };
  };
};

async function index({ queryBuilder }: IndexArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/users?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return response;
}

// ***************************************************
// All
// ***************************************************

type AllArgs = {
  queryBuilder: {
    page?: number;
    sorts?: {
      order: 'desc' | 'asc';
      name: 'name' | 'created_at' | 'status' | 'email';
    }[];
    filters?: {
      id?: number | number[];
      search?: string | undefined;
      can_be_quote_team_member?: boolean;
    };
  };
};

async function all({ queryBuilder }: AllArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/users/all?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: UserSchema.array() }).parseAsync(response.data);
}

// ***************************************************
// Find
// ***************************************************

type FindArgs = {
  id: number;
};

async function find({ id }: FindArgs) {
  const response = await axios.get(`/api/users/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: UserSchema }).parseAsync(response.data);
}

// ***************************************************
// Create
// ***************************************************

type CreateArgs = {
  form: {
    name: string;
    email: string;
    password: string;
    roles: string[];
    default_producer: {
      id: number;
      model_type: string;
    };
  };
};

async function create({ form }: CreateArgs) {
  const response = await axios.post('/api/users', form).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: UserSchema }).parseAsync(response.data);
}

// ***************************************************
// Update
// ***************************************************

type UpdateArgs = {
  id: number;
  form: {
    name: string;
    password: string;
    main_role: string;
    simultaneous_roles: string[];
    default_producer: {
      id: number;
      model_type: string;
    };
    hourly_rate?: number;
    can_be_quote_team_member?: boolean;
    default_mailbox_account_id?: number | null;
  };
};

async function update({ id, form }: UpdateArgs) {
  const response = await axios.put(`/api/users/${id}`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: UserSchema }).parseAsync(response.data);
}

// ***************************************************
// Activate
// ***************************************************

type ActivateArgs = {
  id: number;
};

async function activate({ id }: ActivateArgs) {
  const response = await axios.put(`/api/users/${id}/activate`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: UserSchema }).parseAsync(response.data);
}

// ***************************************************
// Deactivate
// ***************************************************

type DeactivateArgs = {
  id: number;
};

async function deactivate({ id }: DeactivateArgs) {
  const response = await axios.put(`/api/users/${id}/deactivate`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.object({ data: UserSchema }).parseAsync(response.data);
}

// ***************************************************
// Move followups
// ***************************************************

async function moveFollowups({ fromId, toId }: { fromId: number; toId: number }) {
  const response = await axios
    .post(`/api/users/${fromId}/move-followups`, { to_id: toId })
    .catch((error: AxiosError) => {
      Sentry.captureException(error);
      throw error;
    });

  return z.literal('').parseAsync(response.data);
}

export default {
  index,
  all,
  find,
  create,
  update,
  activate,
  deactivate,
  moveFollowups,
  openedClients: {
    all() {
      return axios.get('/api/users/opened-clients');
    },

    find({ clientId }) {
      return axios.get(`/api/users/opened-clients/${clientId}`);
    },

    closeAll() {
      return axios.post('/api/users/opened-clients/close-all');
    },
  },
};
