import { z } from 'zod';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { queryBuilderToQueryString } from '@/js/Helpers';
import { IntakeChildStatusSchema } from '@/js/Api/IntakeChildStatus';
import { DriverAccidentSchema } from './DriverAccident';
import { DriverViolationSchema } from './DriverViolation';

export const DriverSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  dob: z.string().nullable(),
  license_number: z.string().nullable(),
  license_state: z.string().nullable(),
  license_type: z.string().nullable(),
  license_type_other: z.string().nullable(),
  license_cdla_years: z.string().nullable(),
  experience: z.string().nullable(),
  experience_description: z.string().nullable(),
  mvr_file_links: z.string().nullable(),
  mvr_last_checked: z.string().nullable(),
  accidents_violations_3y: z.boolean().nullable(),
  marital_status: z.enum(['SINGLE', 'MARRIED', 'DIVORCED', 'SEPARATED', 'WIDOWED']).nullable(),
  driver_status: z.enum(['RATED DRIVER', 'LISTED DRIVER', 'NON DRIVER']).nullable(),
  ddc_class: z.boolean().nullable(),
  ddc_date: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),

  // Relations
  latest_status: IntakeChildStatusSchema.nullish(),
  statuses: IntakeChildStatusSchema.array().or(z.undefined()),
  accidents: z.array(DriverAccidentSchema),
  violations: z.array(DriverViolationSchema),

  // Appends
  is_empty: z.boolean(),
  model_type: z.literal('Driver'),
});

export type Driver = z.infer<typeof DriverSchema>;

export const DriverStatusSchema = IntakeChildStatusSchema.extend({
  child_type: z.literal('driver'),
});

export type DriverStatus = z.infer<typeof DriverStatusSchema>;

// ***************************************************
// All
// ***************************************************

type QueryBuilder = {
  filters: {
    client_id: number;
  };
};

async function all(queryBuilder: QueryBuilder) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/drivers/all?${qs}`).catch((error) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: DriverSchema.array(),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Destroy
// ***************************************************

async function destroy({ id }: { id: number }) {
  const response = await axios.post(`/api/drivers/${id}`, { _method: 'DELETE' }).catch((error) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.literal('').parseAsync(response.data);
}

// ***************************************************
// Update
// ***************************************************

type UpdateArgs = {
  id: number;
  form: {
    first_name: string | null;
    last_name: string | null;
    dob: string | null;
    license_number: string | null;
    license_state: string | null;
    license_type: string | null;
    license_type_other: string | null;
    license_cdla_years: string | null;
    experience: string | null;
    experience_description: string | null;
    mvr_file_links: string | null;
    mvr_last_checked: string | null;
    accidents_violations_3y: boolean | null;
    marital_status: 'SINGLE' | 'MARRIED' | 'DIVORCED' | 'SEPARATED' | 'WIDOWED' | null;
    driver_status: 'RATED DRIVER' | 'LISTED DRIVER' | 'NON DRIVER' | null;
    ddc_class: boolean | null;
    ddc_date: string | null;
  };
};

async function update({ id, form }: UpdateArgs) {
  const response = await axios.post(`/api/drivers/${id}`, { _method: 'PUT', ...form }).catch((error) => {
    Sentry.captureException(error);
    throw error;
  });

  return z.literal('').parseAsync(response.data);
}

export default {
  all,
  update,
  destroy,
};
