<template>
  <Dropdown
    ref="DropdownRef"
    strategy="fixed"
    class="ajax-select"
    :class="{
      'ajax-select--multivalue': multivalue,
      'ajax-select--processing': processing,
    }"
    placement="bottom-start"
    :disabled="disabled"
    :close-on-inside-click="false"
    @shown="onDropdownShown"
    @hidden="onDropdownHidden"
  >
    <!-- Drodpown trigger -->
    <template #trigger>
      <slot name="trigger">
        <FormGroupDisplay :disabled="disabled" :required="required" :input-value="inputValue">
          <template #label>
            <slot name="label-container">
              <div class="form-group__label-container">
                <div class="form-group__label">
                  <slot name="label">
                    {{ labelComputed }}
                  </slot>
                  <CopyBtn @click.stop="copyValue" />
                </div>
                <div class="form-group__controls">
                  <slot name="controls" />
                </div>
              </div>
            </slot>
          </template>
          <slot name="selected-option" :item="modelValue">
            <template v-if="multivalue && modelValue">
              <span v-for="(selectedOption, i) in modelValue" :key="i" class="ajax-select__selected-option" @click.stop>
                {{ selectedOption[labelMethod] }}
                <SvgIcon name="close" @click="onSelectLocal(selectedOption, false)" />
              </span>
            </template>
            <template v-else-if="modelValue">
              {{ modelValue[labelMethod] }}
            </template>
          </slot>
        </FormGroupDisplay>
      </slot>
    </template>

    <!-- Dropdown body -->
    <template #menu>
      <div class="ajax-select__menu-backdrop">
        <!-- Search -->
        <div class="ajax-select__search">
          <SvgIcon name="search" />
          <input
            ref="searchInputRef"
            v-model="searchQuery"
            class="ajax-select__search-input"
            type="text"
            :format-value="(_, val) => (val === null ? undefined : val)"
            @focus="onSearchFocus"
            @keydown.stop="onSearchKeydown"
          />
        </div>

        <!-- Filters -->
        <template v-if="filterCheckboxes?.length">
          <InputCheckbox
            v-for="(checkbox, i) in filterCheckboxes"
            :key="i"
            v-model="filters[checkbox.name]"
            :label="checkbox.label"
            class="ajax-select__filter"
            @update:model-value="
              (value) => (value === null ? (filters[checkbox.name] = undefined) : (filters[checkbox.name] = value))
            "
          />
        </template>

        <!-- Options -->
        <div ref="optionsRef" class="ajax-select__options">
          <slot name="prefix"></slot>

          <InputCheckbox
            v-for="(option, i) in options"
            :key="checkboxRerenderKey(option)"
            ref="optionRefs"
            :model-value="isChecked(option)"
            class="ajax-select__option"
            :class="currentFocus === i ? 'ajax-select__option--focused' : ''"
            :validator="canUncheck"
            @update:model-value="(checked) => onSelectLocal(option, checked)"
          >
            <template #label>
              <slot name="item" :item="option">
                {{ option[labelMethod] }}
                <span class="ajax-select__option-model-type">
                  {{ option.model_type }}
                </span>
              </slot>
            </template>
          </InputCheckbox>
          <div v-show="hasNextPage" ref="infiniteScrollTriggerRef" class="ajax-select__infinite-scroll-trigger">
            <SvgIcon name="loading" color="#1a497f" />
          </div>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script setup>
import { ref, watch, onMounted, computed } from 'vue';
import Clipboard from '@/js/Helpers/Clipboard';
import Dropdown from '@/js/Common/Dropdown.vue';
import CopyBtn from '@/js/Components/CopyBtn.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import InputCheckbox from '@/js/Common/Form/Input/Checkbox.vue';
import FormGroupDisplay from '@/js/Common/Form/FormGroupDisplay.vue';
import { toastError } from '@/js/Helpers/Alert';
import { useElementSize } from '@vueuse/core';

const props = defineProps({
  modelValue: {
    type: null,
    default: null,
  },
  multitype: {
    type: Boolean,
    default: true,
  },
  multivalue: {
    type: Boolean,
    default: false,
  },
  fetchOptions: {
    type: Function,
    required: true,
  },
  onSelect: {
    type: Function,
    default: undefined,
  },
  labelMethod: {
    type: String,
    default: 'label',
  },
  label: {
    type: String,
    default: null,
  },
  filterCheckboxes: {
    type: Array,
    default: null,
  },
  disabled: {
    type: Boolean,
    required: true,
  },
  required: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['update:model-value', 'selected']);

// State
const options = ref([]);
const limitPerPage = 30;
const currentPage = ref(1);
const fetching = ref(false);
const hasNextPage = ref(false);
const searchQuery = ref();
const filters = ref({});
const currentFocus = ref(-1);
const optionRefs = ref(null);
const optionsRef = ref(null);
const DropdownRef = ref(null);
const searchInputRef = ref(null);
const infiniteScrollTriggerRef = ref(null);
const processing = ref(false);
const optionRerenderKey = ref(1);

const { width } = useElementSize(DropdownRef);

const computedWidth = computed(() => {
  return width.value ? `${width.value}px` : '100%';
});

// Fetch
const performFetch = () => {
  fetching.value = true;
  props
    .fetchOptions(searchQuery.value, currentPage.value, filters.value)
    .then((response) => {
      const opts = response.data.data;
      if (currentPage.value === 1) options.value = opts;
      else options.value = options.value.concat(opts);
      hasNextPage.value = opts.length === limitPerPage;
      if (options.value.length > 0) DropdownRef.value.popperInstance.update();
    })
    .finally(() => {
      fetching.value = false;
    });
};
watch(
  [searchQuery, filters],
  () => {
    currentPage.value = 1;
    optionsRef.value.scrollTo(0, 0);
    performFetch();
  },
  { deep: true },
);

// Infinite scroll
let intersectionObserver = null;
onMounted(() => {
  intersectionObserver = new IntersectionObserver(
    (entries) => {
      const { isIntersecting } = entries[0];
      if (isIntersecting && hasNextPage.value && !fetching.value) {
        currentPage.value += 1;
        performFetch();
      }
    },
    {
      root: optionsRef.value,
    },
  );
});

const onDropdownShown = () => {
  searchInputRef.value.focus();
  intersectionObserver.observe(infiniteScrollTriggerRef.value);
};

const onDropdownHidden = () => {
  options.value = []; // Solves fetching x number of pages
  currentPage.value = 1; // Solves fetching x number of pages
  currentFocus.value = -1;
  intersectionObserver.disconnect();
};

const removeOptionAndReturnOptions = (option) => {
  const newModelValue = [...props.modelValue];
  const index = newModelValue.findIndex((opt) => opt.model_type === option.model_type && opt.id === option.id);
  newModelValue.splice(index, 1);
  return newModelValue;
};

const onSelectLocal = (option, checked) => {
  let newModelValue = null;
  if (!props.multivalue && checked) newModelValue = option;
  else if (!props.multivalue && !checked) newModelValue = null;
  else if (props.multivalue && checked) newModelValue = (props.modelValue ?? []).concat(option);
  else if (props.multivalue && !checked) newModelValue = removeOptionAndReturnOptions(option);

  if (props.onSelect) {
    processing.value = true;
    props
      .onSelect(option, checked, newModelValue)
      .then(() => {
        emit('update:model-value', newModelValue);
        emit('selected', { option, checked });
      })
      .catch((error) => {
        optionRerenderKey.value += 1;

        if (error) {
          toastError(error.message);
        } else {
          toastError();
        }

        throw error;
      })
      .finally(() => {
        processing.value = false;
      });
  } else {
    emit('update:model-value', newModelValue);
    emit('selected', { option, checked });
  }
};

const onSearchFocus = (e) => {
  if (e.relatedTarget && e.relatedTarget.type !== 'checkbox') {
    currentPage.value = 1;
    performFetch();
  }
};

const onSearchKeydown = (e) => {
  if (![40, 38, 13].includes(e.keyCode)) return;

  if (!options.value) return;

  e.preventDefault();

  const items = optionRefs.value;

  if (e.keyCode === 40) {
    currentFocus.value += 1;
  } else if (e.keyCode === 38) {
    currentFocus.value -= 1;
  } else if (e.keyCode === 13) {
    if (currentFocus.value < 0 || currentFocus.value > options.value - 1) return;
    items[currentFocus.value].$el.click();
    searchInputRef.value.focus();
    return;
  }

  if (currentFocus.value <= -1) currentFocus.value = 0;
  else if (currentFocus.value >= items.length) currentFocus.value = items.length - 1;
};

const multivalueIdsMap = computed(() => {
  if (!props.modelValue) return null;
  return props.modelValue.reduce((acc, curr) => {
    acc[`${curr.id}-${curr.model_type}`] = true;
    return acc;
  }, {});
});

const isChecked = (option) => {
  if (!props.modelValue) return false;
  if (!props.multivalue) return option.id === props.modelValue.id && option.model_type === props.modelValue.model_type;
  return !!multivalueIdsMap.value[`${option.id}-${option.model_type}`];
};

const inputValue = computed(() => {
  if (!props.modelValue) return null;
  if (props.multivalue) return props.modelValue.length > 0 ? 1 : null;
  return props.modelValue ? 1 : null;
});

const labelComputed = computed(() => {
  if (!props.label) return null;
  if (props.multivalue && props.modelValue) return `${props.label} (${props.modelValue.length})`;
  if (!props.multivalue && props.modelValue) return `${props.label} (${props.modelValue.model_type})`;
  return props.label;
});

const canUncheck = (event) => {
  if (props.multivalue) return true;
  if (event.target.checked === false && props.required) return false;
  return true;
};

const checkboxRerenderKey = (option) => {
  const result = `${option.id}-${option.model_type}`;
  if (props.multivalue) return result;
  if (!props.modelValue) return result;
  return `${optionRerenderKey.value}-${result}-${props.modelValue.id}-${props.modelValue.model_type}`;
};

const copyValue = () => {
  if (!props.modelValue) return null;
  if (props.multivalue) Clipboard.copy(props.modelValue.map((v) => v[props.labelMethod]).join(', '));
  else Clipboard.copy(props.modelValue[props.labelMethod]);
};
</script>

<style lang="scss">
$option-padding-x: 0.8em;
$search-icon-width: 1.25em;

.ajax-select {
  // Trigger
  .dropdown__trigger {
    width: 100%;
    .form-group-display {
      width: 100%;
      height: rem(48px);
      margin-bottom: 0;
      .form-group__label-container,
      .form-group__label,
      .form-group__controls {
        display: flex;
        align-items: center;
      }
      .form-group__label-container {
        @include form-label;
        padding-top: rem(4px);
        justify-content: space-between;
      }
    }
  }

  // Search
  &__search {
    position: relative;

    .svg-icon {
      top: 0.25em;
      left: $option-padding-x;
      color: #999;
      width: $search-icon-width;
      position: absolute;
    }

    &-input {
      width: 100%;
      font-family: inherit;
      font-size: 1.1em;
      padding: 0.3em 0.3em 0.3em #{$option-padding-x + $search-icon-width + 0.25em};
      border-radius: rem(3px);
      border: rem(1px) solid color('gray', 3);
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

      &:focus {
        outline: 0;
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
      }
    }
  }

  // Filters
  &__filter {
    justify-content: center;
    padding: 1em $option-padding-x;
    border-bottom: rem(1px) solid #ccc;
  }

  .dropdown__menu {
    max-height: unset;
  }

  &__options {
    max-height: 50vh;
    overflow: auto;
  }

  &__option {
    display: flex;
    padding: 0.5em $option-padding-x;
    transition: 0.1s background-color;

    &:not(:last-child) {
      border-bottom: rem(1px) solid #ccc;
    }

    &:hover,
    &--focused {
      background-color: #e3e3e3;
    }

    .checkbox__label {
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-model-type {
      display: block;
      color: color('gray', 5);
      line-height: 1;
    }
  }

  &__infinite-scroll-trigger {
    display: flex;
    justify-content: center;
    padding: 0.5em 1em;
  }

  // Multivalue
  &--multivalue {
    .form-group-display__content {
      max-height: unset;
      padding-top: rem(22px);
      padding-bottom: rem(5px);
    }

    .form-group-display__content-value {
      overflow: unset;
      white-space: unset;
      text-overflow: unset;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .ajax-select__selected-option {
      display: flex;
      align-items: center;
      font-size: rem(16px);
      padding: 0 rem(7.5px);
      background-color: color('gray', 2);
      cursor: default;

      .svg-icon {
        cursor: pointer;
        width: rem(15px);
        margin-left: rem(10px);
      }

      &:not(:first-child) {
        margin-top: rem(7px);
      }
    }
  }

  // Processing
  &--processing &__menu-backdrop {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
