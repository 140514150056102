<template>
  <SearchInDropdown
    class="tablelist-filter-search"
    :search-haystack="searchHaystack"
    :search-replace-input-value="true"
    @update:model-value="selectSearchMatch"
    @input:empty="selectSearchMatch(null)"
  >
    <template #dropdown-trigger>
      {{ labelLeft }}:
      <span>{{ labelRight }}</span>
      <i class="fas fa-caret-down icon"></i>
    </template>
    <template #match-inner="{ match }">
      <slot name="match-inner" :match="match">
        {{ match.name }}
      </slot>
    </template>
  </SearchInDropdown>
</template>

<script setup>
import { ref } from 'vue';
import SearchInDropdown from '@/js/Components/SearchInDropdown.vue';

const props = defineProps({
  searchHaystack: {
    type: Array,
    required: true,
  },
  initialItem: {
    type: Object,
    default: null,
  },
  labelLeft: {
    type: String,
    required: true,
  },
  labelRight: {
    type: String,
    default: 'All',
  },
});

const emit = defineEmits(['selected', 'update:model-value']);

const selectedItem = ref(props.initialItem ? { ...props.initialItem } : null);

// Select search match
const selectSearchMatch = (item) => {
  selectedItem.value = item;
  emit('selected', item);
  emit('update:model-value', item);
};
</script>

<style lang="scss">
.tablelist-filter-search {
  // Dropdown trigger
  .dropdown-trigger {
    color: #999;
    font-size: rem(14px);
    cursor: pointer;

    span {
      color: black;
      margin-left: rem(5px);
    }

    .icon {
      color: black;
      margin-left: rem(10px);
    }
  }

  // Dropdown menu
  .dropdown-menu {
    width: rem(270px);
  }
}
</style>
