<template>
  <!-- Phone number -->
  <FormGroup
    v-model="phoneLocal.number"
    type="phone"
    name="number"
    label-text="Phone"
    :disabled="disabled"
    @update:model-value="emitUpdateDebounced"
  >
    <template #controls>
      <img v-if="phone.is_primary" class="icon verified" src="/img/icons/verified_blue-24px.svg" />
    </template>
  </FormGroup>

  <!-- Phone description -->
  <FormGroup
    v-model.uppercase="phoneLocal.description"
    type="text"
    name="description"
    :disabled="disabled"
    label-text="Phone description"
    @update:model-value="emitUpdateDebounced"
  />
</template>

<script>
import { watch, ref } from 'vue';
import debounce from 'debounce';
import FormGroup from '@/js/Common/Form/FormGroup/FormGroup.vue';

export default {
  components: {
    FormGroup,
  },

  props: {
    phone: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['update:phone'],

  setup(props, { emit }) {
    // Phone (local copy)
    const phoneLocal = ref({ ...props.phone });

    // Override local copy when props change
    watch(
      () => props.phone,
      () => (phoneLocal.value = { ...props.phone })
    );

    // Emit event
    const emitUpdate = () => emit('update:phone', phoneLocal.value);
    const emitUpdateDebounced = debounce(emitUpdate, 1000);

    return {
      phoneLocal,
      emitUpdateDebounced,
    };
  },
};
</script>
