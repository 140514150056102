<template>
  <div v-if="hasAtleastOnePermission" class="sidebar-left__section">
    <!-- Main link -->
    <a class="sidebar-left__link" @click="isExpanded = !isExpanded">
      <SvgIcon name="chart" />
      Directory
    </a>

    <!-- Slidebable menu -->
    <div v-if="isExpanded" class="sidebar-left__section-links">
      <router-link
        v-if="permissions['carriers.index']"
        :to="{ name: 'DirectoryCarriers' }"
        class="sidebar-left__section-link"
      >
        Carriers
      </router-link>

      <router-link
        v-if="permissions['agencies.index']"
        :to="{ name: 'DirectoryAgencies' }"
        class="sidebar-left__section-link"
      >
        Agencies
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { me } from '@/js/Helpers/Session';
import SvgIcon from '@/js/Components/SvgIcon.vue';

// Sliding
const isExpanded = ref(false);

const route = useRoute();
const router = useRouter();

// Is directory page
const isDirectoryPage = (path) => /^\/directory\//.test(path);

// Open menu if visiting directly or refreshing a directory page
if (isDirectoryPage(route.fullPath)) isExpanded.value = !isExpanded.value;

// Collapse menu if user is navigating away from directory
watch(
  () => route.fullPath,
  () => {
    const isNextPageDirectoryPage = isDirectoryPage(route.fullPath);
    const wasPreviousPageDirectoryPage = isDirectoryPage(router.options.history.state.back);

    if (isExpanded.value && !isNextPageDirectoryPage && wasPreviousPageDirectoryPage)
      isExpanded.value = !isExpanded.value;
  }
);

// Permissions
const permissions = computed(() => ({
  'carriers.index': me.permissions['carriers.index'],
  'agencies.index': me.permissions['agencies.index'],
}));

const hasAtleastOnePermission = computed(() => Object.values(permissions.value).includes(true));
</script>
