<template>
  <Modal ref="ModalRef" :is-loading="loading" :close-on-button-click="false" @button:click="CreateFormRef.submit()">
    <!-- Title -->
    <template #title> Create cancellation </template>

    <!-- Modal body -->
    <CreateForm
      ref="CreateFormRef"
      :client-id="clientId"
      :policy-id="policyId"
      @processing="loading = true"
      @finish="loading = false"
      @success="onCreated"
    />
  </Modal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { PolicyCancellation } from '@/js/Api/PolicyCancellation';
import Modal from '@/js/Common/Modal.vue';
import CreateForm from './Create.vue';

interface Props {
  clientId: number;
  policyId: number;
}

interface Emits {
  (e: 'created', cancellation: PolicyCancellation): void;
}

defineProps<Props>();

const emit = defineEmits<Emits>();

const loading = ref<boolean>(false);
const ModalRef = ref<InstanceType<typeof Modal> | null>(null);
const CreateFormRef = ref<InstanceType<typeof CreateForm> | null>(null);

const onCreated = (cancellation: PolicyCancellation) => {
  emit('created', cancellation);
};

const show = () => {
  ModalRef.value.show();
};

const hide = () => {
  ModalRef.value.hide();
};

defineExpose({
  show,
  hide,
});
</script>

<style lang="scss" scoped>
.form-check-container.reasons {
  margin-bottom: rem(20px);
}
</style>
