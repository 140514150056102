<template>
  <Dropdown
    ref="DropdownRef"
    strategy="fixed"
    placement="bottom-start"
    :offset="[0, 10]"
    class="dropdown--tickets-search"
    :disabled="disabled"
  >
    <!-- Trigger -->
    <template #trigger>
      Ticket:
      <span v-if="ticket">
        <strong>{{ ticket.type }}</strong>
        {{ typeof ticket === 'object' ? ticket.subject.shorten(35) : ticket }}
      </span>
      <span v-else>Select a ticket</span>
    </template>

    <!-- Menu -->
    <template #menu>
      <p class="dropdown-description">Select a ticket:</p>
      <DropdownItem
        v-for="option in options"
        :key="option.value"
        :class="{
          'dropdown-item--string': typeof option.value === 'string',
          'dropdown-item--ticket': typeof option.value === 'object',
        }"
        @click="emit('update:ticket', option.value)"
      >
        <template v-if="typeof option.value === 'string'">
          <strong v-if="option.type">{{ option.type }}</strong>
          {{ option.label }}
        </template>
        <TicketListItem v-else :ticket="option.value" />
      </DropdownItem>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Ticket } from '@/js/Api/Ticket';
import Dropdown from '@/js/Common/Dropdown.vue';
import DropdownItem from '@/js/Common/DropdownItem.vue';
import TicketListItem from '@/js/Components/Tickets/ListItem.vue';

export type Value = Ticket | 'New service ticket' | 'New sales ticket' | null;

export type Option = {
  label: string;
  value: Value;
  type?: Ticket['type'];
};

interface Props {
  ticket: Value;
  tickets: Ticket[];
  disabled?: boolean;
  disabledReason?: string;
  triggerTheme?: 'input' | 'text';
  filterOptions?: (t: Option) => boolean;
}

interface Emits {
  (e: 'update:ticket', ticket: Value): void;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  triggerTheme: 'input',
  disabledReason: undefined,
  filterOptions: () => true,
});

const emit = defineEmits<Emits>();

const options = computed<Option[]>(() =>
  [
    {
      label: 'New service ticket',
      value: 'New service ticket',
    } as Option,
    {
      label: 'New sales ticket',
      value: 'New sales ticket',
    } as Option,
    ...props.tickets.map(
      (ticket) =>
        ({
          label: ticket.subject.shorten(60),
          value: ticket,
          type: ticket.type,
        }) as Option,
    ),
  ].filter(props.filterOptions),
);
</script>

<style lang="scss">
.dropdown--tickets-search {
  // Dropdown trigger
  .dropdown__trigger {
    display: block;
    width: 100%;
    color: color('gray', 6);
    background-color: white;
    border-radius: rem(5px);
    padding: 0 rem(5px);
    font-size: rem(14px);

    span {
      color: black;
      margin-left: rem(5px);
    }
  }

  .dropdown__menu {
    overflow: auto;
  }

  .dropdown-item {
    padding-left: 2em;
    padding-right: 2em;
    strong {
      margin-right: rem(5px);
    }
    .ticket {
      width: 100%;
    }
    &--ticket {
      width: 36em;
      white-space: unset;
      cursor: unset;
      border-bottom: unset !important;
      padding-top: 0.3em;
      padding-bottom: 0.3em;
      &:hover {
        background-color: inherit;
      }
    }
  }
}
</style>
