<template>
  <!-- Panel -->
  <SlidePanel v-model:is-active="isFollowupsPanelActive">
    <template #header-left>
      Followups
      <template v-if="(count != null && activeView === 'regular') || activeView === 'watch'"> ({{ count }}) </template>
    </template>

    <!-- Navigation -->
    <template #header-right="{ isActive }">
      <NavDropdown
        v-show="isActive"
        :offset="[0, 10]"
        :active-option-value="activeView"
        :options="[
          {
            label: 'Regular View',
            value: 'regular',
            icon: 'list-bullet',
          },
          {
            label: 'Team View',
            value: 'team',
            icon: 'user-group',
            disabled: !me.permissions['see-others-followups'],
          },
          {
            label: 'Watch View',
            value: 'watch',
            icon: 'eye-view',
          },
          {
            label: 'Team Watch View',
            value: 'teamwatch',
            icon: 'user-team',
          },
        ]"
        @click.stop
        @option:click="(option) => switchView(option.value)"
      />
    </template>

    <!-- View -->
    <component
      :is="activeViewComponent"
      @watch:followup="watchToggle"
      @click:followup="fetchFollowup"
      @sort="commitSort"
    />
  </SlidePanel>

  <!-- Modal (teleported to body) -->
  <NoteModal
    v-if="followupInModal"
    ref="followupModalRef"
    :note="followupInModal"
    @hidden:modal="followupInModal = null"
    @update:note="commitNoteUpdateFromModal"
    @update:subnote="commitNoteUpdateFromModal"
    @create:subnote="commitSubnoteCreateFromModal"
    @deleted:note="commitNoteDeleteFromModal"
    @deleted:subnote="commitNoteDeleteFromModal"
  />
</template>

<script>
import TicketNoteApi from '@/js/Api/TicketNote';
import { useStore } from 'vuex';
import { ref, nextTick, computed } from 'vue';
import useAlert from '@/js/Composables/useAlert';
import useLoader from '@/js/Composables/useLoader';
import SlidePanel from '@/js/Components/SlidePanel.vue';
import NavDropdown from '@/js/Components/NavDropdown.vue';
import NoteModal from '@/js/Components/Tickets/NoteModal/NoteModal.vue';
import ViewRegular from './ViewRegular.vue';
import ViewWatch from './ViewWatch.vue';
import ViewTeam from './ViewTeam.vue';
import ViewTeamwatch from './ViewTeamwatch.vue';

export default {
  components: {
    SlidePanel,
    NoteModal,
    NavDropdown,
    ViewRegular,
    ViewWatch,
    ViewTeam,
    ViewTeamwatch,
  },

  setup() {
    const store = useStore();
    const { toast } = useAlert();
    const { loader } = useLoader();

    // Modal
    const followupInModal = ref(null);
    const followupModalRef = ref(null);

    const isFollowupsPanelActive = computed({
      get: () => store.state.dashboard.followupsPanelSettings.active,
      set: (isActive) =>
        store.commit('dashboard/setSetting', {
          value: isActive,
          property: 'active',
          panel: 'followups',
        }),
    });

    const commitSort = (column, order) => {
      store.commit('dashboard/setSetting', {
        value: column,
        property: 'sortedBy',
        panel: 'followups',
      });
      store.commit('dashboard/setSetting', {
        value: order,
        property: 'sortOrder',
        panel: 'followups',
      });
    };

    // regular, team, etc.
    const activeView = computed(() => store.state.dashboard.followupsPanelSettings.view);

    // FollowupsRegular, FollowupsTeam, etc.
    const activeViewComponent = computed(() => `View${activeView.value.capitalize()}`);

    // Followup count
    const count = computed(() => store.state.dashboard.followupsPanelCount);

    // Mutations
    const switchView = (view) => {
      store.commit('dashboard/setFollowupsView', view);
    };
    const commitNoteDeleteFromModal = (note) => {
      if (note.note_id) {
        const i = followupInModal.value.notes.find((s) => s.id === note.id);
        if (i !== -1) followupInModal.value.notes.splice(i, 1);
      } else {
        note.notes.forEach((s) => store.commit('dashboard/deleteFollowup', s));
      }
      store.commit('dashboard/deleteFollowup', note);
    };
    const commitNoteUpdate = (followup) => {
      if (followup.note_id) {
        const subnoteInModal = followupInModal.value.notes.find((s) => s.id === followup.id);
        Object.assign(subnoteInModal, followup);
      } else if (followupInModal.value?.id === followup.id) {
        Object.assign(followupInModal.value, followup);
      }
      store.commit('dashboard/updateFollowup', followup);
    };
    const commitSubnoteCreateFromModal = (subnote) => {
      subnote.pulse = true;
      followupInModal.value.unsaved_text = null;
      followupInModal.value.notes.push(subnote);
      setTimeout(() => {
        if (!followupInModal.value) return;
        const subnoteInState = followupInModal.value.notes.find((n) => n.id === subnote.id);
        subnoteInState.pulse = false;
      }, 3000);
    };
    const commitNoteUpdateFromModal = (followup) =>
      commitNoteUpdate({
        ...followup,
        is_watching: !!followup.watchers.find((w) => w.id == window._me.id),
      });

    // Fetch followup
    const fetchFollowup = (note) => {
      loader.start();
      TicketNoteApi.find({ id: note.note_id ?? note.id })
        .then(({ data }) => {
          followupInModal.value = data;
          nextTick(() => followupModalRef.value.$refs.ModalRef.show());
        })
        .catch(() => {
          toast({ title: 'An error occurred.', icon: 'error' });
        })
        .finally(() => {
          loader.end();
        });
    };

    // Watch followup
    const watchToggle = async (note) => {
      loader.start();

      // Fetch note (we need watchers relation)
      await TicketNoteApi.find({ id: note.id })
        .then(({ data }) => (note = data))
        .catch(() => toast({ title: 'An error occurred', icon: 'error' }));

      // Action
      const action = note.watchers.find((w) => w.id === window._me.id) ? 'remove' : 'add';

      // If I'm watching, remove me else add me
      const userIDs = (
        action == 'add' ? note.watchers.concat(window._me) : note.watchers.filter((w) => w.id != window._me.id)
      ).map((w) => w.id);

      // Watch
      TicketNoteApi.watch({ id: note.id, watcherIds: userIDs })
        .then(() => {
          const noteCopy = { ...note };
          if (action == 'add') {
            noteCopy.is_watching = true;
            noteCopy.close_cleared = null;
            noteCopy.reschedule_cleared = null;
            toast({ title: 'You are now watching this followup.', icon: 'success' });
          } else {
            noteCopy.is_watching = false;
            noteCopy.close_cleared = undefined;
            noteCopy.reschedule_cleared = undefined;
            toast({ title: 'You are no longer watching this followup.', icon: 'success' });
          }
          commitNoteUpdate(noteCopy);
        })
        .catch((error) => {
          toast({ title: 'An error has occurred.', icon: 'error' });
        })
        .finally(() => {
          loader.end();
        });
    };

    return {
      activeView,
      activeViewComponent,
      switchView,
      watchToggle,
      fetchFollowup,
      count,
      followupInModal,
      followupModalRef,
      isFollowupsPanelActive,
      commitSort,
      commitNoteUpdate,
      commitNoteDeleteFromModal,
      commitNoteUpdateFromModal,
      commitSubnoteCreateFromModal,
    };
  },
};
</script>
