<template>
  <Dropdown class="nav-dropdown">

    <!-- Trigger -->
    <template #trigger>
      <SvgIcon :name="activeOption.icon" />
      {{ activeOption.label }}
    </template>

    <!-- Menu -->
    <template #menu>
      <DropdownItem
        v-for="option in options"
        :key="option.value"
        :icon="option.icon"
        :label="option.label"
        :disabled="option.disabled"
        @click="onClick(option)"
      />
    </template>

  </Dropdown>
</template>

<script>
import { ref, watch } from 'vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import Dropdown from '@/js/Common/Dropdown.vue';
import DropdownItem from '@/js/Common/DropdownItem.vue';

export default {
  components: {
    SvgIcon,
    Dropdown,
    DropdownItem
  },

  props: {
    options: {
      type: Array,
      required: true
    },
    activeOptionValue: {
      type: null,
      default: null
    }
  },

  emits: [
    'option:click'
  ],

  setup(props, { emit }) {
    const activeOption = ref(null);

    if (props.activeOptionValue)
      activeOption.value = { ...props.options.find(o => o.value === props.activeOptionValue) };
    else
      activeOption.value = { ...props.options[0] };

    // Override active option when 'activeOptionValue' changes
    watch(() => props.activeOptionValue, () => {
      if (activeOption.value !== props.activeOptionValue)
        activeOption.value = { ...props.options.find(o => o.value === props.activeOptionValue) };
    });

    const onClick = option => {
      activeOption.value = option;
      emit('option:click', option);
    };

    return {
      onClick,
      activeOption
    };
  }
};
</script>

<style lang="scss">
.nav-dropdown {
  .dropdown-trigger {
    color: black;
    .svg-icon {
      margin-right: rem(10px);
    }
    &::after {
      @include triangleIcon;
      position: static;
      color: color('gray', 7);
      margin-left: rem(5px);
      transform: translateY(#{rem(3px)});
    }
  }
}
</style>
