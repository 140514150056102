<template>
  <div ref="target" class="note__sticky-right" :class="{ 'note__sticky-right--overflown': isOverflown }">
    <!-- 3 dots -->
    <Dropdown strategy="absolute" class="dropdown--3-dots" @shown="isOverflown = true" @hidden="isOverflown = false">
      <template #trigger>
        <SvgIcon name="3-dots" />
      </template>
      <template #menu>
        <!-- More details -->
        <a v-if="!note.note_id" class="dropdown-item" @click="emit('click:more-details')"> More details</a>

        <!-- Edit -->
        <a v-if="isEditOptionShown" class="dropdown-item" @click="emit('update:mode', 'Update')">
          <SvgIcon name="pencil" />
          Edit
        </a>

        <!-- Convert to followup -->
        <a
          v-if="!note.is_followup && canEdit && note.permissions.edit && mode != 'CreateFollowup'"
          class="dropdown-item"
          @click="emit('update:mode', 'CreateFollowup')"
        >
          <SvgIcon name="sticky-note" />
          Convert to followup
        </a>

        <!-- Add subnote -->
        <a v-if="canEdit && !note.note_id" class="dropdown-item" @click="emit('click:add-subnote')">
          <SvgIcon name="add-comment" />
          Add a subnote
        </a>

        <!-- Move to tomorrow -->
        <a
          v-if="note.is_followup && note.permissions.edit && canEdit && !isDueTomorrow"
          class="dropdown-item"
          @click="emit('click:move-to-tomorrow')"
        >
          <SvgIcon name="calendar" />
          Move to tomorrow
        </a>

        <!-- Status -->
        <a
          v-if="canEdit && note.permissions.edit && note.is_followup"
          :class="{
            'dropdown-item': true,
            'dropdown-item--status': true,
            'dropdown-item--resolve': note.status === 'Open',
            'dropdown-item--reopen': note.status === 'Resolved',
          }"
          @click="emit('update:status', note.status === 'Open' ? 'Resolved' : 'Open')"
        >
          <SvgIcon v-if="note.status === 'Resolved'" name="clock-arrow" />
          <SvgIcon v-if="note.status === 'Open'" name="checkmark-circle" color="#5ac10e" />
          {{ note.status === 'Open' ? 'Resolve' : 'Reopen' }}
        </a>

        <!-- Delete -->
        <DropdownItem
          v-if="canEdit && me.permissions['ticket_notes.delete']"
          class="dropdown-item--delete"
          @click="deleteNote"
        >
          <SvgIcon name="trashcan" />
          Delete
        </DropdownItem>

        <!-- View mode -->
        <a v-if="mode !== 'View'" class="dropdown-item" @click="emit('update:mode', 'View')">
          <SvgIcon name="arrow-left" />
          View mode
        </a>
      </template>
    </Dropdown>

    <!-- Save button -->
    <div v-if="mode !== 'View'" class="note__sticky-right-save">
      <SvgIcon name="floppy-disk" placement="bottom" @click="emit('click:save')" />
      <Tooltip show-on="hover"> Save information </Tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { me } from '@/js/Helpers/Session';
import TicketNoteApi, { TicketNote } from '@/js/Api/TicketNote';
import Tooltip from '@/js/Common/Tooltip.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import Dropdown from '@/js/Common/Dropdown.vue';
import DropdownItem from '@/js/Common/DropdownItem.vue';
import Loader from '@/js/Helpers/Loader';
import { toastSuccess, toastError, prompt } from '@/js/Helpers/Alert';

interface Props {
  note: TicketNote;
  canEdit?: boolean;
  mode: 'View' | 'Update' | 'CreateFollowup';
}

const props = defineProps<Props>();

const emit = defineEmits([
  'deleted:note',
  'update:mode',
  'update:note',
  'update:status',
  'click:add-subnote',
  'click:more-details',
  'click:save',
  'click:move-to-tomorrow',
]);

const isOverflown = ref(false);

const isEditOptionShown = computed(() => {
  const baseCondition = props.mode === 'View';
  if (!props.note.is_followup) return baseCondition && props.canEdit && me.permissions['edit-note-content'];
  return baseCondition && props.canEdit && props.note.permissions.edit;
});

// Is tomorrow
const isDueTomorrow = computed(() => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const due = new Date(props.note.due);
  return (
    due.getDate() === tomorrow.getDate() &&
    due.getMonth() === tomorrow.getMonth() &&
    due.getFullYear() === tomorrow.getFullYear()
  );
});

const deleteNote = async () => {
  const result = await prompt({
    title: 'Are you sure?',
    text: 'Type in "delete" to confirm',
  });

  if (!result.value || result.value.toLowerCase() !== 'delete') return;

  const n = props.note;
  Loader.start();
  TicketNoteApi.softDelete({ id: n.id })
    .then(() => {
      toastSuccess();
      emit('deleted:note', n);
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      Loader.end();
    });
};
</script>

<style lang="scss">
.note {
  &__sticky-right {
    top: 0;
    right: 0;
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    &--overflown {
      z-index: 2;
    }
  }

  &__sticky-right-save {
    margin-top: rem(15px);

    .svg-icon {
      color: $green;
      cursor: pointer;
    }
  }

  .dropdown--3-dots {
    margin: 0 rem(5px);

    .dropdown-trigger {
      border-radius: 50%;
      padding: rem(5px);

      .svg-icon {
        color: color('gray', 3);
      }
    }

    .dropdown-trigger:hover,
    &.dropdown--shown .dropdown-trigger {
      background-color: #e5e5e5;
    }

    .dropdown-item .svg-icon {
      margin-right: rem(10px);
    }

    // Status
    .dropdown-item--resolve {
      color: $success;
    }

    .dropdown-item--delete {
      color: $red;
    }
  }

  &--child {
    .dropdown--3-dots {
      font-size: rem(14px);
    }
  }
}
</style>
