import { z } from 'zod';
import axios from 'axios';
import { CoverageTypeSchema } from './CoverageType';
import * as Sentry from '@sentry/vue';

const WCRatingsSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  intakes_wc_id: z.number().nullable(),
  class_description: z.string().nullable(),
  class_code: z.string().nullable(),
  Payroll: z.string().nullable(),
  State: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});

export const IntakeWCSchema = z.object({
  id: z.number(),
  client_id: z.number(),
  is_complete: z.boolean(),
  Limits: z.string(),
  business_description: z.string().nullable(),
  Payroll: z.string().nullable(),
  State: z.string().nullable(),
  Experience_Mod: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullable(),
  coverage_type: CoverageTypeSchema,
  wc_ratings: z.array(WCRatingsSchema).nullish(),
});

export type IntakeWC = z.infer<typeof IntakeWCSchema>;

async function find({ clientId, id }: { clientId: number; id: number }) {
  const response = await axios.get(`/api/clients/${clientId}/intakes/wc/${id}`).catch((error) => {
    Sentry.captureException(error);
  });

  if (!response) return undefined;

  return z
    .object({
      data: IntakeWCSchema,
    })
    .parseAsync(response.data);
}

export default {
  find,
};
