<template>
  <div class="quote-notice-card">
    <!-- Card -->
    <div class="quote-notice-card__card">
      <div class="flex-align-start mb-2">
        <SvgIcon name="thumbs-up" class="mr-1" />
        <p class="mb-0">
          Thanks for inputting the submission!<br />
          Do you want to notify the client of status?
        </p>
      </div>
      <div class="flex-align-center">
        <Btn
          variant="ghost"
          color="secondary"
          class="mr-2"
          :class="{ 'button--active': showCommunicationMethods === true }"
          @click="showCommunicationMethods = true"
        >
          Yes
        </Btn>
        <Btn
          variant="ghost"
          color="secondary"
          :class="{ 'button--active': showCommunicationMethods === false }"
          @click="showCommunicationMethods = false"
        >
          No
        </Btn>
      </div>

      <!-- Divider -->
      <div v-if="showCommunicationMethods" class="quote-notice-card__card-divider"></div>

      <!-- Communication methods -->
      <div v-if="showCommunicationMethods" class="quote-notice-card__comm-methods">
        <div class="left">
          <p>Select a method:</p>
        </div>
        <div class="flex-align-center">
          <Btn variant="ghost" color="secondary" class="mr-2"> Call now </Btn>
          <Btn variant="ghost" color="secondary"> Send an email </Btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import Btn from '@/js/Components/Btn.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';

const showCommunicationMethods = ref<boolean | null>(null);
</script>

<style lang="scss">
.quote-notice-card {
  &__card {
    color: white;
    font-size: rem(14px);
    padding: 1em 3em;
    background-color: #6d99cb;
    border-radius: 1em;

    .svg-icon--thumbs-up {
      color: color('primary');
    }

    &-divider {
      height: 1px;
      width: 100%;
      margin: 1.6em 0;
      background-color: white;
    }

    .button {
      &:not(:hover):not(&--active) {
        color: white;
        border-color: white;
      }
      &--active {
        color: color('primary', 6);
        background-color: white;
        border-color: white;
      }
    }
  }
}
</style>
