<template>
  <FormContainer validate @submit="emit('submit', form)">
    <!-- Name -->
    <FormGroup v-model="form.name" type="text" name="name" label-text="Name" :required="true" :disabled="true" />
    <!-- URL -->
    <FormGroup v-model="form.url" type="text" name="url" label-text="Finance company website" :disabled="true">
      <template #controls>
        <a v-if="financeCompany.url" class="link" :href="financeCompany.url" target="_blank">Finance company website</a>
      </template>
    </FormGroup>
    <!-- Addresses -->
    <Addresses
      v-if="financeCompany.addresses"
      :addresses="financeCompany.addresses"
      parent-type="finance company"
      :parent-i-d="financeCompany.id"
      :with-numbers="true"
      :disabled="true"
    />
    <!-- Contacts -->
    <Contacts
      v-if="financeCompany.contacts"
      :contacts="financeCompany.contacts"
      parent-type="finance company"
      :parent-id="financeCompany.id"
      :with-numbers="true"
      :disabled="true"
    />
    <!-- Submit -->
    <Btn type="submit">Submit</Btn>
  </FormContainer>
</template>

<script setup>
import { ref } from 'vue';
import Btn from '@/js/Components/Btn.vue';
import FormContainer from '@/js/Common/Form/FormContainer.vue';
import FormGroup from '@/js/Common/Form/FormGroup/FormGroup.vue';
import Addresses from '@/js/Common/Form/FormGrid/Addresses.vue';
import Contacts from '@/js/Common/Form/FormGrid/Contacts/Contacts.vue';

const props = defineProps({
  financeCompany: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['submit']);

const form = ref({ ...props.financeCompany });
</script>
