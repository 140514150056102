import { z } from 'zod';
import * as Sentry from '@sentry/vue';
import axios, { AxiosError } from 'axios';
import { queryBuilderToQueryString } from '@/js/Helpers';
import { UserSchema, User } from './User';
import { ExternalProducerSchema, ExternalProducer } from './ExternalProducer';

export const BaseProducerLicenseSchema = z.object({
  id: z.number(),
  producer_type: z.enum(['user', 'external producer']),
  producer_id: z.number(),
  state_abbr: z.string().nullable(),
  state_name: z.string().nullable(),
  license_number: z.string().nullable(),
  valid_until: z.string().nullable(),
  filename: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});

export type ProducerLicense = z.infer<typeof BaseProducerLicenseSchema> & {
  producer?: User | ExternalProducer;
};

export const ProducerLicenseSchema: z.ZodType<ProducerLicense> = BaseProducerLicenseSchema.extend({
  producer: z.lazy(() => z.union([UserSchema, ExternalProducerSchema]).optional()),
});

export default {};
