import Swal from 'sweetalert2';

export function prompt(options) {
  return Swal.mixin({
    text: 'Type in "delete" to confirm.',
    input: 'text',
    showCancelButton: true,
    confirmButtonText: 'Delete',
    confirmButtonColor: '#19497F',
    cancelButtonColor: '#E90001',
  }).fire(options);
}

export function confirm(options) {
  return Swal.mixin({
    text: 'Are you sure?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#19497F',
    cancelButtonColor: '#E90001',
    confirmButtonText: 'Yes, delete it!',
  }).fire(options);
}

export function toast(options) {
  return Swal.mixin({
    customClass: { container: 'swal-toast-container' },
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000,
  }).fire(options);
}

export function toastSuccess(message = 'Success') {
  return toast({ title: message, icon: 'success' });
}

export function toastError(message = 'An error has occurred') {
  return toast({ title: message, icon: 'error' });
}

export default {
  prompt,
  confirm,
  toast,
  toastError,
};
