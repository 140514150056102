export default function get<T, Default = undefined>(
  obj: T,
  path: string,
  defaultValue: Default = undefined as Default,
): unknown extends T ? Default : any {
  let result: any = obj;
  const keys: string[] = path.split('.');

  for (const key of keys) {
    // We need to check if the object is null or undefined because TypeScript doesn't have native optional chaining
    if (result === undefined || result === null) {
      return defaultValue;
    }

    result = result[key];
  }

  return result === undefined ? defaultValue : result;
}
