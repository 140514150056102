import { z } from 'zod';

export const EmailSchema = z.object({
  id: z.number(),
  client_id: z.number().nullable(),
  parent_id: z.number(),
  parent_type: z.string(),
  email: z.string().nullable(),
  description: z.string().nullable(),
  is_primary: z.boolean(),
  created_at: z.string(),
  updated_at: z.string(),
});
