<template>
  <Modal ref="ModalRef" :is-loading="loading">
    <template #title>{{ carrier?.name }}</template>
    <template #footer-btn-text>Close</template>
    <CarrierShow v-if="carrier" :carrier="carrier" />
  </Modal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { toastError } from '@/js/Helpers/Alert';
import CarrierApi, { Carrier } from '@/js/Api/Carrier';
import Modal from '@/js/Common/Modal.vue';
import CarrierShow from '@/js/Components/Carriers/Show.vue';

const loading = ref<boolean>(false);
const carrier = ref<Carrier | null>(null);
const ModalRef = ref<InstanceType<typeof Modal> | null>(null);

const open = (id: number) => {
  loading.value = true;
  ModalRef.value.show();
  CarrierApi.find({ id })
    .then(({ data }) => {
      carrier.value = data;
    })
    .catch(() => {
      toastError();
    })
    .finally(() => {
      loading.value = false;
    });
};

defineExpose({
  open,
});
</script>
