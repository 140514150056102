<template>
  <Dropdown class="priority-picker" placement="bottom" :offset="[0, 5]">
    <!-- Trigger -->
    <template #trigger>
      <SvgIcon :name="`followup-${selectedOption.name}`" />
      <div v-if="weight" class="priority-picker__trigger-weight">
        {{ weight }}
      </div>
    </template>

    <!-- Menu -->
    <template #menu>
      <button
        v-for="option in options"
        :key="option.priority"
        type="button"
        class="priority-picker__dropdown-btn"
        @click="onClick(option)"
      >
        <SvgIcon :name="`followup-${option.name}`" />
      </button>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import Dropdown from '@/js/Common/Dropdown.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';

type ModelValue = 1 | 2 | 3 | 4;

interface Props {
  modelValue?: ModelValue;
  weight?: number;
}

interface Emits {
  (e: 'update:model-value', modelValue: ModelValue): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

type Option = {
  priority: ModelValue;
  name: 'low' | 'medium' | 'high' | 'urgent' | 'urgent-fire';
};

const options = ref<Option[]>([
  { priority: 1, name: 'low' },
  { priority: 2, name: 'medium' },
  { priority: 3, name: 'high' },
  { priority: 4, name: props.weight ? 'urgent' : 'urgent-fire' },
]);

const onClick = (option: Option) => emit('update:model-value', option.priority);

const selectedOption = computed(() => options.value.find((option) => option.priority === props.modelValue) as Option);
</script>

<style lang="scss">
.priority-picker {
  .dropdown-trigger {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .priority-picker__trigger-weight {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      color: white;
      font-size: rem(12px);
    }
  }

  &:not(.dropdown--disabled) .dropdown-trigger &__trigger-weight {
    cursor: pointer;
  }

  .dropdown-menu {
    min-width: unset;
    box-shadow: none;
    background: none;

    .priority-picker__dropdown-btn {
      display: block;
      cursor: pointer;
      box-shadow: none;
      background: none;
      height: auto !important;
      padding: 0;
      border: none;
    }
  }
}
</style>
