<template>
  <div
    ref="rootEl"
    :class="{
      note: true,
      'note--expanded': expanded,
      'note--child': !!noteLocal.note_id,
      'note--parent': !!noteLocal.notes?.length,
    }"
  >
    <!-- Main -->
    <div class="note__main">
      <div class="note__main-container">
        <!-- Relation line -->
        <template v-if="noteLocal.note_id">
          <div class="note__relation-line-top"></div>
          <div class="note__relation-line-bottom"></div>
        </template>

        <!-- Body -->
        <component
          :is="noteBodyComponent"
          ref="NoteBodyRef"
          v-model:mode="mode"
          v-model:note="noteLocal"
          :followup-update-form-type="followupUpdateFormType"
          @finished-editing="mode = 'View'"
        />

        <!-- Note footer -->
        <div v-if="noteLocal.notes?.length" class="note__footer">
          <div class="note__footer-left">
            {{ openFollowupsCount === 0 ? 'No' : openFollowupsCount }} followup{{ openFollowupsCount !== 1 ? 's' : '' }}
            <div class="note__footer-divider"></div>
            {{ notesCount === 0 ? 'No' : notesCount }} note{{ notesCount !== 1 ? 's' : '' }}
          </div>
          <div v-if="noteLocal.watchers.length" class="note__footer-right">
            <SvgIcon name="eye" class="svg-icon--watch" />
            <Tooltip show-on="hover" :offset="[10, 0]" placement="bottom-end" class="tooltip--watch">
              <template v-for="user in noteLocal.watchers" :key="user.id">
                {{ user.name }}
                <br />
              </template>
            </Tooltip>
          </div>
        </div>
      </div>

      <!-- 3 dots -->
      <NoteItem3Dots
        v-model:mode="mode"
        :note="note"
        :can-edit="canEdit"
        @update:note="(note) => $emit('update:note', note)"
        @update:status="updateStatus"
        @deleted:note="(note) => $emit('deleted:note', note)"
        @click:save="onSaveClick"
        @click:move-to-tomorrow="moveToTomorrow"
        @click:add-subnote="showSubnoteForm = !showSubnoteForm"
        @click:more-details="$emit('click:more-details', note)"
      />
    </div>

    <!-- Padding left for subnotes and subnote form -->
    <div v-if="!noteLocal.note_id" class="note__subnotes-container">
      <!-- Subnotes -->
      <template v-if="expanded">
        <NoteItem
          v-for="(subnote, i) in noteLocal.notes"
          :key="subnote.id"
          v-model:note="noteLocal.notes[i]"
          :followup-update-form-type="followupUpdateFormType"
          @update:note="(subnote) => $emit('update:subnote', subnote)"
          @click:more-details="$emit('click:more-details', note)"
        />
      </template>

      <!-- Subnote form -->
      <CreateSubnoteForm
        v-show="showSubnoteForm"
        :key="showSubnoteForm"
        ref="CreateSubnoteFormRef"
        :note="note"
        @post="addSubnote"
        @discard="showSubnoteForm = false"
      />
    </div>
  </div>
</template>

<script setup>
import TicketNoteApi from '@/js/Api/TicketNote';
import { useStore } from 'vuex';
import { onMounted, ref, computed, watch, nextTick } from 'vue';
import useAlert from '@/js/Composables/useAlert';
import useLoader from '@/js/Composables/useLoader';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import Tooltip from '@/js/Common/Tooltip.vue';
import CreateSubnoteForm from '@/js/Components/Tickets/CreateSubnoteForm.vue';
import NoteItem3Dots from './NoteItem3Dots.vue';
import NoteItemBodyView from './NoteItemBodyView.vue';
import NoteItemBodyUpdate from './NoteItemBodyUpdate.vue';
import NoteItemBodyCreateFollowup from './NoteItemBodyCreateFollowup.vue';

const props = defineProps({
  note: {
    type: Object,
    required: true,
  },
  expanded: {
    type: Boolean,
    default: true,
  },
  canEdit: {
    type: Boolean,
    default: true,
  },
  followupUpdateFormType: {
    type: String,
    default: 'small',
    validator: (value) => ['small', 'big'].includes(value),
  },
});

const emit = defineEmits(['click:more-details', 'update:note', 'update:subnote', 'create:subnote', 'deleted:note']);

// Tools
const store = useStore();
const { me } = store.state.global;
const { toast, confirm } = useAlert();
const { loader } = useLoader();

// Mode
const mode = ref('View');

// Local state
const noteLocal = computed({
  get: () => props.note,
  set: (note) => emit('update:note', note),
});

// Body component
const noteBodyComponent = computed(() => {
  switch (mode.value) {
    case 'View':
      return NoteItemBodyView;
    case 'Update':
      return NoteItemBodyUpdate;
    case 'CreateFollowup':
      return NoteItemBodyCreateFollowup;
    default:
      return NoteItemBodyView;
  }
});

// Refs
const rootEl = ref(null);
const NoteBodyRef = ref(null);
const CreateSubnoteFormRef = ref(null);

// Show subnote form
const showSubnoteForm = ref(!!props.note.unsaved_text);

// Scroll note into view
if (props.note.pulse && !props.note.note_id)
  onMounted(() => {
    setTimeout(() => {
      // FIXME - why rootEl.value === null sometimes?
      if (rootEl.value) rootEl.value.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }, 500);
  });

// Reset mode when edit permissions change
watch(
  () => props.note.permissions.edit,
  () => {
    mode.value = 'View';
  },
);

// Focus the input, when subnote form is shown
watch(showSubnoteForm, () => {
  if (!showSubnoteForm.value) return;
  nextTick(() => {
    const subnoteContentInput = CreateSubnoteFormRef.value.$el.querySelector('textarea');
    subnoteContentInput.focus();
    subnoteContentInput.scrollIntoView({ block: 'center', behavior: 'smooth' });
  });
});

const moveToTomorrow = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  TicketNoteApi.autosave({ id: props.note.id, name: 'due', value: tomorrow.toDateInput() })
    .then(({ data }) => {
      emit('update:note', data);
    })
    .catch(() => {
      toast({ title: 'An error occurred', icon: 'error' });
    });
};

// Change status
const updateStatus = async (status) => {
  const verbiageText = status === 'Open' ? 'reopen' : 'resolve';
  const verbiageButton = status === 'Open' ? 'Reopen followup' : 'Resolve followup';

  const result = await confirm({
    icon: 'question',
    confirmButtonText: verbiageButton,
    text: `Are you sure you want to ${verbiageText} this followup?`,
  });
  if (!result.isConfirmed) return;

  TicketNoteApi.autosave({ id: props.note.id, name: 'status', value: status })
    .then(({ data }) => {
      emit('update:note', data);
      mode.value = 'View';
    })
    .catch(() => {
      toast({ title: 'An error occurred', icon: 'error' });
    });
};

// Add subnote
const addSubnote = (content) => {
  const body = {
    note_id: props.note.id,
    ticket_id: props.note.ticket_id,
    content,
    is_followup: false,
  };

  loader.start();
  TicketNoteApi.create(body)
    .then(({ data }) => {
      showSubnoteForm.value = false;
      emit('create:subnote', data);
      toast({ title: 'Note updated', icon: 'success' });
    })
    .catch(() => {
      toast({ title: 'An error occurred', icon: 'error' });
    })
    .finally(() => {
      loader.end();
    });
};

// On save
const onSaveClick = async () => {
  NoteBodyRef.value.submit();
};

// Counts
const notesCount = computed(() => (props.note.notes ? props.note.notes.length : 0));
const openFollowupsCount = computed(() =>
  props.note.notes ? props.note.notes.filter((n) => n.status === 'Open').length : 0,
);
</script>

<style lang="scss">
.note {
  $self: &;
  $footer-height: rem(15px);
  $border-radius: rem(5px);

  // Main
  &__main {
    display: flex;
    align-items: flex-start;
    margin-bottom: rem(11px);

    &-container {
      width: 100%;
      position: relative;
    }
  }

  // Relation lines
  &__relation-line-top {
    width: rem(20px);
    height: calc(50% + 15px);
    left: rem(-21px);
    top: rem(-12px);
    position: absolute;
    border-bottom-left-radius: rem(8px);
    border-left: rem(1px) solid #ccc;
    border-bottom: rem(1px) solid #ccc;
  }

  &__relation-line-bottom {
    height: 55%;
    left: rem(-21px);
    bottom: rem(-3px);
    position: absolute;
    border-left: rem(1px) solid #ccc;
  }

  &--child:nth-last-child(2) {
    .note__relation-line-bottom {
      display: none;
    }
  }

  &__body {
    background-color: white;
    border-radius: rem(7.5px);
    border: rem(1px) solid color('gray', 3);
  }

  // Body
  &--parent > &__main > &__body {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  // Content (body)
  &__content {
    font-size: rem(16px);
    line-height: rem(24px);
  }

  .form-group--textarea-autoexpandable .form-group__input {
    border: none;
    border-radius: rem(6px);
    background-color: #eee;
  }

  // User (body)
  &__user {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    &-label {
      line-height: 1;
      min-width: rem(50px);
      max-width: rem(50px);
      margin-top: rem(3px);
      color: color('gray');
      font-size: rem(12px);
      text-align: center;
    }
  }

  // &--child &__user .user-circle {
  //   min-width: rem(24px);
  //   min-height: rem(24px);
  //   font-size: rem(13px);
  // }

  // Center (body)
  &__center {
    margin-left: rem(15px);
  }

  // Footer
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $footer-height;
    font-size: rem(10px);
    padding: 0 rem(7px);
    color: color('gray', 9);
    background-color: color('gray', 3);
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    &-left,
    &-right {
      display: flex;
      align-items: center;
    }

    &-divider {
      width: rem(1px);
      height: rem(10px);
      margin: 0 rem(6px);
      background-color: color('gray', 5);
    }

    .svg-icon--watch {
      color: color('gray', 6);
      width: rem(13px);
    }

    .tooltip--watch {
      text-align: right;
      font-size: rem(12px);
    }
  }

  // Padding left for subnotes and subnote form
  &__subnotes-container {
    padding-left: rem(30px);
  }

  // Subnote form
  .create-subnote-form {
    margin-bottom: rem(11px);
  }
}
</style>
