<template>
  <div class="attachments">
    <!-- Explorer -->
    <TreeExplorer
      :tree="tree"
      :client-id="clientId"
      :client-name="client ? client.name : null"
      :active-node="activeNode"
      @click:node="onOpen"
    />

    <!-- Panel -->
    <AttachmentsPanel
      :key="`${activeNode?.path}${refreshTrigger}`"
      :tree="tree"
      :node="activeNode"
      :policy="activePolicy"
      @click:open="onOpen"
      @click:back="onBack"
      @add:nodes="onAdd"
      @rename:node="onRename"
      @move:nodes="onDelete"
      @delete:nodes="onDelete"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { Client } from '@/js/Api/Client';
import { TreeNode, Tree } from '@/js/Api/Storage';
import Loader from '@/js/Helpers/Loader';
import useClientStore from '@/js/store/pinia/useClientStore';
import TreeExplorer from '@/js/Components/Attachments/TreeExplorer.vue';
import AttachmentsPanel from '@/js/Components/Attachments/AttachmentsPanel.vue';

const props = defineProps<{
  client: Client | null;
  clientId: number;
}>();

const store = useClientStore();

// Fetch attachments
const { ref: tree, promise } = store.swrAttachmentsTree({ id: props.clientId });
const refreshTrigger = ref(0);

// Active node
const activeNode = ref<TreeNode | null>(null);

// Active policy
const activePolicy = ref<TreeNode['policy'] | null>(null);

// Find a node
const findNode = (callback: (node: TreeNode) => boolean, currentTree: TreeNode[]): TreeNode | null => {
  for (let i = 0; i < currentTree.length; i++) {
    if (callback(currentTree[i])) return currentTree[i];
    const node = findNode(callback, currentTree[i].tree);
    if (node) return node;
  }
  return null;
};

// Preselect a folder
const route = useRoute();
const preselectFolder = (t: Tree) => {
  const policyId = Number(route.query.policy);
  if (route.query.policy) activeNode.value = findNode((node) => node.policy?.id === policyId, t);
  else activeNode.value = t[0];
};
if (tree.value) preselectFolder(tree.value);
promise.then((newTree) => preselectFolder(newTree));

// Refresh tree
const refreshTree = async () => {
  return store.swrAttachmentsTree({ id: props.clientId }).promise.then((t) => {
    activeNode.value = findNode(
      (node) => node.path === activeNode.value.path && node.name === activeNode.value.name,
      t
    );
    refreshTrigger.value++;
  });
};

const onOpen = (node: TreeNode) => {
  if (node.type === 'file') {
    window.open(`/api/storage/open?path=${encodeURIComponent(node.path)}`);
  } else {
    activePolicy.value = node.policy ?? null;
    activeNode.value = node;
  }
};

const onBack = () => {
  const parentNode = findNode(
    (node) =>
      node.tree.find((child) => child.path === activeNode.value.path && child.name === activeNode.value.name) !==
      undefined,
    tree.value as Tree
  ) as TreeNode;
  onOpen(parentNode);
};

const onAdd = (nodes: TreeNode[]) => {
  //activeNode.value.tree = activeNode.value.tree.concat(nodes);
  refreshTree().finally(() => Loader.end());
};

const onDelete = (paths: string[]) => {
  //activeNode.value.tree = activeNode.value.tree.filter((node) => !paths.includes(node.path));
  refreshTree().finally(() => Loader.end());
};

const onRename = () => {
  refreshTree().finally(() => Loader.end());
};
</script>

<style lang="scss">
.attachments {
  display: flex;
  align-items: flex-start;
  height: 100%;
  max-height: 100%;
  overflow: hidden;

  .tree-explorer {
    padding-right: rem(20px);
  }

  .attachments-panel {
    flex: 1;
  }
}
</style>
