<template>
  <Modal ref="ModalRef" class="ticket-merge-modal" :is-loading="loading" @modalhidden="targetTicket = null">
    <template #title> Merge the contents of this ticket with an existing one </template>

    <template #footer>
      <Btn @click="merge">Merge tickets</Btn>
    </template>

    <!-- Body -->
    <template #default="{ isShown }">
      <template v-if="isShown">
        <!-- Left panel -->
        <div class="ticket-merge-modal__left-panel">
          <p class="ticket-merge-modal__p">
            This ticket will be closed and all of it's contents will be moved to the ticket that you select in the right
            side
          </p>
          <p class="ticket-merge-modal__type-subject">
            <strong>{{ ticket.type }}</strong>
            {{ ticket.subject }}
          </p>
          <div class="ticket-merge-modal__notes-list">
            <NoteItem v-for="note in ticket.notes" :key="note.id" :note="note" />
          </div>
        </div>

        <!-- Arrow -->
        <SvgIcon class="ticket-merge-modal__arrow" name="arrow-right" />

        <!-- Right panel -->
        <div class="ticket-merge-modal__right-panel">
          <p class="ticket-merge-modal__p">
            Select the destination ticket. This is where the contents will be moved to
          </p>
          <TicketSelect
            v-model:ticket="targetTicket"
            :tickets="tickets"
            :filter-options="(option) => typeof option.value === 'object' && option.value.id !== ticket.id"
          />
          <div v-if="targetTicket" class="ticket-merge-modal__notes-list">
            <NoteItem v-for="note in targetTicket.notes" :key="note.id" :note="note" />
          </div>
        </div>
      </template>
    </template>
  </Modal>
</template>

<script setup>
import { ref } from 'vue';
import useClientStore from '@/js/store/pinia/useClientStore';
import { confirm, toast, toastError, toastSuccess } from '@/js/Helpers/Alert';
import Modal from '@/js/Common/Modal.vue';
import Btn from '@/js/Components/Btn.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import TicketApi from '@/js/Api/Ticket';
import NoteItem from './NoteItem/NoteItem.vue';
import TicketSelect from './TicketSelect.vue';

const props = defineProps({
  ticket: {
    type: Object,
    required: true,
  },
  tickets: {
    type: Array,
    required: true,
  },
});

const store = useClientStore();

const targetTicket = ref(null);

const ModalRef = ref(null);
const loading = ref(false);
const merge = async () => {
  if (!targetTicket.value) {
    toast({ title: 'Select a target ticket', icon: 'warning' });
    return false;
  }

  const result = await confirm({
    icon: 'warning',
    text: "Are you sure? This action can't be reversed!",
    confirmButtonText: 'Merge tickets',
  });
  if (!result.isConfirmed) return false;

  loading.value = true;
  TicketApi.merge({
    mergingTicketId: props.ticket.id,
    targetTicketId: targetTicket.value.id,
  })
    .then(({ data }) => {
      ModalRef.value.hide();
      store.updateTicket(data.merged_ticket);
      store.updateTicket(data.target_ticket);
      store.currentTicketId = data.target_ticket.id;
      toastSuccess('Tickets have been successfully merged');
    })
    .catch(() => {
      toastError('An error occurred while merging tickets');
    })
    .finally(() => {
      loading.value = false;
    });

  return true;
};
</script>

<style lang="scss">
.ticket-merge-modal {
  .modal__dialog {
    width: 75%;
  }

  .modal__content {
    min-height: 85%;
  }

  .modal__body {
    display: flex;
    justify-content: space-between;
  }

  &__arrow {
    align-self: center;
    height: rem(50px);
    width: rem(50px);
  }

  &__left-panel,
  &__right-panel {
    display: flex;
    flex-direction: column;
    width: 45%;
    max-width: 45%;
    background-color: #eee;
    border-radius: rem(17px);
    padding: rem(17px);
    overflow: hidden;
  }

  &__p {
    margin: 0;
    min-height: rem(70px);
    color: #666;
  }

  &__type-subject {
    line-height: 1.3;
    max-width: 100%;
    overflow: hidden;
    /* stylelint-disable value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: rem(50px);
    margin-bottom: rem(20px);
  }

  .dropdown--tickets-search {
    margin: 1.5em;

    .form-group-display {
      background-color: white;
    }
  }

  &__notes-list {
    overflow: auto;
    max-height: 86%;
    padding-right: rem(10px);
  }

  .note__sticky-right {
    display: none;
  }
}
</style>
