import { z } from 'zod';

export const LeadDistributionListSchema = z.object({
  id: z.number(),
  name: z.string(),
  last_assigned_to: z.number().nullable(),
  admin_only: z.boolean(),
  created_at: z.string(),
  updated_at: z.string(),

  // Appends
  model_type: z.literal('Lead distribution list'),
});

export type LeadDistributionList = z.infer<typeof LeadDistributionListSchema>;
