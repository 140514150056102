<template>
  <!-- Notes -->
  <PanelNotes v-if="panel === 'PanelNotes'" :ticket="ticket" :tickets="tickets" @back="emit('ticket:selected', null)" />

  <!-- Tickets -->
  <PanelTickets
    v-if="panel === 'PanelTickets'"
    :tickets="tickets"
    :client-id="clientId"
    @ticket:click="(clickedTicket) => emit('ticket:selected', clickedTicket)"
  />
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { Ticket } from '@/js/Api/Ticket';
import PanelNotes from './PanelNotes/PanelNotes.vue';
import PanelTickets from './PanelTickets/PanelTickets.vue';

interface Props {
  ticket: Ticket | null;
  tickets: Ticket[];
  clientId: number;
}

const props = defineProps<Props>();

const emit = defineEmits(['ticket:selected']);

const panel = ref('PanelTickets');

// Switch panels when ticket changes
watch(
  () => props.ticket,
  () => {
    panel.value = props.ticket ? 'PanelNotes' : 'PanelTickets';
  }
);
</script>
