import { z } from 'zod';
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';

export const TicketUnsavedTextSchema = z.object({
  id: z.number(),
  user_id: z.number(),
  client_id: z.number().nullable(),
  note_id: z.number().nullable(),
  text: z.string(),
  notification_received: z.boolean(),
  created_at: z.string(),
  updated_at: z.string(),
});

export type TicketUnsavedText = z.infer<typeof TicketUnsavedTextSchema>;

export default {
  //
};
