import z from 'zod';
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';
import { queryBuilderToQueryString } from '@/js/Helpers';

export const FinanceCompanySchema = z.object({
  id: z.number(),
  name: z.string(),
  url: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),

  // Appends
  model_type: z.literal('Finance company'),
});

export type FinanceCompany = z.infer<typeof FinanceCompanySchema>;

// ***************************************************
// Index
// ***************************************************

type IndexArgs = {
  queryBuilder: {
    page: number;
    filters?: {
      name?: string;
    };
  };
};

async function index({ queryBuilder }: IndexArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/finance-companies?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return response;
}

// ***************************************************
// All
// ***************************************************

type AllArgs = {
  queryBuilder: {
    filters?: {
      id?: number[];
      name?: string;
    };
  };
};

async function all({ queryBuilder }: AllArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/finance-companies/all?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: FinanceCompanySchema.array(),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Find
// ***************************************************

type FindArgs = {
  id: number;
};

async function find({ id }: FindArgs) {
  const response = await axios.get(`/api/finance-companies/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: FinanceCompanySchema,
    })
    .parseAsync(response.data);
}

function create(form) {
  return axios.post('/api/finance-companies', form);
}

function update(form, id) {
  return axios.post(`/api/finance-companies/${id}`, { _method: 'PUT', ...form });
}

export default {
  index,
  all,
  find,
  create,
  update,
};
