import { z } from 'zod';
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';
import { queryBuilderToQueryString } from '@/js/Helpers';
import { User, UserSchema } from './User';
import { QuoteTeam, QuoteTeamSchema } from './QuoteTeam';

export type TicketNoteAssignee = User | QuoteTeam;

export const TicketNoteAssigneeSchema = z.union([UserSchema, QuoteTeamSchema]);

const TicketNoteAssigneeModelTypeSchema = z.enum(['User', 'Quote team']);

export const TicketNoteAssigneeValueSchema = z.object({
  id: z.number(),
  model_type: TicketNoteAssigneeModelTypeSchema,
});

export type TicketNoteAssigneeValue = z.infer<typeof TicketNoteAssigneeValueSchema>;

// ***************************************************
// Index
// ***************************************************

type IndexArgs = {
  queryBuilder: {
    page: number;
    filters?: {
      name?: string;
    };
  };
};

async function index({ queryBuilder }: IndexArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/ticket-note-assignees?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  // FIXME:
  return response;
}

// ***************************************************
// All
// ***************************************************

type AllArgs = {
  queryBuilder: {
    filters?: {
      id?: string[];
    };
  };
};

async function all({ queryBuilder }: AllArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/ticket-note-assignees/all?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  // FIXME:
  return response.data;
}

// ***************************************************
// Find
// ***************************************************

type FindArgs = {
  id: string;
};

async function find({ id }: FindArgs) {
  const response = await axios.get(`/api/ticket-note-assignees/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: TicketNoteAssigneeSchema,
    })
    .parseAsync(response.data);
}

export default {
  index,
  all,
  find,
};
