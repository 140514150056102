<template>
  <div>
    <!-- Header -->
    <ProcessingHeader :policy="policy" />

    <div class="divider"></div>

    <!-- Policy form -->
    <PolicyForm :policy="policy" :client="client" :can-edit="canEdit" />

    <!-- Client form -->
    <PolicyClientForm :client="client" :can-edit="canEdit" />

    <!-- Policy details -->
    <PolicyDetails :policy="policy" :can-edit="canEdit" :client="client" />

    <!-- Processing details -->
    <ProcessingDetails />
  </div>
</template>

<script>
import { inject } from 'vue';
import PolicyForm from '@/js/Components/Policies/PolicyForm.vue';
import PolicyClientForm from '@/js/Components/Policies/PolicyClientForm.vue';
import PolicyDetails from '@/js/Components/Policies/PolicyDetails.vue';
import ProcessingHeader from './ProcessingHeader.vue';
import ProcessingDetails from './ProcessingDetails.vue';

export default {
  components: {
    ProcessingDetails,
    PolicyDetails,
    PolicyForm,
    ProcessingHeader,
    PolicyClientForm,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
    policy: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return {
      canEdit: inject('canEditClient'),
    };
  },
};
</script>

<style lang="scss" scoped>
.divider {
  margin-top: rem(17px);
  margin-bottom: rem(23px);
  background: #cccccc;
  height: rem(1px);
}
</style>
