<template>
  <Dropdown v-if="notifications" placement="bottom" container-class="notifications">
    <template v-slot:trigger>
      <div v-if="unreadCount" class="count">
        <div class="circle">
          <div class="number">
            {{ unreadCount }}
          </div>
        </div>
      </div>
      <img src="/img/icons/notifications-1-24px.svg"/>
    </template>
    <template v-if="notifications.length" v-slot:menu>
      <router-link
        v-for="(n, i) in notifications"
        :to="n.data.link ? n.data.link : '#'"
        :class="['dropdown-item', !n.read_at ? 'unread' : '']"
        @click="readSingle(n)">
          <div class="message">
            {{ n.data.message.shorten(75) }}
          </div>
          <div class="details">
            {{ new Date(n.created_at).toDateTime() }}
          </div>
      </router-link>
      <a href="#" class="btn btn-primary" @click.prevent="clearAll">
        Clear notifications
      </a>
    </template>
  </Dropdown>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useSWR from '@/js/Composables/useSWR.js';
import useEcho from '@/js/Composables/useEcho.js';
import useAlert from '@/js/Composables/useAlert.js';
import useLoader from '@/js/Composables/useLoader.js';
import Dropdown from '@/js/Common/Dropdown.vue';

export default {
  components: {
    Dropdown
  },

  setup() {

    // Data
    const
      { notifications } = useSWR('global/fetchNotifications', 'global/notifications'),
      unreadCount = computed(() => notifications.value ? notifications.value.filter(n => !n.read_at).length : undefined);

    // Actions
    const
      { toast } = useAlert(),
      { loader } = useLoader(),
      store = useStore(),
      router = useRouter(),
      notify = (notification) => {
        store.commit('global/unshiftNotification', notification);
        toast({ timer: 6000, icon: 'info', title: notification.data.message });
      },
      readSingle = (notification) => {
        axios.post(`/notifications/${notification.id}/read`, { _method: 'PUT' })
          .then(() => {
            if (!notification.read_at)
              notification.read_at = new Date().toDateTime;
          });
      },
      clearAll = () => {
        loader.start();
        axios.post('/notifications/clear', { _method: 'DELETE' })
          .then(response => {
            notifications.value.splice(0, notifications.value.length);
            toast({ title: 'Notifications cleared.', icon: 'success' });
          })
          .finally(response => {
            loader.end();
          });
      };

    const
      me = store.state.global.me,
      channel = useEcho()
        .private('users.' + me.id)
        .stopListening('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated')
        .notification(notify);

    return {
      notifications,
      unreadCount,
      readSingle,
      clearAll
    };
  }
}
</script>

<style lang="scss" scoped>
  .dropdown.notifications {
    display: flex;
    align-items: center;
    margin-right: rem(30px);

    // Tigger
    :deep(.dropdown-trigger) {
      position: relative;
      .count {
        @include center-content;
        position: absolute;
        top: rem(-14px);
        right: rem(-14px);
        background-color: color('primary');
        border-radius: 50%;
        width: rem(22.5px);
        height: rem(22.5px);
        .circle {
          @include center-content;
          width: 80%;
          height: 80%;
          background-color: #FA3C03;
          border-radius: 50%;
          .number {
            color: white;
            font-weight: bold;
            font-size: rem(15px);
            line-height: 0;
          }
        }
      }
      img {
        display: block;
      }
    }

    // Menu
    :deep(.dropdown-menu) {
      &[data-show] {
        width: rem(400px);
        max-height: 70vh;
        overflow: auto;
      }
      .dropdown-item {
        white-space: initial;
        &.unread .message {
          font-weight: bold;
        }
        .details {
          font-size: rem(14px);
          text-align: right;
        }
      }
      .btn {
        width: 100%;
        display: block;
        text-align: center;
        border-radius: 0;
      }
    }

  }
</style>
