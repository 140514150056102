import axios from 'axios';

export default {
  namespaced: true,

  state: () => ({}),

  actions: {
    // Client
    fetchClient({ commit }, { clientID }) {
      return axios.get(`/api/clients/${clientID}`).then((response) => {
        commit('setClient', response.data.client);
      });
    },

    // Addresses
    fetchAddresses({ commit }, { clientID }) {
      return axios.get(`/api/clients/${clientID}/addresses`).then((response) => {
        commit('setAddresses', response.data.addresses);
      });
    },

    // Contacts
    fetchContacts({ commit }, { clientID }) {
      return axios.get(`/api/clients/${clientID}/contacts`).then((response) => {
        commit('setContacts', response.data.contacts);
      });
    },

    // Emails
    fetchEmails({ commit }, { clientID }) {
      return axios.get(`/api/clients/${clientID}/emails`).then((response) => {
        commit('setEmails', response.data.emails);
      });
    },

    // Related clients
    fetchRelatedClients({ commit }, { clientID }) {
      return axios.get(`/api/clients/${clientID}/related-clients`).then((response) => {
        commit('setRelatedClients', response.data.relatedClients);
      });
    },

    fetchPolicies({ commit }, { clientID, include = [] }) {
      return axios
        .get(`/api/clients/${clientID}/policies${include.length ? `?include=${include.join(',')}` : ''}`)
        .then((response) => {
          commit('setPolicies', response.data.policies);
        });
    },

    fetchPolicy({ commit }, { clientID, policyID, include = [] }) {
      return axios
        .get(`/api/clients/${clientID}/policies/${policyID}${include.length ? `?include=${include.join(',')}` : ''}`)
        .then((response) => {
          commit('setPolicy', response.data.policy);
        });
    },

    // Referral source
    fetchProducer({ commit }, { clientID }) {
      return axios.get(`/api/clients/${clientID}/producer`).then((response) => {
        commit('setProducer', response.data.producer);
      });
    },

    // Referral source
    fetchReferralSource({ commit }, { clientID }) {
      return axios.get(`/api/clients/${clientID}/referral-source`).then((response) => {
        commit('setReferralSource', response.data.referralSource);
      });
    },

    // Created by user
    fetchCreatedByUser({ commit }, { userID }) {
      return axios.get(`/api/users/${userID}`).then((response) => {
        commit('setCreatedByUser', response.data.data);
      });
    },

    // Attachments
    fetchAttachments({ commit }, { clientID }) {
      return axios.get(`/api/clients/${clientID}/attachments`).then((response) => {
        commit('setAttachments', response.data.attachments);
      });
    },
  },

  mutations: {
    // Client
    setClient(state, client) {
      state.client = client;
    },

    // Addresses
    setAddresses(state, addresses) {
      state.addresses = addresses;
    },
    setAddressField(state, { addressID, property, value }) {
      const addressInState = state.addresses.find((a) => a.id === addressID);
      addressInState[property] = value;
    },
    deleteAddress(state, addressID) {
      const i = state.addresses.findIndex((a) => a.id === addressID);
      state.addresses.splice(i, 1);
    },

    // Contacts
    setContacts(state, contacts) {
      state.contacts = contacts;
    },
    setContactField(state, { contactID, property, value }) {
      const contactInState = state.contacts.find((a) => a.id === contactID);
      contactInState[property] = value;
    },
    deleteContact(state, contactID) {
      const i = state.contacts.findIndex((a) => a.id === contactID);
      state.contacts.splice(i, 1);
    },
    pushContact(state, newContact) {
      state.contacts.push(newContact);
    },
    setContactPhoneNumber(state, { contactID, phoneID, number }) {
      const contactInState = state.contacts.find((c) => c.id === contactID);
      const phoneInState = contactInState.phones.find((p) => p.id === phoneID);
      phoneInState.number = number;
    },
    setContactEmailAddress(state, { contactID, emailID, address }) {
      const contactInState = state.contacts.find((c) => c.id === contactID);
      const emailInState = contactInState.emails.find((p) => p.id === emailID);
      emailInState.address = address;
    },

    // Emails
    setEmails(state, emails) {
      state.emails = emails;
    },

    // Related clients
    setRelatedClients(state, relatedClients) {
      state.relatedClients = relatedClients;
    },

    setPolicies(state, policies) {
      state.policies = policies;
    },

    setPolicy(state, policy) {
      state.policy = policy;
    },

    // Producer
    setProducer(state, producer) {
      state.producer = producer;
      if (state.client) {
        state.client.producer_id = producer.id;
        state.client.producer_type = producer.type;
      }
    },

    // Referral source
    setReferralSource(state, referralSource) {
      state.referralSource = referralSource;
    },

    // Created by user
    setCreatedByUser(state, user) {
      state.createdByUser = user;
    },

    // Client name
    updateClientName(state) {
      let clientName;
      if (state.client.type === 'Organization') {
        clientName = state.client.company_name;
        state.client.first_name = null;
        state.client.middle_name = null;
        state.client.last_name = null;
      } else {
        clientName =
          (state.client.first_name ? state.client.first_name : '') +
          (state.client.middle_name ? ` ${state.client.middle_name}` : '') +
          (state.client.last_name ? ` ${state.client.last_name}` : '');
        state.client.company_name = null;
      }

      clientName = clientName.toUpperCase();

      state.client.name = clientName;
    },
    resetClientName(state) {
      state.client.name = null;
      state.client.first_name = null;
      state.client.middle_name = null;
      state.client.last_name = null;
      state.client.company_name = null;
    },

    // Prefill contact name
    prefillContactName(state) {
      if (state.client.type !== 'Individual') return;

      let i = state.contacts.findIndex((c) => c.is_primary);
      i = i !== -1 ? i : 0;

      if (!state.contacts[i].first_name) state.contacts[i].first_name = state.client.first_name;
      if (!state.contacts[i].last_name) state.contacts[i].last_name = state.client.last_name;
    },

    // Attachments
    setAttachments(state, attachments) {
      state.attachments = attachments;
    },
    refreshAttachments(state) {
      let policyName;
      if (state.policies)
        state.policies.forEach((policy) => {
          if (!state.attachments[`policy_${policy.id}`])
            state.attachments[`policy_${policy.id}`] = { policy, files: [] };

          if (policy.is_incomplete) policyName = '(Incomplete policy)';
          else
            policyName = `${(policy.number ? `${policy.number} - ` : '') + policy.type.abbreviation} - ${
              policy.effective_date
            }${policy.expiration_date ? ` - ${policy.expiration_date}` : ''}`;
          state.attachments[`policy_${policy.id}`].policy.name = policyName;
        });
    },
  },
};
