import axios, { AxiosError } from 'axios';
import { z } from 'zod';
import * as Sentry from '@sentry/vue';
import { PolicySchema } from './Policy';

export type Attachment = {
  name: string;
  type: string;
  base64: string;
};

const Permissions = z.object({
  upload: z.boolean(),
  create_folder: z.boolean(),
  rename: z.boolean(),
  move: z.boolean(),
  delete: z.boolean(),
});

const NodeSchema = z.object({
  name: z.string(),
  path: z.string().nullable(),
  type: z.enum(['folder', 'file']),
  is_root: z.union([z.boolean(), z.undefined()]), // @FIXME this should be removed
  permissions: Permissions,
  created_at: z.string().nullable(),
  policy: PolicySchema.pick({
    id: true,
    number: true,
    effective_date: true,
    expiration_date: true,
  }).nullish(),
});
export type Node = z.infer<typeof NodeSchema>;
export type TreeNode = z.infer<typeof NodeSchema> & {
  tree: TreeNode[];
};
const TreeNodeSchema: z.ZodType<TreeNode> = NodeSchema.extend({
  tree: z.lazy(() => z.array(TreeNodeSchema)),
});
const TreeSchema = z.array(TreeNodeSchema);
export type Tree = z.infer<typeof TreeSchema>;

export default {
  async open({ path }: { path: string }) {
    return axios.get('/api/storage', { params: { path } });
  },

  async download({ path }: { path: string }) {
    return axios
      .get('/api/storage/open', {
        responseType: 'blob',
        params: {
          path,
        },
      })
      .then((response) => {
        const regexMatches = response.headers['content-disposition'].match(/filename="(.+)"/);
        const filename = regexMatches ? regexMatches[1] : 'file';
        const href = URL.createObjectURL(response.data);
        const linkEl = document.createElement('a');
        linkEl.setAttribute('download', filename);
        linkEl.href = href;
        document.body.appendChild(linkEl);
        linkEl.click();
        document.body.removeChild(linkEl);
        URL.revokeObjectURL(href);
      });
  },

  async tree({ path }: { path: string }) {
    const response = await axios.get('/api/storage/tree', { params: { path } }).catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

    return z.object({ data: TreeNodeSchema }).parseAsync(response.data);
  },

  async clientTree({ clientId }: { clientId: number }) {
    const response = await axios.get('/api/storage/client-tree', { params: { client_id: clientId } }).catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

    return z.object({ data: TreeSchema }).parseAsync(response.data);
  },

  async createDirectory({ path, name }: { path: string; name: string }) {
    const response = await axios.post('/api/storage/create-directory', { path, name }).catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

    return z.object({ data: TreeNodeSchema }).parseAsync(response.data);
  },

  async upload({ path, files }: { path: string; files: Attachment[] | null }) {
    const response = await axios.post('/api/storage/upload', { path, files }).catch((error: AxiosError) => {
      Sentry.captureException(error);
      throw error;
    });

    return z.object({ data: z.array(TreeNodeSchema) }).parseAsync(response.data);
  },

  async delete({ paths }: { paths: string[] }) {
    const response = await axios.post('/api/storage/delete', { _method: 'DELETE', paths }).catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

    return z.literal('').parseAsync(response.data);
  },

  async rename({ path, name }: { path: string; name: string }) {
    const response = await axios.put('/api/storage/rename', { path, name }).catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

    return z.object({ data: z.string() }).parseAsync(response.data);
  },

  async move({ pathsMap }: { pathsMap: { [p: string]: string } }) {
    const response = await axios.put('/api/storage/move', { paths_map: pathsMap }).catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

    return z.literal('').parseAsync(response.data);
  },
};
