<template>
  <div class="original-input">
    <label class="original-input__label">Original input</label>
    <div v-if="createdByUser" class="original-input__user">{{ createdByUser.name }}</div>
    <div class="original-input__datetime">{{ new Date(client.created_at).toDateTime() }}</div>
  </div>
</template>

<script setup lang="ts">
import useClientStore from '@/js/store/pinia/useClientStore';

const props = defineProps({
  client: {
    type: Object,
    required: true,
  },
});

const { ref: createdByUser } = useClientStore().swrCreatedByUser({
  id: props.client.user_id,
});
</script>

<style lang="scss" scoped>
.original-input {
  &__label {
    @include form-label;
  }

  &__user {
    line-height: rem(15px);
  }
}
</style>
