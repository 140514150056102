<template>
  <Btn class="split-button" :color="color" :disabled="disabled" @click="emit('click:button')">
    <!-- Selected option -->
    <div class="split-button__text" role="button">
      {{ text }}
    </div>

    <!-- Divider -->
    <div class="split-button__div"></div>

    <!-- Options -->
    <Dropdown class="split-button__dropdown" :disabled="disabled" @click.stop>
      <template #trigger>
        <SvgIcon name="caret-down" />
      </template>
      <template #menu>
        <DropdownItem
          v-for="(option, i) in options"
          :key="i"
          :label="option.label"
          @click="emit('click:option', option)"
        />
      </template>
    </Dropdown>
  </Btn>
</template>

<script setup lang="ts">
import Btn, { Props as BtnProps } from '@/js/Components/Btn.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import Dropdown from '@/js/Common/Dropdown.vue';
import DropdownItem from '@/js/Common/DropdownItem.vue';

type Option = {
  value: string;
  label: string;
};

interface Props {
  text: string;
  color: BtnProps['color'];
  options: Option[];
  disabled?: boolean;
}

interface Emits {
  (e: 'click:button'): void;
  (e: 'click:option', option: Option): void;
}

defineProps<Props>();

const emit = defineEmits<Emits>();
</script>

<style lang="scss">
$padding: 0 1em;

.split-button {
  padding: 0;
  position: relative;

  &__text {
    padding: $padding;
  }

  &__div {
    background: #cccccc;
    width: rem(1px);
    align-self: stretch;
  }

  .dropdown {
    height: 100%;
    position: static;
    align-items: stretch;
    &__trigger {
      color: inherit;
      height: 100%;
      padding: $padding;
      align-items: center;
      .svg-icon {
        width: 1em;
        height: 1em;
      }
    }
    &__menu {
      transform: none !important;
      left: 0 !important;
      top: 100% !important;
      width: 100%;
      .dropdown-item {
        padding: 1.2em 1em;
      }
    }
  }
}
</style>
