import { STATUSES, SUBMISSION_METHODS } from '@/js/Api/Quote';

export { STATUSES, SUBMISSION_METHODS } from '@/js/Api/Quote';

export type Status = (typeof STATUSES)[number];
export const STATUS_OPTIONS = STATUSES.map((status) => ({
  label: status,
  value: status,
}));
export type StatusOption = (typeof STATUS_OPTIONS)[number];

export type SUBMISSION_METHOD = (typeof SUBMISSION_METHODS)[number];
export const SUBMISSION_METHOD_OPTIONS = SUBMISSION_METHODS.map((method) => ({
  label: method,
  value: method,
}));
export type SUBMISSION_METHOD_OPTION = (typeof STATUS_OPTIONS)[number];

export default {
  STATUSES,
  StatusOptions: STATUS_OPTIONS,
  SUBMISSION_METHODS,
  SUBMISSION_METHOD_OPTIONS,
};
