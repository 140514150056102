<template>
  <FormGroup
    v-model="quotingSpecialist"
    type="ajax-select"
    label-method="name"
    label="Quoting specialist"
    :disabled="disabled"
    :on-select="onSelect"
    :fetch-options="fetchOptions"
  />
</template>

<script setup>
import clientApi from '@/js/Api/Client';
import settingsApi from '@/js/Api/Settings';
import FormGroup from '@/js/Common/Form/FormGroup/FormGroup.vue';
import useClientStore from '@/js/store/pinia/useClientStore';

const props = defineProps({
  clientId: {
    type: Number,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: true,
  },
});

const clientStore = useClientStore();
const { ref: quotingSpecialist } = clientStore.swrQuotingSpecialist({ id: props.clientId });

const fetchOptions = (query, page) => {
  return settingsApi.quotingSpecialists.index({
    queryBuilder: {
      page,
      filters: {
        search: query,
      },
    },
  });
};

const onSelect = (option) => {
  return clientApi.quotingSpecialist.update({
    id: props.clientId,
    data: {
      id: option ? option.id : null,
      model_type: option ? option.model_type : null,
    },
  });
};
</script>
