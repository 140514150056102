<template>
  <div class="opened-clients">
    <div class="text">Open clients</div>

    <!-- Close all button -->
    <Btn v-if="list.length" class="opened-clients__btn-close-all" variant="outline" @click="closeAll">
      Close All
      <i class="fas fa-times ml-1" />
    </Btn>

    <!-- List -->
    <div v-for="row in list" :key="row.id" class="opened-clients__link-wrapper">
      <!-- Link -->
      <RouterLink :to="`/clients/${row.client_id}`" class="opened-clients__link">
        <template v-if="clientId === row.client_id">
          {{ clientName }}
        </template>
        <template v-else>
          {{ row.client_name ? row.client_name : '(Draft)' }}
        </template>
      </RouterLink>

      <!-- Close button -->
      <i class="fas fa-times icon" @click.prevent="closeOne(row)"></i>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { RouterLink, useRoute, useRouter } from 'vue-router';
import UserApi from '@/js/Api/User';
import ClientApi from '@/js/Api/Client';
import { prompt, confirm, toast } from '@/js/Helpers/Alert';
import Loader from '@/js/Helpers/Loader';
import Btn from '@/js/Components/Btn.vue';
import SvgIcon from '@/js/Components/SvgIcon.vue';
import useClientStore from '@/js/store/pinia/useClientStore';

const list = ref([]);
const route = useRoute();
const router = useRouter();
const clientId = computed(() => Number(route.params.clientId));
const isClientRoute = computed(() => route.matched[0].name === 'Client');
const clientStore = useClientStore();
const clientName = computed(() => clientStore.client?.name || '(Draft)');

// Fetch list
UserApi.openedClients.all().then((response) => {
  list.value = response.data.data;
});

// Open client and refetch list
watch(
  clientId,
  () => {
    if (clientId.value)
      ClientApi.open({ clientId: clientId.value }).then((response) => {
        list.value = response.data.data;
      });
  },
  { immediate: true },
);

const closeAll = async () => {
  const result = await prompt({
    title: 'Are you sure?',
    text: 'Type in "close all" to confirm',
  });

  if (!result.value || result.value.toLowerCase() !== 'close all') return;

  Loader.start();
  UserApi.openedClients
    .closeAll()
    .then((response) => {
      list.value = response.data.data;
      if (isClientRoute.value) {
        toast({ title: 'All clients have been closed. Redirecting...', icon: 'success' });
        setTimeout(() => {
          if (isClientRoute.value) router.push({ path: '/dashboard' });
        }, 2000);
      } else {
        toast({ title: 'All clients have been closed.', icon: 'success' });
      }
    })
    .catch(() => {
      toast({ title: 'An error occurred.', icon: 'error' });
    })
    .finally(() => Loader.end());
};

const closeOne = async (row) => {
  const isClientOpened = isClientRoute.value && clientId.value === row.client_id;

  Loader.start();
  const openedClientResponse = await UserApi.openedClients.find({ clientId: row.client_id });
  const openedClient = openedClientResponse.data.data;
  Loader.end();

  // If client is empty, delete
  if (openedClient.isEmpty) {
    const promptResult = await prompt({
      confirmButtonText: 'Confirm',
      title: 'Client will be deleted',
      text: 'Empty clients are deleted after closing. Please type "delete" to confirm.',
    });
    if (!promptResult.value || promptResult.value.toLowerCase() !== 'delete') return;

    // Else check if there is no activity
  } else if (!openedClient.note_activity || !openedClient.followup_activity) {
    let title = '';
    if (!openedClient.note_activity && !openedClient.followup_activity)
      title = 'No note activity, no followup activity';
    else if (!openedClient.note_activity && openedClient.followup_activity) title = 'No note activity';
    else if (openedClient.note_activity && !openedClient.followup_activity) title = 'No followup activity';

    const popupResult = await confirm({
      title,
      text: false,
      icon: false,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
    });

    if (!popupResult.isConfirmed) return;
  }

  // Close client
  Loader.start();
  ClientApi.close({ clientId: row.client_id })
    .then((response) => {
      list.value = response.data.data;

      if (openedClient.isEmpty) clientStore.deleted = true;

      if (isClientOpened)
        setTimeout(() => {
          const isClientStillOpened = isClientRoute.value && clientId.value === row.client_id;
          if (isClientStillOpened) router.push({ path: '/dashboard' });
        }, 2000);

      toast({
        icon: 'success',
        title: `Client has been ${openedClient.isEmpty ? 'deleted' : 'closed'}.${
          isClientOpened ? ' Redirecting...' : ''
        }`,
      });
    })
    .catch(() => {
      toast({ title: 'An error occurred.', icon: 'error' });
    })
    .finally(() => {
      Loader.end();
    });
};
</script>

<style lang="scss">
.opened-clients {
  padding-left: rem(20px);

  > .text {
    color: color('text', 3);
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: rem(20px);
  }

  &__btn-close-all {
    margin-bottom: rem(10px);
    transform: translateX(rem(-10px));
  }

  &__link-wrapper {
    margin-bottom: rem(14px);
    position: relative;
    display: flex;
    justify-content: space-between;

    a {
      display: block;
      text-decoration: none;
      text-transform: capitalize;
      color: color('text', 3);
      font-size: rem(16px);
      line-height: rem(19px);
      padding-right: rem(10px);

      &.active::before {
        content: '';
        width: rem(10px);
        height: rem(10px);
        position: absolute;
        top: rem(5px);
        left: rem(-17px);
        background-color: color('gray', 3);
        border-radius: 50%;
      }
    }

    .icon {
      opacity: 0;
      color: color('gray', 3);
      cursor: pointer;
    }

    &:hover .icon {
      opacity: 1;
    }
  }
}
</style>
